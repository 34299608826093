import React from "react"
import { observer } from "mobx-react-lite"

import { UserAndGroup } from "@framework/types/user"
import { useStore } from "@store"
import useDebounce from "@components/hooks/useDebounce"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { SearchContext } from "@components/prototypes/SearchContext"
import { searchBy } from "@utils/optionsUtils"
import { getFullName } from "@pages/questions/utils"

import EntityCard from "./EntityCard"

import styles from "./ParticipantList.module.sass"

export interface SolutionParticipantListProps {
  loading?: boolean
  isSelected: (value: UserAndGroup) => boolean
  onSelect: (value: UserAndGroup) => void
}

const SolutionParticipantList: React.FC<SolutionParticipantListProps> =
  observer(({ loading = false, onSelect, isSelected }) => {
    const {
      solutionsStore: { permissions },
    } = useStore()

    const searchContext = React.useContext(SearchContext)
    const query = searchContext?.query ?? ""

    const { debouncedValue: searchQuery, setDebounce } = useDebounce()

    const isLoading = permissions.isLading || loading

    React.useEffect(() => {
      setDebounce(query)
    }, [query])

    const entities = React.useMemo(() => {
      return searchBy(
        permissions.permittedEntities,
        searchQuery,
        (entity) => getFullName(entity) ?? "Deactivated user"
      )
    }, [searchQuery, permissions.permittedEntities])

    return (
      <div className={styles.root}>
        {!entities.length ? (
          isLoading ? (
            <Loader fluid size="large" />
          ) : searchQuery ? (
            <NotFound fluid>No user or group found by query</NotFound>
          ) : (
            <NotFound fluid>Nobody has access yet</NotFound>
          )
        ) : (
          <div className={styles.list}>
            {entities.map((user) => {
              const active = isSelected(user)
              return (
                <EntityCard
                  key={user.id}
                  user={user}
                  toRemove
                  isSelected={active}
                  onSelect={onSelect}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  })

export default SolutionParticipantList
