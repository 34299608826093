import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { initArray } from "@utils/numberUtils"

import { useSolution } from "../ActiveSolutionContext"

import styles from "./SuggestedQuestions.module.sass"

type SuggestedQuestionsProps = {
  className?: string
  moveDown?: boolean
}

const SuggestedQuestions: React.FC<SuggestedQuestionsProps> = observer(
  ({ className, moveDown }) => {
    const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

    const { solution } = useSolution()
    const { avatar } = useActiveAvatar()

    const {
      searchSuggestionStore: { isQuestionsLoading },
      solutionsStore: { appliedFilters },
      contentManagerStore: { companies },
    } = useStore()

    const { factFinderSolutionController } = useController()

    const handleClick = (query: string) => {
      if (solution?.id)
        factFinderSolutionController.search(
          query,
          avatar,
          solution.id,
          appliedFilters,
          companies
        )
    }

    const getQuestions = () => (
      <div className={clsx(styles.questions)}>
        {solution?.suggestedQuestions?.map((question) => (
          <div
            key={question}
            className={styles.question}
            onClick={() => handleClick(question)}
            tabIndex={0}
            role="button"
            onKeyPress={() => {}}
          >
            <Text
              variant="h6"
              className={styles.questionOverflow}
              color="text70Color"
            >
              {question}
            </Text>
          </div>
        ))}

        {isQuestionsLoading &&
          initArray(2, () => (
            <Skeleton
              count={1}
              minWidth={100}
              lineHeight={38}
              spacing={16}
              rounded
            />
          ))}
      </div>
    )

    if (solution?.suggestedQuestions?.length)
      return (
        <div
          className={clsx(styles.root, className, { [styles.down]: moveDown })}
        >
          <div className={styles.questionHeader}>
            <Text variant="h3" weight="bold" align="start">
              Sample Questions
            </Text>
          </div>
          {isMobile ? (
            <div className={styles.questionsWrapper}>{getQuestions()}</div>
          ) : (
            getQuestions()
          )}
        </div>
      )

    return null
  }
)

export default SuggestedQuestions
