import React from "react"
import { useNavigate } from "react-router-dom"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import mainRoutes from "@root/main.routes"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"

interface HeaderProps {
  className?: string
}

export const Header: React.FC<HeaderProps> = ({ className }) => {
  const navigate = useNavigate()

  return (
    <EntityPageHeaderLayout
      className={className}
      left={
        <BackButton
          size="medium"
          onClick={() => navigate(mainRoutes.expertsMenu())}
        >
          Questions
        </BackButton>
      }
    >
      <SearchContextInput />
    </EntityPageHeaderLayout>
  )
}

export default Header
