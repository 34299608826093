import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import moment from "moment"
import get from "lodash/get"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import { useAppColors } from "@components/theme/AppColorSchemeProvider"
import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"
import { renderDatePeriodTupleToRange, sortDatePeriodTuple } from "@utils/date"
import Loader from "@components/ui/Loader/BarLoader"
import { defaultDateRangePresets } from "@framework/constants/common"
import Info from "@components/ui/Tooltip/Info"

import type { ChartFractionMetaData } from "./utils"
import APIUsageTrendChart from "./APIUsageTrendChart"
import ExportTrendDataButton from "./ExportTrendDataButton"

import styles from "./APIUsageTrend.module.sass"

const TITLE = "Usage Trend"
const DESCRIPTION =
  "Day wise trend of number of API calls and error rate. Adjust date range or export chart data as per requirement."

export interface APIUsageTrendsProps {}

export const APIUsageTrends: React.FC<APIUsageTrendsProps> = observer(() => {
  const theme = useAppColors()

  const { apiUsageAnalyticsStore } = useStore()

  const store = apiUsageAnalyticsStore.usageTrendStore

  const initialDate = React.useMemo(
    () => [
      moment(store.state.requestedPeriod.start, YYYY_MM_DD_FORMAT).toDate(),
      moment(store.state.requestedPeriod.end, YYYY_MM_DD_FORMAT).toDate(),
    ],
    []
  )

  const { palette } = theme

  const greenColor = get(palette, "green-100-color", "green")
  const greenBgColor = get(palette, "green-10-color", "green")
  const redColor = get(palette, "red-100-color", "green")
  const redBgColor = get(palette, "red-10-color", "green")

  const chartFractions: ChartFractionMetaData[] = React.useMemo(
    () => [
      {
        label: "Successful calls",
        color: greenBgColor,
        borderColor: greenColor,
        name: "success",
        yAxisID: "y1",
      },
      {
        label: "Failed calls",
        color: redBgColor,
        borderColor: redColor,
        name: "failure",
        yAxisID: "y1",
      },
    ],
    [greenColor, redColor, redBgColor, greenBgColor]
  )

  const handleChangePeriod = (value?: Date | Date[]) => {
    if (!value || !Array.isArray(value) || value.length !== 2) return
    const normalized = sortDatePeriodTuple(value)

    store.setPeriod(
      renderDatePeriodTupleToRange(
        normalized as [Date, Date],
        YYYY_MM_DD_FORMAT
      )
    )
  }

  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Text variant="h4">
            {TITLE} <Info> {DESCRIPTION}</Info>
          </Text>
        }
        right={<ExportTrendDataButton />}
      />
      <div className={clsx(styles.content)}>
        <div className={styles.header}>
          <List
            direction="row"
            gutter="24"
            justify="flex-start"
            overflow="initial"
          >
            {chartFractions.map((it) => (
              <Text
                className={styles.legend}
                style={
                  { "--marker-color": it.borderColor } as React.CSSProperties
                }
                variant="caption2"
                key={it.label}
              >
                {it.label}
              </Text>
            ))}
          </List>

          <DatePicker
            defValue={initialDate}
            onChange={handleChangePeriod}
            presets={defaultDateRangePresets}
          />
        </div>

        <Templates.Header
          className={styles.chartTitles}
          offsetBottom="small"
          left={
            <List
              direction="row"
              gutter="8"
              justify="flex-start"
              overflow="initial"
            >
              Calls
            </List>
          }
        />

        <div className={styles.body}>
          {!store.state.data.length && store.state.isLoading ? (
            <Loader size="large" fluid />
          ) : (
            <APIUsageTrendChart
              startDate={store.state.period.start}
              endDate={store.state.period.end}
              data={store.state.data}
              fractions={chartFractions}
            />
          )}
        </div>
      </div>
    </section>
  )
})

export default APIUsageTrends
