import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { useSolution } from "@pages/launch-solutions/ActiveSolutionContext"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"

import styles from "./Question.module.sass"

export type QuestionProps = {
  question: string
  disabled?: boolean
}
const Question: React.FC<QuestionProps> = ({ question, disabled }) => {
  const { solution } = useSolution()
  const {
    solutionsStore: { appliedFilters },
    factFinderSolutionStore: { searchFlowStore },
    contentManagerStore: { companies },
  } = useStore()
  const { avatar } = useActiveAvatar()

  const { factFinderSolutionController } = useController()

  const handleSearch = () => {
    if (disabled) return

    factFinderSolutionController.search(
      question,
      avatar,
      solution.id,
      appliedFilters,
      companies
    )
  }
  return (
    <Button
      type="button"
      className={clsx(styles.root, { [styles.disabled]: disabled })}
      onClick={disabled ? undefined : handleSearch}
      disabled={disabled}
      onKeyPress={(e) => {
        if (e.key === "Enter") handleSearch()
      }}
    >
      <Text variant="body3" className={styles.body}>
        Q: {question}
      </Text>
      <Icon name="arrow-down" rotateAngle={-90} />
    </Button>
  )
}

export default Question
