export const noteIsValuable = (text: string) =>
  Boolean(text && text !== "<p></p>")

export const getFullName = (
  userData: { name: string } | { firstName?: string; lastName?: string } | null
) => {
  if (userData == null) return null

  if ("firstName" in userData) {
    const name = `${userData.firstName || ""} ${userData.lastName || ""}`.trim()
    if (name) return name
  }

  if ("name" in userData) {
    const name = userData.name?.trim()
    if (name) return name
  }

  return null
}
