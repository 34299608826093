import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { ReportDescriptor } from "@framework/types/workbook-report"

import styles from "./PrimaryReportSuggestionCard.module.sass"

export interface ReportSuggestionCardProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  data: ReportDescriptor
  disabled?: boolean
}

const PrimaryReportSuggestionCard: React.FC<ReportSuggestionCardProps> =
  observer(({ data, disabled = false, className, ...rest }) => {
    return (
      <button
        type="button"
        className={clsx(styles.root, className)}
        disabled={disabled}
        {...rest}
      >
        <GooBackground />

        <div className={styles.header}>
          <Icon name={data.icon} className={styles.titleIcon} />
          <Text
            variant="body1"
            color="inherit"
            align="start"
            className={styles.message}
          >
            {data.displayName}
          </Text>
        </div>

        <div className={styles.body}>
          <Text
            variant="body1"
            color="inherit"
            align="start"
            className={styles.message}
          >
            {data.message}
          </Text>
          <Icon name="arrow-up" rotateAngle={90} className={styles.runIcon} />
        </div>
      </button>
    )
  })

export default PrimaryReportSuggestionCard

const GooBackground: React.FC = React.memo(() => {
  return (
    <div className={styles.bubblesContainer}>
      <span className={styles.bubbles}>
        <span key="one" />
        <span key="two" />
        <span key="three" />
        <span key="four" />
      </span>

      <svg>
        <filter id="liquid">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="name" />
          <feColorMatrix
            in="name"
            mode="matrix"
            values="1 0 0 0 0 
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 21 -9"
            result="aab"
          />
          <feBlend in="SourceGraphic" in2="aab" />
        </filter>
      </svg>
    </div>
  )
})
