import { makeAutoObservable } from "mobx"
import { observer } from "mobx-react-lite"
import React from "react"

import { Option } from "@framework/types/utils"

type EditorMode = "edit" | "preview"

export const modeOptions: Option<EditorMode>[] = [
  { name: "edit", value: "Editor" },
  { name: "preview", value: "Preview" },
]

export class MarkdownEditorState {
  mode: string

  constructor() {
    this.mode = "edit"

    makeAutoObservable(this)
  }

  changeEditorMode = (newMode: string) => {
    this.mode = newMode
  }
}

export const MarkdownEditorContext =
  React.createContext<MarkdownEditorState | null>(null)

export const MarkdownEditorContextProvider: React.FC<
  React.PropsWithChildren<{ value: MarkdownEditorState }>
> = observer(({ value, children }) => {
  return (
    <MarkdownEditorContext.Provider value={value}>
      {children}
    </MarkdownEditorContext.Provider>
  )
})
