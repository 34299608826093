import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import { QueryHistoryExpert } from "@framework/types/analytics"
import Avatar from "@components/ui/Avatar/Avatar"

import styles from "./ExpertCard.module.sass"

type ExpertCardProps = {
  expert: QueryHistoryExpert
  questionId: string
}

const ExpertCard: React.FC<ExpertCardProps> = observer(
  ({ questionId, expert }) => {
    const alert = useAlert()

    const {
      knowledgeStore: { sendQuestionReminderToExperts },
    } = useStore()

    const [isLoading, setLoading] = React.useState(false)

    const handleSendReminder = async () => {
      if (!expert.id) return

      setLoading(true)
      const success = await sendQuestionReminderToExperts(questionId, [
        expert.id,
      ])
      setLoading(false)
      if (success) {
        alert.success("Reminder sent successfully")
      } else {
        alert.error("Failed to send Reminder")
      }
    }

    const isPending = expert.isExpertVerified

    return (
      <div className={styles.root}>
        <List direction="row" justify="space-between" gutter="8">
          <Text className={styles.status} variant="caption2">
            <Icon
              name={isPending ? "timer-fill" : "shield-check-fill"}
              color={isPending ? "gold" : "green"}
            />
            {isPending ? "Waiting for Expert" : "Expert Verified"}
          </Text>
          <Button
            disabled={isLoading}
            after={isLoading && <Loader size="small" />}
            size="tiny"
            color="secondary"
            onClick={handleSendReminder}
          >
            Send Reminder
          </Button>
        </List>
        <div className={styles.expertInfo}>
          <Avatar name={expert.avatarUrl || expert.name} />
          <span className={styles.expertName}>{expert.name}</span>
        </div>
      </div>
    )
  }
)

export default ExpertCard
