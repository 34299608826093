import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"
import { CanceledError } from "axios"

import {
  AnswerStatus,
  ExpertQuestion,
  ExpertQuestionDTO,
  ExpertQuestionsData,
} from "@framework/types/question"
import { BaseUserData } from "@framework/types/user"
import knowledgeService from "@services/knowledge.service"
import { AnswerResponseData } from "@services/search.service"
import { Entity, FailResponse, SuccessResponse } from "@framework/types/common"

export class KnowledgeStore {
  constructor() {
    makeAutoObservable(this)
  }

  isUpdateExpertQuestionLoading: boolean = false

  isSendReminderLoading: boolean = false

  updateExpertQuestionError: string | null = null

  isExpertQuestionsLoading: boolean = false

  expertQuestionsError: string | null = null

  expertQuestions: Partial<ExpertQuestionsData> = {}

  reloadTrigger = 0

  private eqAbortController: AbortController | null = null

  loadExpertQuestions = async (query?: string) => {
    try {
      this.eqAbortController?.abort()
      this.eqAbortController = new AbortController()
      const signal = this.eqAbortController?.signal

      this.isExpertQuestionsLoading = true
      this.expertQuestionsError = null
      this.expertQuestions = {}

      const response = await knowledgeService.getExpertQuestions(
        { query },
        signal
      )

      this.expertQuestions = {
        waiting:
          response.data.data.waiting?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
              verifiedBy: [],
            })
          ) ?? [],
        answered:
          response.data.data.answered?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
            })
          ) ?? [],
      }
    } catch (error) {
      if (error instanceof CanceledError) {
        // Do nothing
      }
      this.expertQuestionsError = "Failed to load questions"
    } finally {
      this.isExpertQuestionsLoading = false
    }
    return this.expertQuestionsError
  }

  reloadExpertQuestions = () => {
    this.reloadTrigger += 1
  }

  updateExpertQuestion = async (
    questionId: string,
    status: AnswerStatus,
    answer: string | null = null,
    supportingDocuments: File[] = []
  ): Promise<SuccessResponse<ExpertQuestion> | FailResponse> => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null
    try {
      const res = await knowledgeService.updateExpertQuestion(questionId, {
        status,
        answer: answer ?? undefined,
        supportingDocuments,
      })

      return { status: "SUCCESS", data: res.data.data }
    } catch (error) {
      const message = "Can't update question"
      this.updateExpertQuestionError = message
      return {
        status: "FAILED",
        message: "Can't update question",
      }
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
  }

  deleteExpertQuestion = async (questionId: string) => {
    try {
      this.isUpdateExpertQuestionLoading = true
      this.updateExpertQuestionError = null

      await knowledgeService.removeExpertQuestionById(questionId)
    } catch (error) {
      this.updateExpertQuestionError = "Failed to remove question"
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return this.updateExpertQuestionError
  }

  expertsList: BaseUserData[] = []

  isExpertsLoading = true

  loadExpertsError = ""

  loadExperts = async () => {
    this.isExpertsLoading = true
    this.loadExpertsError = ""
    try {
      const response = await knowledgeService.getKnowledgeExperts()
      this.expertsList = response.data.data.experts ?? []
      return true
    } catch (error) {
      this.loadExpertsError = `Can't load experts`
    } finally {
      this.isExpertsLoading = false
    }
    return false
  }

  assignExpertQuestionToSelf = async (
    question: string,
    delegatedToUserIds: string[],
    answer?: AnswerResponseData | AnswerResponseData[],
    questionNote?: string,
    summary?: string,
    suppressNotification: boolean = false
  ) => {
    try {
      const response =
        await knowledgeService.assignationQuestionDirectlyMultipleExperts({
          question,
          answer: JSON.stringify(answer),
          delegatedToUserIds,
          userNote: questionNote,
          summary,
          suppressNotification,
        })

      return response.data
    } catch (error) {
      return null
    }
  }

  assignQuestionToExpertDirectly = async (
    question: string,
    delegatedToUserIds: string[],
    answer?: AnswerResponseData | AnswerResponseData[],
    questionNote?: string,
    summary?: string
  ): Promise<SuccessResponse<ExpertQuestion[]> | FailResponse> => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null
    try {
      const response =
        await knowledgeService.assignationQuestionDirectlyMultipleExperts({
          question,
          answer: JSON.stringify(answer),
          delegatedToUserIds,
          userNote: questionNote,
          summary,
          suppressNotification: delegatedToUserIds.length === 0,
        })
      return {
        status: "SUCCESS",
        data: response.data.data,
      }
    } catch (error) {
      this.updateExpertQuestionError = `Cancellation failed`
      return {
        status: "FAILED",
        message: this.updateExpertQuestionError,
      }
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
  }

  sendQuestionReminderToExperts = async (
    questionId: string,
    expertIds: string[]
  ): Promise<boolean> => {
    this.isSendReminderLoading = true
    try {
      const response = await knowledgeService.sendReminderToExperts(
        questionId,
        expertIds
      )
      return response.data.status === "SUCCESS"
    } catch (error) {
      return false
    } finally {
      this.isSendReminderLoading = false
    }
  }
}

export default KnowledgeStore
