import { makeAutoObservable, toJS, when } from "mobx"
import { AxiosProgressEvent, CanceledError } from "axios"

import ContentManagerAPI from "@services/content-manager.service"
import RootStore from "@store/RootStore"
import {
  ConnectorDocument,
  CreateContentSourceParams,
  GetConnectorDocumentsParams,
  KibanaConnector,
  MANUAL_UPLOAD_FILE_STATUS,
  SyncJobInfo,
  WebsiteInfo,
} from "@framework/types/content-manager"
import { formatBytes, getUniqueNameForFile } from "@utils/file"
import { pluralize } from "@utils/textUtils"
import { FailResponse, SuccessResponse } from "@framework/types/common"
import defaultErrorHandler from "@store/utils/error-handlers"

import ContentManagerStore from "./content-manager.store"

export default class ContentManagerController {
  contentManagerStore: ContentManagerStore

  constructor(injections: RootStore) {
    this.contentManagerStore = injections.contentManagerStore

    makeAutoObservable(this)
  }

  getContentSourceCountsByType = (type: string) => {
    const data = this.contentManagerStore.kibanaConnectorsCount?.find(
      (item) => item.type === type
    )
    const accountCount = data?.accountCount || 0
    const documentCount = data?.documentCount || 0
    const fileCount = data?.fileCount || 0
    const sizeInfo = formatBytes(data?.sizeInBytes || 0, 2)
    switch (type) {
      case "website":
        return `${accountCount} ${pluralize("Website", accountCount > 1)} | ${
          documentCount || 0
        } ${pluralize("Page", documentCount > 1)}| ${fileCount} ${pluralize(
          "File",
          fileCount > 1
        )} | ${sizeInfo}`

      case "manual":
        return `${documentCount} Content | ${sizeInfo}`

      default:
        return `${accountCount} ${pluralize(
          "Accounts",
          accountCount > 1
        )} | ${documentCount} Content | ${sizeInfo}`
    }
  }

  loadKibanaConnectorAccounts = async (type: string) => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorAccounts = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getAvailableContentSources({
        type,
      })
      store.kibanaConnectorAccounts = response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorAccounts = false
    }
  }

  deleteKibanaConnectorAccount = async (id: string): Promise<boolean> => {
    const store = this.contentManagerStore
    store.error = null
    try {
      const response = await ContentManagerAPI.deleteKibanaConnectorAccount(id)
      return response.status === 200
    } catch (error: any) {
      store.error = error.message
    }
    return false
  }

  loadKibanaConnectorsCount = async () => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorsCount = true
    store.error = null
    store.kibanaConnectorAccounts = []
    try {
      const response = await ContentManagerAPI.getKibanaConnectorsCount()
      store.kibanaConnectorsCount = response.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorsCount = false
    }
  }

  createContentSource = async (
    data: CreateContentSourceParams
  ): Promise<boolean> => {
    const store = this.contentManagerStore
    store.loadingCreatedContentSource = true
    store.error = null
    try {
      const response = await ContentManagerAPI.createContentSource(data)
      store.createdContentSource = response.data
      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingCreatedContentSource = false
    }
    return false
  }

  connectWebsite = async (
    data: CreateContentSourceParams
  ): Promise<boolean> => {
    const store = this.contentManagerStore
    store.loadingCreatedContentSource = true
    store.error = null
    try {
      const response = await ContentManagerAPI.connectWebsite({
        websiteName: data.websiteName || "",
        url: data.domain,
        companyIds: data.companyIds,
        keywords: data.keywords || "",
        childUrls: data?.childUrls?.split(",") || [],
      })
      store.createdContentSource = response.data
      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingCreatedContentSource = false
    }
    return false
  }

  documentListAC: AbortController = new AbortController()

  loadDocumentsList = async (params: GetConnectorDocumentsParams) => {
    const store = this.contentManagerStore
    try {
      const ac = new AbortController()

      this.documentListAC.abort()
      this.documentListAC = ac

      store.loadingDocumentsList = true
      store.error = null

      if (params.query || params.filter) {
        store.documentsList = []
      }

      const response = await ContentManagerAPI.getDocumentsList(
        params,
        ac.signal
      )

      if (ac.signal.aborted) return

      store.documentsList = response.data.data
      store.documentsMeta = response.data.meta
    } catch (error: any) {
      if (error instanceof CanceledError) {
        return
      }
      store.error = error.message
    }
    store.loadingDocumentsList = false
  }

  uploadDocument = async (
    file: File,
    contentType?: string,
    companyIds: string[] = []
  ): Promise<boolean> => {
    const store = this.contentManagerStore
    const uploadId = getUniqueNameForFile(file)
    store.addUpload(uploadId, {
      fileName: file.name,
      status: MANUAL_UPLOAD_FILE_STATUS.UPLOADING,
      progress: 0,
    })

    const handleProgress = (progressEvent: AxiosProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent?.total || 1)
      )
      store.updateUpload(uploadId, {
        progress: percentCompleted,
      })
    }

    try {
      const response = await ContentManagerAPI.uploadDocument(
        file,
        contentType,
        companyIds,
        handleProgress
      )
      const { succefulFileUploads, failedUploadedFiles } = response.data

      if (succefulFileUploads.includes(file.name)) {
        store.updateUpload(uploadId, {
          status: MANUAL_UPLOAD_FILE_STATUS.COMPLETED,
          progress: 100,
          error: "",
        })
      } else if (failedUploadedFiles.includes(file.name)) {
        store.updateUpload(uploadId, {
          status: MANUAL_UPLOAD_FILE_STATUS.FAILED,
          progress: 0,
        })
      }
      return true
    } catch (error: any) {
      store.updateUpload(uploadId, {
        status: MANUAL_UPLOAD_FILE_STATUS.FAILED,
        error: error.message,
        progress: 0,
      })
    }
    return false
  }

  loadSyncJobs = async () => {
    const store = this.contentManagerStore
    store.loadingSyncJobs = true
    store.error = null
    try {
      const inProgressResponse = await ContentManagerAPI.getSyncJobs()
      const errorResponse = await ContentManagerAPI.getAvailableContentSources({
        status: "error",
      })
      const syncingWebsitesResponse = await ContentManagerAPI.getWebsites(true)
      const websiteSyncJobs = syncingWebsitesResponse.data.map(
        (website: WebsiteInfo, index) =>
          ({
            type: "Website",
            name: website.websiteName,
            domain: website.url,
            id: `${website.id}`,
            status: website.status,
            indexed_document_count: website.result?.total?.toString(),
            companies: website.companies,
            statusInfo: [
              {
                key: "Pages Synced",
                value:
                  (website?.result?.total || 0) > 0
                    ? `${website.pages} / ${website.result.total}`
                    : website.pages.toString(),
              },
              {
                key: "Products Found",
                value: website.productsFound.toString(),
              },
            ],
          } as SyncJobInfo)
      )

      const errorSyncJobs = errorResponse.data.data.map(
        (item) =>
          ({
            type: item.type,
            name: item.name,
            domain: item.domain,
            status: "error",
            error: item.error,
            id: item.id,
            indexed_document_count: `${item.contentCount}`,
          } as SyncJobInfo)
      )
      // Combine the sync jobs from both sources, prioritizing errorResponse items
      const combinedJobs = [
        ...inProgressResponse.data.data,
        ...errorSyncJobs,
        ...websiteSyncJobs,
      ]

      store.syncJobs = combinedJobs
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingSyncJobs = false
    }
  }

  loadWebsiteInfos = async () => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorAccounts = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getWebsites()
      store.websiteInfos = response.data
      // converting website info to kibana connector account as we need same data in FE
      store.kibanaConnectorAccounts = store.websiteInfos.map(
        (website) =>
          ({
            id: website.id,
            name: website.websiteName,
            status: website.status,
            type: "website",
            domain: website.url,
            siteCollections: "",
            lastSyncDate: website.createdAt,
            connectedBy: website.connectedBy,
            contentCount: website.pages || 0,
            companies: website.companies,
            statusObj: website.result,
            childUrls: website.childUrls,
            sizeInBytes: website.sizeInBytes,
            isPublic: website.isPublic,
          } as KibanaConnector)
      )
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorAccounts = false
    }
  }

  updateContentTypesForDocuments = async (
    contentType: string,
    documents: ConnectorDocument[]
  ): Promise<string | null> => {
    const store = this.contentManagerStore
    store.loadingUpdateContentTypes = true
    store.error = null

    try {
      const updatePromises = documents.map(({ connectorId, id: documentId }) =>
        ContentManagerAPI.updateContentTypeForDocument({
          connectorId,
          documentId: encodeURIComponent(documentId),
          contentType,
        })
      )

      await Promise.all(updatePromises)
      return null
    } catch (error: any) {
      store.error = error.message
      return error.message
    } finally {
      store.loadingUpdateContentTypes = false
    }
  }

  reportContent = async (payload: {
    contentId: string
    reason: string
  }): Promise<SuccessResponse<any> | FailResponse> => {
    const store = this.contentManagerStore

    try {
      store.isContentReporting = true
      store.contentReportError = null

      await ContentManagerAPI.reportDocument(payload)

      return {
        status: "SUCCESS",
        data: {},
      }
    } catch (error: any) {
      store.contentReportError = defaultErrorHandler(error)
      return {
        status: "FAILED",
        message: store.contentReportError,
      }
    } finally {
      store.isContentReporting = false
    }
  }

  getCompanies = async (filterByHasContent?: boolean) => {
    const store = this.contentManagerStore
    store.loadingCompanies = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getCompanies(filterByHasContent)
      store.companies = response.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingCompanies = false
    }
  }

  getCategories = async (manufacturerId?: string) => {
    const store = this.contentManagerStore
    store.loadingCategories = true
    store.errorCategories = null
    store.categories = []
    try {
      const response = await ContentManagerAPI.getIdentifiers(manufacturerId)

      store.categories = response.data?.data || []
    } catch (error: any) {
      store.errorCategories = error.message
    } finally {
      store.loadingCategories = false
    }
  }

  deleteConnectorDocuments = async (
    documents: ConnectorDocument[]
  ): Promise<string | null> => {
    const store = this.contentManagerStore
    store.loadingUpdateContentTypes = true
    store.error = null

    try {
      const updatePromises = documents.map(({ connectorId, id: documentId }) =>
        ContentManagerAPI.deleteDocument({
          connectorId,
          documentId: encodeURIComponent(documentId),
        })
      )

      await Promise.all(updatePromises)
      return null
    } catch (error: any) {
      store.error = error.message
      return error.message
    } finally {
      store.loadingUpdateContentTypes = false
    }
  }

  clearSearch() {
    const store = this.contentManagerStore
    store.loadingDocumentsList = true
    store.documentsList = []
  }
}
