import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import useSearch from "@components/hooks/useSearch"
import useDebounce from "@components/hooks/useDebounce"
import { useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Loader from "@components/ui/Loader/BarLoader"
import { V2_Products } from "@store/product/product.store"

import styles from "./LinkProductsModal.module.sass"

export interface LinkProductsModalProps {
  companyId?: string
  invalidProductIds?: string[]
  onSave: (docs: V2_Products[]) => void
}

export const LinkProductsModal: React.FC<LinkProductsModalProps> = observer(
  ({ onSave, companyId, invalidProductIds }) => {
    const [selectedRows, setSelectedRows] = useState<V2_Products[]>([])

    const [allProducts, setAllProducts] = useState<V2_Products[]>([])

    const [searchProps, searchContext] = useSearch()
    const { debouncedValue, setDebounce } = useDebounce()

    const {
      productComparisonStore: {
        productStore: {
          products,
          loadingProductsList,
          loadProducts,
          productsMetaData,
        },
      },
    } = useStore()

    const { hideModal } = useModal(ModalsTypes.LINK_PRODUCTS_MODAL)

    const handleReject = async () => {
      hideModal()
    }

    const handleConfirm = async () => {
      onSave(selectedRows)
      hideModal()
    }

    const page = productsMetaData?.page || 0

    const loadPage = (pageNum = page + 1) =>
      loadProducts({
        filters: {
          search: debouncedValue,
          companyIds: companyId ? [companyId] : [],
        },
        pagination: {
          pageSize: 10,
          page: pageNum,
        },
      })

    const hasMore = page < (productsMetaData?.totalPages || 0)

    const lastItemRef = useInfiniteScroll({
      callback: () => loadPage(page + 1),
      isLoading: loadingProductsList,
      hasMore,
    })

    const clearSearch = () => {
      setAllProducts([])
      searchContext.setQuery("")
      loadPage()
    }

    useEffect(() => {
      if (productsMetaData) {
        productsMetaData.page = 1
      }
      setAllProducts([])
      loadPage(1)
    }, [debouncedValue])

    useEffect(() => {
      setDebounce(searchProps.value)
    }, [searchProps.value])

    useEffect(() => {
      if (products.length) {
        setAllProducts([...allProducts, ...products])
      }
    }, [products])

    useEffect(() => {
      setAllProducts([])
    }, [])

    const toggleSelectRow = (item: V2_Products) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.map((row) => row.id).includes(item.id)
          ? prevSelectedRows.filter((row) => row.id !== item.id)
          : [...prevSelectedRows, item]
      )
    }

    const filteredProducts = allProducts.filter(
      (p) => !invalidProductIds?.includes(p.id)
    )

    return (
      <BaseModal
        title={
          <Text
            className={styles.header}
            weight="bold"
            align="center"
            variant="h2"
          >
            Add Products
          </Text>
        }
        className={styles.root}
        containerClassName={styles.container}
      >
        <TextInput
          {...searchProps}
          before={<Icon name="search" />}
          placeholder="Search"
          after={
            !!searchProps.value && <Icon name="cross" onClick={clearSearch} />
          }
        />
        <LoadingCover
          isLoading={loadingProductsList && !allProducts.length}
          className={styles.body}
        >
          {!loadingProductsList && !allProducts?.length && (
            <NotFound className={styles.notFound}>
              <Text variant="h2">No Products Found</Text>
            </NotFound>
          )}
          {filteredProducts?.map((item, index) => (
            <>
              <div
                key={item.id}
                onClick={() => toggleSelectRow(item)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    toggleSelectRow(item)
                  }
                }}
                role="button"
                tabIndex={0}
                className={`${styles.rowItem} ${
                  selectedRows.includes(item) ? styles.selected : ""
                }`}
              >
                <Text>{item.name}</Text>
              </div>
              {index === filteredProducts.length - 1 && (
                <div className={styles.lastElem} ref={lastItemRef} />
              )}
            </>
          ))}

          {!!loadingProductsList && !!filteredProducts.length && (
            <div className={styles.loader}>
              <Loader size="small" />
            </div>
          )}
        </LoadingCover>
        <div className={styles.footer}>
          <Button variant="outlined" size="medium" onClick={handleReject}>
            Cancel
          </Button>
          <Button color="primary" size="medium" onClick={handleConfirm}>
            Add
          </Button>
        </div>
      </BaseModal>
    )
  }
)

export default LinkProductsModal
