import { makeAutoObservable } from "mobx"
import omit from "lodash/omit"

import RootStore from "@store/RootStore"
import subscriptionService from "@services/subscription.service"
import { errorToText } from "@store/utils/error-handlers"
import { SubscriptionsData } from "@framework/types/subscription"
import RestrictionsStore from "@store/restrictions/restrictions.store"

import SubscriptionStore from "./subscription.store"

export class SubscriptionController {
  subscriptionStore: SubscriptionStore

  restrictionsStore: RestrictionsStore

  constructor(injections: RootStore) {
    this.subscriptionStore = injections.subscriptionStore
    this.restrictionsStore = injections.restrictionsStore
    makeAutoObservable(this)
  }

  private loadSubscriptionData = async () => {
    const res = await subscriptionService.getSubscription()

    if (res?.data?.data == null) throw new Error("Subscription not found")

    return res.data.data
  }

  private loadAvailableOptions = async () => {
    const res = await subscriptionService.getAvailableOptions()

    if (res?.data?.data == null)
      throw new Error("Subscription options not found")

    return res.data.data
  }

  private loadUsageData = async () => {
    const res = await subscriptionService.getMonthlySubscriptionUsage()

    if (res?.data?.data == null) throw new Error("Subscription usage not found")

    return res.data.data
  }

  init = async () => {
    const store = this.subscriptionStore
    const access = this.restrictionsStore
    try {
      store.initializationFailed = false
      store.setLoading()
      store.setError()

      const subscriptionData = await this.loadSubscriptionData()

      store.storeEditedData(subscriptionData)
      store.storeData(subscriptionData)

      const usageData = await this.loadUsageData()
      store.storeUsageData(usageData)

      if (access.isSubscriptionUpdateAvailable) {
        const optionsData = await this.loadAvailableOptions()
        store.storeAvailableOptions(optionsData)
      }
    } catch (error) {
      store.setError(errorToText(error))
      store.initializationFailed = true
    } finally {
      store.setLoading(false)
    }
  }

  setEditable = (value: boolean) => {
    this.subscriptionStore.isEditable = value
  }

  updateEditData = (key: string, value: any) => {
    const store = this.subscriptionStore
    store.editedSubscriptionData = {
      ...store.editedSubscriptionData,
      [key]: value,
    }
  }

  updateSubscription = async (data: SubscriptionsData) => {
    try {
      this.subscriptionStore.setLoading()
      this.subscriptionStore.setError()
      const newData = data
      newData.connectors = [
        ...new Set([
          ...(data.connectorsBasic || []),
          ...(data.connectorsPremium || []),
          ...(data.connectorsCustom || []),
        ]),
      ]
      const response = await subscriptionService.updateSubscription(
        omit(
          newData,
          "apiQuotaConsumed",
          "createdAt",
          "updatedAt",
          "id",
          "connectorsBasic",
          "connectorsPremium",
          "connectorsCustom"
        )
      )
      this.subscriptionStore.storeData(response.data.data)
      return response
    } catch (error) {
      this.subscriptionStore.setError(
        errorToText(error, "Failed to update subscription")
      )
      return { error: this.subscriptionStore.error }
    } finally {
      this.subscriptionStore.setLoading(false)
    }
  }

  upgradeSubscription = async (description: string) => {
    try {
      this.subscriptionStore.setLoading()
      this.subscriptionStore.setError()
      const response = await subscriptionService.upgradeSubscription(
        description
      )

      return response
    } catch (error) {
      this.subscriptionStore.setError(
        errorToText(error, "Failed to send request to upgrade subscription")
      )
      return { error: this.subscriptionStore.error, data: null }
    } finally {
      this.subscriptionStore.setLoading(false)
    }
  }

  addAvailableOption = async (name: string, groupName: string) => {
    try {
      this.subscriptionStore.setError()
      const res = await subscriptionService.addAvailableOption({
        name,
        groupName,
      })
      if (res.data.data) {
        const store = this.subscriptionStore
        const oldOptions = store.availableOption[groupName]
        const newOptions = [...oldOptions, res.data.data]
        store.availableOption[groupName] = newOptions
        return res.data.data
      }
    } catch (error) {
      this.subscriptionStore.setError(
        errorToText(error, "Failed to add subscription option")
      )
    } finally {
      this.subscriptionStore.setLoading(false)
    }
    return null
  }
}

export default SubscriptionController
