import React, { useCallback, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store"
import mainRoutes from "@root/main.routes"
import useCollapsible from "@components/ui/Collapsable/useCollapsible"
import Collapsable from "@components/ui/Collapsable/Collapsable"
import CollapseHeader from "@components/ui/Collapsable/CollapseHeader"
import Text from "@components/ui/Typography/Text"
import { Product } from "@framework/types/product"

import { LineItemOption } from "./SolutionOptions"

import styles from "./SidePanel.module.sass"

const LineItem: React.FC<{
  name: string
  groupId?: string
  info?: string
  options: LineItemOption[]
  product?: Product
}> = observer(({ name, groupId = name, options, info, product }) => {
  const alert = useAlert()

  const { solutionsStore } = useStore()

  const collapsibleContext = useCollapsible()

  const handleOptionClick = useCallback(
    (option: LineItemOption) => {
      if (option.type === "url") {
        window.open(option.value, "_blank")
      } else if (option.name === "Product Overview") {
        if (!product?.id) {
          alert.info(`Unable to open ${option.name}`)
          return
        }
        window.open(mainRoutes.productView(product.id), "_blank")
      } else {
        const solution = solutionsStore.solutions?.find(
          (solution) =>
            solution.name.toLowerCase() === option.value.toLowerCase()
        )

        if (solution) {
          if (option.type === "product") {
            window.open(
              `${mainRoutes.solutionView(solution.id)}?product=${
                option?.product?.name
              }`,
              "_blank"
            )
          } else if (option.type === "account") {
            window.open(
              `${mainRoutes.solutionView(
                solution.id
              )}?manufacturer=${encodeURIComponent(
                option?.company?.url ?? (option?.company?.name || "")
              )}`,
              "_blank"
            )
          }
        }
      }
    },
    [solutionsStore]
  )

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option.type === "url" ||
          option.name === "Product Overview" ||
          solutionsStore.solutions?.some(
            (solution) =>
              solution.name.toLowerCase() === option.value.toLowerCase()
          )
      ),
    [options, solutionsStore.solutions]
  )

  const isOpen = collapsibleContext.isActive(groupId)

  return (
    <div className={styles.lineItem}>
      <CollapseHeader
        open={isOpen}
        title={name}
        onToggle={() => collapsibleContext.toggle(groupId)}
      >
        <div className={styles.lineItemHeading}>
          <Text className={styles.noWrap} variant="caption2" color="inherit">
            {name}
          </Text>
          {info && (
            <Text
              className={styles.noWrap}
              variant="caption2"
              color="text50Color"
            >
              {info}
            </Text>
          )}
        </div>
      </CollapseHeader>

      <Collapsable open={isOpen}>
        <div className={styles.insideCollection}>
          {filteredOptions.map((option) => (
            <Button
              key={option.value}
              variant="text"
              size="tiny"
              after={<Icon name="arrow-up" rotateAngle={90} />}
              className={styles.lineItemOption}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </Button>
          ))}
        </div>
      </Collapsable>
    </div>
  )
})

export default LineItem
