import React from "react"
import { observer } from "mobx-react-lite"

import { ExpertQuestion } from "@framework/types/question"
import Text from "@components/ui/Typography/Text"
import Avatar from "@components/ui/Avatar/Avatar"
import { noteIsValuable } from "@pages/questions/utils"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"
import UserChip from "@components/prototypes/UserChip"
import List from "@components/ui/List/List"

import UserNotes from "../UserNotes/UserNotes"
import ExpertQuestionContextMenu from "../ContextMenu"

import styles from "./QuestionCard.module.sass"

const usersPreviewLimit = 25

interface QuestionCardProps {
  data: ExpertQuestion
}

const QuestionCard: React.FC<QuestionCardProps> = observer(({ data }) => {
  return (
    <div className={styles.root}>
      {!!data.assignees?.length && (
        <div className={styles.preHeader}>
          <Tooltip placement="bottom" color="primary">
            <TooltipTrigger>
              <UsersBubbles prefix="Assigned Experts:" users={data.assignees} />
            </TooltipTrigger>
            <TooltipContent style={{ width: 200, borderRadius: 20 }}>
              <List gutter="8" overflow="initial">
                {data.assignees.slice(0, usersPreviewLimit).map((it) => (
                  <UserChip data={it} color="secondary-solid" key={it.name} />
                ))}

                {data.assignees.length > usersPreviewLimit ? (
                  <Text variant="caption2" color="text70Color">
                    `And ${data.assignees.length - usersPreviewLimit} more`
                  </Text>
                ) : null}
              </List>
            </TooltipContent>
          </Tooltip>
        </div>
      )}

      <div className={styles.header}>
        <Avatar
          name={data.author.name}
          src={data.author.avatarUrl || ""}
          height={32}
          width={32}
        />

        <div className={styles.question}>
          <Text variant="h4">{data.question}</Text>
        </div>

        <ExpertQuestionContextMenu question={data} />
      </div>

      {noteIsValuable(data.userNote) && (
        <div className={styles.body}>
          <UserNotes text={data.userNote} />
        </div>
      )}
    </div>
  )
})

export default QuestionCard
