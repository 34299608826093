/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react"
import { convertFromHTML, convertToHTML } from "draft-convert"
import {
  DraftHandleValue,
  EditorCommand,
  EditorState,
  RichUtils,
} from "draft-js"
import clsx from "clsx"
import createListDepthPlugin from "draft-js-list-depth-plugin"

import DraftEditor from "@draft-js-plugins/editor"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import VoiceInputButton from "@components/ui/Button/VoiceInputButton"

import Templates from "../Templates"
import useSpeechEditor from "./useSpeechEditor"

import "./Editor.css"

import "draft-js/dist/Draft.css"

import styles from "./Editor.module.sass"

const listDepthPlugin = createListDepthPlugin()

const plugins = [listDepthPlugin]

const BLOCK_TYPES: Option[] = [
  {
    value: "Bullet list",
    name: "unordered-list-item",
    icon: "format_list_bulleted",
  },
  {
    value: "Numbered list",
    name: "ordered-list-item",
    icon: "format_list_numbered",
  },
]

export interface EditorProps {
  readOnly?: boolean
  onChange?: (value: string) => void
  placeholder?: string
  defaultValue?: string
  className?: string
  textAreaClassName?: string
}

export const Editor: React.FC<EditorProps> = ({
  readOnly,
  onChange,
  placeholder,
  defaultValue = "",
  className,
  textAreaClassName,
}) => {
  const { restrictionsStore: access } = useStore()

  const editor = useRef<DraftEditor>(null)

  const [editorState, setEditorState] = useState<EditorState>(() => {
    return EditorState.moveSelectionToEnd(
      EditorState.createWithContent(convertFromHTML(defaultValue))
    )
  })

  const htmlValue = convertToHTML(editorState.getCurrentContent())

  const handleToggleStyle =
    (style: string): React.MouseEventHandler =>
    (e) => {
      e.preventDefault()
      setEditorState(RichUtils.toggleBlockType(editorState, style))
      setTimeout(handleFocus, 0)
    }

  const handleFocus = () => editor?.current?.focus()

  const handleKeyCommand = (command: EditorCommand): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return "handled"
    }

    return "not-handled"
  }

  useEffect(() => {
    onChange?.(htmlValue)
  }, [htmlValue])

  const speechRecognizer = useSpeechEditor({
    value: editorState,
    onChange: setEditorState,
  })

  useEffect(() => {
    if (editor.current && !speechRecognizer.listening) handleFocus()
  }, [speechRecognizer.listening])

  const disabled = speechRecognizer.listening

  return (
    <div className={clsx(styles.root, className)}>
      <div
        className={clsx(styles.textArea, textAreaClassName)}
        onClick={handleFocus}
      >
        <DraftEditor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          readOnly={readOnly || disabled}
          placeholder={placeholder}
          plugins={plugins}
        />
      </div>

      {!readOnly && (
        <Templates.Header
          className={styles.control}
          left={
            <>
              {BLOCK_TYPES.map((item) => {
                const selection = editorState.getSelection()
                const blockType = editorState
                  .getCurrentContent()
                  .getBlockForKey(selection.getStartKey())
                  .getType()

                return (
                  <Button
                    title={item.value}
                    className={styles.controlButton}
                    variant={blockType === item.name ? "contained" : "slim"}
                    color={blockType === item.name ? "primary" : "default"}
                    onClick={handleToggleStyle(item.name)}
                    noPadding
                    disabled={disabled}
                    key={item.name}
                  >
                    {item.icon ? <Icon name={item.icon} /> : item.value}
                  </Button>
                )
              })}
            </>
          }
          right={
            access.isVoiceRecognitionEnabled && (
              <VoiceInputButton
                onClick={speechRecognizer.toggle}
                listening={speechRecognizer.listening}
                error={speechRecognizer.error}
              />
            )
          }
        />
      )}
    </div>
  )
}

export default Editor
