import {
  WorkbookReportTypeName,
  ReportDescriptor,
} from "@framework/types/workbook-report"

export const reportOptionsMap: Record<
  WorkbookReportTypeName,
  ReportDescriptor
> = {
  PRODUCTCOMPARISON: {
    type: "PRODUCTCOMPARISON",
    displayName: "Product Comparison",
    message: "Generate a comparison report for two or more products",
    icon: "scales",
  },
  PRODUCTPOSITIONING: {
    type: "PRODUCTPOSITIONING",
    displayName: "Product Positioning",
    message: "Create Product Positioning for two and more products",
    icon: "positioning",
  },
  PRODUCTRECOMMENDATION: {
    type: "PRODUCTRECOMMENDATION",
    displayName: "Product Recommendation",
    message:
      "Generate product recommendation report for a target application, scenario or metrics.",
    icon: "recommendation",
  },
}

export const getReportDescriptionByType = (
  reportType: WorkbookReportTypeName
) => reportOptionsMap[reportType]
