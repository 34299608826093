import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"

import { TextInput, TextInputProps } from "@components/ui/TextInput/TextInput"
import useQueryParams from "@components/hooks/useQueryParams"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import Button from "@components/ui/Button/Button"
import {
  CommentType,
  PasswordValidationComment,
  strongPasswordValidationSchema,
} from "@framework/constants/auth"
import { SignUpFormType } from "@framework/types/auth"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import RequiredPasswordField from "../RequiredPasswordField/RequiredPasswordField"

import styles from "./SignUpForm.module.sass"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required").default(""),

  lastName: yup.string().required("Last name is required").default(""),

  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email")
    .default(""),

  password: strongPasswordValidationSchema
    .clone({ abortEarly: false })
    .test({
      test: (value, context) => {
        const { email, lastName, firstName, jobTitle, businessUnit } =
          context.parent
        return [email, lastName, firstName, jobTitle, businessUnit].every(
          (it) => !it || !value || !value.includes(it)
        )
      },
      message: "Must not contain any of your account info (name, email, etc)",
    })
    .default(""),

  inviteCode: yup.string().required("Invitation code is required").default(""),

  jobTitle: yup.string().label("Job title").default(""),

  businessUnit: yup.string().label("Business unit").default(""),
})

export interface SignUpFormProps {
  className?: string
}

const SignUpForm: React.FC<SignUpFormProps> = observer(() => {
  const navigate = useNavigate()
  const params = useQueryParams()

  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const invitationCode = params.x
  const invitationEmail = params.email

  const {
    authStore: {
      isSignUpLoading: isLoading,
      signUpErrors,
      confirmation2FAEmail,
      signUp,
    },
  } = useStore()

  const [showPassword, setShowPassword] = React.useState(false)

  const formik = useFormik<SignUpFormType>({
    initialValues: validationSchema.cast({
      email: invitationEmail,
      inviteCode: invitationCode,
    }),
    validationSchema,
    onSubmit: signUp,
  })

  React.useEffect(() => {
    formik.setErrors(signUpErrors)
  }, [signUpErrors])

  React.useEffect(() => {
    if (confirmation2FAEmail) navigate(mainRoutes.signUpConfirm())
  }, [confirmation2FAEmail])

  const getProps = (
    name: keyof SignUpFormType,
    after: React.ReactNode = null
  ): TextInputProps => {
    const error =
      (name === "password"
        ? PasswordValidationComment[formik.errors[name] as CommentType]
        : null) || formik.errors[name]

    const withError = Boolean(formik.touched[name] && error)

    return {
      name,
      value: formik.values[name].toString(),
      withError,
      after:
        withError || after != null ? (
          <>
            <div className={styles.after}>
              {!!error && (
                <ErrorChip message={error} messagePlacement="bottom" />
              )}
              {after}
            </div>
          </>
        ) : null,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    }
  }

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <TextInput placeholder="Email" {...getProps("email")} disabled />

      <RequiredPasswordField
        placeholder="Password"
        {...getProps(
          "password",
          <Icon
            name={showPassword ? "eye-close" : "eye"}
            className={styles.eyeIcon}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        type={showPassword ? "" : "password"}
      />

      <TextInput placeholder="First name" {...getProps("firstName")} />

      <TextInput placeholder="Last name" {...getProps("lastName")} />

      <TextInput placeholder="Job title" {...getProps("jobTitle")} />

      <TextInput placeholder="Business unit" {...getProps("businessUnit")} />

      <Button
        size={isTablet ? "big" : "large"}
        type="submit"
        color="primary"
        disabled={isLoading}
        after={isLoading && <Loader />}
      >
        Sign Up
      </Button>
    </form>
  )
})

export default SignUpForm
