import { observer } from "mobx-react-lite"
import React from "react"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import IconChip from "@components/ui/Chip/IconChip"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"

import styles from "./LibraryPage.module.sass"

export const LibraryPage: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <div className={styles.header}>
            <h2>Library</h2>
          </div>
          <div className={styles.body}>
            {access.canViewManufacturer && (
              <LinkCard
                to={mainRoutes.manufacturer()}
                label="Manufacturer"
                description="Create or edit manufacturer"
                icon={
                  <IconChip color="primary">
                    <Icon name="factory" />
                  </IconChip>
                }
              />
            )}
            {access.canViewContentManager && (
              <LinkCard
                to={mainRoutes.contentManager()}
                label="Content Manager"
                description="Manage your all content at one place"
                icon={
                  <IconChip color="primary">
                    <Icon name="cloud" />
                  </IconChip>
                }
              />
            )}

            {access.canViewProducts && (
              <LinkCard
                to={mainRoutes.products()}
                label="Products"
                description="Manage your products"
                icon={
                  <IconChip color="primary">
                    <Icon name="box" />
                  </IconChip>
                }
              />
            )}
          </div>
        </div>
      </Container>
    </MainLayout>
  )
})

export default LibraryPage
