import React, { useEffect, useState, useMemo } from "react"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as Yup from "yup"
import { useNavigate, useParams } from "react-router-dom"
import { useAlert } from "react-alert"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import List from "@components/ui/List/List"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import Loader from "@components/ui/Loader/BarLoader"
import { AddedDeletedType } from "@framework/types/common"
import Select from "@components/ui/MultiSelect/Select"

import LinkedContentTable from "./LinkedContentTable"
import ChildIdentifiersTable from "./ChildIdentifiersTable"
import ProductsTable from "./ProductsTable"

import styles from "./index.module.sass"

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  parentCategoryId: Yup.string().optional(),
})

const IdentifierEditPage = observer(() => {
  const { id } = useParams()
  const navigate = useNavigate()
  const alert = useAlert()
  const { contentManagerController, identifierController } = useController()
  const [contentIds, setContentIds] = useState<AddedDeletedType>({
    added: [],
    deleted: [],
  })

  const [productIds, setProductIds] = useState<AddedDeletedType>({
    added: [],
    deleted: [],
  })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      parentCategoryId: "",
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        removedContentIds: contentIds.deleted,
        addedContentIds: contentIds.added,
        addedProductIds: productIds.added,
        removedProductIds: productIds.deleted,
      }
      if (id) {
        const success = await identifierController.updateIdentifier(id, data)
        if (success) {
          let showSuccess = true
          if (success.failedToAddProducts?.length) {
            showSuccess = false
            alert.error(
              `Error Adding Products - ${success.failedToAddProducts.join(
                ", "
              )}`
            )
          }
          if (success.failedToRemoveProducts?.length) {
            showSuccess = false
            alert.error(
              `Error Removing Products - ${success.failedToRemoveProducts.join(
                ", "
              )}`
            )
          }

          if (success.failedToLinkContent) {
            showSuccess = false
            alert.error(
              `Failed to link Content - ${success.failedToLinkContent}`
            )
          }
          if (success.failedToRemoveLinkedContent) {
            showSuccess = false
            alert.error(
              `Error Removing link Content ${success.failedToRemoveLinkedContent}`
            )
          }

          if (showSuccess) {
            alert.success("Successfully updated")
            navigate(-1)
          }
        } else {
          alert.error("Failed to update product")
        }
      }
    },
  })
  const { contentManagerStore, identifierStore } = useStore()

  const { categories } = contentManagerStore || {}
  const { identifierDetails, loading, loadingUpdate, loadingList } =
    identifierStore

  useEffect(() => {
    contentManagerController.getCompanies()
  }, [])

  useEffect(() => {
    if (id) {
      identifierController.getIdentifier(id)
    }
  }, [id])

  useEffect(() => {
    if (identifierDetails) {
      formik.setValues({
        name: identifierDetails?.category?.name,
        parentCategoryId: identifierDetails?.category?.parent?.id,
      })
      contentManagerController.getCategories(
        identifierDetails?.category?.company?.id
      )
    }
  }, [identifierDetails])

  const selectedParent = categories?.find(
    (c) => c.id === formik.values.parentCategoryId
  )

  const parentIdentifierOptions = useMemo(() => {
    return categories
      .filter(
        (c) =>
          c.id !== id &&
          !identifierDetails?.childCategories
            ?.map((ci) => ci.name)
            ?.includes(c.name)
      )
      .map((company) => ({
        name: company.id,
        value: company.name,
      }))
  }, [categories, id, identifierDetails?.childCategories])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={<BackButton>{identifierDetails?.category?.name}</BackButton>}
            right={
              <List
                direction="row"
                gutter="16"
                overflow="initial"
                justify="flex-end"
              >
                <Button
                  className={styles.headerButton}
                  color="secondary"
                  size="big"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.headerButton}
                  color="primary"
                  size="big"
                  disabled={loadingUpdate}
                  onClick={formik.submitForm}
                  after={loadingUpdate && <Loader size="small" />}
                >
                  Save
                </Button>
              </List>
            }
          />
          <LoadingCover isLoading={loading} className={styles.container}>
            <div className={styles.mainContent}>
              <FormikProvider value={formik}>
                <div className={styles.inputGrid}>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Identifier Name</Text>
                    <FormTextInput
                      name="name"
                      key="name"
                      placeholder="Enter Identifier name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className={styles.inputContainer}>
                    <Text variant="body2">Manufacturer</Text>
                    <FormTextInput
                      name="companyName"
                      key="companyName"
                      disabled
                      value={identifierDetails?.category?.company?.name}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className={styles.inputContainer} />

                  <div className={styles.inputContainer}>
                    <Text variant="body2">Parent Identifier</Text>
                    <Select
                      clearable
                      placeholder="Add Parent Identifier "
                      options={parentIdentifierOptions}
                      value={formik.values.parentCategoryId}
                      onChange={(parentCategoryId) => {
                        formik.setFieldValue(
                          "parentCategoryId",
                          parentCategoryId
                        )
                      }}
                      loading={loadingList}
                      withError={!!formik.errors.parentCategoryId}
                    />
                  </div>
                </div>

                {!loading && identifierDetails && (
                  <>
                    <ChildIdentifiersTable
                      identifierDetails={identifierDetails}
                      parentName={selectedParent?.name || ""}
                      identifierName={formik.values.name}
                    />
                    <ProductsTable
                      companyId={identifierDetails?.category?.company?.id}
                      productIds={productIds}
                      setProductIds={setProductIds}
                      products={identifierDetails.products}
                    />
                    <LinkedContentTable
                      contentIds={contentIds}
                      identifierDetails={identifierDetails}
                      setContentIds={setContentIds}
                    />
                  </>
                )}
              </FormikProvider>
            </div>
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})

export default IdentifierEditPage
