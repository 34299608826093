import * as React from "react"

import Icon from "../Icon/Icon"
import Button from "../Button/Button"
import { useDropdownContext } from "./DropdownContext"

export const AddButton: React.FC = () => {
  const context = useDropdownContext()

  const trimmedText = context.searchQuery.trim()

  if (!trimmedText) return null

  const handleSelect = async () => {
    await context.onAddOption?.(trimmedText)

    context.setSearchQuery?.("")

    if (context.closeOnSelect) {
      context.onOpenChange(false)
    }
  }

  return (
    <Button
      variant="outlined"
      size="small"
      style={{ margin: 4 }}
      before={<Icon name="plus" />}
      onMouseDown={(e) => {
        handleSelect()
        e.preventDefault()
      }}
    >
      <strong>Add &ldquo;{trimmedText}&rdquo;</strong>
    </Button>
  )
}

export default AddButton
