import React from "react"
import clsx from "clsx"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Icon, { IconProps } from "@components/ui/Icon/Icon"
import { renderVoiceRecognitionError } from "@pages/search/components/SearchWidget/useSpeechRecognizer"

import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/v2/Tooltip"

import styles from "./VoiceInputButton.module.sass"

export interface VoiceInputButtonProps extends ButtonProps<"button"> {
  listening?: boolean
  error?: string | null
}

export const VoiceInputButton: React.FC<VoiceInputButtonProps> = ({
  listening,
  variant = "outlined",
  noPadding = true,
  className,
  error = null,
  ...rest
}) => {
  const withError = error != null

  return (
    <Tooltip color="primary">
      <TooltipTrigger>
        <Button
          className={clsx(styles.controlButton, className)}
          variant={variant}
          color={listening ? "red" : withError ? "gold" : "default"}
          noPadding={noPadding}
          {...rest}
        >
          <VoiceInputIcon listening={listening} withError={withError} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {listening
          ? "Stop Recording"
          : error
          ? renderVoiceRecognitionError(error)
          : "Tap for Speech"}
      </TooltipContent>
    </Tooltip>
  )
}

export interface VoiceInputIconProps extends IconProps {
  listening?: boolean
  withError?: boolean
}

export const VoiceInputIcon: React.FC<VoiceInputIconProps> = ({
  listening = false,
  withError = false,
  className,
  ...rest
}) => {
  return (
    <Icon
      {...rest}
      className={clsx({ [styles.recording]: listening }, className)}
      name={listening ? "record-circle" : withError ? "alert" : "mic-fill"}
    />
  )
}

export default VoiceInputButton
