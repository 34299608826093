import React from "react"

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"

export interface NavItemTooltipProps {
  disabled?: boolean
  text: React.ReactNode
  className?: string
}

export const NavItemTooltip: React.FC<NavItemTooltipProps> = ({
  children,
  text,
  disabled,
  className,
}) => (
  <Tooltip disabled={disabled} placement="right" color="primary">
    <TooltipContent color="primary">{text}</TooltipContent>
    <TooltipTrigger className={className}>{children}</TooltipTrigger>
  </Tooltip>
)

export default NavItemTooltip
