import * as React from "react"

import Icon from "../Icon/Icon"
import { useDropdownContext } from "./DropdownContext"

import styles from "./Select.module.sass"

export const DropdownArrow: React.FC = () => {
  const context = useDropdownContext()

  return (
    <button
      tabIndex={-1}
      onMouseDown={(e) => {
        const { open } = context
        context.onOpenChange(!open)
        if (open) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      type="button"
      className={styles.arrowContainer}
    >
      <Icon
        name="arrow-down"
        rotateAngle={context.open ? 180 : 0}
        className={styles.controlIcon}
      />
    </button>
  )
}

export default DropdownArrow
