import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"

import { useStore } from "@store/index"
import NotFound from "@components/ui/NotFound/NotFound"

import ProductList from "./components/ProductList"
import ProductViewPage from "./components/view"
import ProductEditPage from "./components/edit"

const ProductRoot: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()

  return (
    <Routes>
      <Route path="/" element={<Navigate to="list" />} />
      {access.canViewProducts && (
        <Route path="/list" element={<ProductList />} />
      )}
      {access.canViewProduct && (
        <Route path="/view/:id" element={<ProductViewPage />} />
      )}
      {access.canUpdateProduct && (
        <Route path="/edit/:id" element={<ProductEditPage />} />
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
})

export default ProductRoot
