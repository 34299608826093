import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import { UserAndGroup } from "@framework/types/user"
import { PaginationListMeta } from "@framework/types/utils"

export class CompanyPermissionStore {
  constructor() {
    makeAutoObservable(this)
  }

  entities: UserAndGroup[] = []

  error: string | null = null

  isLading: boolean = false

  meta: PaginationListMeta = {
    total: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
  }

  reloadTrigger = nanoid()
}

export default CompanyPermissionStore
