import React, { useEffect, useState } from "react"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as Yup from "yup"
import { useNavigate, useParams } from "react-router-dom"
import { useAlert } from "react-alert"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import List from "@components/ui/List/List"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import Loader from "@components/ui/Loader/BarLoader"
import getCategoryFromChild from "@utils/category"
import { DocumentTypeId, LinkProducts } from "@framework/types/product"
import Select from "@components/ui/MultiSelect/Select"
import { Option } from "@framework/types/utils"
import { nullToUndefined } from "@utils/form-validation"

import LinkedContentTable from "./LinkedContentTable"
import ProductsTable, { LinkProductInfo } from "./ProductsTable"
import AttributesTable from "./AttributesTable"

import styles from "./index.module.sass"

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required").default(""),
  companyId: Yup.string().required("Manufacturer is required").default(""),
  categoryIds: Yup.array(Yup.string().required().default(""))
    .required()
    .default([]),
  description: Yup.string().transform(nullToUndefined).default(""),
  notes: Yup.string().transform(nullToUndefined).default(""),
})

type FormData = Yup.InferType<typeof validationSchema>

export type AddedDeletedType = {
  added: DocumentTypeId[]
  deleted: DocumentTypeId[]
}

const ProductEditPage = observer(() => {
  const { id } = useParams()
  const navigate = useNavigate()
  const alert = useAlert()
  const { contentManagerController, productController } = useController()
  const [contentIds, setContentIds] = useState<AddedDeletedType>({
    added: [],
    deleted: [],
  })

  // FIXME ???
  const [productIds, setProductIds] = useState<AddedDeletedType>({
    added: [],
    deleted: [],
  })

  const formik = useFormik<FormData>({
    validationSchema,
    initialValues: validationSchema.getDefault(),
    onSubmit: async ({ companyId, name, notes, categoryIds, description }) => {
      const data = {
        companyId,
        name,
        notes,
        categoryIds,
        description,
        addedDocumentIds: contentIds.added,
        deletedDocumentIds: contentIds.deleted,
      }
      if (id) {
        const success = await productController.updateProductDetails(id, data)
        if (success) {
          alert.success("Successfully updated")
          navigate(-1)
        } else {
          alert.error("Failed to update product")
        }
      }
    },
  })
  const {
    contentManagerStore,
    productComparisonStore: { productStore },
  } = useStore()

  const { companies, categories, loadingCategories } = contentManagerStore || {}
  const { productDetails, isProductDetailsLoading, isUpdateProductLoading } =
    productStore

  useEffect(() => {
    contentManagerController.getCompanies()
  }, [])

  useEffect(() => {
    if (id) {
      productController.getProductDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (productDetails) {
      formik.setValues(
        validationSchema.cast(
          {
            companyId: productDetails.company.id,
            name: productDetails.name,
            description: productDetails.description,
            categoryIds: productDetails?.categories?.map(
              (item) => getCategoryFromChild(item).id
            ),
            notes: productDetails.notes,
          },
          { stripUnknown: true }
        )
      )
      contentManagerController.getCategories(productDetails.company.id)
    }
  }, [productDetails])

  const companyOptions: Option[] = React.useMemo(() => {
    return (companies ?? []).map((company) => {
      return {
        name: company.id,
        value: company.name,
      }
    })
  }, [companies])

  const identifierOptions: Option[] = React.useMemo(() => {
    if (formik.values.companyId)
      return (categories ?? []).map((category) => {
        return {
          name: category.id,
          value: category.name,
        }
      })
    return []
  }, [formik.values.companyId, categories])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={<BackButton>{productDetails?.name}</BackButton>}
            right={
              <List
                direction="row"
                gutter="16"
                overflow="initial"
                justify="flex-end"
              >
                <Button
                  className={styles.headerButton}
                  color="secondary"
                  size="big"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.headerButton}
                  color="primary"
                  size="big"
                  disabled={isUpdateProductLoading}
                  onClick={formik.submitForm}
                  after={isUpdateProductLoading && <Loader size="small" />}
                >
                  Save
                </Button>
              </List>
            }
          />
          <LoadingCover
            isLoading={isProductDetailsLoading}
            className={styles.container}
          >
            <div className={styles.mainContent}>
              <FormikProvider value={formik}>
                <div className={styles.inputGrid}>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Product Name</Text>
                    <FormTextInput
                      name="name"
                      key="name"
                      placeholder="Enter Product name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className={styles.inputContainer}>
                    <Text variant="body2">Manufacturer</Text>
                    <Select
                      clearable
                      placeholder="Select the manufacturer"
                      options={companyOptions}
                      value={formik.values.companyId}
                      onChange={(selectedCompanyId) => {
                        formik.setFieldValue("categoryIds", [], false)
                        formik.setFieldValue("companyId", selectedCompanyId)
                        contentManagerController.getCategories(
                          selectedCompanyId || undefined
                        )
                      }}
                      withError={Boolean(
                        formik.touched.companyId && formik.errors.companyId
                      )}
                    />
                  </div>

                  <div className={styles.inputContainer} />

                  <div className={styles.inputContainer}>
                    <Text variant="body2">Identifier Name</Text>
                    <Select
                      clearable
                      loading={loadingCategories}
                      placeholder="Select Identifiers..."
                      isMulti
                      value={formik.values.categoryIds}
                      onChange={(selectedItems) => {
                        formik.setFieldValue("categoryIds", selectedItems)
                      }}
                      options={identifierOptions}
                      withError={Boolean(
                        formik.touched.categoryIds && formik.errors.categoryIds
                      )}
                      disabled={!formik.values.companyId}
                    />
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <Text variant="body2">Product Description</Text>
                  <TextAreaInput
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    withError={Boolean(
                      formik.touched.description && formik.errors.description
                    )}
                    onBlur={formik.handleBlur}
                    type="textarea"
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Text variant="body2">Product Notes</Text>
                  <TextAreaInput
                    name="notes"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    withError={!!(formik.touched.notes && formik.errors.notes)}
                    onBlur={formik.handleBlur}
                    type="textarea"
                  />
                </div>

                {!isProductDetailsLoading && (
                  <>
                    <AttributesTable
                      productId={productDetails?.id || ""}
                      attributes={productDetails?.attributes || []}
                    />
                    <ProductsTable
                      productId={id || ""}
                      products={
                        mapToLinkProductInfo(
                          productDetails?.linkedProducts || [],
                          productDetails?.id || ""
                        ) || []
                      }
                    />
                    <LinkedContentTable
                      contentIds={contentIds}
                      setContentIds={setContentIds}
                    />
                  </>
                )}
              </FormikProvider>
            </div>
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})
export default ProductEditPage

const mapToLinkProductInfo = (
  linkProducts: LinkProducts[],
  productId: string
): LinkProductInfo[] => {
  return linkProducts.map((linkProduct) => ({
    id: linkProduct.id,
    productId,
    relatedProductId: linkProduct.relatedProduct.id,
    relationshipType: linkProduct.relationshipType,
    note: linkProduct.note,
    product: linkProduct.relatedProduct,
  }))
}
