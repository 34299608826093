import { FieldProps } from "formik"
import { observer } from "mobx-react-lite"
import React, { useMemo, useState } from "react"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import ListItem from "@components/ui/ListItem/ListItem"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import { ColorType } from "@framework/types/utils"
import { SearchContext } from "@components/prototypes/SearchContext"
import NotFound from "@components/ui/NotFound/NotFound"

import styles from "./RecentActivityFilterStatusField.module.sass"

interface RecentActivityStatusFieldProps extends FieldProps<string[]> {
  label?: string
  field: any
  form: any
}

const statusOptions = [
  { id: "pending", name: "Pending" },
  { id: "verified", name: "Verified" },
  { id: "not-assigned", name: "Not Assigned" },
]

const iconName: { [key: string]: IconName } = {
  pending: "timer-fill",
  verified: "shield-check-fill",
  "not-assigned": "alert-fill",
}
const iconColor: { [key: string]: ColorType } = {
  pending: "gold",
  verified: "green",
  "not-assigned": "primary",
}
const RecentActivityStatusField: React.FC<RecentActivityStatusFieldProps> =
  observer(({ label, field, form }) => {
    const [open, setOpen] = useState(false)

    const { value: fieldValue = [], name } = field
    const { setFieldValue } = form

    const value = [...fieldValue]
    const context = React.useContext(SearchContext)

    const handleStatusClick = (newValue: { id: string; name: string }) => {
      const foundIdx = value?.findIndex((value) => value === newValue.id)
      if (foundIdx === -1) {
        setFieldValue(name, [...value, newValue.id])
        return
      }
      value.splice(foundIdx, 1)
      setFieldValue(name, value)
    }

    const isChecked = !!value?.length

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    const filteredOptions = useMemo(() => {
      return statusOptions.filter((item) =>
        item.name.toLowerCase().includes(context?.query?.toLowerCase() || "")
      )
    }, [statusOptions, context?.query])

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {!filteredOptions.length && <NotFound />}
            {filteredOptions.map((status) => (
              <ListItem
                key={status.id}
                className={styles.lineItem}
                active={!!value?.find((data) => data === status.id)}
                onClick={() => handleStatusClick(status)}
              >
                <Icon
                  name={iconName[status.id.toLowerCase()]}
                  color={iconColor[status.id.toLowerCase()]}
                />
                <Text>{status.name}</Text>
              </ListItem>
            ))}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  })

export default RecentActivityStatusField
