import React from "react"
import { observer } from "mobx-react-lite"

import {
  SearchBlock,
  SearchEntityStore,
} from "@store/search/search-entity.store"

interface SearchEntityContextStore {
  searchEntity: SearchEntityStore
}

export const SearchEntityContext =
  React.createContext<SearchEntityStore | null>(null)

export const SearchEntityContextProvider: React.FC<SearchEntityContextStore> =
  observer(({ searchEntity, children }) => (
    <SearchEntityContext.Provider value={searchEntity}>
      {children}
    </SearchEntityContext.Provider>
  ))

interface SearchBlockContextStore {
  searchBlockId: string
  answerIndex?: string
}

export const SearchBlockContext =
  React.createContext<SearchBlockContextStore | null>(null)

export const SearchBlockContextProvider: React.FC<SearchBlockContextStore> =
  observer(({ searchBlockId, answerIndex, children }) => (
    <SearchBlockContext.Provider value={{ searchBlockId, answerIndex }}>
      {children}
    </SearchBlockContext.Provider>
  ))

export const useSearchEntityContext = () => {
  const context = React.useContext(SearchEntityContext)
  if (context != null) return context
  throw new Error("Search Entity Context not found")
}

export const useSearchBlockContext = () => {
  const context = React.useContext(SearchBlockContext)
  if (context != null) return context
  throw new Error("Search Block Context not found")
}

export const useSearchEntity = () => {
  const searchEntity = useSearchEntityContext()

  return { searchEntity }
}

export const useUnstructuredSearchEntity = () => {
  const { searchEntity } = useSearchEntity()

  if (!(searchEntity instanceof SearchEntityStore))
    throw new Error("Wrong Search Entity store instance")

  return { searchEntity }
}

export const useSearchBlock = <T extends SearchBlock>(
  t?: new (...args: any) => T
) => {
  const { searchEntity } = useUnstructuredSearchEntity()
  const { searchBlockId, answerIndex } = useSearchBlockContext()
  const searchEntityBlock = searchEntity.getBlockById(searchBlockId)

  if (t != null && !(searchEntityBlock instanceof t))
    throw new Error("Wrong Search Block store instance")

  return {
    searchEntityBlock,
    searchEntity,
    searchBlockId,
    answerIndex,
  } as {
    searchEntityBlock: T
    searchEntity: SearchEntityStore
    searchBlockId: string
    answerIndex?: string
  }
}
