import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import {
  getSelectOptions,
  getSelectedOptionIds,
} from "@utils/subscriptionUtils"
import {
  SubscriptionOption,
  SubscriptionOptionList,
} from "@framework/types/subscription"
import Select from "@components/ui/MultiSelect/Select"

const emptyArray: any[] = []

export interface SimpleTableProps {
  label?: string
  optionName: string
}

export const EditSelect: React.FC<SimpleTableProps> = observer(
  ({ optionName, label = "Select an option" }) => {
    const { subscriptionStore } = useStore()
    const { subscriptionController } = useController()
    const { editedSubscriptionData } = subscriptionStore
    const [isLoading, setIsLoading] = useState(false)

    const { availableOption } = subscriptionStore

    const selectedValue = ((editedSubscriptionData as any)[optionName] ||
      emptyArray) as SubscriptionOption[]

    const selectedOptions = getSelectOptions(
      (availableOption as SubscriptionOptionList) || {},
      optionName,
      selectedValue
    )

    const handleAddNew = async (newOptionName: string) => {
      setIsLoading(true)
      const res = await subscriptionController.addAvailableOption(
        newOptionName,
        optionName
      )
      setIsLoading(false)

      return res?.id ?? null
    }

    const onSelectionChange = (selectedItems: readonly string[]) => {
      const updatedData = selectedOptions.filter((item) =>
        selectedItems.includes(item.id)
      )
      subscriptionController.updateEditData(optionName, updatedData)
    }

    const options = selectedOptions.map((it) => ({
      name: it.id,
      value: it.name,
    }))

    const selectedOptionIds = getSelectedOptionIds(selectedValue)

    return (
      <Select
        isMulti
        creatable
        clearable
        options={options}
        onAddOption={handleAddNew}
        placeholder={label}
        value={selectedOptionIds}
        onChange={onSelectionChange}
        loading={isLoading}
      />
    )
  }
)

export default EditSelect
