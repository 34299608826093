import clsx from "clsx"
import React from "react"

import { ColorType } from "@framework/types/utils"

import colorStyles from "./Button-color.module.sass"
import styles from "./Button-root.module.sass"
import sizeStyles from "./Button-size.module.sass"
import variantStyles from "./Button-variant.module.sass"

export const buttonSizeOptions = [
  "large",
  "big",
  "medium",
  "small",
  "tiny",
] as const

export const buttonVariantOptions = [
  "contained",
  "outlined",
  "text",
  "slim",
] as const

export type ButtonVariant = (typeof buttonVariantOptions)[number]

export type ButtonSize = (typeof buttonSizeOptions)[number]

export type ButtonColor = ColorType

export type ButtonElement = "button" | "a"

type ButtonAdditionalProps<T extends ButtonElement> =
  T extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[T] : unknown

export type ButtonProps<T extends ButtonElement> = ButtonAdditionalProps<T> & {
  as?: T
  variant?: ButtonVariant
  color?: ButtonColor
  size?: ButtonSize
  noPadding?: boolean
  after?: React.ReactNode
  before?: React.ReactNode
}

export const Button = <T extends ButtonElement = "button">({
  children,
  color = "default",
  variant = "contained",
  size = "large",
  as,
  noPadding = false,
  className,
  before,
  after,
  type: propsType,
  ...rest
}: ButtonProps<T>) => {
  const Component = as || "button"

  const type = propsType ?? (Component === "button" ? "button" : undefined)

  return (
    <Component
      {...(rest as any)}
      type={type}
      className={clsx(
        styles.root,
        variantStyles[variant],
        colorStyles[color],
        sizeStyles[size],
        noPadding ? styles.noPadding : "",
        className
      )}
    >
      {before != null && <span className={styles.before}>{before}</span>}
      {children}
      {after != null && <span className={styles.after}>{after}</span>}
    </Component>
  )
}

export default Button
