import React from "react"
import { observer } from "mobx-react-lite"

import NotFound from "@components/ui/NotFound/NotFound"
import Button from "@components/ui/Button/Button"
import { WorkbookReportTypeName } from "@framework/types/workbook-report"

import ProductRecommenderForm from "./ProductRecommenderForm"
import ProductComparisonForm from "./ProductComparisonForm"
import ProductPositioningForm from "./ProductPositioningForm"

import styles from "./WorkbookReportTemplate.module.sass"

export type ReportRequest = {
  id: string
  query: string
  data: any
}

export interface WorkbookReportTemplateProps {
  reportType: WorkbookReportTypeName
  onSubmit?: () => void
  onCancel?: () => void
}

const WorkbookReportTemplate: React.FC<WorkbookReportTemplateProps> = observer(
  ({ reportType, onSubmit, onCancel }) => {
    const handleSubmit = onSubmit
    const handleCancel = onCancel

    const renderForm = () => {
      if (reportType === "PRODUCTRECOMMENDATION")
        return (
          <ProductRecommenderForm
            onSubmit={handleSubmit}
            onClose={handleCancel}
          />
        )

      if (reportType === "PRODUCTCOMPARISON")
        return (
          <ProductComparisonForm
            onSubmit={handleSubmit}
            onClose={handleCancel}
          />
        )

      if (reportType === "PRODUCTPOSITIONING")
        return (
          <ProductPositioningForm
            onSubmit={handleSubmit}
            onClose={handleCancel}
          />
        )

      return null
    }

    return (
      <div className={styles.root}>
        {renderForm() ?? (
          <NotFound
            className={styles.placeholder}
            retryButton={
              <Button size="big" variant="outlined" onClick={handleCancel}>
                Ok
              </Button>
            }
          >
            Such workflow in not exists or being removed.
            <br />
            Please reach out to an administrator to get more details
          </NotFound>
        )}
      </div>
    )
  }
)

export default WorkbookReportTemplate
