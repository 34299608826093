import { MenuItemProps } from "@components/containers/Sidebar/MenuItem"
import { IconName } from "@components/ui/Icon/IconName"

export interface ISelectableList {
  totalSelected: number
  isSelected: (name: string) => boolean
  select: (name: string) => void
  selectAll: (select?: boolean) => void
  isAnySelected: boolean
  isAllSelected: boolean
}

export type Entity = {
  readonly id: string
}

export type Point<T = number> = {
  x: T
  y: T
}

export type Range<T> = { start: T; end: T }

export type DateRange = Range<string>

export interface ContextOption<T> extends MenuItemProps {
  label: string
  icon: IconName
  type: T
}

export type SuccessResponse<T> = {
  status: "SUCCESS"
  data: T
}

export type FailResponse<T = string> = {
  status: "FAILED"
  message: T
}

export type ErrorMessage = {
  status: string
  message: string
}

export const direction2D = ["DOWN", "UP", "LEFT", "RIGHT"] as const

export type Direction2D = (typeof direction2D)[number]

export const filterMode = ["include", "exclude"] as const

export type FilterMode = (typeof filterMode)[number]

export type AddedDeletedType = {
  added: string[]
  deleted: string[]
}

export type SortBy = {
  /**
   * @param name sorting attribute
   */
  name: string
  /**
   * @param direction true - ASC, false - DESC
   */
  direction: boolean
}
