import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import SuggestedQuestions from "./SuggestedQuestions"

import styles from "./AppControlContainer.module.sass"

type AppControlContainerProps = {
  title?: string
  description?: string
  moveDown?: boolean
  disableAnimation?: boolean
}

const AppControlContainer: React.FC<AppControlContainerProps> = observer(
  ({
    title,
    description,
    children,
    moveDown = false,
    disableAnimation = false,
  }) => {
    const isTablet = useMediaQuery(AppMediaQueries.minTablet)
    const isMobile = useMediaQuery(AppMediaQueries.minMobile)

    return (
      <div
        className={clsx(styles.root, {
          [styles.down]: moveDown,
          [styles.withAnimation]: !disableAnimation,
        })}
      >
        {!moveDown && (
          <div className={styles.header}>
            <Text
              variant={isMobile ? "h4" : isTablet ? "h3" : "h2"}
              align="center"
              weight="semibold"
            >
              {title}
            </Text>
            <Text align="center" color="text70Color" weight="normal">
              {description}
            </Text>
          </div>
        )}

        <div className={styles.body}>{children}</div>

        {!moveDown && (
          <div className={styles.footer}>
            <SuggestedQuestions />
          </div>
        )}
      </div>
    )
  }
)

export default AppControlContainer
