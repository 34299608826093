import React from "react"
import { observer } from "mobx-react-lite"
import sortBy from "lodash/sortBy"
import { useAlert } from "react-alert"

import AlertBanner from "@components/ui/AlertBanner/AlertBanner"
import { useStore } from "@store/index"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import BlockCard from "@pages/search/SearchFlow/BlockCard"
import Text from "@components/ui/Typography/Text"
import MarkdownWithCitations from "@components/prototypes/ResultsCard/AnswerSection/MarkdownWithCitations"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import SuggestImprovementsBanner from "@components/prototypes/ExpertOptions/SuggestImprovements/SuggestImprovementBanner"
import MarkdownEditor from "@components/prototypes/ResultsCard/AnswerSection/MarkdownEditor"
import { AnswerStatus, ExpertQuestion } from "@framework/types/question"
import { SummaryQueryType } from "@store/search/types"
import { AnswerResponseData } from "@services/search.service"
import QuestionAttachments from "@pages/questions/components/QuestionForm/QuestionAttachments"
import { pluralize } from "@utils/textUtils"

import AnswerControl from "./AnswerControl"
import ReportChip from "./components/Tabs/UnifiedMatrix/ReportChip"

import styles from "./AnswerSummary.module.sass"

type AnswerSummaryPassageProps = {
  hidePostToExpert?: boolean
}

const AnswerSummaryPassage: React.FC<AnswerSummaryPassageProps> = observer(
  ({ hidePostToExpert = false }) => {
    const {
      searchEntityBlock,
      searchEntity,
      searchBlockId: blockId,
    } = useSearchBlock(SearchSummaryBlockStore)

    const {
      restrictionsStore: access,
      userStore: { user },
      factFinderSolutionStore: {
        searchFlowStore: {
          showSuggestImprovementsBanner,
          selectedReportId,
          showReport,
        },
      },
      knowledgeStore: {
        assignExpertQuestionToSelf,
        updateExpertQuestion,
        loadExpertQuestions,
      },
    } = useStore()

    const alert = useAlert()

    const [isEditingAnswer, setIsEditingAnswer] = React.useState(false)

    const { citationsStore, searchSummary: summary } = searchEntityBlock

    const {
      suggestedSummary,
      expertAnswer,
      isAttachmentsShown,
      setSuggestedSummary,
      setVerifiedAnswer,
      showAttachments,
    } = searchEntity

    React.useEffect(() => {
      loadExpertQuestions()
    }, [])

    const isSummaryDataReady = summary.isLoaded

    const isSummaryLoading =
      !summary.isLoaded && summary.isLoading && searchEntity.isLoading

    const isPassagesDataReady = searchEntityBlock.searchAnswersData != null

    const isPassagesLoading =
      searchEntityBlock.searchAnswersData == null && searchEntity.isLoading

    const getCitationLink = (index: string) =>
      `${searchEntity.id}/${blockId}/${index}`

    const hasSources = !!citationsStore?.totalSources

    if (!isSummaryDataReady || searchEntity.error) return null

    const rawAnswer = searchEntity.allRawData

    const initExpertQuestion = async (
      userId: string,
      rawAnswer: AnswerResponseData[]
    ): Promise<ExpertQuestion> => {
      if (expertAnswer != null) return expertAnswer

      const summaries: string[] = []

      sortBy(searchEntity.blocks, (block) => {
        if (block instanceof SearchSummaryBlockStore) {
          if (block.queryType === "PRODUCT_FINDER") return 1
          if (block.queryType === "TABLE") return 1
          if (block.queryType === "BASIC") return 2
          if (block.queryType === "ADVANCED") return 3
        }
        return 0
      }).forEach((block) => {
        if (block instanceof SearchSummaryBlockStore) {
          const title = getSummaryTitle(block.queryType)
          summaries.push(`### ${title}\n${block.searchSummary.summary}`)
        }
      })

      const summary = summaries.join("")

      const res = await assignExpertQuestionToSelf(
        searchEntity.filter.searchQuery,
        [userId],
        rawAnswer,
        "",
        summary,
        true
      )

      if (!res?.status) {
        alert.error("Failed to update suggestion")
        throw new Error("There was an error submitting suggestion.")
      }

      const question = res.data?.at(0)

      if (question == null) throw new Error("Failed to update suggestion")

      return question
    }

    const submitSuggestion = async (formData: {
      text: string
      attachments: File[]
    }) => {
      if (!user?.id) return

      if (!rawAnswer) return

      try {
        const question = await initExpertQuestion(user?.id, rawAnswer)

        const res = await updateExpertQuestion(
          question.id,
          AnswerStatus.ANSWERED,
          formData.text,
          formData.attachments
        )

        if (res.status === "FAILED") {
          alert.error("Failed to update suggestion")
          throw new Error("Failed to update suggestion")
        }

        setVerifiedAnswer(res.data)
        if (formData.attachments.length) showAttachments()

        alert.success("Suggestion submitted successfully")
        setSuggestedSummary(formData.text)
        setIsEditingAnswer(false)
      } catch (error) {
        console.log(error)
        throw new Error("Failed to submit suggestion")
      }
    }

    // Show suggest improvements banner once the summary is loaded and the user has access to suggest improvements and the summary is not already suggested by the user
    const showSuggestionBanner =
      !searchEntity.isLoading &&
      access.canSuggestImprovements &&
      showSuggestImprovementsBanner &&
      !suggestedSummary

    const someCitationWithWarning =
      access.showSearchContentWarming && citationsStore?.totalSources
        ? citationsStore.citations.find((it) => it.isDocumentOld)
        : undefined

    const summaryText = suggestedSummary || summary.summary || "No summary"

    return (
      <div className={styles.avatarSummary}>
        <BlockCard className={styles.summary}>
          <Text
            className={styles.summaryPassage}
            variant="body2"
            color="text70Color"
          >
            {isSummaryLoading ? (
              <Skeleton count={5} />
            ) : isEditingAnswer ? (
              <MarkdownEditor
                initialValue={summaryText}
                onSave={submitSuggestion}
                onClose={() => {
                  setIsEditingAnswer(false)
                }}
              />
            ) : (
              <>
                <MarkdownWithCitations
                  citationLink={getCitationLink}
                  hideCitations={!hasSources}
                >
                  {summaryText}
                </MarkdownWithCitations>

                {isAttachmentsShown &&
                expertAnswer?.supportingDocuments?.length ? (
                  <QuestionAttachments
                    files={expertAnswer?.supportingDocuments}
                  />
                ) : null}

                {someCitationWithWarning != null ? (
                  <AlertBanner type="info">
                    Warning: This answer cites sources that are older than{" "}
                    {someCitationWithWarning.documentAgeCutoff != null
                      ? pluralize(
                          `${someCitationWithWarning.documentAgeCutoff} year`,
                          someCitationWithWarning.documentAgeCutoff !== 1
                        )
                      : "a few years"}
                    .
                  </AlertBanner>
                ) : null}
              </>
            )}

            {!!searchEntityBlock.reportId && !isEditingAnswer && (
              <ReportChip
                reportId={searchEntityBlock.reportId}
                active={selectedReportId === searchEntityBlock.reportId}
                onClick={() => showReport(searchEntityBlock.reportId)}
              />
            )}
          </Text>

          {summary.showAttachmentTruncationWarning && (
            <AlertBanner type="info">
              Summary may be incomplete due to content exceeding model limit
            </AlertBanner>
          )}

          {access.showAIGeneratedSummaryAlert && !!summary.summary && (
            <AlertBanner>
              This is a Beta version of an AI-generated summary.
            </AlertBanner>
          )}

          {showSuggestionBanner && <SuggestImprovementsBanner />}

          {!searchEntity.isLoading && !isEditingAnswer && (
            <AnswerControl
              hidePostToExpert={hidePostToExpert}
              setIsEditingAnswer={setIsEditingAnswer}
            />
          )}

          {!isPassagesDataReady && !isPassagesLoading && hasSources && (
            <AlertBanner type="error">
              Failed to load summary sources
            </AlertBanner>
          )}
        </BlockCard>
      </div>
    )
  }
)
export default AnswerSummaryPassage

const getSummaryTitle = (queryType: SummaryQueryType) => {
  switch (queryType) {
    case "ADVANCED":
      return "Revised Summary"
    case "TABLE":
      return "Table Summary"
    case "PRODUCT_FINDER":
      return "Product Recommendation"
    default:
      return "Summary"
  }
}
