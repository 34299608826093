import React, { useState } from "react"
import debounce from "lodash/debounce"

import useEvent from "./useEvent"

export const useDebounced = <T extends (...args: any[]) => any>(
  callback: T,
  delay = 500
) => {
  const callbackEvent = useEvent(callback)

  return React.useCallback(
    debounce((...args: Parameters<T>) => callbackEvent(...args), delay),
    [delay]
  )
}

export default useDebounced
