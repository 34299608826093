import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, useParams } from "react-router-dom"

import { useStore } from "@store/index"
import {
  SearchBlockContextProvider,
  SearchEntityContextProvider,
} from "@pages/search/SearchContext/SearchResultContext"
import { SearchDetailsContextProvider } from "@pages/search/SearchContext/SearchDetailsContext"
import SearchEntityStore from "@store/search/search-entity.store"

import SearchFlowPassageContextProvider from "./SearchFlowPassageContextProvider"
import SourceView from "./SourceView/SourceView"

export interface FactFinderSearchPassagesSidebarProps {
  onBack?: () => void
}

export const FactFinderSearchPassagesSidebar: React.FC<FactFinderSearchPassagesSidebarProps> =
  observer(({ onBack }) => {
    const {
      factFinderSolutionStore: { searchFlowStore },
    } = useStore()

    const { searchId, blockId, answerIndex } = useParams<{
      searchId: string
      blockId: string
      answerIndex?: string
    }>()

    if (searchId == null || blockId == null)
      throw new Error(
        "Source Passages Page requires searchId and blockId params"
      )

    const flowExists = searchFlowStore.hasById(searchId)

    React.useEffect(() => {
      if (!flowExists) onBack?.()
    }, [flowExists])

    const entity = searchFlowStore.getById(searchId)

    if (!flowExists || !(entity instanceof SearchEntityStore)) return null
    return (
      <SearchEntityContextProvider searchEntity={entity}>
        <SearchBlockContextProvider
          searchBlockId={blockId}
          answerIndex={answerIndex}
        >
          <SearchFlowPassageContextProvider>
            <SearchDetailsContextProvider>
              <SourceView />
            </SearchDetailsContextProvider>
          </SearchFlowPassageContextProvider>
        </SearchBlockContextProvider>
      </SearchEntityContextProvider>
    )
  })

export default FactFinderSearchPassagesSidebar
