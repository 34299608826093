import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"

import GlossaryPage from "@pages/upload/Glossary/Glossary"
import { useStore } from "@store/index"

import FeaturesPage from "./Features/Features"
import InstanceConfigPage from "./InstanceConfig"
import AdminPanelMenuPage from "./Menu/Menu"
import RolesPage from "./Roles/Roles"
import UsersPage from "./Users/Users"
import AvatarsPage from "./Avatars/Avatars"
import ApiKeys from "./ApiKeys/ApiKeys"
import SubscriptionPage from "./Subscription/Subscription"

const AdminPanelRoot: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()

  return (
    <Routes>
      <Route index element={<AdminPanelMenuPage />} />

      {access.isUsersListAvailable && (
        <Route path="/users/*" element={<UsersPage />} />
      )}

      {access.onlySuperAdminAccess && (
        <Route path="/roles" element={<RolesPage />} />
      )}

      {access.onlySuperAdminAccess && (
        <Route path="/features" element={<FeaturesPage />} />
      )}

      {access.isGlossaryAvailable && (
        <Route path="glossary" element={<GlossaryPage />} />
      )}

      {access.onlySuperAdminAccess && (
        <Route path="config" element={<InstanceConfigPage />} />
      )}

      {access.isAvatarsAvailable && (
        <Route path="avatars" element={<AvatarsPage />} />
      )}

      {access.canAccessAPIKeys && (
        <Route path="api-keys" element={<ApiKeys />} />
      )}

      {access.isSubscriptionPageAvailable && (
        <Route path="/subscription" element={<SubscriptionPage />} />
      )}

      <Route path="/*" element={<Navigate to="." replace />} />
      <Route path="/**" element={<Navigate to="." replace />} />
    </Routes>
  )
})

export default AdminPanelRoot
