import React from "react"

const TasiMeasurementLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    version="1.1"
    id="layer"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1763.2 688.3"
  >
    <g>
      <path
        fill="#F16323"
        d="M11.5,273.1c-1.2,10.5-1.3,19.7-0.1,30.1c6.1,54.5,28.4,104.2,61.8,144.1c4.3,5.1,8.8,10.1,13.4,14.9
		c1.5,1.5,3,3.1,0.4,0.5L0,559.1c112.3-99,273.7-212.2,330.4-347.1c33.1-78.5,8.2-107.8-39.7-107.8
		C204.2,104.3,42.8,199.6,11.5,273.1 M25.4,180.8C131.1,88.2,269,22.7,411.2,32.5C372.8,11.8,328.8,0,282.1,0
		C163.8,0,63.1,75.4,25.4,180.8"
      />
      <path
        fill="#F16323"
        d="M11.5,273.1c-1.2,10.5-1.3,19.7-0.1,30.1c6.1,54.5,28.4,104.2,61.8,144.1c4.3,5.1,8.8,10.1,13.4,14.9
		c1.5,1.5,3,3.1,0.4,0.5L0,559.1c112.3-99,273.7-212.2,330.4-347.1c33.1-78.5,8.2-107.8-39.7-107.8
		C204.2,104.3,42.8,199.6,11.5,273.1 M25.4,180.8C131.1,88.2,269,22.7,411.2,32.5C372.8,11.8,328.8,0,282.1,0
		C163.8,0,63.1,75.4,25.4,180.8"
      />
      <path
        fill="#00AEEF"
        d="M292.3,544.7L292.3,544.7l-71.1,67l105.1-70.4c129.4-21.1,228.2-133.5,228.2-268.9
		c0-21.5-2.5-42.5-7.2-62.5C513.7,312,390.7,458.6,292.3,544.7 M475,200.3C426.1,376.7,211.9,549,80.9,688.3
		c149.8-122.8,364.7-296.1,417.9-487.9c8.3-29.5,12.1-54.6,12.2-75.7c-8.5-13.1-18-25.4-28.6-36.8
		C490.3,114.5,488.7,151.4,475,200.3"
      />
      <path
        fill="#00AEEF"
        d="M292.3,544.7L292.3,544.7l-71.1,67l105.1-70.4c129.4-21.1,228.2-133.5,228.2-268.9
		c0-21.5-2.5-42.5-7.2-62.5C513.7,312,390.7,458.6,292.3,544.7 M475,200.3C426.1,376.7,211.9,549,80.9,688.3
		c149.8-122.8,364.7-296.1,417.9-487.9c8.3-29.5,12.1-54.6,12.2-75.7c-8.5-13.1-18-25.4-28.6-36.8
		C490.3,114.5,488.7,151.4,475,200.3"
      />
      <path
        fill="currentColor"
        d="M763.6,461.8V153.2H643.9V87.8h323.2v65.3H846.9v308.7L763.6,461.8L763.6,461.8z M1761,178.6l-83.2,14.1
		v269.1h83.2V178.6z M1540.7,198.2c-2-19.2-9.4-33.4-22.2-42.8c-12.8-9.4-31.9-14.1-57.5-14.1c-47.1,0-70.6,15.2-70.6,45.6
		c0,10.9,4.9,20,14.7,27.3c9.8,7.3,25.4,13.4,47,18.3c25.5,5.6,47.7,10.9,66.4,15.8c18.7,4.9,35.9,10.5,51.5,16.9
		c8.8,3.4,17.1,7.6,24.8,12.7c7.8,5.1,14.4,11.4,19.8,18.9s9.7,16.5,12.9,27c3.2,10.5,4.8,23.3,4.8,38.3c0,18-4,34-12,47.9
		s-18.8,25.6-32.3,35.2c-13.6,9.6-29.5,16.9-47.9,22s-37.7,7.6-58.1,7.6c-57.9,0-101.4-10.5-130.5-31.5s-44.5-52.2-46.1-93.5h80.2
		c0.4,19.1,8.7,34.3,24.8,45.3c16.2,11.1,36.4,16.6,60.8,16.6c26.3,0,46.3-4.5,59.9-13.5s20.4-21.2,20.4-36.6
		c0-6-0.8-11.5-2.4-16.6s-4.7-9.7-9.3-13.8c-4.6-4.1-10.9-7.8-18.8-11c-8-3.2-18.4-6.1-31.1-8.7c-30.3-6-56.6-12.1-78.7-18.3
		c-22.2-6.2-40.4-13.9-54.8-23.1s-24.9-20.4-31.7-33.5c-6.8-13.2-10.2-29.9-10.2-50.1c0-15.4,3-29.8,9-43.4
		c6-13.5,15.2-25.3,27.5-35.2c12.4-9.9,27.6-17.8,45.8-23.7c18.2-5.8,39.6-8.7,64.3-8.7c23.9,0,45.5,2.9,64.7,8.7
		c19.2,5.8,35.6,14.1,49.4,24.8s24.4,23.7,32,38.9c7.6,15.2,11.8,32,12.6,50.4h-79.1L1540.7,198.2L1540.7,198.2z M1110.1,171.7
		h-0.6l-50.9,148.7h101.2L1110.1,171.7z M1208.8,461.8l-25.1-77.2H1037l-28.1,77.2h-88l141.9-374h96.4l140.1,374H1208.8z"
      />

      <rect fill="#00AEEF" x="1677.8" y="87.8" width="83.7" height="72.4" />

      <path
        fill="currentColor"
        d="M763.8,605.5v-82.7h25.9l15.6,56.4l15.4-56.4h26v82.7h-16.1v-65.1l-17,65.1h-16.7l-17-65.1v65.1H763.8z"
      />
      <path
        fill="currentColor"
        d="M870.7,605.5v-82.7h63.7v14H888v18.3h43.1V569H888v22.5h48v13.9H870.7z"
      />
      <path
        fill="currentColor"
        d="M1034.8,605.5h-18.9l-7.5-18.8h-34.3l-7.1,18.8h-18.4l33.4-82.7h18.3L1034.8,605.5z M1002.9,572.8L991,542
		l-11.6,30.7H1002.9z"
      />
      <path
        fill="currentColor"
        d="M1046.3,578.6l16.9-1.6c1,5.5,3.1,9.5,6.2,12c3.1,2.6,7.3,3.8,12.6,3.8c5.6,0,9.8-1.1,12.6-3.4
		c2.8-2.3,4.2-4.9,4.2-8c0-2-0.6-3.6-1.8-5c-1.2-1.4-3.3-2.6-6.2-3.6c-2-0.7-6.7-1.9-13.9-3.6c-9.3-2.2-15.8-4.9-19.6-8.2
		c-5.3-4.6-7.9-10.1-7.9-16.6c0-4.2,1.2-8.2,3.7-11.8c2.5-3.7,6-6.5,10.7-8.4c4.7-1.9,10.3-2.9,16.9-2.9c10.8,0,18.9,2.3,24.3,6.8
		c5.4,4.6,8.3,10.6,8.6,18.2l-17.3,0.7c-0.7-4.3-2.3-7.3-4.8-9.2c-2.4-1.9-6.1-2.8-11-2.8c-5,0-9,1-11.8,3c-1.8,1.3-2.8,3-2.8,5.1
		c0,2,0.9,3.6,2.6,5c2.2,1.8,7.5,3.6,15.9,5.5c8.4,1.9,14.7,3.9,18.7,6c4,2.1,7.2,4.9,9.5,8.4c2.3,3.6,3.4,7.9,3.4,13.2
		c0,4.7-1.4,9.2-4.1,13.3c-2.7,4.1-6.6,7.2-11.6,9.2c-5,2-11.2,3-18.7,3c-10.9,0-19.2-2.4-25-7.3
		C1050.9,594.9,1047.4,587.8,1046.3,578.6z"
      />
      <path
        fill="currentColor"
        d="M1137.3,522.7h17.3v44.8c0,7.1,0.2,11.7,0.6,13.8c0.7,3.4,2.5,6.1,5.3,8.2c2.8,2.1,6.6,3.1,11.4,3.1
		c4.9,0,8.6-1,11.1-2.9c2.5-1.9,4-4.3,4.5-7.1c0.5-2.8,0.8-7.5,0.8-14.1v-45.8h17.3v43.5c0,9.9-0.5,17-1.4,21.1
		c-0.9,4.1-2.7,7.6-5.2,10.4c-2.5,2.8-5.9,5.1-10.1,6.7s-9.7,2.5-16.5,2.5c-8.2,0-14.4-0.9-18.7-2.7c-4.2-1.8-7.6-4.2-10-7.1
		s-4.1-6-4.9-9.2c-1.1-4.7-1.7-11.7-1.7-21V522.7z"
      />
      <path
        fill="currentColor"
        d="M1230.8,605.5v-82.7h36.5c9.2,0,15.8,0.7,20,2.2c4.2,1.5,7.5,4.1,10,7.9c2.5,3.8,3.7,8.1,3.7,13
		c0,6.2-1.9,11.3-5.7,15.4s-9.4,6.6-17,7.6c3.7,2.1,6.8,4.4,9.3,6.9c2.4,2.5,5.7,7,9.9,13.4l10.5,16.1h-20.7l-12.5-18
		c-4.5-6.4-7.5-10.5-9.1-12.2c-1.6-1.7-3.4-2.8-5.2-3.4c-1.8-0.6-4.7-0.9-8.7-0.9h-3.5v34.5H1230.8z M1248.1,557.7h12.8
		c8.3,0,13.5-0.3,15.6-1c2.1-0.7,3.7-1.8,4.9-3.5c1.2-1.7,1.8-3.7,1.8-6.2c0-2.8-0.8-5-2.3-6.7c-1.5-1.7-3.7-2.8-6.5-3.2
		c-1.4-0.2-5.6-0.3-12.6-0.3h-13.5V557.7z"
      />
      <path
        fill="currentColor"
        d="M1324,605.5v-82.7h63.7v14h-46.3v18.3h43.1V569h-43.1v22.5h48v13.9H1324z"
      />
      <path
        fill="currentColor"
        d="M1410.5,605.5v-82.7h25.9l15.6,56.4l15.4-56.4h26v82.7h-16.1v-65.1l-17,65.1h-16.7l-17-65.1v65.1H1410.5z"
      />
      <path
        fill="currentColor"
        d="M1517.3,605.5v-82.7h63.7v14h-46.3v18.3h43.1V569h-43.1v22.5h48v13.9H1517.3z"
      />
      <path
        fill="currentColor"
        d="M1604.2,605.5v-82.7h16.9l35.1,55.3v-55.3h16.1v82.7h-17.4l-34.6-54v54H1604.2z"
      />
      <path
        fill="currentColor"
        d="M1716.7,605.5v-68.7h-25.5v-14h68.2v14H1734v68.7H1716.7z"
      />
    </g>
  </svg>
)

export default TasiMeasurementLogo
