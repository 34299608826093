import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import sortBy from "lodash/sortBy"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button, {
  ButtonColor,
  ButtonVariant,
} from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { SummaryQueryType } from "@store/search/types"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useStore } from "@store/index"
import { countSuffix } from "@utils/numberUtils"
import { copyToClipboard } from "@utils/clipboard"
import mainRoutes from "@root/main.routes"

import { useUnstructuredSearchEntity } from "../SearchContext/SearchResultContext"

type ExpertProps = {
  color?: ButtonColor
  variant?: ButtonVariant
}

export const Expert: React.FC<ExpertProps> = observer(
  ({ color = "primary", variant = "outlined" }) => {
    const alert = useAlert()

    const { searchEntity } = useUnstructuredSearchEntity()

    const {
      knowledgeStore: { assignQuestionToExpertDirectly },
    } = useStore()

    const { showModal: showDirectAssignModal } = useModal(
      ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL
    )

    const rawAnswer = searchEntity.allRawData

    const handleAssign = () => {
      if (!rawAnswer) return

      const summaries: string[] = []

      sortBy(searchEntity.blocks, (block) => {
        if (block instanceof SearchSummaryBlockStore) {
          if (block.queryType === "PRODUCT_FINDER") return 1
          if (block.queryType === "TABLE") return 1
          if (block.queryType === "BASIC") return 2
          if (block.queryType === "ADVANCED") return 3
        }
        return 0
      }).forEach((block) => {
        if (block instanceof SearchSummaryBlockStore) {
          const title = getSummaryTitle(block.queryType)
          summaries.push(`### ${title}\n${block.searchSummary.summary}`)
        }
      })

      const summary = summaries.join("")

      showDirectAssignModal({
        title: "Post to Expert",
        questions: [searchEntity.filter.searchQuery],
        onSubmit: async (expertIds, questionNote) => {
          const result = await assignQuestionToExpertDirectly(
            searchEntity.filter.searchQuery,
            expertIds,
            rawAnswer,
            questionNote,
            summary
          )

          if (result.status === "FAILED") {
            alert.error(result.message)
            return false
          }

          const totalExperts = expertIds.length

          alert.success(
            `Question successfully assigned to expert${countSuffix(
              totalExperts
            )}`
          )

          return true
        },
        onCopy: async () => {
          const result = await assignQuestionToExpertDirectly(
            searchEntity.filter.searchQuery,
            [],
            rawAnswer,
            "",
            summary
          )

          if (result.status === "FAILED") {
            alert.error(result.message)
            return false
          }

          const questionId = result.data[0].id

          if (!questionId) {
            alert.error("Failed to generate link")
            return false
          }

          copyToClipboard(
            `${window.location.origin}${mainRoutes.question(questionId)}`
          )

          alert.success(`Question link copied to clipboard`)

          return true
        },
      })
    }

    if (rawAnswer == null) return null

    return (
      <Button
        color={color}
        variant={variant}
        size="small"
        before={<Icon name="users" />}
        onClick={handleAssign}
      >
        Post to Expert
      </Button>
    )
  }
)

export default Expert

const getSummaryTitle = (queryType: SummaryQueryType) => {
  switch (queryType) {
    case "ADVANCED":
      return "Revised Summary"
    case "TABLE":
      return "Table Summary"
    case "PRODUCT_FINDER":
      return "Product Recommendation"
    default:
      return "Summary"
  }
}
