import React from "react"

import { UserAndGroup } from "@framework/types/user"
import ListItem from "@components/ui/ListItem/ListItem"
import UserCard from "@components/ui/UserCard/UserCard"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"

import styles from "./EntityCard.module.sass"

interface EntityCardProps {
  user: UserAndGroup
  isSelected?: boolean
  onSelect?: (user: UserAndGroup) => void
  lastItemRef?: React.Ref<HTMLDivElement>
  toRemove?: boolean
}

export const getName = (user: UserAndGroup) => {
  return user.type === "user" ? `${user.firstName} ${user.lastName}` : user.name
}

const EntityCard: React.FC<EntityCardProps> = ({
  user,
  isSelected = false,
  onSelect,
  lastItemRef,
  toRemove = false,
}) => {
  return (
    <ListItem
      color={toRemove ? "red" : "primary"}
      className={styles.root}
      active={isSelected}
      onClick={onSelect ? () => onSelect(user) : undefined}
      ref={lastItemRef}
    >
      <UserCard
        avatarSrc={user.avatarURL}
        fullName={getName(user) || "Deactivated user"}
        metaInfo={
          user.type === "user" ? user.email : `${user.userCount || 0} users`
        }
      />
      {onSelect ? (
        isSelected ? (
          <Tooltip color="primary">
            <TooltipTrigger>
              <Text
                style={{ whiteSpace: "nowrap" }}
                variant="caption2"
                color={toRemove ? "redColor" : "primaryColor"}
              >
                {toRemove ? "To Remove" : "To Add"}
              </Text>
            </TooltipTrigger>
            <TooltipContent>
              {toRemove
                ? `Access will be withdrawn from ${user.type} once confirmed`
                : `Access will be granted to ${user.type} once confirmed`}
            </TooltipContent>
          </Tooltip>
        ) : (
          <Icon
            name={toRemove ? "cross" : "plus"}
            className={styles.hoverVisible}
          />
        )
      ) : null}
    </ListItem>
  )
}

export default EntityCard
