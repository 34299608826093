import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import { ModalsTypes } from "@components/modals/constants"
import Loader from "@components/ui/Loader/BarLoader"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store/index"

import DatatypeCreateForm from "./components/DatatypeCreateForm/DatatypeCreateForm"
import ModalContainer from "../components/Container"

export interface DatatypeCreateModalProps {
  datatypeId?: string
  onSubmit?: () => boolean | void
}

const DatatypeCreateModal: React.FC<DatatypeCreateModalProps> = observer(
  ({ datatypeId, onSubmit }) => {
    const alert = useAlert()

    const { hideModal } = useModal(ModalsTypes.DATATYPE_CREATE_MODAL)

    const {
      knowledgeDatatypesController: { loadDatatypeDetails },
    } = useController()

    const {
      knowledgeDatatypeDetailsStore: { isLoading, data },
    } = useStore()

    React.useEffect(() => {
      const init = async (id: string) => {
        const error = await loadDatatypeDetails(id)
        if (error) alert.error(error)
      }

      if (datatypeId) init(datatypeId)
    }, [datatypeId])

    const datatype = datatypeId ? data : null

    const handleSubmit = () => {
      if (onSubmit?.() ?? true) hideModal()
    }

    return (
      <ModalContainer onClose={hideModal}>
        {isLoading ? (
          <Loader size="large" fluid />
        ) : (
          <DatatypeCreateForm
            datatype={datatype ?? {}}
            onSubmit={handleSubmit}
            onCancel={hideModal}
          />
        )}
      </ModalContainer>
    )
  }
)

export default DatatypeCreateModal
