import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import { DocumentIconType, getDocumentIconSrc } from "@framework/types/utils"
import ProgressBar from "@components/ui/ProgressBar/ProgressBar"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Text from "@components/ui/Typography/Text"

import styles from "./DocumentUploadRow.module.sass"

export interface DocumentUploadRowProps {
  isUploaded?: boolean
  type?: DocumentIconType | string
  name: string
  progress?: number
  error?: string | null
  className?: string
  onCancel?: () => void
}

export const DocumentUploadRow: React.FC<DocumentUploadRowProps> = ({
  isUploaded = false,
  type,
  name,
  progress = -1,
  error,
  className,
  onCancel,
}) => (
  <div className={clsx(styles.root, className)}>
    <div className={styles.container}>
      <div className={styles.icon}>
        <img
          src={getDocumentIconSrc(type)}
          height="28px"
          width="28px"
          alt={type}
        />
      </div>

      <div className={styles.progress}>
        {progress >= 0 ? (
          <ProgressBar
            variant="candy"
            value={progress}
            label={name}
            isDone={isUploaded}
            color="primary"
            progress={
              error ? (
                <ErrorChip message={error} messagePlacement="left" />
              ) : null
            }
          />
        ) : (
          <Text className={styles.label} variant="caption1" title={name}>
            {name}
          </Text>
        )}
      </div>

      {onCancel != null && (
        <Icon name="cross" onClick={onCancel} className={styles.cancelBtn} />
      )}
    </div>
  </div>
)

export default DocumentUploadRow
