import React from "react"
import { nanoid } from "nanoid"
import clsx from "clsx"

import useResizeObserver from "@components/hooks/useResizeObserver"

import styles from "./ShiftContainer.module.sass"

export const ShiftContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> =
  React.memo(({ children, className, ...rest }) => {
    const keyRef = React.useRef(nanoid())
    const rootRef = React.useRef<HTMLDivElement>(null)

    const rect = useResizeObserver(rootRef)

    const [withAnimation, enableAnimation] = React.useState(false)

    React.useLayoutEffect(() => {
      enableAnimation(true)

      const timeout = setTimeout(() => enableAnimation(false), 1000)

      return () => clearTimeout(timeout)
    }, [children])

    return (
      <div
        className={clsx(
          styles.container,
          { [styles.withAnimation]: withAnimation },
          className
        )}
        style={{ height: withAnimation ? rect?.height : "fit-content" }}
        {...rest}
      >
        <div ref={rootRef} className={styles.blink} key={keyRef.current}>
          {children}
        </div>
      </div>
    )
  })

export default ShiftContainer
