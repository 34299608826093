import React from "react"
import { useNavigate } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import useDebounce from "@components/hooks/useDebounce"
import { useStore } from "@store"
import useSearch from "@components/hooks/useSearch"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Button from "@components/ui/Button/Button"
import BackButton from "@components/prototypes/BackButton"
import mainRoutes from "@root/main.routes"

import ProductsList from "./ProductsList"
import ProductsFilter from "../ProductsFilter"

import styles from "./index.module.sass"

const ProductList: React.FC = () => {
  const [searchProps, searchContext] = useSearch()
  const navigate = useNavigate()

  const {
    restrictionsStore: access,
    productComparisonStore: {
      productStore: { updateSearchQuery },
    },
  } = useStore()

  const addProductsModal = useModal(ModalsTypes.ADD_PRODUCTS_MODAL)

  const { debouncedValue: searchQuery, setDebounce } = useDebounce()

  const openIdentifiersPage = () => {
    navigate(mainRoutes.productsIdentifiers())
  }

  const openAddProductsModal = () => {
    addProductsModal.showModal({})
  }

  const clearSearch = () => {
    searchContext.setQuery("")
  }

  React.useEffect(() => {
    setDebounce(searchProps.value)
  }, [searchProps.value])

  React.useEffect(() => {
    updateSearchQuery(searchQuery)
  }, [searchQuery])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={<BackButton to={mainRoutes.library()}>Products</BackButton>}
            right={
              <div className={styles.headerButtons}>
                {access.canViewIdentifier && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="big"
                    onClick={openIdentifiersPage}
                  >
                    Manage Identifiers
                  </Button>
                )}
                {access.canAddProducts && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="big"
                    onClick={openAddProductsModal}
                  >
                    Add Products
                  </Button>
                )}
              </div>
            }
          >
            <TextInput
              {...searchProps}
              className={styles.searchInput}
              before={<Icon name="search" />}
              placeholder="Search"
              after={
                !!searchProps.value && (
                  <Icon name="cross" onClick={clearSearch} />
                )
              }
            />
          </EntityPageHeaderLayout>

          <ProductsFilter />

          <ProductsList />
        </div>
      </Container>
    </MainLayout>
  )
}

export default ProductList
