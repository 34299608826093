import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { Company, CompanyWithMetaInfo } from "@framework/types/company"
import { useController, useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import NotFound from "@components/ui/NotFound/NotFound"

import { ModalsTypes } from "../constants"

import styles from "./LinkPublicDataModal.module.sass"

type CompanyWithLocalIndex = Company & { index?: number }

export type LinkPublicDataModalProps = {
  selectedCompany: CompanyWithMetaInfo
}

const LinkPublicDataModal: React.FC<LinkPublicDataModalProps> = observer(
  ({ selectedCompany }) => {
    const modal = useModal(ModalsTypes.LINK_PUBLIC_DATA_MODAL)
    const { companyStore } = useStore()
    const alert = useAlert()
    const { companyController } = useController()
    const [isCompanyChanged, setIsCompanyChanged] = useState(false)

    const { trialInstanceCompanies, loading, loadingCompanies } = companyStore

    const [selectedPublicCompany, setSelectedPublicCompany] = useState<
      CompanyWithLocalIndex | undefined
    >()

    const handleSelectCompany = (company: CompanyWithLocalIndex) => {
      setIsCompanyChanged(true)
      if (selectedPublicCompany?.id === company.id) {
        setSelectedPublicCompany(undefined)
      } else {
        setSelectedPublicCompany(company)
      }
    }

    const handleLinkCompany = async () => {
      const success = await companyController.linkCompanies({
        companyId: selectedCompany.id,
        publicCompanyId: selectedPublicCompany?.id,
        unlinkPublicCompanyId: selectedCompany?.linkedPublicCompanies?.[0]?.id,
      })

      if (success) {
        alert.success("Successfully linked Manufacturer")
        companyController.requestCompaniesReload()
        modal.hideModal()
      } else {
        alert.error("Failed to link Manufacturer")
      }
    }

    useEffect(() => {
      companyController.fetchTrialInstanceCompanies()
    }, [])

    useEffect(() => {
      const selectedLinkCompanyId =
        selectedCompany?.linkedPublicCompanies?.[0]?.id
      let selectedLinkCompany = {} as CompanyWithLocalIndex
      const selectedLinkCompanyIdx = trialInstanceCompanies?.findIndex(
        (company) => company.id === selectedLinkCompanyId
      )
      if (selectedLinkCompanyIdx !== -1) {
        selectedLinkCompany = trialInstanceCompanies[selectedLinkCompanyIdx]
        selectedLinkCompany.index = selectedLinkCompanyIdx
      }

      setSelectedPublicCompany(selectedLinkCompany)
    }, [trialInstanceCompanies])

    const filteredCompanies = trialInstanceCompanies.filter(
      (company) => company.id !== selectedCompany.id
    )

    const isLinkUnlinkSame =
      selectedCompany?.linkedPublicCompanies?.[0]?.id ===
      selectedPublicCompany?.id

    return (
      <BaseModal
        className={styles.root}
        title={
          <Text className={styles.header} align="center" variant="h2">
            Link to Public Data
          </Text>
        }
      >
        <div className={styles.selectedManufacturersContainer}>
          <Text variant="body2">Manufacturer</Text>
          <div className={styles.selectedManufacturers}>
            <div>
              {selectedPublicCompany?.id && (
                <Button
                  onClick={() => handleSelectCompany(selectedPublicCompany)}
                  before={
                    <Icon
                      className={clsx({
                        [styles.greenIcon]:
                          (selectedPublicCompany?.index || 0) % 3 === 0,
                        [styles.goldIcon]:
                          (selectedPublicCompany?.index || 0) % 3 === 1,
                        [styles.primaryIcon]:
                          (selectedPublicCompany?.index || 0) % 3 === 2,
                      })}
                      name="factory"
                    />
                  }
                  size="tiny"
                  variant="text"
                  after={<Icon name="cross" />}
                >
                  {selectedPublicCompany?.name}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {loadingCompanies && (
            <div className={styles.skeleton}>
              <Skeleton minWidth={100} lineHeight={52} />
              <Skeleton minWidth={100} lineHeight={52} />
              <Skeleton minWidth={100} lineHeight={52} />
              <Skeleton minWidth={100} lineHeight={52} />
            </div>
          )}
          {!loadingCompanies &&
            (filteredCompanies.length ? (
              <div className={styles.list}>
                {filteredCompanies.map((item, index) => (
                  <div
                    key={item.id}
                    className={clsx(styles.company, {
                      [styles.selected]: selectedPublicCompany?.id === item.id,
                    })}
                    role="button"
                    tabIndex={-1}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleSelectCompany({ ...item, index })
                      }
                    }}
                    onClick={() => handleSelectCompany({ ...item, index })}
                  >
                    <Icon
                      name="factory"
                      color="primary"
                      className={styles.icon}
                    />
                    <div>
                      <Text variant="body2" color="text100Color">
                        {item.name}
                      </Text>
                      <Text variant="caption2" color="text50Color">
                        {item.url}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NotFound>No public data found</NotFound>
            ))}
        </div>
        <div className={styles.footer}>
          <Button size="medium" variant="outlined" onClick={modal.hideModal}>
            Cancel
          </Button>
          <Button
            size="medium"
            color="primary"
            disabled={loading || isLinkUnlinkSame || !isCompanyChanged}
            onClick={handleLinkCompany}
            after={loading && <Loader size="small" />}
          >
            Submit
          </Button>
        </div>
      </BaseModal>
    )
  }
)

export default LinkPublicDataModal
