import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import { SearchContext } from "@components/prototypes/SearchContext"
import useDebounced from "@components/hooks/useDebounced"

import ExpertQuestionDetails from "../components/QuestionForm/ExpertQuestionDetails"
import QuestionList from "../components/QuestionList/QuestionList"
import Header from "./Header"

import styles from "./Questions.module.sass"

type ParamsType = {
  questionId?: string
}

interface QuestionsPageProps {}

const QuestionsPage: React.FC<QuestionsPageProps> = observer(() => {
  const { questionId } = useParams<ParamsType>()

  const navigate = useNavigate()

  const {
    knowledgeStore: {
      expertQuestions,
      isExpertQuestionsLoading,
      isUpdateExpertQuestionLoading,
      reloadTrigger,
      reloadExpertQuestions,
      loadExpertQuestions,
    },
    userStore: { user },
  } = useStore()

  const isExpert = React.useMemo(
    () =>
      user?.userRoles?.some(
        (role) =>
          role.name.toLowerCase() === "expert" ||
          role.name.toLowerCase() === "superadmin"
      ),
    [user]
  )

  const [query, setQuery] = React.useState("")

  const questionsList = React.useMemo(() => {
    const list = []

    if (isExpert) {
      list.push(...(expertQuestions.waiting ?? []))
    }

    list.push(...(expertQuestions.answered ?? []))

    return list
  }, [expertQuestions, isExpert])

  const handleQuestionChange = (questionId: string) => {
    navigate(mainRoutes.question(questionId), { replace: true })
  }

  const debouncedReload = useDebounced(reloadExpertQuestions)

  React.useEffect(() => {
    loadExpertQuestions(query)
  }, [reloadTrigger])

  React.useEffect(() => {
    if (
      !isExpertQuestionsLoading &&
      questionsList.length &&
      (!questionId || !questionsList.find(({ id }) => id === questionId))
    ) {
      handleQuestionChange(questionsList[0]?.id ?? "")
    }
  }, [questionsList, questionId, isExpertQuestionsLoading])

  const currentQuestion =
    questionsList.find(({ id }) => id === questionId) ?? null

  const searchContext = React.useMemo(
    () => ({
      query,
      setQuery: (query: string) => {
        setQuery(query)
        debouncedReload()
      },
    }),
    [query]
  )

  return (
    <SearchContext.Provider value={searchContext}>
      <MainLayout>
        <div className={styles.root}>
          <Header className={styles.header} />

          {!questionsList.length ? (
            isExpertQuestionsLoading ? (
              <Loader size="huge" fluid />
            ) : (
              <NotFound>No questions found</NotFound>
            )
          ) : (
            <div className={styles.body}>
              <div className={styles.left}>
                <ScrollableContainer className={styles.questionListContainer}>
                  <QuestionList
                    items={questionsList}
                    activeQuestionId={questionId}
                    onSelect={handleQuestionChange}
                    emptyMessage="No questions found"
                    className={styles.questionList}
                    loading={isExpertQuestionsLoading}
                  />
                </ScrollableContainer>
              </div>

              <div className={styles.right}>
                {isExpertQuestionsLoading || isUpdateExpertQuestionLoading ? (
                  <Loader size="huge" fluid />
                ) : currentQuestion ? (
                  <ExpertQuestionDetails
                    questionData={currentQuestion}
                    key={currentQuestion.id}
                  />
                ) : (
                  <NotFound>No question selected</NotFound>
                )}
              </div>
            </div>
          )}
        </div>
      </MainLayout>
    </SearchContext.Provider>
  )
})

export default QuestionsPage
