import clsx from "clsx"
import React from "react"
import { NavLink, useLocation } from "react-router-dom"

import CitationBadge from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/ImageResult/AnswerOriginalView/components/CitationBadge"

import styles from "./MarkdownNavLink.module.sass"

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode & React.ReactNode[]
  text?: string
}

const MarkdownNavLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ href = "/", className, children, text, ...restProps }, ref) => {
    const location = useLocation()

    const citation = isCitation(text)

    return (
      <NavLink
        ref={ref}
        to={href}
        {...restProps}
        target={citation ? undefined : "_blank"}
        className={clsx(className, {
          [styles.citation]: citation,
        })}
        state={{ backTo: location.pathname }}
      >
        {citation ? <CitationBadge citationNumber={Number(text)} /> : children}
      </NavLink>
    )
  }
)

export default MarkdownNavLink

const isCitation = (children: React.ReactNode) => {
  return !Number.isNaN(Number(children))
}
