export const LIST_TYPES = ["numbered-list", "bulleted-list"]

export const HEADING_LEVEL_MAP = [
  "heading_one",
  "heading_two",
  "heading_three",
  "heading_four",
  "heading_five",
  "heading_six",
]
