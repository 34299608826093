import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import mainRoutes from "@root/main.routes"

import DataTypes from "./DataTypes"
import useAvatarScope from "../AvatarScopeContext/useAvatarScope"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import DataSources from "./DataSources"
import CategoryInfo from "./CategoryInfo"

import styles from "./HomeInfo.module.sass"

const HomeInfo: React.FC = observer(() => {
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const {
    solutionsStore: { category },
    contentManagerStore,
    restrictionsStore: access,
  } = useStore()

  const { contentManagerController } = useController()

  const { avatar } = useAvatarScope()

  useEffect(() => {
    contentManagerController.getCompanies()
  }, [])

  const { loadingCompanies, companies } = contentManagerStore

  const filteredCompanies = companies
    ?.slice()
    ?.filter((company) => (company?.productsCount || 0) > 0)
    ?.sort((a, b) => (b.productsCount ?? 0) - (a.productsCount ?? 0))

  const showProductsSection =
    access.showHomeProducts &&
    (loadingCompanies ? true : !!filteredCompanies.length)

  return (
    <div className={styles.sidebar}>
      {!isMobile && category != null && (
        <CategoryInfo
          category={category.category}
          description={category.description}
          heading={category.heading}
        />
      )}
      {showProductsSection && (
        <div className={styles.section}>
          <Text variant={isTablet ? "h5" : "h3"}>Products</Text>
          <div className={styles.sectionList}>
            {loadingCompanies ? (
              <>
                <Skeleton lineHeight={30} count={2} minWidth={100} />
              </>
            ) : (
              <>
                <div className={styles.listWrapper}>
                  {filteredCompanies.map((company) => (
                    <Link
                      key={company.id}
                      to={`${mainRoutes.products()}?manufacturerId=${
                        company.id
                      }&manufacturerName=${company.name}`}
                      className={styles.link}
                    >
                      <div key={company.id} className={styles.product}>
                        <div>
                          <Icon
                            color="primary"
                            className={styles.productIcon}
                            name="box"
                          />
                          <span>{company.name}</span>
                        </div>
                        <span>{company.productsCount}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {access.showHomeContentTypes && (
        <div className={styles.section}>
          {avatar?.id !== null && (
            <ActiveAvatarContextProvider
              loaderNode={<Skeleton lineHeight={30} count={5} minWidth={100} />}
              avatarId={avatar?.id}
            >
              <DataTypes />
            </ActiveAvatarContextProvider>
          )}
        </div>
      )}
      {access.showHomeContentSources && (
        <div className={styles.section}>
          <DataSources />
        </div>
      )}
    </div>
  )
})

export default HomeInfo
