import React from "react"
import { useSlate } from "slate-react"
import { Range, Transforms } from "slate"
import { observer } from "mobx-react-lite"

import {
  SpeechRecognizerState,
  useSpeechRecognizer,
} from "@pages/search/components/SearchWidget/useSpeechRecognizer"

import { MarkdownEditorContext } from "./EditorContext"

export const EditorVoiceToTextContext =
  React.createContext<SpeechRecognizerState | null>(null)

export const EditorVoiceToTextContextProvider: React.FC<
  React.PropsWithChildren<{
    disable?: boolean
  }>
> = observer(({ disable, children }) => {
  const editorContext = React.useContext(MarkdownEditorContext)
  const editor = useSlate()

  const disabled = Boolean(editorContext?.mode === "preview" || disable)

  const speechControl = useSpeechRecognizer({
    continuous: true,
    disabled,
    onChange: (textToInsert) => {
      const { selection } = editor

      if (selection && !disabled) {
        Transforms.insertText(editor, textToInsert)

        const start = Range.start(selection)
        const end = {
          path: start.path,
          offset: start.offset + textToInsert.length,
        }

        Transforms.setSelection(editor, { anchor: start, focus: end })
      }
    },
  })

  return (
    <EditorVoiceToTextContext.Provider value={disabled ? null : speechControl}>
      {children}
    </EditorVoiceToTextContext.Provider>
  )
})
