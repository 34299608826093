import React from "react"
import { observer } from "mobx-react-lite"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Info from "@components/ui/Tooltip/Info"

import UserStickinessChart from "../components/Chart/UserStickinessChart"

export const UserStickinessWidget: React.FC = observer(() => {
  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Text variant="h4">
            User Stickiness{" "}
            <Info>
              Trend of percentage of users who keep returning to the platform
            </Info>
          </Text>
        }
      />

      <List direction="row" gutter="24" overflow="initial">
        <UserStickinessChart />
      </List>
    </section>
  )
})

export default UserStickinessWidget
