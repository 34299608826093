import React from "react"
import { observer } from "mobx-react-lite"

import { QuestionAttachmentData } from "@framework/types/question"
import DocumentUploadRow from "@pages/upload/components/DocumentUploadRow/DocumentUploadRow"
import { isDocumentIconName } from "@framework/types/utils"
import { getExtension } from "@utils/textUtils"

import styles from "./ExpertQuestionDetails.module.sass"

export const QuestionAttachments: React.FC<{
  files: QuestionAttachmentData[]
}> = observer(({ files }) => {
  return (
    <div className={styles.attachments}>
      {files?.map((file) => {
        const extension = getExtension(file.elasticSearchId)
        const icon = isDocumentIconName(`img:${extension}`)
          ? `img:${extension}`
          : "img:file"

        return (
          <a
            className={styles.noDecoration}
            href={file.signedUrl}
            target="__blank"
          >
            <DocumentUploadRow
              type={icon}
              name={file.elasticSearchId}
              key={file.elasticSearchId}
            />
          </a>
        )
      })}
    </div>
  )
})

export default QuestionAttachments
