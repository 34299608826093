/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import IconButton from "@components/ui/IconButton/IconButton"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import Text from "@components/ui/Typography/Text"
import Avatar from "@components/ui/Avatar/Avatar"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import { DocumentIconType } from "@framework/types/utils"
import {
  TooltipContent,
  Tooltip,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"

import styles from "./Option.module.sass"

export interface OptionProps {
  isEdit?: boolean
  label?: string
  metaInfo?: React.ReactNode
  avatarUrl?: string | null
  checked?: boolean
  onClick?: () => void
  icon?: DocumentIconType | IconName
}

const Option: React.FC<OptionProps> = ({
  isEdit,
  label,
  checked,
  onClick,
  metaInfo,
  avatarUrl,
  icon,
}) => {
  const handleContainerClick = () => {
    if (isEdit) onClick?.()
  }

  const handleCheck: React.MouseEventHandler | undefined = onClick
    ? (e) => {
        e.stopPropagation()
        onClick()
      }
    : undefined

  return (
    <div
      className={clsx(styles.root, {
        [styles.checked]: checked,
        [styles.clickable]: onClick,
      })}
      onClick={handleContainerClick}
    >
      <div className={styles.body}>
        {icon ? (
          <DocumentIcon icon={icon ?? "global"} height={45} width={45} />
        ) : (
          <Avatar src={avatarUrl} name={label} height={45} width={45} />
        )}
        <div>
          <Text variant="h4">{label}</Text>
          {metaInfo && (
            <Text variant="caption1" color="text70Color">
              {metaInfo}
            </Text>
          )}
        </div>
      </div>
      {isEdit ? (
        <Checkbox checked={checked} onClick={handleCheck} />
      ) : (
        <Tooltip color="primary" placement="left">
          <TooltipContent>Remove</TooltipContent>
          <TooltipTrigger>
            <IconButton className={styles.remove} rounded onClick={onClick}>
              <Icon name="cross" circle />
            </IconButton>
          </TooltipTrigger>
        </Tooltip>
      )}
    </div>
  )
}

export default Option
