import React, { useState } from "react"
import { FormikProps } from "formik"

import Text from "@components/ui/Typography/Text"
import Switch from "@components/ui/Switch/Switch"
import { CreateContentSourceParams } from "@framework/types/content-manager"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Icon from "@components/ui/Icon/Icon"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"

import styles from "./ContentManagerConfigFormModal.module.sass"

const SharePointConfig: React.FC<{
  formik: FormikProps<CreateContentSourceParams>
}> = ({ formik }) => {
  const [showSecret, setShowSecret] = useState(false)

  return (
    <>
      <div className={styles.inputContainer}>
        <Text variant="body2">Authentication Method</Text>
        <Switch
          items={[
            { name: "useClientSecret", value: "Use Client Secret" },
            { name: "useCertificate", value: "Use Certificate" },
          ]}
          checked={
            formik.values.useCertificate ? "useCertificate" : "useClientSecret"
          }
          onChange={(name) => {
            const useCertificate = name === "useCertificate"
            if (useCertificate) {
              formik.setFieldValue("clientSecret", "")
            } else {
              formik.setFieldValue("certificate", {
                certificate: "",
                privateKey: "",
              })
            }

            return formik.setFieldValue("useCertificate", useCertificate)
          }}
        />
      </div>

      {formik.values.useCertificate ? (
        <>
          <div className={styles.inputContainer}>
            <Text variant="body2">Certificate</Text>
            <TextAreaInput
              name="certificate.certificate"
              placeholder="Enter Certificate"
              value={formik.values.certificate?.certificate || ""}
              onChange={formik.handleChange}
            />
          </div>

          <div className={styles.inputContainer}>
            <Text variant="body2">Private Key</Text>
            <TextAreaInput
              name="certificate.privateKey"
              placeholder="Enter Private Key"
              value={formik.values.certificate?.privateKey || ""}
              onChange={formik.handleChange}
            />
          </div>
        </>
      ) : (
        <div className={styles.inputContainer}>
          <Text variant="body2">Client Secret *</Text>
          <FormTextInput
            name="clientSecret"
            key="clientSecret"
            placeholder="Enter client secret"
            type={showSecret ? "text" : "password"}
            after={
              <Icon
                name={showSecret ? "eye-close" : "eye"}
                className={styles.eyeIcon}
                onClick={() => setShowSecret(!showSecret)}
              />
            }
          />
        </div>
      )}

      <div className={styles.inputContainer}>
        <Text variant="body2">Tenant ID *</Text>
        <FormTextInput
          name="tenantId"
          placeholder="Enter tenant ID"
          value={formik.values.tenantId}
          onChange={formik.handleChange}
        />
      </div>
      <div className={styles.inputContainer}>
        <Text variant="body2">Tenant Name *</Text>
        <FormTextInput
          name="tenantName"
          key="tenantName"
          placeholder="Enter tenant name"
        />
      </div>
      <div className={styles.inputContainer}>
        <Text variant="body2">Enumerate All Sites</Text>
        <Switch
          items={[
            { name: "enumerateAllSites", value: "Yes" },
            { name: "disableEnumerateAllSites", value: "No" },
          ]}
          checked={
            formik.values.enumerateAllSites
              ? "enumerateAllSites"
              : "disableEnumerateAllSites"
          }
          onChange={(name) => {
            const enumerateAllSites = name === "enumerateAllSites"

            if (enumerateAllSites) {
              formik.setFieldValue("fetchSubsites", false)
            }

            return formik.setFieldValue("enumerateAllSites", enumerateAllSites)
          }}
        />
      </div>
      {!formik.values.enumerateAllSites && (
        <div className={styles.inputContainer}>
          <Text variant="body2">Fetch Subsites</Text>
          <Switch
            items={[
              { name: "fetchSubsites", value: "Yes" },
              { name: "disableFetchSubsites", value: "No" },
            ]}
            checked={
              formik.values.fetchSubsites
                ? "fetchSubsites"
                : "disableFetchSubsites"
            }
            onChange={(name) =>
              formik.setFieldValue("fetchSubsites", name === "fetchSubsites")
            }
          />
        </div>
      )}
    </>
  )
}

export default SharePointConfig
