import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"
import { QueryHistoryExpert } from "@framework/types/analytics"
import { useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"

import ExpertCard from "./ExpertCard"

import styles from "./PostedExperts.module.sass"

export type PostedExpertsProps = {
  onAddNewExperts: () => void
  experts: QueryHistoryExpert[]
  questionId: string
}

const PostedExperts: React.FC<PostedExpertsProps> = observer(
  ({ onAddNewExperts, experts, questionId }) => {
    const alert = useAlert()

    const {
      knowledgeStore: { sendQuestionReminderToExperts },
    } = useStore()

    const [isLoading, setLoading] = useState(false)

    const handleSendReminder = async () => {
      setLoading(true)
      const success = await sendQuestionReminderToExperts(
        questionId,
        experts.map((item) => item.id).filter((id): id is string => !!id)
      )
      setLoading(false)
      if (success) {
        alert.success("Reminder sent successfully")
      } else {
        alert.error("Failed to send Reminder")
      }
    }

    return (
      <div className={styles.postedExperts}>
        <div className={styles.heading}>
          <Text variant="h3">Posted Experts</Text>
          <div className={styles.actions}>
            <Button onClick={onAddNewExperts} size="tiny" variant="outlined">
              Add Other Experts
            </Button>
            <Button
              size="tiny"
              color="primary"
              onClick={handleSendReminder}
              disabled={isLoading}
              after={isLoading && <Loader size="small" />}
            >
              Remind All
            </Button>
          </div>
        </div>
        <List gutter="16" className={styles.postedExpertsList}>
          {experts.map((expert) => (
            <ExpertCard
              expert={expert}
              questionId={questionId}
              key={expert.id ?? expert.name}
            />
          ))}
        </List>
      </div>
    )
  }
)

export default PostedExperts
