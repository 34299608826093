import { makeAutoObservable } from "mobx"

import {
  ReportStatus,
  ReportData,
  ReportETA,
  WorkbookReportEntity,
} from "@framework/types/workbook-report"

const defaultEta = {
  minTimeInSec: 5,
  maxTimeInSec: 10,
}

export default class WorkbookReportStore {
  readonly id: string

  data: ReportData | null = null

  createdAt: string | null = null

  updatedAt: string | null = null

  eta: ReportETA

  status: ReportStatus

  isLoading: boolean = false

  error: string | null = null

  constructor(id: string) {
    this.id = id
    this.eta = { ...defaultEta }
    this.status = "in-progress"

    makeAutoObservable(this)
  }

  update = (data: WorkbookReportEntity) => {
    this.data = data.documentText
    this.status = data.status
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.eta = { ...defaultEta, ...data.eta }
  }
}
