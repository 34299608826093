import React from "react"
import { Slate } from "slate-react"
import { ErrorBoundary } from "react-error-boundary"

import { markdownToSlate } from "../RichEditor/markdown-parser"
import { initViewer } from "../RichEditor/utils"
import Viewer, { ViewerProps } from "../RichEditor/MarkdownViewer"
import AlertBanner from "../AlertBanner/AlertBanner"

interface Props {
  children?: string
  className?: string
  errorFallback?: React.ReactNode
  customElements?: ViewerProps["customElement"]
}

const Markdown: React.FC<Props> = ({
  children = "",
  className,
  errorFallback,
  customElements,
}) => {
  const initialState: any[] = React.useMemo(
    () => markdownToSlate(children),
    [children]
  )

  const editor = React.useMemo(initViewer, [])

  return (
    <ErrorBoundary
      fallback={
        errorFallback == null && (
          <AlertBanner>Unable to render content</AlertBanner>
        )
      }
    >
      <Slate editor={editor} initialValue={initialState}>
        <Viewer className={className} customElement={customElements} />
      </Slate>
    </ErrorBoundary>
  )
}

export default Markdown
