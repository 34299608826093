import React from "react"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import Templates from "@components/ui/Templates"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import ProductsField from "@components/prototypes/ProductPicker/MultipleProductsField"
import ProductSelectorField from "@components/prototypes/ProductPicker/SingleProductField"

import Footer from "./ReportTemplateFooter"

import styles from "./WorkbookReportTemplate.module.sass"

export interface ProductPositioningFormProps {
  onSubmit?: () => Promise<void | boolean> | void | boolean
  onClose?: () => void
}

const validationSchema = yup.object({
  targetProduct: yup.string().label("Target Product").required().default(null),

  alternateProducts: yup
    .array()
    .label("Alternate Products")
    .of(yup.string().required().default(""))
    .min(1, `at least one alternative product need to be provided`)
    .required()
    .default([]),

  application: yup.string().label("Application").required().default(""),
})

export const renderQuery = (
  targetProduct: string,
  alterProducts: string[],
  application: string
) => {
  const targets = `[${targetProduct || "Not provided"}]`
  const alters = alterProducts.length
    ? `${alterProducts.map((it) => `[${it}]`).join(", ")}`
    : "[Not provided]"

  return `Generate a Product Positioning report for product ${targets} against products ${alters} in application to [${application}]`
}

type FormData = yup.InferType<typeof validationSchema>

const ProductPositioningForm: React.FC<ProductPositioningFormProps> = observer(
  ({ onSubmit, onClose }) => {
    const { productComparisonStore } = useStore()

    const { unifiedMatrixController, factFinderSolutionController } =
      useController()

    const [isLoading, setLoading] = React.useState(false)

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (data) => {
        try {
          setLoading(true)

          const target = productComparisonStore.productStore.getProductById(
            data.targetProduct
          )?.name

          if (target == null) throw new Error("Unable to load product")

          const alternates = data.alternateProducts.map((id) => {
            const item = productComparisonStore.productStore.getProductById(id)
            if (item == null) throw new Error("Unable to load product")
            return item.name
          })

          const query = renderQuery(target, alternates, data.application)

          const store = factFinderSolutionController.pushReport({
            type: "PRODUCTPOSITIONING",
            query,
            data,
          })

          if (store == null) return

          const shouldClose = (await onSubmit?.()) ?? true

          const res =
            await unifiedMatrixController.generateProductPositioningReport(
              data.application,
              target,
              alternates
            )

          if (res.status === "SUCCESS") {
            store.setData(res.data)

            if (shouldClose) onClose?.()
            return
          }

          const error =
            "Unexpected error while report generation. Please try again later"

          store.setError(error)
        } finally {
          setLoading(false)
        }
      },
    })

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={clsx(styles.form)}>
          <Templates.RollScript
            gutter="24"
            footerSocket={<Footer isLoading={isLoading} onClose={onClose} />}
          >
            <div className={styles.inlineFlow}>
              <div className={styles.chunk}>
                <Text variant="body1" className={styles.messageText}>
                  Generate a Product Positioning report for product
                </Text>
                <div className={styles.input}>
                  <ProductSelectorField
                    name="targetProduct"
                    placeholder="Select Target product..."
                    clearable={false}
                    disabled={isLoading}
                  />
                </div>
              </div>

              <div className={styles.chunk}>
                <Text variant="body1" className={styles.messageText}>
                  against products
                </Text>
                <div className={styles.input}>
                  <ProductsField
                    name="alternateProducts"
                    placeholder="Select Alternative products..."
                    clearable={false}
                    disabled={isLoading}
                  />
                </div>
              </div>

              <div className={styles.chunk}>
                <Text variant="body1" className={styles.messageText}>
                  in application to
                </Text>
                <div className={styles.input}>
                  <FormTextInput
                    name="application"
                    placeholder="Enter Application..."
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </Templates.RollScript>
        </form>
      </FormikProvider>
    )
  }
)

export default ProductPositioningForm
