import React from "react"
import { observer } from "mobx-react-lite"

import Chip, { ChipProps } from "@components/ui/Chip/Chip"
import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"
import { SimpleBaseUserData, SimpleUserData } from "@framework/types/user"
import { getFullName } from "@pages/questions/utils"

const UserChip: React.FC<{
  data?: SimpleBaseUserData | SimpleUserData | null
  isLoading?: boolean
  color?: ChipProps["color"]
}> = observer(({ data, isLoading = false, color = "secondary" }) => {
  if (data == null && isLoading)
    return (
      <Text variant="caption1" color="text100Color">
        -
      </Text>
    )

  if (data == null)
    return (
      <Chip
        variant="rounded"
        color={color}
        before={<Avatar />}
        title="Deactivated user"
      >
        Deactivated user
      </Chip>
    )

  const fullName = getFullName(data)
  const displayName = fullName || "Deactivated user"

  const avatarUrl = "avatarURL" in data ? data.avatarURL : data.avatarUrl

  return (
    <Chip
      variant="rounded"
      color={color}
      before={<Avatar src={avatarUrl} name={fullName} />}
      title={displayName}
    >
      {displayName}
    </Chip>
  )
})

export default UserChip
