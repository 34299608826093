import { makeAutoObservable } from "mobx"

import { RecentActivityFilters } from "@framework/types/question"

export default class RecentActivityStore {
  constructor() {
    makeAutoObservable(this)
  }

  searchQuery: string = ""

  activeFilter: RecentActivityFilters = {}

  activePage: number = 1

  filterSearchQuery: string = ""

  updateSearchQuery = (query: string) => {
    this.searchQuery = query
  }

  updateFilterSearchQuery = (query: string) => {
    this.filterSearchQuery = query
  }

  updateActivePage = (page: number) => {
    this.activePage = page
  }

  updateActiveFilter = (filter: RecentActivityFilters) => {
    this.activeFilter = filter
  }
}
