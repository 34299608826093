import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import Table from "@components/ui/BaseTable/Table"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import Pagination from "@components/ui/Pagination/Pagination"
import { useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import useQueryParams from "@components/hooks/useQueryParams"
import { initArray } from "@utils/numberUtils"
import NotFound from "@components/ui/NotFound/NotFound"
import mainRoutes from "@root/main.routes"
import {
  TableOrderContext,
  useTableOrder,
} from "@components/ui/BaseTable/TableOrderContext"
import { SortBy } from "@framework/types/common"

import ProductsTableHeader from "./ProductListHeader"
import ProductListRow from "./ProductListRow"
import ProductListRowSkeleton from "./ProductListRowSkeleton"

import styles from "./productList.module.sass"

interface ProductListProps {}

const ProductsList: React.FC<ProductListProps> = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const initialPage = parseInt(queryParams.get("page") || "1", 10)

  const [initialLoad, setInitialLoad] = React.useState(true)

  const updatePageInURL = (page: number) => {
    queryParams.set("page", page.toString())
    navigate({ search: queryParams.toString() }, { replace: true })
  }

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newPage = selected + 1
    setActivePage(newPage)
    updatePageInURL(newPage)
  }

  const searchParams = useQueryParams()

  const manufacturerId = searchParams?.manufacturerId || ""
  const manufacturerName = searchParams?.manufacturerName || ""

  const {
    productComparisonStore: {
      productStore: {
        products,
        loadingProductsList,
        errorProductsList,
        loadProducts,
        productsMetaData: { totalPages },
        searchQuery,
        activeFilter,
        orderBy,
        activePage,
        updateActivePage: setActivePage,
        updateActiveFilter,
        updateSearchQuery,
        updateOrder,
      },
    },
  } = useStore()

  const orderContext = useTableOrder({
    orderBy,
    onOrderChange: updateOrder,
  })

  const getProducts = () => {
    const companyIds = activeFilter.manufacturers.map((company) => company.id)

    loadProducts({
      pagination: {
        page: activePage,
      },
      filters: {
        search: searchQuery,
        companyIds,
        categoryIds: activeFilter.identifiers.map((category) => category.id),
        include: activeFilter.includes,
      },
      ...(orderBy != null
        ? {
            sortBy: orderBy.name,
            sortOrder: orderBy.direction ? "ASC" : "DESC",
          }
        : null),
    })
  }

  React.useLayoutEffect(() => {
    setActivePage(initialPage)
  }, [])

  React.useEffect(() => {
    getProducts()
  }, [activePage, orderBy])

  React.useEffect(() => {
    const isManufacturerActive = !!activeFilter.manufacturers.some(
      (manufacturer) => manufacturer.id === manufacturerId
    )

    if (!isManufacturerActive) {
      navigate(`${mainRoutes.products()}?page=${activePage}`, { replace: true })
    }

    if (initialLoad) {
      setInitialLoad(false)
      return
    }

    if (activePage === 1) {
      getProducts()
    } else {
      setActivePage(1)
      navigate(`${mainRoutes.products()}?page=1`, { replace: true })
    }
  }, [searchQuery, activeFilter, orderBy])

  React.useEffect(() => {
    if (!manufacturerId) return

    updateActiveFilter({
      ...activeFilter,
      manufacturers: [
        ...activeFilter.manufacturers,
        {
          id: manufacturerId,
          name: manufacturerName,
        },
      ],
    })
  }, [manufacturerId])

  React.useEffect(() => {
    // cleanup all
    return () => {
      updateActiveFilter({
        manufacturers: [],
        identifiers: [],
        includes: true,
      })

      updateSearchQuery("")
    }
  }, [])

  const isEmptyProducts = !searchQuery && products.length === 0

  if (isEmptyProducts && !loadingProductsList && !errorProductsList) {
    return (
      <div className={styles.productList}>
        <NotFound className={styles.notFoundBox}>
          No Products have been added yet!
        </NotFound>
      </div>
    )
  }

  return (
    <TableOrderContext.Provider value={orderContext}>
      <div className={styles.productListContainer}>
        <Table className={styles.productList} header={<ProductsTableHeader />}>
          {products.map((productData) => {
            return (
              <ProductListRow
                onDelete={getProducts}
                key={productData.id}
                product={productData}
              />
            )
          })}

          {loadingProductsList &&
            initArray(15, (index) => (
              <ProductListRowSkeleton key={index + 1} />
            ))}

          {errorProductsList && (
            <Text className={styles.alertText} variant="body1" color="redColor">
              {errorProductsList}
            </Text>
          )}

          {!errorProductsList &&
            !loadingProductsList &&
            products.length === 0 && (
              <NotFound className={styles.notFoundBox}>
                No products found
              </NotFound>
            )}
        </Table>
        <EntityPageHeaderLayout
          right={
            <Pagination
              pageCount={totalPages}
              forcePage={activePage - 1}
              onPageChange={handlePageChange}
            />
          }
        />
      </div>
    </TableOrderContext.Provider>
  )
})

export default ProductsList
