import { makeAutoObservable } from "mobx"

import { UserAndGroup } from "@framework/types/user"

export class SolutionPermissionStore {
  constructor() {
    makeAutoObservable(this)
  }

  permittedEntities: UserAndGroup[] = []

  error: string | null = null

  isLading: boolean = false
}

export default SolutionPermissionStore
