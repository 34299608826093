import clsx from "clsx"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { Option } from "@framework/types/utils"

import Button from "../Button/Button"
import { Icon } from "../Icon/Icon"
import { TabsItem } from "./TabsItem"
import { RenderItemCallbackType } from "./types"

import styles from "./TabsCollection.module.sass"

const ADDITIONAL_SCROLL = 500
const DEFAULT_SCROLL = 300

export interface TabsCollectionProps<T extends string> {
  defaultValue?: T
  items: Option<T>[]
  className?: string
  arrowClassName?: string
  underlined?: boolean
  disabled?: boolean
  onSelect?: (name: T) => void
  renderItem?: RenderItemCallbackType<T>
}

export const TabsCollection = <T extends string = string>({
  defaultValue,
  items,
  className,
  arrowClassName,
  underlined = false,
  disabled = false,
  onSelect,
  renderItem = defaultRenderItemCallback,
}: TabsCollectionProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<HTMLElement | null>()

  const containerNode = containerRef.current

  const [value, setValue] = useState<T | undefined>(defaultValue)

  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)

  const handleSelect = useCallback(
    (item: Option<T>, e: React.MouseEvent<HTMLElement>) => {
      setValue(item.name)
      onSelect?.(item.name)

      if (!containerNode) return

      const button = e.target as HTMLButtonElement

      if (!button) return

      const forwardDiff =
        Math.floor(button.offsetLeft + button.offsetWidth) -
        Math.floor(containerNode.scrollLeft + containerNode.offsetWidth)

      if (forwardDiff > 0) {
        const newScrollLeft =
          containerNode.scrollLeft + forwardDiff + ADDITIONAL_SCROLL
        containerNode.scroll({
          left: newScrollLeft,
          behavior: "smooth",
        })
      }

      const backDiff = containerNode.scrollLeft - button.offsetLeft

      if (backDiff > 0) {
        const newScrollLeft =
          containerNode.scrollLeft - backDiff - ADDITIONAL_SCROLL
        containerNode.scroll({
          left: newScrollLeft,
          behavior: "smooth",
        })
      }
    },
    [onSelect]
  )

  const handleArrowClick = (shift: number) =>
    containerNode &&
    containerNode?.scroll({
      left: containerNode.scrollLeft + shift,
      behavior: "smooth",
    })

  const handleScroll = useCallback(() => {
    setShowLeft(canScrollLeft(containerNode as HTMLElement))
    setShowRight(canScrollRight(containerNode as HTMLElement))
  }, [containerNode])

  const handleWheel = (e: React.UIEvent) => {
    if (!e) return
    if (containerNode) {
      containerNode.scrollLeft += (e as any).deltaY ?? 0
    }
    e.preventDefault()
  }

  useEffect(() => {
    handleScroll()
  }, [])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (!value) activeRef.current = null
  }, [value])

  const updateActiveNode = useCallback(
    (newElement: HTMLElement) => {
      if (!newElement) {
        activeRef.current = newElement
        return
      }
      if (activeRef.current === newElement) return
      activeRef.current = newElement
      if (activeRef.current && containerNode) {
        const center =
          newElement.offsetLeft -
          containerNode.offsetWidth / 2 +
          newElement.offsetWidth / 2
        containerNode.scroll({
          left: center,
        })
        handleScroll()
      }
    },
    [containerNode, handleScroll]
  )

  const context = {
    handleSelect,
  }

  return (
    <div
      className={clsx(
        styles.root,
        { [styles.underlined]: underlined, [styles.disabled]: disabled },
        className
      )}
    >
      <div
        className={styles.scrollableContainer}
        ref={containerRef}
        onScroll={handleScroll}
        onWheel={handleWheel}
      >
        <div className={styles.items}>
          {showLeft && (
            <Button
              size="small"
              noPadding
              className={clsx(styles.leftArrow, arrowClassName)}
              onClick={() => handleArrowClick(-DEFAULT_SCROLL)}
            >
              <Icon name="arrow-down" rotateAngle={90} />
            </Button>
          )}
          {showRight && (
            <Button
              size="small"
              noPadding
              className={clsx(styles.rightArrow, arrowClassName)}
              onClick={() => handleArrowClick(DEFAULT_SCROLL)}
            >
              <Icon name="arrow-down" rotateAngle={-90} />
            </Button>
          )}
          {items.map((item) => {
            const isActive = item.name === value
            return (
              <span
                ref={isActive ? updateActiveNode : null}
                className={styles.itemContainer}
                key={item.name}
              >
                {renderItem({ ...context, item, isActive })}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const defaultRenderItemCallback: RenderItemCallbackType = ({
  item,
  isActive,
  handleSelect,
}) => (
  <TabsItem
    onClick={(e) => handleSelect(item, e)}
    active={isActive}
    key={item.name}
  >
    {item.icon && <Icon name={item.icon} />} {item.value}
  </TabsItem>
)

const canScrollLeft = (element?: HTMLElement) => {
  if (!element) return false
  return element.scrollLeft > 0
}

const canScrollRight = (element?: HTMLElement) => {
  if (!element) return false
  const value =
    Math.floor(element.scrollLeft + element.offsetWidth - element.scrollWidth) <
    0
  return value
}

export default TabsCollection
