import React from "react"
import sortBy from "lodash/sortBy"

import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import DocumentUploadRow from "@pages/upload/components/DocumentUploadRow/DocumentUploadRow"
import { getExtension } from "@utils/textUtils"
import { isDocumentIconName } from "@framework/types/utils"
import Text from "@components/ui/Typography/Text"

const UploadingFilesList: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { fileUploadingStore } = useStore()

  const totalUploaded = fileUploadingStore.tasks.length

  return (
    <Templates.RollScript
      className={className}
      gutter="8"
      headerSocket={
        totalUploaded > 0 && (
          <Text variant="caption1">
            {totalUploaded} files{" "}
            <Text variant="inherit" color="text50Color" inline>
              are attached
            </Text>
          </Text>
        )
      }
    >
      {totalUploaded > 0 ? (
        <List gutter="8">
          {sortBy(fileUploadingStore.tasks, (it) => {
            if (it.status === "FAILED") return 1
            if (it.status === "IN_PROGRESS") return 2
            return 3
          }).map((task) => {
            const file = task.data
            const extension = getExtension(file.name)
            const icon = isDocumentIconName(`img:${extension}`)
              ? `img:${extension}`
              : "img:file"

            return (
              <DocumentUploadRow
                name={file.name}
                type={icon}
                progress={100}
                isUploaded={task.status === "SUCCESS"}
                error={
                  task.status === "FAILED"
                    ? task.error ?? "Unexpected error"
                    : null
                }
                key={task.id}
              />
            )
          })}
        </List>
      ) : null}
    </Templates.RollScript>
  )
}

export default UploadingFilesList
