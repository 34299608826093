import React from "react"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import HeaderCell from "@components/ui/BaseTable/HeaderCell"

const IdentifierTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <HeaderCell name="name">Name</HeaderCell>
      <HeaderCell name="parent">Parent Identifier</HeaderCell>
      <HeaderCell name="company">Manufacturer</HeaderCell>
      <HeaderCell name="contentCount">Content</HeaderCell>
      <HeaderCell name="products">Products</HeaderCell>
      <HeaderCell width={38}> </HeaderCell>
    </Row>
  )
})

export default IdentifierTableHeader
