/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import Checkbox, { CheckboxProps } from "./Checkbox"

import styles from "./CheckboxWithLabel.module.sass"

export interface CheckboxWithLabelProps extends CheckboxProps {
  labelClassName?: string
  label?: React.ReactNode
  after?: React.ReactNode
  title?: string
  onCheck?: React.MouseEventHandler
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  className,
  labelClassName,
  label,
  after,
  id,
  title,
  onClick,
  onCheck,
  ...rest
}) => {
  const classes = clsx(styles.root, className)

  const handleCheck: React.MouseEventHandler | undefined = onCheck
    ? (e) => {
        e.stopPropagation()
        onCheck(e)
      }
    : undefined

  return (
    <label className={classes} htmlFor={id} onClick={onClick}>
      <Checkbox id={id} {...rest} onClick={handleCheck} />
      {label != null && (
        <span className={clsx(styles.label, labelClassName)} title={title}>
          {label}
        </span>
      )}
      {after}
    </label>
  )
}

export default CheckboxWithLabel
