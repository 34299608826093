import { observer } from "mobx-react-lite"
import moment from "moment"
import React from "react"

import DatePicker from "@components/ui/DatePicker/DatePicker"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"
import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"

const DealToValue = observer(() => {
  const {
    subscriptionStore: { isEditable, data },
  } = useStore()

  const { subscriptionController } = useController()

  const initialDate = React.useMemo(
    () => moment(data.purchasedDate || new Date(), YYYY_MM_DD_FORMAT).toDate(),
    [data]
  )

  const handleChangePeriod = (value?: Date | Date[]) => {
    if (!value || Array.isArray(value)) return

    subscriptionController.updateEditData(
      "purchasedDate",
      moment(value).format(YYYY_MM_DD_FORMAT)
    )
  }

  const formattedDate = moment(data.purchasedDate).format("LL")

  return isEditable ? (
    <DatePicker
      multiSelect={false}
      defValue={initialDate}
      onChange={handleChangePeriod}
    />
  ) : (
    <>
      <Text variant="body2">{formattedDate}</Text>
    </>
  )
})

export default DealToValue
