import React from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import styles from "./SearchInput.module.sass"

export const SearchSubmitButton: React.FC<{
  autoFocus?: boolean
  disabled?: boolean
}> = ({ autoFocus, disabled }) => {
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  return (
    <Button
      size={isMobile ? "small" : "big"}
      color="primary"
      type="submit"
      autoFocus={autoFocus}
      disabled={disabled}
      className={styles.searchButton}
    >
      <Icon name="arrow-up" />
    </Button>
  )
}

export default SearchSubmitButton
