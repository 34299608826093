import { SimpleBaseUserData, SimpleUserData } from "./user"

export type QuestionAssignityType = "waiting" | "answered" | "declined"

export interface KnowledgeQuestion {
  id: string
  question: string
  avatarName: string
  avatarId: string
  isAssigned?: boolean
}

export interface ExpertVerification extends SimpleBaseUserData {
  updatedAt: string
  createdAt: string
  summary?: string
  summaryType: summaryType
}

export enum summaryType {
  Verified = "verified",
  Suggestion = "suggestion",
}
export interface ExpertQuestion extends KnowledgeQuestion {
  answer: string | null
  author: SimpleUserData
  assignee: SimpleUserData | null
  assignees?: SimpleUserData[]
  /**
   * @deprecated
   */
  // thread: Record<
  //   string,
  //   {
  //     author: SimpleUserData
  //     answer: string | null
  //     lastUpdated?: string
  //   }
  // >
  channel: string // ==> avatar
  lastUpdated: string
  status: AnswerStatus
  delegatedToSelf: boolean
  userNote: string
  summary?: string
  verifiedBy: ExpertVerification[]
  supportingDocuments?: QuestionAttachmentData[]
}

export interface ExpertQuestionDTO extends KnowledgeQuestion {
  answer: string | null
  author: SimpleUserData
  assignee: SimpleUserData | null
  userNote: string
  channel: string // ==> avatar
  lastUpdated: string
  status: AnswerStatus
  delegatedToSelf: boolean
  verifiedBy: ExpertVerification[]
  supportingDocuments: QuestionAttachmentData[]
}

export type QuestionAttachmentData = {
  createdAt: string
  elasticSearchId: string
  fileUrl: string
  id: string
  knowledgeGapId: string
  signedUrl: string
}

export type ExpertQuestionsDataDTO = Record<
  QuestionAssignityType,
  ExpertQuestionDTO[] | undefined
>

export type ExpertQuestionsData = Record<
  QuestionAssignityType,
  ExpertQuestion[] | undefined
>

export enum AnswerStatus {
  UNASSIGNED = "unassigned",
  WAITING = "waiting",
  ANSWERED = "answered",
  DECLINED = "declined",
}

export type RecentActivityFilters = {
  status?: string[]
  connectedDate?: {
    dateRangeStart: number
    dateRangeEnd: number
  }
}
