import * as React from "react"

interface MinimalInputProps extends React.HTMLProps<HTMLInputElement> {
  minWidth?: number
}
export const MinimalInput = React.forwardRef<
  HTMLInputElement,
  MinimalInputProps
>(
  (
    { value, placeholder, minWidth = 10, className, style, ...props },
    forwardedRef
  ) => {
    const shadowRef = React.useRef<HTMLSpanElement>(null)

    const [width, setWidth] = React.useState(0)

    const text = value || placeholder || " "

    React.useEffect(() => {
      const rect = shadowRef.current?.getBoundingClientRect()
      setWidth(rect?.width || 0)
    }, [text])

    return (
      <span
        style={{
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <span
          ref={shadowRef}
          className={className}
          style={{
            position: "absolute",
            visibility: "hidden",
            color: "transparent",
            height: 0,
            whiteSpace: "pre",
          }}
        >
          {text}
        </span>

        <input
          {...props}
          value={value}
          placeholder={placeholder}
          className={className}
          ref={forwardedRef}
          style={{ ...style, width, minWidth, maxWidth: "100%" }}
        />
      </span>
    )
  }
)

export default MinimalInput
