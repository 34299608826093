import React from "react"

import Icon from "@components/ui/Icon/Icon"
import {
  Placement,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"

import styles from "./Info.module.sass"

export const Info: React.FC<
  React.PropsWithChildren<{ placement?: Placement }>
> = ({ children, placement = "top" }) => {
  return (
    <Tooltip color="primary" placement={placement}>
      <TooltipContent>{children}</TooltipContent>
      <TooltipTrigger>
        <Icon name="info" inline className={styles.icon} />
      </TooltipTrigger>
    </Tooltip>
  )
}

export default Info
