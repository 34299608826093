import React, { ReactNode, useMemo, useState } from "react"
import ReactJson from "react-json-view"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import Chip from "@components/ui/Chip/Chip"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Avatar from "@components/ui/Avatar/Avatar"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import Text from "@components/ui/Typography/Text"
import { ConnectorDocument } from "@framework/types/content-manager"
import { IconName } from "@components/ui/Icon/IconName"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { useAppThemeManager } from "@components/theme/AppThemeProvider"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import { formatStringToJSON } from "@utils/textUtils"
import { useStore } from "@store"
import { Company } from "@framework/types/company"

import ContentInfo from "./ContentInfo"
import ContextMenu from "./ContextMenu"

import styles from "./ContentRow.module.sass"

type ContentData = {
  title: string
  icon: ReactNode
  info: ReactNode
}

export type ContentRowProps = {
  data: ConnectorDocument
  onSelect: (doc: ConnectorDocument) => void
  onDelete?: (doc: ConnectorDocument) => void
  selected: boolean
  showContentTypeModal?: (doc: ConnectorDocument) => void
  isCompact?: boolean
  isLink?: boolean
}

const ContentRow: React.FC<ContentRowProps> = observer(
  ({
    data,
    selected,
    onSelect,
    showContentTypeModal,
    onDelete,
    isCompact,
    isLink,
  }) => {
    const [showMore, setShowMore] = useState(false)
    const { restrictionsStore: access } = useStore()

    const {
      contentType,
      source,
      connectorName,
      connectedBy,
      lastModifiedDateTime,
      docInfo,
      id,
      uploadedBy,
      isTrialDoc,
      companies,
    } = data

    const desc = getDataSourceNodeContextProps(source)

    const isManual = source.toString() === "manual"
    const isWebsite = source.toString() === "website"
    const metaData: any = useMemo(() => {
      return docInfo?.metadata ? formatStringToJSON(docInfo?.metadata) : {}
    }, [docInfo?.metadata])

    const rows: ContentData[] = useMemo(
      () => [
        {
          title: "Content Type",
          icon: contentType?.iconUrl ? (
            <Icon color="primary" name={contentType.iconUrl as IconName} />
          ) : null,
          info: contentType?.name,
        },
        {
          title: "Content Source",
          icon: <DocumentIcon icon={desc.icon} />,
          info: `${desc.displayName}${isTrialDoc ? " (Public)" : ""}`,
        },
        ...(isCompact
          ? []
          : [
              {
                title: "Manufacturers",
                icon: null,
                info: renderCompanies(companies),
              },
            ]),
        ...(isManual || isWebsite
          ? []
          : [
              {
                title: "Content Connector",
                icon: <Icon name="storage" />,
                info: connectorName,
              },
            ]),
        ...(isCompact || !isWebsite
          ? []
          : [
              {
                title: "Website Name",
                icon: null,
                info: metaData.websiteName,
              },
              {
                title: "Page URL",
                icon: null,
                info: metaData.webpageUrl ? (
                  <a
                    href={metaData.webpageUrl}
                    target="_blank"
                    className={styles.webpageUrl}
                    rel="noopener noreferrer"
                  >
                    {metaData.webpageUrl}
                  </a>
                ) : (
                  "N/A"
                ),
              },
              {
                title: "Page Name",
                icon: null,
                info: metaData.webpageName || "N/A",
              },
            ]),
        ...(isCompact
          ? []
          : [
              {
                title: "Connected By",
                icon: (
                  <Avatar
                    name={connectedBy?.name || uploadedBy?.name}
                    src={connectedBy?.avatarUrl || uploadedBy?.avatarUrl}
                  />
                ),
                info: uploadedBy?.name || connectedBy?.name,
              },
            ]),
      ],
      [
        contentType,
        source,
        connectorName,
        connectedBy,
        lastModifiedDateTime,
        metaData,
        isCompact,
      ]
    )
    const { activeColorSchemeName } = useAppThemeManager()
    const isDarkTheme = activeColorSchemeName === "Default Dark"
    const theme = useAppConfig()

    const moreRows: ContentData[] = useMemo(
      () => [
        ...(isCompact
          ? [
              {
                title: "Manufacturers",
                icon: null,
                info: renderCompanies(companies),
              },
              {
                title: "Connected By",
                icon: (
                  <Avatar
                    name={connectedBy?.name || uploadedBy?.name}
                    src={connectedBy?.avatarUrl || uploadedBy?.avatarUrl}
                  />
                ),
                info: uploadedBy?.name || connectedBy?.name,
              },
            ]
          : []),
        ...Object.entries(docInfo).map(([key, value], index) => {
          const collapsed =
            (typeof value === "object" ? JSON.stringify(value) : `${value}`)
              ?.length > 500
          return {
            title: key,
            icon: null,
            info: !value ? null : (
              <ReactJson
                src={
                  typeof value === "object" ? (value as any) : { [key]: value }
                }
                name={false}
                collapsed={collapsed}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize
                theme={isDarkTheme ? "google" : "rjv-default"}
                style={{
                  background: "transparent",
                  fontFamily:
                    theme?.typography?.variables?.["main-font-family"],
                  fontSize: "14px",
                }}
              />
            ),
          }
        }),
      ],
      [docInfo, isDarkTheme, isCompact]
    )

    const allData = useMemo(
      () => [...rows, ...(showMore ? moreRows : [])],
      [rows, moreRows, showMore]
    )

    const showContextOptions =
      access.canAssignContentType || access.canDeleteContent

    return (
      <div className={clsx(styles.root, { [styles.compact]: isCompact })}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              {showContextOptions && (isLink || !isTrialDoc) && (
                <Checkbox checked={selected} onClick={() => onSelect(data)} />
              )}
              <Text variant="body2">Content ID:</Text>
              <Text variant="body2" className={styles.documentId}>
                {id}
              </Text>
              <Chip color="green-solid" variant="rounded">
                New
              </Chip>
            </div>
            {onDelete && showContentTypeModal && !isTrialDoc && (
              <div className={styles.headerAction}>
                <ContextMenu
                  showContentTypeModal={() => showContentTypeModal(data)}
                  deleteDocument={() => {
                    onDelete(data)
                  }}
                />
              </div>
            )}
          </div>
          {allData.map((item, index) => (
            <ContentInfo
              key={item.title}
              isStriped={index % 2 === 0}
              title={item.title}
              icon={item.icon}
              info={item.info}
            />
          ))}
        </div>
        <div
          className={styles.expander}
          tabIndex={0}
          role="button"
          onClick={() => setShowMore(!showMore)}
          onKeyPress={() => {}}
        >
          <Icon name="arrow-down" rotateAngle={180} />
          <Icon name="arrow-down" />
        </div>
      </div>
    )
  }
)

export default ContentRow

const renderCompanies = (companies: Company[] = []) => {
  return (
    companies
      .reduce<string[]>((acc, it) => {
        if (it?.name) acc.push(it.name)
        return acc
      }, [])
      .join(", ") || "N/A"
  )
}
