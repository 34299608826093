import * as yup from "yup"

import { CreateContentSourceParams } from "@framework/types/content-manager"

const commonRequiredFunc = (val: string) =>
  val === "sharepoint_online" || val === "salesforce"

export const configFormValidationSchema = yup.object({
  clientId: yup.string().when("type", {
    is: commonRequiredFunc,
    then: yup.string().required("Client ID is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  clientSecret: yup.string().when(["type", "useCertificate"], {
    is: (type: string, useCertificate: boolean) =>
      (type === "sharepoint_online" && !useCertificate) ||
      type === "salesforce",
    then: yup.string().required("Client Secret is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  contentType: yup.string(),
  certificate: yup.object().when(["type", "clientSecret"], {
    is: (type: string, clientSecret: string) =>
      type === "sharepoint_online" && !clientSecret,
    then: yup.object().shape({
      certificate: yup.string().required("Certificate is required"),
      privateKey: yup.string().required("Private Key is required"),
    }),
    otherwise: yup.object().shape({
      certificate: yup.string().notRequired(),
      privateKey: yup.string().notRequired(),
    }),
  }),
  useCertificate: yup.boolean().when("type", {
    is: "sharepoint_online",
    then: yup.boolean().required("Use Certificate is required").defined(),
    otherwise: yup.boolean().notRequired(),
  }),
  domain: yup.string().when("type", {
    is: "website",
    then: yup.string().required("Website URL is required").trim().defined(),
    otherwise: yup.string().when("type", {
      is: "sharepoint_online",
      then: yup
        .string()
        .required("Site Collection is required")
        .trim()
        .defined(),
      otherwise: yup.string().when("enumerateAllSites", {
        is: false,
        then: yup
          .string()
          .required("Domain is required")
          .trim()
          .notOneOf(
            ["*"],
            "Domain cannot contain '*' when 'Enumerate All Sites' is false"
          )
          .defined(),
        otherwise: yup.string().required("Domain is required").trim().defined(),
      }),
    }),
  }),
  enumerateAllSites: yup.boolean(),
  fetchSubsites: yup.boolean(),
  name: yup.string().when("type", {
    is: commonRequiredFunc,
    then: yup
      .string()
      .required("Name is required")
      .trim()
      .matches(
        /^[a-z0-9-]+$/,
        "Name must be alphanumeric, lowercase, and can only contain '-' as a special character"
      )
      .defined(),
    otherwise: yup
      .string()
      .trim()
      .matches(
        /^[a-z0-9-]+$/,
        "Name must be alphanumeric, lowercase, and can only contain '-' as a special character"
      ),
  }),
  tenantId: yup.string().when("type", {
    is: "sharepoint_online",
    then: yup.string().required("Tenant ID is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  tenantName: yup.string().when("type", {
    is: "sharepoint_online",
    then: yup.string().required("Tenant Name is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  type: yup.string(),
  useTextExtractionService: yup.boolean(),
  websiteName: yup.string().when("type", {
    is: "website",
    then: yup.string().required("Website Name is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  companyIds: yup
    .array()
    .of(yup.string().trim().label("Manufacturer Name"))
    .label("Manufacturers")
    .required()
    .min(1),
  keywords: yup.string(),
  childUrls: yup
    .string()
    .test(
      "is-comma-separated-string",
      "Child URLs must be a valid comma-separated string without spaces",
      (value) => {
        if (!value) return true // Allow empty strings
        const parts = value.split(",").map((part) => part.trim())
        return parts.every((part) => part.length > 0)
      }
    ),
})

export const configFormInitialValues: CreateContentSourceParams = {
  name: "",
  domain: "",
  clientId: "",
  clientSecret: "",
  contentType: "",
  tenantId: "",
  tenantName: "",
  type: "",
  companyIds: [],
  websiteName: undefined,
  useTextExtractionService: true,
  fetchSubsites: false,
  enumerateAllSites: false,
  certificate: {
    certificate: "",
    privateKey: "",
  },
  useCertificate: false,
}

export const getFormHeadingsAndOtherInfo = (sourceName: string) => {
  const isWebsite = sourceName.toString() === "website"

  let domainHeading = "Domain"
  let domainPlaceholder = "Enter domain URL"
  if (sourceName === "sharepoint_online") {
    domainHeading = "Site Collection"
    domainPlaceholder = "Enter Comma-separated list of sites"
  } else if (isWebsite) {
    domainHeading = "Website URL"
  }

  return { domainHeading, domainPlaceholder, isWebsite }
}
