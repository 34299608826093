import React from "react"
import { FileRejection, useDropzone } from "react-dropzone"

import Button, { ButtonProps } from "../Button/Button"
import Icon from "../Icon/Icon"

export interface DocumentDropZoneProps extends ButtonProps<"button"> {
  multiple?: boolean
  extensions?: string[]
  loading?: boolean
  withError?: boolean
  onAttach?: (accepted: File[], rejected: FileRejection[]) => void
  onReject?: (files: File[]) => void
}

export const AttachDocumentButton: React.FC<DocumentDropZoneProps> = ({
  multiple = true,
  disabled = false,
  extensions,
  loading,
  withError = false,
  children = "Attach Document",
  onAttach,
  onReject,
  ...rest
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onAttach,
    multiple,
    disabled,
    noDrag: true,
    accept: extensions,
  })

  return (
    <div {...getRootProps()}>
      <Button before={<Icon name="paperclip" />} disabled={disabled} {...rest}>
        {children}
      </Button>
      <input {...getInputProps()} />
    </div>
  )
}

export default AttachDocumentButton
