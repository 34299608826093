import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import Select from "@components/ui/MultiSelect/Select"
import { Option } from "@framework/types/utils"

export interface ProductsFieldProps {
  name: string
  placeholder?: string
  clearable?: boolean
  disabled?: boolean
}

const ProductsField: React.FC<ProductsFieldProps> = observer(
  ({ name, placeholder, clearable, disabled }) => {
    const {
      productComparisonStore: {
        productStore: {
          products,
          loadingProductsList,
          loadProducts,
          productsMetaData,
        },
      },
    } = useStore()

    const [field, meta, helpers] = useField<string[]>(name)

    const options: Option[] = products.map((it) => ({
      name: it.id,
      value: it.name,
    }))

    React.useEffect(() => {
      if (!products.length)
        loadProducts({
          filters: { search: "" },
          pagination: { ...productsMetaData, pageSize: 500 },
        })
    }, [])

    return (
      <Select
        isMulti
        disabled={disabled}
        clearable={clearable}
        placeholder={placeholder}
        onChange={(selectedItems) => {
          helpers.setValue([...selectedItems])
          helpers.setTouched(false)
        }}
        loading={loadingProductsList}
        value={field.value}
        options={options}
        withError={!!meta.touched && !!meta.error}
        onBlur={field.onBlur}
        searchable
      />
    )
  }
)

export default ProductsField
