import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { Attribute } from "@framework/types/product"
import { useStore } from "@store"

import ContextMenu from "./ContextMenu"

import styles from "./AttributesTable.module.sass"

export type AttributesTableProps = {
  attributes: Attribute[]
  productId: string
}

const AttributesTable: React.FC<AttributesTableProps> = observer(
  ({ attributes, productId }) => {
    const [attributeList, setAttributeList] = useState(attributes)
    const alert = useAlert()
    const addUpdateAttributeModal = useModal(
      ModalsTypes.ADD_UPDATE_ATTRIBUTE_MODAL
    )
    const deleteModal = useModal(ModalsTypes.CONFIRM_MODAL)
    const {
      productComparisonStore: {
        productStore: { deleteAttribute },
      },
    } = useStore()

    const handleAddChildClick = (attribute?: Attribute) => {
      addUpdateAttributeModal.showModal({
        productId,
        attribute,
        onSuccess: (newAttribute) => {
          setAttributeList((prevAttributes) => {
            const existingAttributeIndex = prevAttributes.findIndex(
              (attr) => attr.id === newAttribute.id
            )

            if (existingAttributeIndex !== -1) {
              const updatedAttributes = [...prevAttributes]
              updatedAttributes[existingAttributeIndex] = newAttribute
              return updatedAttributes
            }
            return [...prevAttributes, newAttribute]
          })
        },
      })
    }

    const handleRemove = (item: Attribute) => {
      deleteModal.showModal({
        message: `Do you want to remove ${item.name} ?`,
        onConfirm: async () => {
          if (!item.id) return
          const success = await deleteAttribute(productId, item.id)
          if (success) {
            setAttributeList((prevAttributes) =>
              prevAttributes.filter((attr) => attr.id !== item.id)
            )
            alert.success("Successfully deleted attribute")
          } else {
            alert.error("Failed to delete attribute")
          }
        },
      })
    }

    return (
      <div className={styles.root}>
        <Text variant="h2" weight="bold">
          Attributes
        </Text>
        <div className={styles.scrollableTable}>
          <Table
            header={
              <Row>
                <TextColumn width={100}>Name</TextColumn>
                <TextColumn width={100}>Type</TextColumn>
                <TextColumn width={100}>Value</TextColumn>
                <TextColumn width={100}>Unit</TextColumn>
                <Column> </Column>
              </Row>
            }
          >
            {attributeList.map((item) => (
              <Row key={item.id}>
                <Column> {item.name}</Column>
                <Column> {item.type}</Column>
                <Column> {item.value}</Column>
                <Column> {item.unit}</Column>
                <Column className={styles.actionColumn}>
                  <div>
                    <ContextMenu
                      onEdit={() => handleAddChildClick(item)}
                      onDelete={() => handleRemove(item)}
                    />
                  </div>
                </Column>
              </Row>
            ))}
          </Table>
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            size="tiny"
            variant="text"
            before={<Icon name="plus" />}
            onClick={() => handleAddChildClick()}
          >
            Add Attribute
          </Button>
        </div>
      </div>
    )
  }
)

export default AttributesTable
