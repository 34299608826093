import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Table from "@components/ui/BaseTable/Table"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import Pagination from "@components/ui/Pagination/Pagination"
import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import useQueryParams from "@components/hooks/useQueryParams"
import { initArray } from "@utils/numberUtils"
import NotFound from "@components/ui/NotFound/NotFound"
import mainRoutes from "@root/main.routes"
import useQuery from "@components/hooks/useQuery"

import IdentifierTableHeader from "./IdentifierListHeader"
import IdentifierListRow from "./IdentifierListRow"
import IdentifierListRowSkeleton from "./IdentifierListRowSkeleton"

import styles from "./IdentifierList.module.sass"

interface IdentifierListProps {}

const IdentifierList: React.FC<IdentifierListProps> = observer(() => {
  const navigate = useNavigate()
  const queryParams = useQuery()

  const initialPage = parseInt(queryParams.get("page") || "1", 10)

  const [initialLoad, setInitialLoad] = React.useState(true)

  const updatePageInURL = (page: number) => {
    queryParams.set("page", page.toString())
    navigate({ search: queryParams.toString() }, { replace: true })
  }

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newPage = selected + 1
    setActivePage(newPage)
    updatePageInURL(newPage)
  }

  const searchParams = useQueryParams()

  const manufacturerId = searchParams?.manufacturerId || ""
  const manufacturerName = searchParams?.manufacturerName || ""

  const {
    identifierStore: {
      identifiers,
      loadingList,
      activePage,
      activeFilter,
      orderBy,
      searchQuery,
      errorList,
      reloadTrigger,
      identifiersMetaData: { totalPages },
    },
  } = useStore()

  const {
    identifierController: {
      getIdentifiers,
      updateActivePage: setActivePage,
      updateActiveFilter,
      reset,
    },
  } = useController()

  const loadIdentifiers = () => {
    const companyIds = activeFilter.manufacturers.map((company) => company.id)

    getIdentifiers({
      pageNum: activePage,
      search: searchQuery || undefined,
      companyIds,
      include: activeFilter.includes,
      ...(orderBy != null
        ? {
            sortBy: orderBy.name,
            sortOrder: orderBy.direction ? "ASC" : "DESC",
          }
        : null),
    })
  }

  React.useLayoutEffect(() => {
    setActivePage(initialPage)
  }, [])

  React.useEffect(() => {
    loadIdentifiers()
  }, [activePage])

  React.useEffect(() => {
    const isManufacturerActive = !!activeFilter.manufacturers.some(
      (manufacturer) => manufacturer.id === manufacturerId
    )

    if (!isManufacturerActive) {
      navigate(`${mainRoutes.productsIdentifiers()}?page=${activePage}`, {
        replace: true,
      })
    }

    if (initialLoad) {
      setInitialLoad(false)
      return
    }

    if (activePage === 1) {
      loadIdentifiers()
    } else {
      setActivePage(1)
      navigate(`${mainRoutes.productsIdentifiers()}?page=1`, { replace: true })
    }
  }, [searchQuery, activeFilter, orderBy, reloadTrigger])

  React.useEffect(() => {
    if (!manufacturerId) return

    updateActiveFilter({
      ...activeFilter,
      manufacturers: [
        ...activeFilter.manufacturers,
        {
          id: manufacturerId,
          name: manufacturerName,
        },
      ],
    })
  }, [manufacturerId])

  React.useEffect(() => {
    // cleanup all
    return reset
  }, [])

  const isEmptyIdentifiers = !searchQuery && identifiers.length === 0

  if (isEmptyIdentifiers && !loadingList && !errorList) {
    return (
      <div className={styles.identifierList}>
        <NotFound className={styles.notFoundBox}>
          No Identifiers have been added yet!
        </NotFound>
      </div>
    )
  }

  return (
    <div className={styles.identifierListContainer}>
      <Table
        className={styles.identifierList}
        header={<IdentifierTableHeader />}
      >
        {!loadingList &&
          identifiers.map((identifier) => {
            return (
              <IdentifierListRow
                onDelete={loadIdentifiers}
                key={identifier.id}
                identifier={identifier}
              />
            )
          })}

        {loadingList &&
          initArray(15, (index) => (
            <IdentifierListRowSkeleton key={index + 1} />
          ))}

        {errorList && (
          <Text className={styles.alertText} variant="body1" color="redColor">
            {errorList}
          </Text>
        )}

        {!errorList && !loadingList && identifiers.length === 0 && (
          <NotFound className={styles.notFoundBox}>
            No Identifiers found
          </NotFound>
        )}
      </Table>
      <EntityPageHeaderLayout
        right={
          <Pagination
            pageCount={totalPages}
            forcePage={activePage - 1}
            onPageChange={handlePageChange}
          />
        }
      />
    </div>
  )
})

export default IdentifierList
