import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import Column from "@components/ui/BaseTable/Column"
import Table from "@components/ui/BaseTable/Table"
import { useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import { SUBSCRIPTION_OPTION_LABELS } from "@framework/constants/subscription"
import { SubscriptionOption } from "@framework/types/subscription"

import EditSelectRow from "./EditSelectRow"
import StatusSwitch from "./StatusSwitch"

import styles from "./index.module.sass"

export type ConnectorOptionsProps = {
  optionName: string
  showTopBorder?: boolean
}

const ConnectorOptions: React.FC<ConnectorOptionsProps> = observer(
  ({ optionName, showTopBorder }) => {
    const { subscriptionStore } = useStore()
    const { isEditable, editedSubscriptionData } = subscriptionStore
    const label = SUBSCRIPTION_OPTION_LABELS[optionName]

    const selectedOptions: SubscriptionOption[] | undefined = (
      editedSubscriptionData as any
    )?.[optionName]

    return (
      <>
        {isEditable && <EditSelectRow label={label} optionName={optionName} />}
        {selectedOptions?.length ? (
          <Table hideBorder>
            {selectedOptions.map((connector: any, index) => {
              return (
                <Row
                  key={connector.id}
                  className={clsx({
                    [styles.borderTop]: showTopBorder && index === 0,
                  })}
                >
                  <Column style={{ paddingLeft: "0px" }}>
                    <Text variant="h5">{index === 0 ? label : ""}</Text>
                  </Column>
                  <Column width="30%">
                    <Text variant="h5">{connector.name}</Text>
                  </Column>
                  <Column width="50%">
                    <StatusSwitch option={connector} optionName={optionName} />
                  </Column>
                </Row>
              )
            })}
          </Table>
        ) : null}
      </>
    )
  }
)
export default ConnectorOptions
