import { observer } from "mobx-react-lite"
import React from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"

type RetryButtonProps = {
  delay?: number // seconds
  onTimeUp?: () => void
  onRetry: () => void
}

export const RetryButton: React.FC<RetryButtonProps> = observer(
  ({ delay = 0, children, onRetry, onTimeUp }) => {
    const [timeToRetry, setTimeToRetry] = React.useState(999)

    React.useEffect(() => {
      setTimeToRetry(Math.max(delay, 0))
    }, [delay])

    React.useEffect(() => {
      if (timeToRetry < 1) {
        onTimeUp?.()
        return undefined
      }

      const timer = setTimeout(() => {
        setTimeToRetry((v) => Math.max(v - 1, 0))
      }, 1_000)

      return () => clearTimeout(timer)
    }, [timeToRetry])

    return (
      <Button
        disabled={timeToRetry > 0}
        size="small"
        variant="outlined"
        before={<Icon name="retry" />}
        onClick={onRetry}
      >
        {children} {timeToRetry > 0 ? `(${timeToRetry}s)` : ""}
      </Button>
    )
  }
)

export default RetryButton
