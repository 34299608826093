import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel, {
  CheckboxWithLabelProps,
} from "@components/ui/Checkbox/CheckboxWithLabel"
import IconButton from "@components/ui/IconButton/IconButton"
import { useStore } from "@store/index"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"

import styles from "./FeatureFlagCard.module.sass"

interface FeatureFlagCardProps extends CheckboxWithLabelProps {
  id: string
  onDelete?: React.MouseEventHandler
}

export const FeatureFlagCard: React.FC<FeatureFlagCardProps> = observer(
  ({ id, className, checked, onDelete, ...rest }) => {
    const { featuresStore } = useStore()

    const inUse = featuresStore.isFlagUsed(id)

    return (
      <CheckboxWithLabel
        {...rest}
        className={clsx(styles.root, { [styles.active]: checked }, className)}
        checked={checked}
        after={
          <>
            {inUse && <UsageBadge />}

            {onDelete != null && (
              <Tooltip color="primary" placement="top">
                <TooltipContent>Delete Flag</TooltipContent>
                <TooltipTrigger>
                  <IconButton onClick={onDelete}>
                    <Icon name="trash-can" />
                  </IconButton>
                </TooltipTrigger>
              </Tooltip>
            )}
          </>
        }
      />
    )
  }
)
export default FeatureFlagCard

const UsageBadge: React.FC = () => {
  return (
    <Tooltip color="primary" placement="top">
      <TooltipContent>Flag is used in current version of App</TooltipContent>
      <TooltipTrigger>
        <Icon name="done" color="green" />
      </TooltipTrigger>
    </Tooltip>
  )
}
