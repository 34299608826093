import { makeAutoObservable } from "mobx"

import { AnswerData } from "@framework/types/search"
import { ExpertVerification } from "@framework/types/question"
import { SimpleUserData } from "@framework/types/user"
import { SimpleContentTypeData } from "@framework/types/content-manager"
import { DataSourceName } from "@framework/types/upload"
import { isDataSourceName } from "@framework/constants/upload"

import SearchAnswersDataStore from "./search-answers-data.store"

export class SearchCitationsStore {
  searchAnswersStore: SearchAnswersDataStore

  constructor(config: { searchAnswersStore: SearchAnswersDataStore }) {
    this.searchAnswersStore = config.searchAnswersStore

    makeAutoObservable(this)
  }

  get searchAnswers() {
    return this.searchAnswersStore.allAnswers || []
  }

  get totalSources() {
    return this.searchAnswers.length
  }

  get citations(): SearchCitationData[] {
    return this.searchAnswers.map<SearchCitationData>((item, index) => {
      const isExpertAnswer = item.value?.source === "expert answer"

      const defaultProps = {
        id: item.name,
        citationNumber: index + 1,
        description: (item.value as any).text ?? (item.value as any).value,
        answerSource: item.value,
        connectorName: item.value.connectorName ?? null,
        detailsLoadingError: null,
        sourceName: item.value.source_name,
        sourceURL: item.value.source,
        sourceType: item.value.source_type,
        isDocumentOld: !!item.value.isDocumentOld,
        documentAgeCutoff: item.value.documentAgeCutoff ?? null,
      }

      if (isExpertAnswer) {
        return {
          questionId: item.value.documentId ?? null,
          question: item.value?.source_name,
          isExpertAnswer: true,
          verifiedBy: [],
          ...defaultProps,
        }
      }

      const contentSourceName =
        item.value?.source_type && isDataSourceName(item.value?.source_type)
          ? (item.value.source_type as DataSourceName)
          : null

      return {
        contentId: item.value.documentId ?? null,
        url: Array.isArray(item.value.screenshots)
          ? item.value.screenshots.join(", ")
          : item.value.screenshots ?? "",
        isExpertAnswer: false,
        contentSourceName,
        ...defaultProps,
      }
    })
  }

  get citationsMap() {
    const { citations } = this
    return new Map(citations.map((it) => [it.id, it]))
  }

  get getByIndex() {
    const { citations } = this
    return (index: number): SearchCitationData | null => {
      return citations[index] ?? null
    }
  }

  get getById() {
    const { citationsMap } = this
    return (id: string): SearchCitationData | null => {
      return citationsMap.get(id) ?? null
    }
  }
}

export default SearchCitationsStore

export type BaseSearchCitation = {
  id: string
  connectorName?: string | null
  citationNumber: number
  description?: string
  answerSource?: AnswerData
  isExpertAnswer: boolean
  sourceURL?: string | null
  sourceName?: string | null
  url?: string
  detailsLoadingError?: string | null
  isDocumentOld: boolean
  documentAgeCutoff: number | null
}

export type ContentCitationData = {
  isExpertAnswer: false
  contentId?: string | null
  contentSourceName: DataSourceName | null
  connectedBy?: SimpleUserData
  contentType?: SimpleContentTypeData
  sourceType?: string
} & BaseSearchCitation

export type ExpertInsightCitationData = {
  questionId?: string | null
  question: string | null
  verifiedBy: ExpertVerification[]
  isExpertAnswer: true
} & BaseSearchCitation

export type SearchCitationData = ExpertInsightCitationData | ContentCitationData
