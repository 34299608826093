import clsx from "clsx"
import React from "react"

import styles from "./ListItem.module.sass"

export interface ListItemProps extends React.HTMLProps<HTMLDivElement> {
  active?: boolean
  focused?: boolean
  sticky?: boolean
  color?: "primary" | "secondary" | "red"
}

export const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(
  (
    {
      children,
      className,
      active = false,
      focused = false,
      sticky = false,
      color = "primary",
      onKeyPress,
      onClick,
      onDoubleClick,
      disabled,
      ...rest
    },
    ref
  ) => {
    const rootClassName = clsx(
      styles.item,
      styles[`color-${color}`],
      {
        [styles.sticky]: sticky,
        [styles.active]: active,
        [styles.focused]: focused,
        [styles.clickable]: !!onClick || !!onDoubleClick,
        [styles.disabled]: !!disabled,
      },
      className
    )
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        ref={ref}
        className={rootClassName}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onKeyPress={onKeyPress}
        {...rest}
      >
        {children}
      </div>
    )
  }
)

export default ListItem
