import React from "react"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import HeaderCell from "@components/ui/BaseTable/HeaderCell"

export const UserTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <HeaderCell name="firstName" key="name" width="350px">
        User
      </HeaderCell>

      <HeaderCell key="actions" width="70px" />

      <HeaderCell name="userRoles" key="roles" width="auto">
        Roles
      </HeaderCell>

      <HeaderCell name="userGroups" key="groups" width="auto">
        Groups
      </HeaderCell>

      <HeaderCell name="solutions" key="solutions" width="auto">
        Solutions
      </HeaderCell>

      <HeaderCell name="createdAt" key="created_date" width="160px">
        Created Date
      </HeaderCell>

      <HeaderCell
        name="lastActiveDateTime"
        key="lastActiveDateTime"
        width="160px"
      >
        Last Activity
      </HeaderCell>
    </Row>
  )
})

export default UserTableHeader
