import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import { CompanyWithMetaInfo } from "@framework/types/company"

import CompanyPermissionStore from "./permissions.store"

class CompanyStore {
  permissions = new CompanyPermissionStore()

  companies: CompanyWithMetaInfo[] = []

  trialInstanceCompanies: CompanyWithMetaInfo[] = []

  loading: boolean = false

  loadingCompanies: boolean = false

  error: string | null = null

  reloadTrigger = nanoid()

  constructor() {
    makeAutoObservable(this)
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  setError(error: string | null) {
    this.error = error
  }

  triggerReload = () => {
    this.reloadTrigger = nanoid()
  }
}

export default CompanyStore
