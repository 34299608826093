import React from "react"
import { useNavigate, To } from "react-router-dom"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"

export interface BackButtonProps extends ButtonProps<"button"> {
  to?: To
}

export const BackButton: React.FC<BackButtonProps> = ({
  to,
  children,
  ...rest
}) => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate(to ?? (-1 as To))}
      variant="text"
      size="medium"
      before={<Icon name="line-arrow-down" rotateAngle={90} />}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default BackButton
