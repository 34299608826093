import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { UserAndGroup } from "@framework/types/user"
import { useController, useStore } from "@store"
import useDebounce from "@components/hooks/useDebounce"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import { useSearchContext } from "@components/prototypes/SearchContext"
import Label from "@components/ui/Label/Label"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"
import { EntityAccessEndpoints } from "@services/entities.service"
import Skeleton from "@components/ui/Skeleton/Skeleton"

import EntityCard from "./EntityCard"
import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import useSelectedEntities from "./useSelectedEntities"

import styles from "./AddParticipantForm.module.sass"

export interface AddParticipantFormProps {
  loading?: boolean
  excludeByRelation?: EntityAccessEndpoints
  relationId?: string
  onCancel: () => void
  onSubmit: (value: UserAndGroup[]) => void
}

const AddParticipantForm: React.FC<AddParticipantFormProps> = observer(
  ({ loading = false, relationId, excludeByRelation, onSubmit, onCancel }) => {
    const { entitiesStore } = useStore()
    const { entitiesController } = useController()

    const isLoading = entitiesStore.isLoading || loading

    const searchContext = useSearchContext()

    const { debouncedValue: searchQuery, setDebounce } = useDebounce({
      value: searchContext.query,
    })

    const entitiesToAdd = useSelectedEntities<UserAndGroup>()

    const loadMoreContent = () => {
      entitiesController.loadEntities({
        page: entitiesStore.meta.page + 1,
        pageSize: 10,
        query: searchQuery,
        excludeByRelation,
        relationId,
      })
    }

    const hasMoreContent =
      (entitiesStore.meta?.page || 1) < (entitiesStore.meta?.totalPages || 0)

    const lastItemRef = useInfiniteScroll({
      callback: loadMoreContent,
      isLoading: entitiesStore.isLoading,
      hasMore: hasMoreContent,
    })

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      onSubmit(entitiesToAdd.getSelectedEntities())
    }

    React.useEffect(() => {
      setDebounce(searchContext.query)
    }, [searchContext.query])

    React.useEffect(() => {
      entitiesController.loadEntities(
        {
          page: 1,
          pageSize: 10,
          query: searchQuery,
          excludeByRelation,
          relationId,
        },
        true
      )
    }, [searchQuery, excludeByRelation, relationId])

    return (
      <form className={styles.root} onSubmit={handleSubmit}>
        <div className={styles.listContainer}>
          <div>
            <Label id="search-users" label="Select User and Groups to add" />
            <SearchContextInput autoFocus className={styles.searchInput} />
          </div>

          <div className={styles.content}>
            {!entitiesStore.entities.length ? (
              isLoading ? (
                <Loader fluid size="large" />
              ) : (
                <NotFound fluid>No Users or Groups found</NotFound>
              )
            ) : (
              <div className={styles.list}>
                {entitiesStore.entities.map((entity) => {
                  const active = entitiesToAdd.isSelected(entity)
                  return (
                    <EntityCard
                      key={entity.id}
                      user={entity}
                      isSelected={active}
                      onSelect={entitiesToAdd.select}
                    />
                  )
                })}
                {hasMoreContent && (
                  <div ref={lastItemRef}>
                    <Skeleton
                      count={entitiesStore.meta.pageSize || 3}
                      lineHeight={56}
                      minWidth={100}
                      rounded
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <ModalFooterContainer>
          <Button
            size="big"
            variant="outlined"
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            after={isLoading && <Loader size="small" />}
            size="big"
            color="primary"
            disabled={isLoading || !entitiesToAdd.total}
            type="submit"
          >
            Add {entitiesToAdd.total ? `(${entitiesToAdd.total})` : ""}
          </Button>
        </ModalFooterContainer>
      </form>
    )
  }
)

export default AddParticipantForm
