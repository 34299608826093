import React from "react"
import without from "lodash/without"

import useEvent from "@components/hooks/useEvent"
import { Entity } from "@framework/types/common"

const getId = (entity: Entity) => entity.id

const useSelectedEntities = <T extends Entity>() => {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([])
  const [entitiesCache, setEntityCache] = React.useState<Map<string, T>>(
    () => new Map()
  )

  const select = useEvent((option: T) => {
    entitiesCache.set(getId(option), option)
    setSelectedIds((prev) =>
      prev.includes(option.id) ? without(prev, option.id) : [...prev, option.id]
    )
  })

  const isSelected = React.useCallback(
    (value: T | string): boolean => {
      return selectedIds.includes(
        typeof value === "string" ? value : getId(value)
      )
    },
    [selectedIds]
  )

  const reset = React.useCallback(() => {
    setSelectedIds([])
    setEntityCache(new Map())
  }, [selectedIds])

  const getSelectedEntities = useEvent((): T[] => {
    return selectedIds.reduce<T[]>((acc, id) => {
      const entity = entitiesCache.get(id)
      if (entity != null) acc.push(entity)
      return acc
    }, [])
  })

  return {
    selectedIds,
    total: selectedIds.length,
    reset,
    select,
    isSelected,
    getSelectedEntities,
  }
}

export default useSelectedEntities
