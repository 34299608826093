import React, { useState } from "react"
import * as yup from "yup"
import clsx from "clsx"

import TextInput, { TextInputProps } from "@components/ui/TextInput/TextInput"
import {
  CommentType,
  PasswordValidationComment,
  passwordValidationRequiredOptions,
  strongPasswordValidationSchema,
} from "@framework/constants/auth"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import Text from "@components/ui/Typography/Text"

import styles from "./RequiredPasswordField.module.sass"

interface RequiredPasswordFieldProps extends TextInputProps {}

const initialErrors = [
  CommentType.Character,
  CommentType.Digit,
  CommentType.Duplicate,
  CommentType.Length,
  CommentType.LowerCase,
  CommentType.UpperCase,
]

const RequiredPasswordField: React.FC<RequiredPasswordFieldProps> = ({
  onChange,
  ...rest
}) => {
  const [errors, setErrors] = useState<string[]>(initialErrors)

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    try {
      onChange?.(e)
      await strongPasswordValidationSchema.validate(value, {
        abortEarly: false,
      })
      setErrors([])
    } catch (errors: any) {
      const validationErrors: string[] = errors.inner?.reduce(
        (acc: string[], error: yup.ValidationError) => {
          return acc.some((item) => item === error.message)
            ? acc
            : [...acc, error.message]
        },
        []
      )
      setErrors(!value ? initialErrors : validationErrors)
    }
  }

  return (
    <Tooltip
      mode="onFocus"
      className={styles.root}
      content={
        <TooltipContainer
          placement="top"
          color="primary"
          className={clsx(styles.tooltip, { [styles.error]: !!errors.length })}
        >
          <Text variant="caption1">Your password must contain:</Text>
          <ul>
            {passwordValidationRequiredOptions.map((value) => (
              <li
                key={value}
                className={clsx({
                  [styles.done]: !errors.includes(value),
                })}
              >
                {PasswordValidationComment[value]}
              </li>
            ))}
          </ul>
        </TooltipContainer>
      }
    >
      <TextInput {...rest} onChange={handleChange} />
    </Tooltip>
  )
}

export default RequiredPasswordField
