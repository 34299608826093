import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import get from "lodash/get"

import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import useEvent from "@components/hooks/useEvent"
import { SolutionData } from "@framework/types/solution"

type ChangeSolutionCallback = (value: string) => void

export interface ActiveSolutionContextType {
  solution: SolutionData
  setSolution: ChangeSolutionCallback
}

export const ActiveSolutionContext =
  React.createContext<ActiveSolutionContextType | null>(null)

export type Props = {
  solutionId?: string | null
  loader?: React.ReactNode
  fallback?: React.ReactNode
  onChange?: ChangeSolutionCallback
}

export const SolutionContextProvider: React.FC<Props> = observer(
  ({ solutionId, children, loader, fallback, onChange }) => {
    const { solutionsStore } = useStore()
    const { solutionsController } = useController()

    const solution = solutionId ? solutionsStore.getById(solutionId) : null

    const setSolution = useEvent((solution: string) => {
      onChange?.(solution)
    })

    React.useEffect(() => {
      if (!solutionsStore.isAllSolutionsLoading && !solutionsStore.allSolutions)
        solutionsController.loadAllSolutions()
    }, [])

    if (solution != null) {
      const context: ActiveSolutionContextType = {
        solution,
        setSolution,
      }

      return (
        <ActiveSolutionContext.Provider value={context}>
          {children}
        </ActiveSolutionContext.Provider>
      )
    }

    if (solutionsStore.isAllSolutionsLoading)
      return <>{loader != null ? loader : <Loader size="huge" fluid />}</>

    return (
      <>
        {fallback != null ? (
          fallback
        ) : (
          <NotFound>Solution not accessible or no longer exist</NotFound>
        )}
      </>
    )
  }
)

interface RouteWithSolutionContextProviderProps {
  solutionIdPropName?: string
  onSolutionChange?: (avatarId: string) => void
}

export const RouteWithSolutionContextProvider: React.FC<RouteWithSolutionContextProviderProps> =
  observer(
    ({ onSolutionChange, solutionIdPropName = "solutionId", children }) => {
      const props = useParams()

      const solutionId = get(props, solutionIdPropName, null)

      if (solutionId == null)
        throw new Error(
          `Solution Id was not found in URL params by name: ${solutionIdPropName}`
        )

      return (
        <SolutionContextProvider
          solutionId={solutionId}
          onChange={onSolutionChange}
        >
          {children}
        </SolutionContextProvider>
      )
    }
  )

export const useSolution = () => {
  const context = React.useContext(ActiveSolutionContext)

  if (context != null) return context

  throw new Error("Component should be wrapped with SolutionContextProvider")
}
