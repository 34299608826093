import { makeAutoObservable } from "mobx"

import { UserAndGroup } from "@framework/types/user"

export default class EntitiesStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading: boolean = false

  error: string | null = null

  meta = {
    totalItems: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10,
  }

  entities: UserAndGroup[] = []
}
