/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { MouseEventHandler } from "react"
import clsx from "clsx"

import { SimpleBaseUserData, SimpleUserData } from "@framework/types/user"
import Avatar from "@components/ui/Avatar/Avatar"
import { getFullName } from "@pages/questions/utils"

import Text from "../Typography/Text"

import styles from "./UsersBubbles.module.sass"

export type UsersBubblesProps = {
  users: (SimpleBaseUserData | SimpleUserData)[]
  total?: number
  prefix?: string
  suffix?: string
  size?: "medium" | "small"
  className?: string
  onClick?: MouseEventHandler
}

enum Sizes {
  small = 24,
  medium = 32,
}

export const UsersBubbles: React.FC<UsersBubblesProps> = React.memo(
  ({ prefix, total, users, className, suffix, onClick, size = "medium" }) => {
    const totalCount = total && total > users.length ? total : users.length
    const previewUsers = users.length >= 3 ? users.slice(0, 3) : users
    const leftoverUserNumber = totalCount - previewUsers.length

    const avatarProps = {
      width: Sizes[size],
      height: Sizes[size],
    }

    return (
      <div
        className={clsx(
          styles.root,
          {
            [styles.withPrefix]: !!prefix,
            [styles.clickable]: !!onClick,
          },
          className
        )}
        onClick={onClick}
      >
        {!!prefix && <Text variant="h5">{prefix}</Text>}
        <div className={clsx(styles.container, styles[`size-${size}`])}>
          {previewUsers.map((user) => {
            const userName = getFullName(user)

            const avatarURL =
              "avatarURL" in user ? user.avatarURL : user.avatarUrl

            return (
              <Avatar
                className={styles.avatar}
                name={userName}
                src={avatarURL ?? undefined}
                key={user.id}
                {...avatarProps}
              />
            )
          })}

          {!!leftoverUserNumber && (
            <Avatar
              className={clsx(styles.avatar, styles.defAvatar)}
              name={`+${leftoverUserNumber}`}
              nameIsInitials
              {...avatarProps}
            />
          )}
        </div>
        {!!suffix && <Text variant="h5">{suffix}</Text>}
      </div>
    )
  }
)

export default UsersBubbles
