import React from "react"
import { jsPDF as JsPdf } from "jspdf"
import html2canvas from "html2canvas"

import { runTask } from "@utils/promise"

export const useReportDownload = ({
  contentRef,
}: {
  contentRef: React.RefObject<HTMLDivElement>
}) => {
  const [inProgress, setInProgress] = React.useState(false)

  const handleDownload = React.useCallback(async (reportName: string) => {
    if (!contentRef.current) return

    try {
      const canvas = await html2canvas(contentRef.current, {
        scale: 1,
        useCORS: true, // Handle cross-origin images
      })

      // Convert the canvas to an image
      const imgData = canvas.toDataURL("image/png")

      // Create a new jsPDF instance in landscape mode
      const pdf = new JsPdf("l", "pt")

      // Get PDF dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()

      // Calculate the height ratio to fit content properly
      const contentHeight = canvas.height * (pdfWidth / canvas.width)

      // Start the Y position
      let yPosition = 0

      // Loop to add pages if content height exceeds one page
      while (yPosition < contentHeight) {
        // Calculate the remaining content height
        const remainingHeight = contentHeight - yPosition

        // If the content doesn't fit in one page, add a new page
        if (remainingHeight > pdfHeight) {
          pdf.addImage(
            imgData,
            "PNG",
            0,
            -yPosition,
            pdfWidth,
            canvas.height * (pdfWidth / canvas.width)
          )
          yPosition += pdfHeight // Move the position down by one page height
          pdf.addPage() // Add a new page
        } else {
          // Add the remaining content on the last page
          pdf.addImage(
            imgData,
            "PNG",
            0,
            -yPosition,
            pdfWidth,
            canvas.height * (pdfWidth / canvas.width)
          )
          break
        }
      }

      // Save the PDF
      pdf.save(`${reportName}.pdf`)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const download = React.useCallback(
    async (reportName: string) => {
      try {
        if (inProgress) return

        setInProgress(true)

        await runTask(async () => {
          await handleDownload(reportName)
        })
      } finally {
        setInProgress(false)
      }
    },
    [inProgress, handleDownload]
  )

  return {
    inProgress,
    download,
  }
}

export default useReportDownload
