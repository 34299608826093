import React from "react"

import { UserActionEntity } from "@framework/types/security"
import Info from "@components/ui/Tooltip/Info"

import styles from "./index.module.sass"

interface ActionDescriptionProps {
  action: UserActionEntity
}

const ActionDescription: React.FC<ActionDescriptionProps> = React.memo(
  ({ action }) => {
    return (
      <div className={styles.label}>
        {action.name.toUpperCase()}
        <Info placement="right">
          {action.description || "This action has no description"}
        </Info>
      </div>
    )
  }
)

export default ActionDescription
