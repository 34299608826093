import { makeAutoObservable } from "mobx"

import RootStore from "@store/RootStore"
import productsService from "@services/products.service"
import {
  LinkProductsData,
  LinkProductsResponseData,
  PatchProductRequest,
} from "@framework/types/product"

import ProductStore from "./product.store"

export default class ProductController {
  protected productStore: ProductStore

  constructor(injections: RootStore) {
    this.productStore = injections.productComparisonStore.productStore

    makeAutoObservable(this)
  }

  getProductDetails = async (id: string) => {
    const store = this.productStore
    store.isProductDetailsLoading = true
    store.error = ""
    try {
      store.productDetails = null
      const response = await productsService.getProductDetails(id)
      store.productDetails = response.data
    } catch (error: any) {
      store.error = error?.response?.data?.message || "Failed to load products"
    } finally {
      store.isProductDetailsLoading = false
    }
  }

  loadMoreProductContentIds = async (
    pageNum: number,
    pageSize: number,
    productId: string
  ) => {
    const store = this.productStore
    store.isContentsLoading = true
    store.error = ""
    try {
      store.productContents = []
      store.contentPaginationMeta = null
      const response = await productsService.getProductContents(
        pageNum,
        pageSize,
        productId
      )
      store.productContents = response.data.data
      store.contentPaginationMeta = response.data.pagination
    } catch (error: any) {
      store.error = error?.response?.data?.message || "Failed to load content"
    } finally {
      store.isContentsLoading = false
    }
  }

  updateProductDetails = async (id: string, data: PatchProductRequest) => {
    const store = this.productStore
    store.isUpdateProductLoading = true
    store.error = ""
    try {
      await productsService.patchProduct(id, data)
      return true
    } catch (error: any) {
      store.error = error?.response?.data?.message || "Failed to update product"
      return false
    } finally {
      store.isUpdateProductLoading = false
    }
  }

  deleteProduct = async (id: string) => {
    const store = this.productStore
    store.deletingProducts[id] = true
    store.error = ""
    try {
      await productsService.deleteProduct(id)
      return true
    } catch (error: any) {
      store.error = error?.response?.data?.message || "Failed to delete product"
      return false
    } finally {
      store.deletingProducts[id] = false
    }
  }

  linkProduct = async (
    productId: string,
    linkProducts: LinkProductsData[]
  ): Promise<LinkProductsResponseData | null> => {
    const store = this.productStore
    store.isLinkingProduct = true
    store.error = ""
    try {
      const response = await productsService.linkProduct(
        productId,
        linkProducts
      )
      store.linkedProducts = response.data.data
      return response.data
    } catch (error: any) {
      store.error = error?.response?.data?.message || "Failed to link product"
      return null
    } finally {
      store.isLinkingProduct = false
    }
  }

  updateLinkProduct = async (
    productId: string,
    linkProductData: LinkProductsData
  ) => {
    const store = this.productStore
    store.isUpdatingLinkProduct = true
    store.error = ""
    try {
      const response = await productsService.updateLinkProduct(
        productId,
        linkProductData
      )
      store.linkedProducts = store.linkedProducts.map((product) =>
        product?.id === linkProductData.id ? response.data : product
      )
      return true
    } catch (error: any) {
      store.error =
        error?.response?.data?.message || "Failed to update linked product"
      return false
    } finally {
      store.isUpdatingLinkProduct = false
    }
  }

  deleteLinkProduct = async (productId: string, linkProductId: string) => {
    const store = this.productStore
    store.isDeletingLinkProduct = true
    store.error = ""
    try {
      const res = await productsService.deleteLinkProduct(
        productId,
        linkProductId
      )
      store.linkedProducts = store.linkedProducts.filter(
        (product) => product.id !== linkProductId
      )
      return !!res.data
    } catch (error: any) {
      store.error =
        error?.response?.data?.message || "Failed to delete linked product"
      return false
    } finally {
      store.isDeletingLinkProduct = false
    }
  }
}
