import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import NotFound from "@components/ui/NotFound/NotFound"
import Button from "@components/ui/Button/Button"
import { WorkbookReportTypeName } from "@framework/types/workbook-report"
import { getReportDescriptionByType } from "@framework/constants/workbook"

import ProductRecommenderForm from "./ProductRecommenderForm"
import ProductComparisonForm from "./ProductComparisonForm"
import ProductPositioningForm from "./ProductPositioningForm"

import styles from "./GenerateWorkbookReportModal.module.sass"

export type ReportRequest = {
  id: string
  query: string
  data: any
}

export interface GenerateWorkbookReportModalProps {
  reportType: WorkbookReportTypeName
  onSubmit?: (data: ReportRequest) => void
}

const GenerateWorkbookReportModal: React.FC<GenerateWorkbookReportModalProps> =
  observer(({ reportType, onSubmit }) => {
    const modal = useModal(ModalsTypes.CREATE_WORKBOOK_REPORT_MODAL)

    const closeModal = () => {
      modal.hideModal()
    }

    const handleSubmit = async (data: ReportRequest) => {
      await onSubmit?.(data)
    }

    const handleClose = () => {
      modal.hideModal()
    }

    const reportDescription = getReportDescriptionByType(reportType)

    const renderForm = () => {
      if (reportType === "PRODUCTRECOMMENDATION")
        return (
          <ProductRecommenderForm
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        )

      if (reportType === "PRODUCTCOMPARISON")
        return (
          <ProductComparisonForm
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        )

      if (reportType === "PRODUCTPOSITIONING")
        return (
          <ProductPositioningForm
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        )

      return null
    }

    return (
      <BaseModal
        title={<ModalTitle titleText={reportDescription.displayName} />}
        onClose={closeModal}
        className={styles.root}
      >
        {renderForm() ?? (
          <NotFound
            className={styles.placeholder}
            retryButton={
              <Button
                size="big"
                variant="outlined"
                onClick={() => modal.hideModal()}
              >
                Ok
              </Button>
            }
          >
            Such workflow in not exists or being removed.
            <br />
            Please reach out to an administrator to get more details
          </NotFound>
        )}
      </BaseModal>
    )
  })

export default GenerateWorkbookReportModal
