import React from "react"
import { useSlate } from "slate-react"
import { Editor, Transforms, Range } from "slate"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import { VoiceInputIcon } from "@components/ui/Button/VoiceInputButton"
import { renderVoiceRecognitionError } from "@pages/search/components/SearchWidget/useSpeechRecognizer"

import {
  isFormatActive,
  toggleFormat,
  isBlockActive,
  toggleBlock,
} from "./utils"
import Switch from "../Switch/Switch"
import Templates from "../Templates"
import { MarkdownEditorContext, modeOptions } from "./EditorContext"
import { EditorVoiceToTextContext } from "./EditorVoiceToTextContext"
import List from "../List/List"
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/v2/Tooltip"

import styles from "./Toolbar.module.sass"

const FormatButton: React.FC<{
  title?: string
  format: string
}> = ({ format, title, children }) => {
  const editor = useSlate()

  const isActive = isFormatActive(editor, format)

  return (
    <IconButton
      title={title}
      size="small"
      active={isActive}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleFormat(editor, format)
      }}
    >
      {children || title || format}
    </IconButton>
  )
}
const BlockButton: React.FC<{
  title?: string
  format: string
}> = ({ format, title, children }) => {
  const editor = useSlate()

  const isActive = isBlockActive(editor, format)

  return (
    <IconButton
      title={title}
      size="small"
      active={isActive}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {children || title || format}
    </IconButton>
  )
}

export const Toolbar: React.FC = observer(() => {
  const editor = useSlate()

  const editorContext = React.useContext(MarkdownEditorContext)

  return (
    <Templates.Header
      className={styles.root}
      left={
        editorContext?.mode !== "preview" && (
          <List direction="row" gutter="4" overflow="initial" wrap="wrap">
            <FormatButton format="strong" title="Bold">
              <Icon name="bold" />
            </FormatButton>
            <FormatButton format="emphasis" title="Italic">
              <Icon name="italic" />
            </FormatButton>
            <FormatButton format="delete" title="Strikethrough">
              <Icon name="strikethrough" />
            </FormatButton>

            <BlockButton format="paragraph">Normal Text</BlockButton>
            <BlockButton format="heading_one" title="Heading 1">
              <Icon name="h-1" />
            </BlockButton>
            <BlockButton format="heading_two" title="Heading 2">
              <Icon name="h-2" />
            </BlockButton>
            <BlockButton format="heading_three" title="Heading 3">
              <Icon name="h-3" />
            </BlockButton>
            <BlockButton format="blockquote" title="Quotation">
              <Icon name="chat-new" />
            </BlockButton>
            <BlockButton format="numbered-list" title="Numbered List">
              <Icon name="format_list_numbered" />
            </BlockButton>
            <BlockButton format="bulleted-list" title="Bullet List">
              <Icon name="format_list_bulleted" />
            </BlockButton>

            <VoiceToTextButton />
          </List>
        )
      }
      right={
        editorContext != null && (
          <Switch
            items={modeOptions}
            checked={editorContext.mode}
            onChange={editorContext.changeEditorMode}
            size="medium"
          />
        )
      }
    />
  )
})

export default Toolbar

const VoiceToTextButton = observer(() => {
  const editor = useSlate()

  const speechContext = React.useContext(EditorVoiceToTextContext)

  if (speechContext == null) return null

  const { listening, error } = speechContext
  const withError = error != null

  return (
    <Tooltip color="primary">
      <TooltipTrigger>
        <IconButton
          size="medium"
          color={listening ? "red" : withError ? "gold" : "default"}
          onClick={(e) => {
            const { selection } = editor

            if (selection == null) {
              const endPoint = Editor.end(editor, [])
              Transforms.select(editor, endPoint)
            }

            speechContext.toggle()
            e.preventDefault()
          }}
        >
          <VoiceInputIcon
            listening={speechContext.listening}
            withError={!!speechContext.error}
          />
        </IconButton>
      </TooltipTrigger>
      <TooltipContent>
        {listening
          ? "Stop Recording"
          : error
          ? renderVoiceRecognitionError(error)
          : "Tap for Speech"}
      </TooltipContent>
    </Tooltip>
  )
})
