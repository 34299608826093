import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { QueryHistoryData } from "@framework/types/analytics"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Loader from "@components/ui/Loader/BarLoader"
import useDebounce from "@components/hooks/useDebounce"
import NotFound from "@components/ui/NotFound/NotFound"

import QuestionCard from "./QuestionCard"
import RecentActivityFilter from "./RecentActivityFilter"

import styles from "./RecentActivity.module.sass"

export type RecentActivityProps = {
  onClose: () => void
}

const RecentActivity: React.FC<RecentActivityProps> = observer(
  ({ onClose }) => {
    const {
      usageAnalyticsStore: { queryHistoryStore },
      userStore: { user },
      recentActivityStore: { activeFilter },
    } = useStore()
    const { debouncedValue, setDebounce } = useDebounce()

    const [allQuestions, setAllQuestions] = useState<QueryHistoryData[]>([])
    const { analyticsController } = useController()
    const [searchProps, searchContext] = useSearch()
    const { isLoading, meta, data } = queryHistoryStore

    const hasMoreContent =
      (meta?.pageNum || (data.length && 1)) < (meta?.totalPages || 0)

    const loadMoreContent = useCallback(
      (forceCall?: boolean) => {
        if ((forceCall || hasMoreContent) && user?.id) {
          let pageNum = data?.length ? (meta?.pageNum || 0) + 1 : 0
          if (forceCall) pageNum = 0
          analyticsController.loadQueryHistoryData(
            {
              userIds: [user?.id],
              ...activeFilter,
              expertStatus: activeFilter.status,
              searchQuery: searchProps.value,
              isUserHistoryFlow: true,
            },
            {
              pageNum,
              pageSize: 10,
            }
          )
        }
      },
      [meta, analyticsController, debouncedValue, activeFilter]
    )

    const lastItemRef = useInfiniteScroll({
      callback: loadMoreContent,
      isLoading,
      hasMore: hasMoreContent,
    })

    const reset = () => {
      if (user?.id) {
        setAllQuestions([])
        queryHistoryStore.resetDate()
        loadMoreContent(true)
      }
    }

    const handleClose = () => {
      analyticsController.abortQueryHistory()
      onClose()
    }

    useEffect(() => {
      queryHistoryStore.resetDate()
      setAllQuestions([])
      return () => {
        queryHistoryStore.resetDate()
      }
    }, [])

    useEffect(reset, [activeFilter, debouncedValue])

    useEffect(() => {
      setDebounce(searchProps.value)
    }, [searchProps.value])

    const clearSearch = () => {
      searchContext.setQuery("")
    }

    useEffect(() => {
      setAllQuestions([...allQuestions, ...data])
    }, [data])

    return (
      <Templates.RollScript
        gutter="24"
        className={styles.root}
        headerSocket={
          <List gutter="24" overflow="initial">
            <div className={styles.headingWrapper}>
              <Text className={styles.header} variant="h3" weight="bold">
                Your Recent Activity
              </Text>

              <Button onClick={handleClose} size="tiny" variant="text">
                <Icon name="cross" />
              </Button>
            </div>
            <div className={styles.searchFilters}>
              <TextInput
                {...searchProps}
                className={styles.searchInput}
                before={<Icon name="search" />}
                placeholder="Search"
                after={
                  !!searchProps.value && (
                    <Icon name="cross" onClick={clearSearch} />
                  )
                }
              />
              <RecentActivityFilter />
            </div>
          </List>
        }
      >
        <List gutter="16" overflow="initial">
          {allQuestions?.map((question, index) => (
            <React.Fragment key={question.id}>
              <QuestionCard
                question={question}
                key={question.id}
                onExpertsAdded={reset}
              />
              {index === allQuestions.length - 1 && (
                <div className={styles.lastElem} ref={lastItemRef} />
              )}
            </React.Fragment>
          ))}
          {isLoading && (
            <div className={styles.loader}>
              <Loader fluid size="small" />
            </div>
          )}
          {!isLoading && !allQuestions.length && <NotFound />}
        </List>
      </Templates.RollScript>
    )
  }
)
export default RecentActivity
