import React from "react"
import { observer } from "mobx-react-lite"

import { WorkFlowType } from "@framework/types/solution"

import { useSolution } from "./ActiveSolutionContext"
import SearchSolution from "./SearchSolution"
import WorkbookSolution from "./WorkbookSolution"

const LaunchSolutions: React.FC = observer(() => {
  const { solution } = useSolution()

  return (
    <>
      {solution.workflowType === WorkFlowType.SEARCH && <SearchSolution />}
      {solution.workflowType === WorkFlowType.WORKBOOK && <WorkbookSolution />}
    </>
  )
})

export default LaunchSolutions
