import React, { useContext } from "react"
import { makeAutoObservable } from "mobx"

export type ISearchContext = {
  query: string

  setQuery: (value: string) => void
}

export class SearchContextStore implements ISearchContext {
  constructor() {
    makeAutoObservable(this)
  }

  query = ""

  setQuery = (value: string) => {
    this.query = value
  }
}

export const SearchContext = React.createContext<ISearchContext | null>(null)

export const useSearchContext = () => {
  const context = useContext(SearchContext)
  if (context == null) throw new Error("Can't find Search context")
  return context
}
