import React from "react"
import { ReactEditor, withReact } from "slate-react"
import {
  Editor,
  Text,
  Transforms,
  Element as SlateElement,
  BaseEditor,
  BaseElement,
  Path,
  createEditor,
} from "slate"
import { withHistory } from "slate-history"

import HTMLText from "@components/ui/Typography/HTMLText"

import { renderMath } from "./markdown-parser"
import { LIST_TYPES } from "./constants"
import Blockquote from "./elements/Blockquote"
import Table from "./elements/Table"

export const isBlockActive = (
  editor: BaseEditor,
  format: string | string[]
) => {
  const { selection } = editor

  if (!selection) return false

  const matches = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        return !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          Array.isArray(format)
          ? format.includes((n as any).type)
          : (n as any).type === format
      },
    })
  )

  return !!matches[0]
}

export const isFormatActive = (editor: BaseEditor, format: any) => {
  const matches = Array.from(
    Editor.nodes(editor, {
      match: (n: any) => n[format] === true,
      universal: true,
    })
  )

  return !!matches[0]
}

export const toggleFormat = (editor: BaseEditor, format: string) => {
  const isActive = isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [format]: isActive ? undefined : true },
    { match: (n) => Text.isText(n), split: true }
  )
}

export const toggleBlock = (editor: BaseEditor, format: string) => {
  const isList = LIST_TYPES.includes(format)
  const isListActive = isBlockActive(editor, LIST_TYPES)
  const isActive = isBlockActive(editor, format)

  if (isListActive) {
    Transforms.unwrapNodes(editor, {
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as any).type === "list-item",
      split: true,
    })

    Transforms.unwrapNodes(editor, {
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes((n as any).type),
      split: true,
    })
  }

  if (isList && !isActive) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)

    const itemBlock = { type: "list-item", children: [] }
    Transforms.wrapNodes(editor, itemBlock)

    return
  }

  // other format

  const newProperties: any = {
    type: isActive ? "paragraph" : format,
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)
}

export const withCustomElements = (editor: ReactEditor) => {
  const { isInline, isVoid, normalizeNode } = editor

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element: any) =>
    ["link", "inlineMath"].includes(element.type) || isInline(element)

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element: any) =>
    ["math"].includes(element.type) || isVoid(element)

  const mergeLists = (
    firstNode: BaseElement,
    secondNode: BaseElement,
    path: Path
  ) => {
    if ((secondNode as any).type === (firstNode as any).type) {
      Transforms.mergeNodes(editor, {
        at: path,
        match: (n) => {
          return (n as any).type === (firstNode as any).type
        },
      })
    }
  }

  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = (entry: any) => {
    const [baseNode, basePath] = entry

    if (
      LIST_TYPES.includes((baseNode as any).type) &&
      SlateElement.isElement(baseNode)
    ) {
      const prevEntry = Editor.previous(editor, { at: basePath })
      const nextEntry = Editor.next(editor, { at: basePath })

      if (prevEntry) {
        const [prevNode] = prevEntry
        if (SlateElement.isElement(prevNode))
          mergeLists(baseNode, prevNode, basePath)
      }

      if (nextEntry) {
        const [nextNode, nextPath] = nextEntry
        if (SlateElement.isElement(nextNode))
          mergeLists(baseNode, nextNode, nextPath)
      }

      if (prevEntry || nextEntry) return
    }

    normalizeNode(entry)
  }

  return editor
}

export const renderElement = (
  props: any,
  readOnly = false
): React.ReactNode => {
  const { attributes, children, element }: any = props

  switch (element.type) {
    case "heading_one":
      return <h1 {...attributes}>{children}</h1>
    case "heading_two":
      return <h2 {...attributes}>{children}</h2>
    case "heading_three":
      return <h3 {...attributes}>{children}</h3>
    case "heading_four":
      return <h4 {...attributes}>{children}</h4>
    case "heading_five":
      return <h5 {...attributes}>{children}</h5>
    case "heading_six":
      return <h6 {...attributes}>{children}</h6>
    case "paragraph":
      return <p {...attributes}>{children}</p>
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>
    case "list-item":
      return <li {...attributes}>{children}</li>
    case "blockquote":
      return <Blockquote {...attributes}>{children}</Blockquote>
    case "table":
      return <Table {...attributes}>{children}</Table>
    case "tableHeader":
      return <thead {...attributes}>{children}</thead>
    case "tableBody":
      return <tbody {...attributes}>{children}</tbody>
    case "tableRow":
      return <tr {...attributes}>{children}</tr>
    case "tableCell":
      return <td {...attributes}>{children}</td>
    case "link": {
      return (
        <a {...attributes} title={element.title} href={element.url}>
          {children}
        </a>
      )
    }
    case "thematicBreak":
      return <hr {...attributes} />
    case "code":
    case "html":
      return <div {...attributes}>{children}</div>

    default: {
      return <span {...attributes}>{children}</span>
    }
  }
}

export const renderLeaf = (props: any, readOnly = false): React.ReactNode => {
  const { leaf } = props

  let { children } = props

  if (leaf.emphasis) {
    children = <em>{children}</em>
  }
  if (leaf.delete) {
    children = <del>{children}</del>
  }
  if (leaf.strong) {
    children = <strong>{children}</strong>
  }
  if (leaf.html && readOnly) {
    return <HTMLText {...props.attributes}>{leaf.text}</HTMLText>
  }
  if (leaf.math && readOnly) {
    return <span {...props.attributes}>{renderMath(leaf.text)}</span>
  }

  return <span {...props.attributes}>{children}</span>
}

export const initEditor = () =>
  withCustomElements(withHistory(withReact(createEditor())))

export const initViewer = () => withReact(createEditor())
