import * as React from "react"

import Text from "../Typography/Text"

import styles from "./Select.module.sass"

export const DropdownList: React.FC = ({ children }) => {
  const childrenArray = React.Children.toArray(children)
  return (
    <div className={styles.listContainer}>
      {childrenArray.length ? (
        children
      ) : (
        <Text
          color="text50Color"
          align="center"
          className={styles.notFountText}
        >
          No options found
        </Text>
      )}
    </div>
  )
}

export default DropdownList
