import React from "react"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import Column from "@components/ui/BaseTable/Column"
import { useStore } from "@store"
import HeaderCell from "@components/ui/BaseTable/HeaderCell"

const ProductsTableHeader: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()
  return (
    <Row>
      <HeaderCell name="name">Product Name</HeaderCell>
      <HeaderCell name="company">Manufacturer</HeaderCell>
      <HeaderCell name="categories">Identifiers</HeaderCell>
      <HeaderCell>Linked Contents</HeaderCell>
      {(access.canUpdateProduct || access.canDeleteProduct) && (
        <Column width={38} />
      )}
    </Row>
  )
})

export default ProductsTableHeader
