import React from "react"
import clsx from "clsx"
import identity from "lodash/identity"

import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/BarLoader"
import Info from "@components/ui/Tooltip/Info"

import styles from "./TrendCard.module.sass"

export interface TrendCardProps {
  title: React.ReactNode
  description: React.ReactNode
  totalAmount?: number
  trend?: number
  loading?: boolean
  period?: string
  renderAmountValue?: (value: number) => React.ReactNode
}

export const TrendCard: React.FC<TrendCardProps> = (props) => {
  const {
    title,
    period,
    totalAmount,
    description,
    loading,
    renderAmountValue = identity,
  } = props
  return (
    <div className={clsx(styles.root)}>
      <div className={styles.header}>
        {period != null && (
          <Text variant="caption2" color="text50Color">
            <Icon name="timer" inline /> {period}
          </Text>
        )}

        <Text variant="h5">
          {title} <Info>{description}</Info>
        </Text>
      </div>
      <div className={styles.body}>
        {totalAmount == null && loading ? (
          <Loader fluid size="medium" />
        ) : (
          <>
            {renderTrend(props)}

            <Text variant="h1" weight="bold">
              {totalAmount != null ? renderAmountValue(totalAmount) : "-"}
            </Text>
          </>
        )}
      </div>
    </div>
  )
}

export default TrendCard

const renderTrend = ({ trend }: TrendCardProps) => {
  if (trend == null) return null

  if (trend > 0) {
    return (
      <Chip
        variant="rounded"
        color="green"
        before={<Icon name="trending-up" />}
      >
        +{trend}
      </Chip>
    )
  }

  if (trend < 0) {
    return (
      <Chip
        variant="rounded"
        color="red"
        before={<Icon name="trending-down" />}
      >
        {trend}
      </Chip>
    )
  }

  return (
    <Chip
      variant="rounded"
      color="secondary"
      before={<Icon name="trending-up" />}
    >
      0
    </Chip>
  )
}
