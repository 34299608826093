import flatten from "lodash/flatten"

import { randomNumber } from "@utils/numberUtils"
import { delay } from "@utils/promise"

import { validateOperand } from "../utils"
import { FunctionDescription } from "../types"
import { defaultNumberValidator, numberOrArrayOfNumber } from "../validation"

const DEFAULT_FUNCTION_LIST: FunctionDescription[] = [
  {
    name: "SUM",
    description: "Sum of a series of numbers",
    requiredArgumentsNum: 0,
    maxArgumentsNum: 999,
    arguments: [{ displayName: "Number", validator: numberOrArrayOfNumber }],
    handler: (...args: any[]) => args.reduce((acc, it) => acc + it, 0),
    transformArgs: (args: any[]) => {
      return flatten(args).map((it) => validateOperand(it))
    },
  },
  {
    name: "RANDOM",
    description: "Return random number in range",
    arguments: [
      { displayName: "Min", validator: defaultNumberValidator },
      { displayName: "Max", validator: defaultNumberValidator },
    ],
    handler: (...args: [number, number]) => randomNumber(...args),
    transformArgs: (args: any[]) => {
      const min = Number(args[0] || 0)
      const max = Number(args[1] || min + 100)

      return [min, max]
    },
  },
  {
    name: "DELAY",
    description: "Return Value after wait Timeout (in milliseconds)",
    requiredArgumentsNum: 2,
    maxArgumentsNum: 2,
    arguments: [
      { displayName: "Timeout", validator: numberOrArrayOfNumber },
      { displayName: "Value" },
    ],
    handler: async (...args: [number, any]) => {
      return delay(...args)
    },
    transformArgs: (args: any[]) => {
      const timeout = Number(args[0] || 1000)

      return [timeout, args[1]]
    },
  },
]

export default DEFAULT_FUNCTION_LIST
