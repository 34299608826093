import React from "react"
import { observer } from "mobx-react-lite"

import { WorkbookReportEntity } from "@framework/types/workbook-report"
import WorkbookEntityStore from "@store/search/workbook-entity.store"

interface WorkbookEntityContextStore {
  reportEntity: WorkbookEntityStore
}

export const WorkbookEntityContext =
  React.createContext<WorkbookEntityStore | null>(null)

export const WorkbookEntityContextProvider: React.FC<WorkbookEntityContextStore> =
  observer(({ reportEntity: searchEntity, children }) => (
    <WorkbookEntityContext.Provider value={searchEntity}>
      {children}
    </WorkbookEntityContext.Provider>
  ))

export const useSearchEntityContext = () => {
  const context = React.useContext(WorkbookEntityContext)
  if (context != null) return context
  throw new Error("Workbook Entity Context not found")
}

export const useWorkbookEntity = () => {
  const reportEntity = useSearchEntityContext()
  return { reportEntity }
}
