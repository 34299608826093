import React from "react"

import { SortBy } from "@framework/types/common"
import useEvent from "@components/hooks/useEvent"

interface TableOrderOptions {
  switchMode?: boolean
  orderBy?: SortBy | null
  onOrderChange: (name: SortBy | null) => void
}

export const useTableOrder = ({
  orderBy,
  onOrderChange,
  switchMode = true,
}: TableOrderOptions) => {
  const toggleOrder = useEvent((name: string) => {
    if (orderBy?.name !== name) {
      // ASC
      onOrderChange({ name, direction: true })
      return
    }

    // DESC
    if (orderBy.direction) {
      onOrderChange({ name, direction: false })
      return
    }

    if (switchMode) {
      onOrderChange({ name, direction: !orderBy.direction })
      return
    }

    onOrderChange(null)
  })

  return React.useMemo(
    () => ({
      sortBy: orderBy ? { ...orderBy } : null,
      toggleOrder,
    }),
    [orderBy?.name, orderBy?.direction, toggleOrder]
  )
}

export const TableOrderContext = React.createContext<ReturnType<
  typeof useTableOrder
> | null>(null)

export const useTableOrderContext = () => {
  const context = React.useContext(TableOrderContext)

  if (context != null) return context

  throw new Error(
    "useTableOrderContext must be wrapped with TableOrderContext Provider"
  )
}
