import React from "react"

const AspentechLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    version="1.1"
    id="layer"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 599.9 117.8"
  >
    <path
      fill="currentColor"
      d="M99.2,105.1c0-3.1,2.5-5.4,5.6-5.4c2.9,0,5.4,2.3,5.4,5.4s-2.5,5.4-5.4,5.4C101.7,110.5,99.2,108.3,99.2,105.1
		M104.6,109.6c2.5,0,4.3-2,4.3-4.5s-2-4.5-4.3-4.5c-2.5,0-4.5,2-4.5,4.5C100.3,107.8,102.1,109.6,104.6,109.6 M103.5,108.3h-0.9V102
		h2.3c1.4,0,2.2,0.5,2.2,1.8c0,1.1-0.7,1.6-1.6,1.8l1.8,2.7h-1.1l-1.6-2.7h-1.1C103.5,105.6,103.5,108.3,103.5,108.3z M104.6,104.7
		c0.7,0,1.4,0,1.4-1.1c0-0.7-0.7-0.9-1.4-0.9h-1.3v2H104.6z"
    />
    <path
      fill="currentColor"
      d="M49.1,26.3L46.8,29c-0.2,0.2-0.4,0.5-0.4,0.9v38.6c0,0.4,0.2,0.5,0.4,0.9l2.3,2.7c0.2,0.2,0.5,0.4,0.9,0.4
		s0.7-0.2,0.9-0.4l2.3-2.7c0.2-0.2,0.4-0.5,0.4-0.9l-0.2-38.8c0-0.4-0.2-0.5-0.4-0.7l-2.3-2.7c-0.2-0.4-0.5-0.4-0.9-0.4
		C49.6,26,49.3,26,49.1,26.3 M40.4,33.3c-0.2,0-0.5,0.2-0.5,0.4l-2.3,2.7c-0.2,0.2-0.2,0.4-0.2,0.5v31.4c0,0.2,0,0.4,0.2,0.5l2.3,2.9
		c0.2,0.2,0.4,0.4,0.7,0.4c0.2,0,0.5-0.2,0.5-0.4l2.2-2.9c0.2-0.2,0.2-0.4,0.2-0.5V36.9c0-0.2,0-0.4-0.2-0.5L41,33.7
		C40.8,33.5,40.6,33.3,40.4,33.3 M31.4,42.2c-0.2,0-0.4,0-0.5,0.2l-2.3,2.3c-0.2,0.2-0.2,0.2-0.2,0.4v15.3c0,0.2,0,0.4,0.2,0.4
		l2.3,2.3c0.2,0.2,0.4,0.2,0.5,0.2c0.2,0,0.4,0,0.5-0.2l2.2-2.4c0.2-0.2,0.2-0.4,0.2-0.4V45c0-0.2,0-0.4-0.2-0.4l-2.2-2.3
		C31.8,42.2,31.6,42.2,31.4,42.2 M70.5,26c-0.4,0-0.7,0.2-0.9,0.4l-2.3,2.7c-0.2,0.2-0.4,0.5-0.4,0.7v38.4c0,0.4,0.2,0.5,0.4,0.9
		l2.3,2.7c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.2,0.9-0.4l2.3-2.7c0.2-0.2,0.4-0.5,0.4-0.9V29.7c0-0.4-0.2-0.5-0.4-0.9l-2.3-2.5
		C71.1,26,70.9,26,70.5,26 M79.4,33.7l-2.3,2.7c0,0.2-0.2,0.4-0.2,0.5v31.5c0,0.2,0,0.4,0.2,0.5l2.2,2.9c0.2,0.2,0.4,0.4,0.5,0.4
		s0.5-0.2,0.5-0.4l2.3-2.9c0.2-0.2,0.2-0.4,0.2-0.5V37c0-0.2,0-0.4-0.2-0.5l-2.3-2.7c0.2-0.4,0-0.5-0.2-0.5
		C79.7,33.3,79.5,33.5,79.4,33.7 M88.5,42.3l-2.3,2.3C86,44.8,86,44.8,86,45v15.3c0,0.2,0,0.4,0.2,0.4l2.2,2.3
		c0.2,0.2,0.4,0.2,0.5,0.2s0.4,0,0.5-0.2l2.3-2.3c0.2-0.2,0.2-0.4,0.2-0.4V45c0-0.2,0-0.4-0.2-0.4l-2.2-2.3c-0.2-0.2-0.4-0.2-0.5-0.2
		C88.7,42.2,88.5,42.2,88.5,42.3"
    />
    <path
      fill="currentColor"
      d="M73.1,93.9c10.8,0.9,23.4-5.2,29-11c9.4-9.5,13.5-20.9,13.5-35.7C115.6,18.7,95.8,0,65,0
		C44.3,0,26.1,8.8,13.5,24.5C4.7,35.3,0,48.6,0,62.9c0,18,7.4,32.6,20.7,42.7c9.9,7.6,24.1,12.8,41.3,12.1
		c11.2-0.4,22.5-3.4,28.5-7.9c4.9-3.8,2.7-3.6,1.1-3.4c-1.1,0.2-2.5,0.7-2.5,0.7c-8.6,3.8-17.5,6.1-26.8,6.1
		c-31,0-51.4-18.6-51.4-49.2c0-20.9,8.5-38.4,23.8-49.4C44.1,7.8,55.1,4.3,67,4.3c26.3,0,44.9,16.9,44.7,42
		c-0.2,15.5-2.9,24.1-11.2,33.5c-7.6,8.6-19.3,10.8-26.3,10.1c-8.6-0.9-9.5-5.9-9.7-10.3v-0.5v-56c0-0.5-0.2-0.9-0.4-1.4l-2-3.1
		c-0.4-0.7-1.1-1.1-1.6-1.1c-0.7,0-1.3,0.4-1.6,1.1l-1.8,3.1c-0.2,0.4-0.4,0.9-0.4,1.3v55.5v1.8l0,0l0,0
		C56.3,87.2,63.5,93.2,73.1,93.9"
    />
    <path
      fill="currentColor"
      d="M599.9,91.7V50.3c0-11-5.8-17.7-17.3-17.7c-7.9,0-14.4,4.1-20.4,9.4V3.8l-8.1,1.4v86.5h8.1V49.9
		c6.3-6.3,12.6-10.1,18.7-10.1c7.4,0,10.8,4.5,10.8,12.1v40h8.1v-0.2H599.9z M545.8,86.3l-1.1-7.4c-5.9,5.2-11.5,6.8-17.7,6.8
		c-11.7,0-18.9-8.8-18.9-23.1c0-14.2,7-23.1,18.2-23.1c6.7,0,12.1,2.3,17.7,7l1.3-8.1c-4.7-3.4-11-5.9-18.9-5.9
		c-14.8,0-26.5,10.8-26.5,30.3c0,19.1,11.4,30.1,26.1,30.1C534,93,540.3,90.8,545.8,86.3 M485.8,56.9h-31.5
		c1.6-11.2,7.4-17.8,16.6-17.8C480.1,39.1,484.9,46.5,485.8,56.9 M494.1,61.6c0-16-7.6-29-23.4-29c-14.2,0-25,11.5-25,30.1
		c0,20,10.8,30.3,26.1,30.3c9,0,16.4-3.2,22.2-8.3l-1.3-7.4c-5.9,5.2-12.8,8.6-20.4,8.6c-11.7,0-18.2-8.5-18.6-22.7h40.4V61.6z
		M443.7,91.7l-1.3-6.7c-1.3,0.2-3.6,0.5-5.4,0.5c-6.3,0-8.6-2.5-8.6-8.5V40.7h14.4l-1.1-6.8h-13.3V12.5l-7.9,1.3v20h-9v6.8h9v37.8
		c0,10.6,5.9,14.2,15.1,14.2C439,92.6,441.9,92.3,443.7,91.7 M407.8,91.7V49.2c0-11-5.9-17.8-16.9-17.8c-7.9,0-14.2,4.3-19.6,9.5
		l-1.3-8.3h-12.4v59.1h14.8V51.9c4.3-4.5,8.8-7.4,13.5-7.4c5.4,0,7.6,3.6,7.6,8.6v38.7h14.4v-0.1H407.8z M336.1,55.9h-23.4
		c1.1-9.7,5.9-14.4,11.7-14.4C331.4,41.4,335,46.7,336.1,55.9 M350.4,61.4c0-16.8-7.9-30.1-25.8-30.1c-15.5,0-27,11.7-27,30.8
		c0,21.3,12.4,30.8,28.8,30.8c9.5,0,17.3-2.9,23.6-7.2l-1.8-11.5c-6.5,4.5-12.8,7.4-20.4,7.4c-9,0-15.1-5-15.9-16.6h38
		C350.4,64.2,350.4,62.7,350.4,61.4 M279.2,61.4c0,13-5.8,19.3-13.3,19.3c-5,0-7.7-0.9-11.4-2.5V52.6c4-5,8.1-8.3,13.3-8.3
		C274.5,44.3,279.2,49.9,279.2,61.4 M294.1,60.5c0-19.8-9.9-29.2-22.3-29.2c-8.3,0-13.9,4.5-18.2,9.4l-1.3-8.1h-12.4v81.8l14.8-2.2
		V90c3.6,1.3,8.1,2.3,13.7,2.3C281.9,92.3,294.1,82.5,294.1,60.5 M233.8,74.4c0-11.2-5.9-15.3-21.3-19.3c-8.8-2.3-11-4-11-7.2
		s2.7-5.4,8.3-5.4c5.9,0,12.8,2.2,18.7,5.4l2-12.3c-5.9-2.7-13-4.1-20.7-4.1c-13.7,0-21.8,7.2-21.8,17.3c0,10.8,5.9,15,20.4,18.6
		c9.4,2.3,12.1,4,12.1,8.3c0,3.8-3.4,6.1-9.7,6.1c-7.4,0-15.1-2.9-21.6-7l-2,12.3c5.6,3.1,14.4,5.8,23.1,5.8
		C225.5,92.8,233.8,86,233.8,74.4 M166,75.5c-4,4-8.8,6.3-13.9,6.3c-4.5,0-7.2-2.5-7.2-6.8c0-5.6,4-10.6,21.1-12.8V75.5L166,75.5z
		M180.8,91.7V51.2c0-14.2-7.6-19.8-22.9-19.8c-9.4,0-16.6,2.2-23.4,4.9l1.8,11.7c6.3-2.9,12.8-4.7,19.6-4.7c6.5,0,10.1,2.5,10.1,8.8
		v1.8c-23.8,2.2-35.5,8.3-35.5,22.7c0,10.3,6.7,16.4,16.8,16.4c8.8,0,15-4,19.6-9l1.3,7.7C168.2,91.7,180.8,91.7,180.8,91.7z"
    />
  </svg>
)

export default AspentechLogo
