import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { V2_Products } from "@store/product/product.store"
import { useController, useStore } from "@store"
import { LinkProductsData } from "@framework/types/product"

import ContextMenu from "./ContextMenu"

import styles from "./ProductsTable.module.sass"

export type LinkProductInfo = LinkProductsData & { product: V2_Products }

export type ProductsTableProps = {
  products: LinkProductInfo[]
  productId: string
}

const ProductsTable: React.FC<ProductsTableProps> = observer(
  ({ products, productId }) => {
    const [productsList, setProductsList] = useState(products)
    const alert = useAlert()
    const linkContentModal = useModal(
      ModalsTypes.LINK_PRODUCT_RELATIONSHIP_MODAL
    )
    const {
      productComparisonStore: { productStore },
    } = useStore()
    const { productController } = useController()

    const handleAddChildClick = (item?: LinkProductInfo) => {
      productStore.linkProductRelationShipModalData = {
        notes: "",
        products: [],
        type: "",
        addModalShowed: false,
      }
      linkContentModal.showModal({
        productId,
        linkProductData: item,
        product: item?.product,
        invalidProductIds:
          productsList.map((item) => item.relatedProductId || "") || [],
        onLinkProductRelationShip: (data) => {
          const newProducts = data.filter(
            (p) =>
              !productsList.some(
                (existingProduct) => existingProduct.id === p.id
              )
          )
          setProductsList([...productsList, ...newProducts])
        },
        onUpdateLinkProduct: (data) => {
          setProductsList((prevProductsList) =>
            prevProductsList.map((product) =>
              product.id === data.id
                ? {
                    ...product,
                    relationshipType: data.relationshipType,
                    note: data.note,
                  }
                : product
            )
          )
        },
      })
    }
    const handleRemove = async (item: LinkProductInfo) => {
      const success = await productController.deleteLinkProduct(
        productId,
        item?.id || ""
      )
      if (success) {
        setProductsList(
          productsList.filter((product) => product.id !== item.id)
        )
        alert.success("Successfully deleted link product")
      }
    }

    useEffect(() => {
      if (productStore.error) {
        alert.error(productStore.error)
        productStore.error = ""
      }
    }, [productStore.error])

    return (
      <div className={styles.root}>
        <Text variant="h2" weight="bold">
          Linked Products
        </Text>
        <div className={styles.scrollableTable}>
          <Table
            header={
              <Row>
                <TextColumn width={100}>Name</TextColumn>
                <TextColumn width={100}>Manufacturer</TextColumn>
                <TextColumn width={100}>Relationship</TextColumn>
                <TextColumn width={100}>Notes</TextColumn>
                <Column> </Column>
              </Row>
            }
          >
            {productsList.map((item) => (
              <>
                <Row key={item.id}>
                  <Column> {item.product.name}</Column>
                  <Column> {item.product?.company?.name || "N/A"}</Column>
                  <Column> {item.relationshipType}</Column>
                  <Column> {item.note}</Column>
                  <Column className={styles.actionColumn}>
                    <div>
                      <ContextMenu
                        onEdit={() => handleAddChildClick(item)}
                        onDelete={() => handleRemove(item)}
                      />
                    </div>
                  </Column>
                </Row>
              </>
            ))}
          </Table>
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            size="tiny"
            variant="text"
            before={<Icon name="plus" />}
            onClick={() => handleAddChildClick()}
          >
            Link Products
          </Button>
        </div>
      </div>
    )
  }
)

export default ProductsTable
