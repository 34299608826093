import React from "react"
import { Field, useFormikContext } from "formik"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import FilterButton from "@components/ui/Button/FilterButton"
import { useController, useStore } from "@store"
import FormSwitch from "@components/prototypes/form-elements/FormSwitch"
import ProductFilterManufacturerField from "@pages/product-page/components/ProductsFilter/ProductFilterManufacturerField"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"
import {
  SearchContext,
  useSearchContext,
} from "@components/prototypes/SearchContext"

import IdentifierFilterHeader from "../ProductIdentifiersList/IdentifierFilterHeader"

import styles from "./IdentifierFilterModal.module.sass"

interface IdentifierFilterProps {}

interface IdentifierFilterButtonProps {
  onToggle: () => void
  onReset: () => void
}

const IdentifierFilterButton: React.FC<IdentifierFilterButtonProps> = ({
  onToggle,
  onReset,
}) => (
  <FilterButton
    size="big"
    color="secondary"
    counter={0}
    onOpen={onToggle}
    onClean={onReset}
  >
    Filter
  </FilterButton>
)

const options = [
  { name: "true", value: "Include" },
  { name: "false", value: "Exclude" },
]

const IdentifierFilterForm: React.FC<{ visible?: boolean }> = observer(
  ({ visible = false }) => {
    const form = useFormikContext()

    const searchContext = useSearchContext()

    const {
      identifierStore: { activeFilter },
    } = useStore()

    React.useEffect(() => {
      if (!visible) searchContext.setQuery("")
    }, [visible])

    React.useEffect(() => {
      form.setFieldValue("manufacturers", activeFilter.manufacturers)
    }, [activeFilter])

    return (
      <>
        <FormSwitch items={options} name="includes" />

        <Field
          name="manufacturers"
          label="Manufacturer"
          valueKey="id"
          component={ProductFilterManufacturerField}
          searchQuery={searchContext.query}
        />
      </>
    )
  }
)

const IdentifierFilter: React.FC<IdentifierFilterProps> = observer(() => {
  const {
    identifierStore: { activeFilter },
  } = useStore()

  const {
    identifierController: { updateActiveFilter },
  } = useController()

  const [query, setQuery] = React.useState("")

  const searchContext = React.useMemo(() => ({ query, setQuery }), [query])

  return (
    <SearchContext.Provider value={searchContext}>
      <Templates.Header
        className={styles.filterHeader}
        left={
          activeFilter.manufacturers.length ? <IdentifierFilterHeader /> : null
        }
        right={
          <FilterSidebar
            initialValue={{
              identifiers: [],
              manufacturers: [],
              searchQuery: "",
              includes: "true",
            }}
            defaultValue={{
              identifiers: [],
              manufacturers: [],
              searchQuery: "",
              includes: "true",
            }}
            onChange={(newValue) => {
              updateActiveFilter({
                manufacturers: toJS(newValue.manufacturers) || [],
                includes: toJS(newValue.includes) === "true",
              })
            }}
            targetSlot={IdentifierFilterButton}
            headerSlot={<SearchContextInput />}
          >
            {({ isOpen }) => <IdentifierFilterForm visible={isOpen} />}
          </FilterSidebar>
        }
      />
    </SearchContext.Provider>
  )
})

export default IdentifierFilter
