import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"

import Button, { ButtonSize } from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/BarLoader"
import { ReportData } from "@framework/types/workbook-report"

import Report from "./Report"
import useReportDownload from "./useReportDownload"

import styles from "./WorkbookReport.module.sass"

export const DownloadReportButton: React.FC<{
  size?: ButtonSize
  report: ReportData
}> = observer(({ report, size }) => {
  const hiddenDivRef = React.useRef<HTMLDivElement>(null)

  const reportFile = useReportDownload({
    contentRef: hiddenDivRef,
  })

  return (
    <>
      <Button
        before={<Icon name="download" />}
        size={size}
        color="primary"
        onClick={() => reportFile.download(report?.title || "Workbook Report")}
        disabled={reportFile.inProgress}
        after={reportFile.inProgress && <Loader size="small" />}
      >
        {reportFile.inProgress ? "Downloading..." : "Download"}
      </Button>

      {reportFile.inProgress
        ? ReactDOM.createPortal(
            <div ref={hiddenDivRef} className={styles.hiddenDiv}>
              <Report data={report} />
            </div>,
            document.body
          )
        : null}
    </>
  )
})

export default DownloadReportButton
