import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"

import SolutionsCardContainer from "./SolutionsCardContainer"

import styles from "./AvailableSolutions.module.sass"

const AvailableSolutions: React.FC<{
  onCreate?: () => void
}> = observer(({ onCreate }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { solutionsStore } = useStore()

  const handleLaunch = (solution: SolutionData) => {
    navigate(mainRoutes.solutionView(solution.id))
  }

  React.useEffect(() => {
    if (solutionsStore.solutions == null) return

    const solutionId = new URLSearchParams(location.search).get("solutions")
    const solution = solutionsStore.solutions.find(
      (item) => item.id === solutionId
    )
    if (solution) {
      handleLaunch(solution)
    }
  }, [location.search, solutionsStore.solutions])

  return (
    <div className={styles.root}>
      <SolutionsCardContainer onLaunch={handleLaunch} onCreate={onCreate} />
    </div>
  )
})

export default AvailableSolutions
