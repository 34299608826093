import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Chip from "@components/ui/Chip/Chip"

import {
  Placement,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../Tooltip/v2/Tooltip"

import styles from "./ErrorChip.module.sass"

export interface ErrorChipProps {
  message: string
  messagePlacement?: Placement
}

export const ErrorChip: React.FC<ErrorChipProps> = ({
  message,
  messagePlacement = "bottom",
}) => (
  <Tooltip color="primary" placement={messagePlacement}>
    <TooltipContent className={styles.errorMessage}>
      <span>{message}</span>
    </TooltipContent>
    <TooltipTrigger asChild>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Chip
          color="red"
          before={<Icon name="info" className={styles.errorIcon} />}
        >
          Error
        </Chip>
      </div>
    </TooltipTrigger>
  </Tooltip>
)

export default ErrorChip
