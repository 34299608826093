import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController } from "@store"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import { ReportRequest } from "./GenerateWorkbookReportModal"

import styles from "./GenerateWorkbookReportModal.module.sass"

export const renderQuery = (data: FormData) => {
  return `Generate product recommendation report for ${data.context}`
}

export interface ProductRecommenderFormProps {
  onSubmit?: (data: ReportRequest) => Promise<void | boolean> | void | boolean
  onClose?: () => void
}

const validationSchema = yup.object({
  context: yup.string().label("Context").required().default(""),
})

type FormData = yup.InferType<typeof validationSchema>

const ProductRecommenderForm: React.FC<ProductRecommenderFormProps> = observer(
  ({ onSubmit, onClose }) => {
    const alert = useAlert()

    const [isLoading, setLoading] = React.useState(false)

    const { unifiedMatrixController } = useController()

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      onSubmit: async (data) => {
        try {
          setLoading(true)

          const query = renderQuery(data)

          const res =
            await unifiedMatrixController.generateProductRecommendationReport(
              data.context
            )

          if (res.status === "SUCCESS") {
            const shouldClose =
              (await onSubmit?.({
                id: res.data.id,
                data: res.data,
                query,
              })) ?? true
            if (shouldClose) onClose?.()
            return
          }

          alert.error(
            "Unexpected error while report generation. Please try again later"
          )
        } finally {
          setLoading(false)
        }
      },
    })

    return (
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Templates.RollScript
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button
                size="big"
                variant="outlined"
                disabled={isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="big"
                color="primary"
                disabled={isLoading}
                after={isLoading && <Loader size="small" />}
              >
                Confirm
              </Button>
            </ModalFooterContainer>
          }
        >
          <Text variant="body1" className={styles.messageText}>
            Generate product recommendation report for:
          </Text>

          <TextAreaInput
            value={formik.values.context}
            placeholder="...a target application,
            scenario or metrics (Enter some context)"
            onChange={(event) => {
              formik.setFieldValue("context", event.currentTarget.value)
            }}
            withError={!!(formik.touched.context && formik.errors.context)}
          />
          {formik.touched.context && formik.errors.context ? (
            <Text
              variant="caption2"
              color="redColor"
              className={styles.errorMessage}
            >
              {formik.errors.context}
            </Text>
          ) : null}
        </Templates.RollScript>
      </form>
    )
  }
)

export default ProductRecommenderForm
