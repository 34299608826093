import React from "react"
import clsx from "clsx"
import groupBy from "lodash/groupBy"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import { Attribute } from "@framework/types/product"
import NotFound from "@components/ui/NotFound/NotFound"

import styles from "./index.module.sass"

export type AttributesProps = {
  attributes: Attribute[]
}

const Attributes: React.FC<AttributesProps> = ({ attributes }) => {
  if (attributes.length === 0) {
    return <NotFound />
  }
  const groupedAttributes = groupBy(attributes, "type")
  return (
    <>
      {Object.entries(groupedAttributes).map(([key, value]) => (
        <>
          <Text variant="h4" className={styles.header}>
            {key} ({value.length})
          </Text>
          <div className={clsx(styles.card, styles.attributes)}>
            <Table
              hideBorder
              header={
                <Row>
                  <TextColumn>Name</TextColumn>
                  <TextColumn>Value</TextColumn>
                  <TextColumn>Unit</TextColumn>
                </Row>
              }
            >
              {value.map((item) => (
                <Row>
                  <Column> {item.name}</Column>
                  <Column> {item.value}</Column>
                  <Column> {item.unit}</Column>
                </Row>
              ))}
            </Table>
          </div>
        </>
      ))}
    </>
  )
}

export default Attributes
