import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import useSearch from "@components/hooks/useSearch"
import useDebounce from "@components/hooks/useDebounce"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import ContentRow from "@pages/content-manager/components/ContentRow/ContentRow"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Loader from "@components/ui/Loader/BarLoader"
import { ConnectorDocument } from "@framework/types/content-manager"

import styles from "./LinkContentModal.module.sass"

export interface LinkContentModalProps {
  selectedContentIds: string[]
  onSave: (docs: ConnectorDocument[]) => void
  identifierId?: string
}

export const LinkContentModal: React.FC<LinkContentModalProps> = observer(
  ({ onSave, identifierId, selectedContentIds }) => {
    const [selectedRows, setSelectedRows] = useState<ConnectorDocument[]>([])

    const [allDocs, setAllDocs] = useState<ConnectorDocument[]>([])

    const [searchProps, searchContext] = useSearch()
    const { debouncedValue, setDebounce } = useDebounce()

    const { contentManagerController } = useController()
    const { contentManagerStore } = useStore()
    const { documentsList, loadingDocumentsList, documentsMeta } =
      contentManagerStore

    const { hideModal } = useModal(ModalsTypes.LINK_CONTENT_MODAL)

    const handleReject = async () => {
      hideModal()
    }

    const handleConfirm = async () => {
      onSave(selectedRows)
      hideModal()
    }

    const page = documentsMeta?.pageNum || 0

    const loadPage = (pageNum = page + 1) =>
      contentManagerController.loadDocumentsList({
        pageSize: 10,
        pageNum,
        query: debouncedValue,
        ...(identifierId
          ? {
              filter: JSON.stringify({
                include: false,
                identifiers: [identifierId],
              }),
            }
          : {}),
      })

    const hasMore = page < (documentsMeta?.totalPages || 0)

    const lastItemRef = useInfiniteScroll({
      callback: () => loadPage(page + 1),
      isLoading: loadingDocumentsList,
      hasMore,
    })

    const clearSearch = () => {
      contentManagerController.clearSearch()
      searchContext.setQuery("")
      loadPage()
    }

    useEffect(() => {
      if (documentsMeta) {
        documentsMeta.pageNum = 1
      }
      setAllDocs([])
      loadPage(1)
    }, [debouncedValue])

    useEffect(() => {
      setDebounce(searchProps.value)
    }, [searchProps.value])

    useEffect(() => {
      if (documentsList.length) {
        setAllDocs([
          ...allDocs,
          ...documentsList.filter(
            (doc) => !selectedContentIds.includes(doc.id)
          ),
        ])
      }
    }, [documentsList])

    return (
      <BaseModal
        title={
          <Text
            className={styles.header}
            weight="bold"
            align="center"
            variant="h2"
          >
            Link Content
          </Text>
        }
        className={styles.root}
        containerClassName={styles.container}
      >
        <TextInput
          {...searchProps}
          before={<Icon name="search" />}
          placeholder="Search"
          after={
            !!searchProps.value && <Icon name="cross" onClick={clearSearch} />
          }
        />
        <LoadingCover
          isLoading={loadingDocumentsList && !allDocs.length}
          className={styles.body}
        >
          {!loadingDocumentsList && !allDocs?.length && (
            <NotFound className={styles.notFound}>
              <Text variant="h2">No documents found</Text>
            </NotFound>
          )}
          {allDocs?.map((item, index) => (
            <>
              <ContentRow
                isCompact
                isLink
                selected={selectedRows.includes(item)}
                onSelect={(val) => {
                  setSelectedRows((prevSelectedRows) =>
                    prevSelectedRows.map((item) => item.id).includes(val.id)
                      ? prevSelectedRows.filter((row) => row.id !== val.id)
                      : [...prevSelectedRows, val]
                  )
                }}
                key={item.id}
                data={item}
              />
              {index === allDocs.length - 1 && (
                <div className={styles.lastElem} ref={lastItemRef} />
              )}
            </>
          ))}

          {!!loadingDocumentsList && !!allDocs.length && (
            <div className={styles.loader}>
              <Loader size="small" />
            </div>
          )}
        </LoadingCover>
        <div className={styles.footer}>
          <Button variant="outlined" size="medium" onClick={handleReject}>
            Cancel
          </Button>
          <Button color="primary" size="medium" onClick={handleConfirm}>
            Save
          </Button>
        </div>
      </BaseModal>
    )
  }
)

export default LinkContentModal
