import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { Identifier, IdentifierDetails } from "@framework/types/identifier"
import IconButton from "@components/ui/IconButton/IconButton"
import { useController } from "@store"

import styles from "./ChildIdentifiersTable.module.sass"

export type LinkedContentTableProps = {
  identifierDetails: IdentifierDetails
  parentName: string
  identifierName: string
}

const ChildIdentifiersTable: React.FC<LinkedContentTableProps> = observer(
  ({ identifierDetails, parentName, identifierName }) => {
    const { identifierController } = useController()
    const linkContentModal = useModal(ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL)
    const deleteModal = useModal(ModalsTypes.CONFIRM_MODAL)
    const [identifiersList, setIdentifiersList] = useState(
      identifierDetails.childCategories || []
    )

    const handleAddChildClick = () => {
      linkContentModal.showModal({
        parentCategoryId: identifierDetails?.category?.id,
        companyId: identifierDetails?.category?.company?.id,
        invalidNames: [
          parentName,
          identifierName,
          ...(identifierDetails.childCategories?.map((c) => c.name) || []),
        ],
        onSuccess: (res) => {
          setIdentifiersList([...identifiersList, res.category])
        },
      })
    }
    const handleRemove = (item: Identifier) => {
      deleteModal.showModal({
        message: `Do you want to remove ${item.name} ?`,
        onConfirm: async () => {
          const success = await identifierController.deleteIdentifier(item.id)
          if (success) {
            setIdentifiersList((prevList) =>
              prevList.filter((identifier) => identifier.id !== item.id)
            )
          }
          return success
        },
      })
    }
    return (
      <div className={styles.root}>
        <Text variant="h2" weight="bold">
          Child Identifiers
        </Text>
        <div className={styles.scrollableTable}>
          <Table
            header={
              <Row>
                <TextColumn width={100}>Identifier Name</TextColumn>
                <Column> </Column>
              </Row>
            }
          >
            {identifiersList.map((item) => (
              <Row key={item.id}>
                <Column> {item.name}</Column>
                <Column className={styles.actionColumn}>
                  <IconButton onClick={() => handleRemove(item)}>
                    <Icon color="red" name="trash-can" />
                  </IconButton>
                </Column>
              </Row>
            ))}
          </Table>
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            size="tiny"
            variant="text"
            before={<Icon name="plus" />}
            onClick={handleAddChildClick}
          >
            Add Child Identifier
          </Button>
        </div>
      </div>
    )
  }
)

export default ChildIdentifiersTable
