import React from "react"
import { observer } from "mobx-react-lite"
import { Link, useLocation } from "react-router-dom"
import zip from "lodash/zip"

import List from "@components/ui/List/List"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import IntroLayout from "@components/layout/IntroLayout/IntroLayout"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Text from "@components/ui/Typography/Text"
import LoginSSOForm from "@pages/auth/LoginSSOForm"
import LoginMSSSOForm from "@pages/auth/LoginMSSSOForm"
import Logo from "@components/prototypes/Logo/Logo"
import { initArray } from "@utils/numberUtils"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import ExternalSignForm from "./ExternalSignUpForm"
import SignInForm from "./components/SignInForm/SignInForm"
import AgreementStamp from "./AgreementStamp"

import styles from "./SignIn.module.sass"

export interface SignInPageProps {}

const SignInUsingSSOForm: React.FC<{ primary?: boolean }> = observer(
  ({ primary }) => {
    const theme = useAppConfig()

    const loginConfig = theme.authorization.login

    if (loginConfig.SSO.variant === "SAML") {
      return <LoginSSOForm variant="SAML" primary={primary} />
    }

    if (loginConfig.SSO.variant === "OIDC") {
      return <LoginSSOForm variant="OIDC" primary={primary} />
    }

    return <LoginMSSSOForm primary={primary} />
  }
)

const SignInPage: React.FC<SignInPageProps> = observer(() => {
  const location = useLocation()
  const theme = useAppConfig()

  const { restrictionsStore: access } = useStore()

  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const loginConfig = theme.authorization.login
  const signupConfig = theme.authorization.signUp

  const getActionNodes = () => {
    const allowClassicLogin = !!location?.state?.allowClassicLogin

    const subForms = []

    if (!loginConfig.classic.disabled || allowClassicLogin)
      subForms.push({
        name: "CLASSIC_LOGIN",
        priority: loginConfig.classic.displayPriority ?? 0,
      })

    if (!loginConfig.SSO.disabled)
      subForms.push({
        name: "SSO_LOGIN",
        priority: loginConfig.SSO.displayPriority ?? 0,
      })

    if (!signupConfig.external.disabled)
      subForms.push({
        name: "EXTERNAL_SIGNUP",
        priority: signupConfig.external.displayPriority ?? 0,
      })

    const actionNodes: React.ReactNode[] = subForms
      .sort((ita, itb) => itb.priority - ita.priority)
      .map((it, idx) => {
        const isPrimary = idx === 0
        if (it.name === "CLASSIC_LOGIN")
          return <SignInForm primary={isPrimary} key={it.name} />

        if (it.name === "SSO_LOGIN")
          return <SignInUsingSSOForm primary={isPrimary} key={it.name} />

        if (it.name === "EXTERNAL_SIGNUP")
          return (
            <List gutter="24" overflow="initial" key={it.name}>
              <Text
                variant={isTablet ? "h6" : "h4"}
                color="text50Color"
                align="center"
              >
                Don&lsquo;t have a Personal Account?
              </Text>

              <ExternalSignForm primary={isPrimary} />
            </List>
          )

        return null
      })

    const totalDividers = Math.max(actionNodes.length - 1, 0)

    return zip(
      actionNodes,
      initArray(totalDividers, () => <span className={styles.divider}>or</span>)
    )
  }

  const mentionNesh = !!theme.copy.mentionNesh

  return (
    <IntroLayout>
      <div className={styles.root}>
        <div className={styles.logo}>
          <Logo
            name={theme.copy.logo}
            height={isTablet ? 36 : 48}
            withText
            primary
          />
        </div>

        <div className={styles.header}>
          <Text variant={isTablet ? "h1" : "title"} align="center">
            Sign In
          </Text>

          {!signupConfig.classic.disabled && (
            <Text variant={isTablet ? "h6" : "h4"}>
              Don&lsquo;t have an account?&nbsp;
              <Link to={mainRoutes.signUp()}>Sign Up</Link>
            </Text>
          )}
        </div>

        <div className={styles.body}>{getActionNodes()}</div>

        {mentionNesh && (
          <>
            <Text variant="h6" align="center" className={styles.footer}>
              Powered by Nesh
            </Text>
            <br />
          </>
        )}

        {access.isAppAgreementsStampAvailable && <AgreementStamp />}
      </div>
    </IntroLayout>
  )
})

export default SignInPage
