import React from "react"
import { observer } from "mobx-react-lite"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import { useController, useStore } from "@store"
import useSearch from "@components/hooks/useSearch"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Button from "@components/ui/Button/Button"
import useDebounced from "@components/hooks/useDebounced"
import mainRoutes from "@root/main.routes"
import BackButton from "@components/prototypes/BackButton"
import {
  TableOrderContext,
  useTableOrder,
} from "@components/ui/BaseTable/TableOrderContext"

import IdentifierList from "./IdentifierList"
import IdentifierFilter from "../IdentifierFilter"

import styles from "./index.module.sass"

const ProductIdentifiers: React.FC = observer(() => {
  const addProductIdentifierModal = useModal(
    ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL
  )

  const {
    restrictionsStore: access,
    identifierStore: { searchQuery, orderBy },
  } = useStore()

  const {
    identifierController: { reload, updateSearchQuery, updateOrder },
  } = useController()

  const debouncedSearch = useDebounced(updateSearchQuery)

  const [searchProps, searchContext] = useSearch({
    initialValue: searchQuery,
    onChange: debouncedSearch,
  })

  const openAddIdentifierModal = () => {
    addProductIdentifierModal.showModal({
      onSuccess: () => {
        reload()
      },
    })
  }

  const clearSearch = () => {
    searchContext.setQuery("")
  }

  const orderContext = useTableOrder({
    orderBy,
    onOrderChange: updateOrder,
  })

  return (
    <TableOrderContext.Provider value={orderContext}>
      <MainLayout>
        <Container>
          <div className={styles.root}>
            <EntityPageHeaderLayout
              className={styles.header}
              left={
                <BackButton to={mainRoutes.products()}>
                  Manage Product Identifiers
                </BackButton>
              }
              right={
                access.canCreateIdentifier && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="big"
                    onClick={openAddIdentifierModal}
                  >
                    Add Identifier
                  </Button>
                )
              }
            >
              <TextInput
                {...searchProps}
                className={styles.searchInput}
                before={<Icon name="search" />}
                placeholder="Search"
                after={
                  !!searchProps.value && (
                    <Icon name="cross" onClick={clearSearch} />
                  )
                }
              />
            </EntityPageHeaderLayout>

            <IdentifierFilter />

            <IdentifierList />
          </div>
        </Container>
      </MainLayout>
    </TableOrderContext.Provider>
  )
})

export default ProductIdentifiers
