import React from "react"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import ProductsField from "@components/prototypes/ProductPicker/MultipleProductsField"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import { ReportRequest } from "./GenerateWorkbookReportModal"

import styles from "./GenerateWorkbookReportModal.module.sass"

export interface ProductComparisonFormProps {
  onSubmit?: (data: ReportRequest) => Promise<void | boolean> | void | boolean
  onClose?: () => void
}

const minProductsNum = 2

const validationSchema = yup.object({
  products: yup
    .array()
    .label("Products")
    .of(yup.string().required().default(""))
    .min(
      minProductsNum,
      `at least ${minProductsNum} products needed for comparison`
    )
    .required()
    .default([]),
})

type FormData = yup.InferType<typeof validationSchema>

export const renderQuery = (products: string[]) => {
  const productsText = products.length
    ? `${products.map((it) => `[${it}]`).join(" vs ")}`
    : "[Not provided]"

  return `Generate a comparison report for products ${productsText}`
}

const ProductComparisonForm: React.FC<ProductComparisonFormProps> = observer(
  ({ onSubmit, onClose }) => {
    const alert = useAlert()

    const { productComparisonStore } = useStore()
    const { unifiedMatrixController } = useController()

    const [isLoading, setLoading] = React.useState(false)

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: async (data) => {
        try {
          setLoading(true)

          const products = data.products.map((id) => {
            const item = productComparisonStore.productStore.getProductById(id)
            if (item == null) throw new Error("Unable to load product")
            return item.name
          })

          const query = renderQuery(products)

          const res =
            await unifiedMatrixController.generateProductComparisonReport(
              products
            )

          if (res.status === "SUCCESS") {
            const shouldClose =
              (await onSubmit?.({
                id: res.data.id,
                data: res.data,
                query,
              })) ?? true
            if (shouldClose) onClose?.()
            return
          }

          alert.error(
            "Unexpected error while report generation. Please try again later"
          )
        } finally {
          setLoading(false)
        }
      },
    })

    return (
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className={clsx(styles.form, styles.tall)}
        >
          <Templates.RollScript
            gutter="24"
            footerSocket={
              <ModalFooterContainer>
                <Button
                  size="big"
                  variant="outlined"
                  disabled={isLoading}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="big"
                  color="primary"
                  disabled={isLoading}
                  after={isLoading && <Loader size="small" />}
                >
                  Confirm
                </Button>
              </ModalFooterContainer>
            }
          >
            <div className={styles.inlineFlow}>
              <Text variant="body1" className={styles.messageText}>
                Generate a comparison report for products
              </Text>

              <div className={styles.input}>
                <ProductsField
                  name="products"
                  placeholder={`Select ${minProductsNum} and more products...`}
                  clearable={false}
                />
              </div>
            </div>
          </Templates.RollScript>
        </form>
      </FormikProvider>
    )
  }
)

export default ProductComparisonForm
