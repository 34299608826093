import { AxiosResponse } from "axios"

import { UserAndGroup } from "@framework/types/user"

import HttpService from "./http.service"

export interface GetUserAndGroupsResponse {
  data: UserAndGroup[]
  pagination: {
    totalItems: number
    totalPages: number
    page: number
    pageSize: number
  }
}

export type EntityAccessEndpoints = "solution" | "company"

export type GetEntitiesRequestFilter = {
  excludeByRelation: EntityAccessEndpoints
  relationId: string
}

export type GetEntitiesPaginationRequest = {
  page: number
  pageSize?: number
  query?: string
}

export type GetEntitiesRequest = GetEntitiesPaginationRequest &
  (GetEntitiesRequestFilter | {})

class EntitiesApi extends HttpService {
  getUserAndGroups = (
    params: GetEntitiesRequest
  ): Promise<AxiosResponse<GetUserAndGroupsResponse>> =>
    this.get("ts/entities", true, params)
}

export default new EntitiesApi()
