import clsx from "clsx"
import React from "react"

import styles from "./Blockquote.module.sass"

export const Blockquote = React.forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement>
>(({ children, className, ...rest }, ref) => {
  return (
    <blockquote {...rest} ref={ref} className={clsx(styles.root, className)}>
      {children}
    </blockquote>
  )
})

export default Blockquote
