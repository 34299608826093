import React from "react"

const FlexconLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    viewBox="0 0 1072 187"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M239.707 184.119V62.6719C239.707 28.3839 268.27 0.579041 302.573 0.579041H336.644V31.9117H304.29C287.843 31.9117 272.71 46.8121 272.71 63.2598L272.664 75.5144H326.742V106.491H272.911V184.103H239.691L239.707 184.119Z"
      fill="currentColor"
    />
    <path
      d="M624.936 109.075L660.647 58.0146H622.723L598.353 95.4436C594.083 88.5272 578.006 64.9465 573.736 58.0146H534.048L570.518 110.096C575.005 116.502 575.02 125.121 570.564 131.542L534.063 184.103H571.74C576.01 177.187 592.582 152.322 597.115 145.406L622.986 184.103H662.179L624.936 130.505C620.464 124.084 620.48 115.466 624.967 109.06L624.936 109.075Z"
      fill="currentColor"
    />
    <path
      d="M725.03 85.7107C741.617 85.7107 749.4 96.2632 752.402 106.553H783.053C781.027 80.5892 757.941 54.8113 724.272 54.8113C688.344 54.8113 660.214 82.1829 660.214 120.37C660.214 158.557 688.854 186.114 725.278 185.944C759.256 185.79 779.201 160.383 783.053 134.45H752.402C750.189 144.075 742.36 155.292 725.262 155.292C708.165 155.292 693.605 142.728 693.605 120.37C693.605 98.0117 707.917 85.7107 724.999 85.7107H725.03Z"
      fill="currentColor"
    />
    <path
      d="M864.395 54.9503C827.476 54.9503 799.579 82.4921 799.579 120.896C799.579 159.3 827.461 185.929 864.395 185.929C901.329 185.929 929.211 159.052 929.211 120.896C929.211 82.7397 901.329 54.9503 864.395 54.9503ZM864.395 156.282C848.071 156.282 833 144.152 833 120.896C833 97.6401 848.071 85.5248 864.395 85.5248C880.719 85.5248 895.789 97.408 895.789 120.896C895.789 144.384 880.719 156.282 864.395 156.282Z"
      fill="currentColor"
    />
    <path
      d="M501.416 142.249C496.897 151.254 488.635 157.289 474.91 157.289C458.339 157.289 444.522 145.483 443.763 129.159H532.191C532.191 128.648 532.702 123.635 532.702 118.854C532.702 79.1661 509.848 54.7962 471.661 54.7962C440.004 54.7962 410.868 80.4194 410.868 119.86C410.868 161.559 440.762 186.084 474.678 185.929C506.986 185.774 524.95 161.807 531.139 142.234H501.431L501.416 142.249ZM472.156 81.9357C491.25 81.9357 499.296 93.9891 499.791 106.306H444.522C445.28 95.0103 454.827 81.9357 472.156 81.9357Z"
      fill="currentColor"
    />
    <path
      d="M1009.17 55.7239C974.655 55.7239 946.68 83.699 946.68 118.219V184.242H979.173V119.24C979.173 102.669 992.603 89.2383 1009.17 89.2383C1025.75 89.2383 1039.18 102.669 1039.18 119.24V184.242H1071.67V118.219C1071.67 83.699 1043.69 55.7239 1009.17 55.7239Z"
      fill="currentColor"
    />
    <path
      d="M385.926 186.346L403.982 157.087C396.292 154.225 387.968 147.772 387.968 126.064V0.408264H354.67V126.992C354.67 139.742 356.713 150.496 360.921 159.872C366.151 171.554 374.708 180.559 385.926 186.346Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.3806 92.8749V104.681H53.9696V92.8749C53.9696 71.5532 71.2529 54.2855 92.5745 54.2855H182.054V69.6965H92.5745C79.763 69.6965 69.3806 80.0788 69.3806 92.8749Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3648 90.9405V104.789H26.9537V90.9405C26.9537 55.786 55.4549 27.2849 90.6093 27.2849H182.054V42.6959H90.6093C63.965 42.6959 42.3648 64.2961 42.3648 90.9405Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.411 90.5696V104.805H0V90.5696C0 40.7159 40.4153 0.316101 90.2536 0.316101H182.054V15.7271H90.2536C48.9254 15.7271 15.411 49.226 15.411 90.5696Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.658 78.6251H128.069V90.431C128.069 111.753 110.786 129.02 89.4644 129.02H0V113.609H89.4799C102.291 113.609 112.674 103.227 112.674 90.431V78.6251H112.658Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.69 78.5163H155.101V92.3646C155.101 127.519 126.599 156.005 91.445 156.005H0V140.594H91.445C118.089 140.594 139.69 118.993 139.69 92.3646V78.5163Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.643 78.5012H182.054V92.7363C182.054 142.59 141.639 182.99 91.8008 182.99H0V167.579H91.8008C133.129 167.579 166.643 134.08 166.643 92.7363V78.5012Z"
      fill="currentColor"
    />
  </svg>
)

export default FlexconLogo
