import React, { useEffect } from "react"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import * as Yup from "yup"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import { IdentifierDetails } from "@framework/types/identifier"
import Select from "@components/ui/MultiSelect/Select"
import { Option } from "@framework/types/utils"

import styles from "./AddProductIdentifierModal.module.sass"

export interface AddProductIdentifierModalProps {
  onSuccess: (res: IdentifierDetails) => void
  parentCategoryId?: string
  companyId?: string
  invalidNames?: string[]
}
const AddProductIdentifierModal: React.FC<AddProductIdentifierModalProps> =
  observer(({ onSuccess, parentCategoryId, companyId, invalidNames }) => {
    const addProductIdentifierModal = useModal(
      ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL
    )

    const validationSchema = Yup.object({
      companyId: Yup.string().required("Manufacturer is required").default(""),
      name: Yup.string()
        .required("Name is required")
        .notOneOf(invalidNames || [], "Name is invalid")
        .default(""),
      parentCategoryId: Yup.string().default(""),
    })

    const {
      contentManagerStore: { companies, categories, loadingCompanies },
      identifierStore,
    } = useStore()
    const { loadingCreate, loadingList, error } = identifierStore

    const { identifierController, contentManagerController } = useController()

    const closeModal = () => {
      addProductIdentifierModal.hideModal()
    }

    const alert = useAlert()

    const formik = useFormik<Yup.InferType<typeof validationSchema>>({
      initialValues: validationSchema.cast({
        companyId,
        parentCategoryId,
      }),
      validationSchema,
      onSubmit: async (values) => {
        const res = await identifierController.createIdentifier(values)
        if (res !== null) {
          alert.success("Successfully created identifier")
          closeModal()
          onSuccess(res)
        }
      },
    })

    useEffect(() => {
      if (error) {
        alert.error(error)
        identifierStore.error = ""
      }
    }, [error])

    useEffect(() => {
      contentManagerController.getCompanies()
      return formik.resetForm()
    }, [])

    const companiesOptions: Option[] = React.useMemo(() => {
      return companies.map((company) => ({
        name: company.id,
        value: company.name,
      }))
    }, [companies])

    const categoriesOptions: Option[] = React.useMemo(() => {
      return categories.map((category) => ({
        name: category.id,
        value: category.name,
      }))
    }, [categories])

    return (
      <BaseModal
        title={<ModalTitle titleText="Add Identifier" />}
        className={styles.root}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button size="medium" variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                tabIndex={0}
                disabled={loadingCreate}
                after={loadingCreate && <Loader size="small" />}
                size="medium"
                color="primary"
                onClick={formik.submitForm}
              >
                Save
              </Button>
            </div>
          }
        >
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputWrapper}>
                  <Text variant="body2" className={styles.formInputHeading}>
                    Identifier Name
                  </Text>
                  <FormTextInput
                    value={formik.values.name}
                    name="name"
                    placeholder="Identifier Name..."
                    onChange={(name) => {
                      formik.setFieldValue("name", name)
                    }}
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <Text variant="body2" className={styles.formInputHeading}>
                    Manufacturer
                  </Text>
                  <Select
                    clearable
                    placeholder="Select Manufacturer..."
                    loading={loadingCompanies}
                    options={companiesOptions}
                    value={formik.values.companyId}
                    onChange={(selectedCompanyId) => {
                      formik.setFieldValue("parentCategoryId", undefined, false)
                      formik.setFieldValue("companyId", selectedCompanyId)
                      contentManagerController.getCategories(
                        selectedCompanyId || undefined
                      )
                    }}
                    withError={
                      !formik.values.companyId && formik.touched.companyId
                    }
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <Text variant="body2" className={styles.formInputHeading}>
                    Parent Identifier (Optional)
                  </Text>
                  <Select
                    clearable
                    placeholder="Add Parent Identifier..."
                    options={categoriesOptions}
                    value={formik.values.parentCategoryId}
                    onChange={(parentCategoryId) => {
                      formik.setFieldValue("parentCategoryId", parentCategoryId)
                    }}
                    loading={loadingList}
                    disabled={!formik.values.companyId}
                    withError={
                      !!formik.errors.parentCategoryId &&
                      formik.touched.parentCategoryId
                    }
                  />
                </div>
              </div>
            </form>
          </FormikProvider>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default AddProductIdentifierModal
