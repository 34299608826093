import React from "react"

import { SimpleBaseUserData } from "@framework/types/user"
import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"
import { getFullName } from "@pages/questions/utils"

import styles from "./ExpertStrip.module.sass"

type ExpertStripProps = {
  data: SimpleBaseUserData
  rating: number
}

const ExpertStrip: React.FC<ExpertStripProps> = ({ data, rating }) => {
  const fullName = getFullName(data)
  return (
    <div className={styles.root}>
      <Avatar
        width={45}
        height={45}
        className={styles.avatar}
        src={data.avatarURL}
        name={fullName}
      />
      <div className={styles.expertDetails}>
        <Text variant="h5">{fullName ?? "Deactivated user"}</Text>

        <Text variant="h5">{rating}</Text>
      </div>
    </div>
  )
}

export default ExpertStrip
