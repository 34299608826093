import * as React from "react"

import { UseInteractionsReturn, UseFloatingReturn } from "@floating-ui/react"

export interface DropdownContextState {
  open: boolean
  onOpenChange: (open: boolean) => void
  activeIndex: number | null
  onActiveIndexChange: (index: number | null) => void
  onSelectByIndex: (value: number) => void
  searchQuery: string
  setSearchQuery?: (query: string) => void
  interactions: UseInteractionsReturn
  floating: UseFloatingReturn
  listRef: React.MutableRefObject<Array<HTMLElement | null>>
  closeOnSelect: boolean
  loading: boolean
  disabled: boolean
  clearable: boolean
  creatable: boolean
  searchable: boolean
  isMulti: boolean
  onAddOption?: (value: string) => Promise<void> | void
}
export const DropdownContext = React.createContext<DropdownContextState | null>(
  null
)
export const useDropdownContext = () => {
  const context = React.useContext(DropdownContext)
  if (context == null) throw new Error("Can't find DropdownContext")
  return context
}
