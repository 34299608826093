import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController } from "@store"
import Templates from "@components/ui/Templates"

import Footer from "./ReportTemplateFooter"

import styles from "./WorkbookReportTemplate.module.sass"

export const renderQuery = (data: FormData) => {
  return `Generate product recommendation report for ${data.context}`
}

export interface ProductRecommenderFormProps {
  onSubmit?: () => Promise<void | boolean> | void | boolean
  onClose?: () => void
}

const validationSchema = yup.object({
  context: yup.string().label("Context").required().default(""),
})

type FormData = yup.InferType<typeof validationSchema>

const ProductRecommenderForm: React.FC<ProductRecommenderFormProps> = observer(
  ({ onSubmit, onClose }) => {
    const [isLoading, setLoading] = React.useState(false)

    const { unifiedMatrixController, factFinderSolutionController } =
      useController()

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      onSubmit: async (data) => {
        try {
          setLoading(true)

          const query = renderQuery(data)

          const store = factFinderSolutionController.pushReport({
            type: "PRODUCTRECOMMENDATION",
            query,
            data,
          })

          if (store == null) return

          const shouldClose = (await onSubmit?.()) ?? true

          const res =
            await unifiedMatrixController.generateProductRecommendationReport(
              data.context
            )

          if (res.status === "SUCCESS") {
            store.setData(res.data)

            if (shouldClose) onClose?.()

            return
          }

          const error =
            "Unexpected error while report generation. Please try again later"

          store.setError(error)
        } finally {
          setLoading(false)
        }
      },
    })

    return (
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Templates.RollScript
          gutter="24"
          footerSocket={<Footer isLoading={isLoading} onClose={onClose} />}
        >
          <div className={styles.inlineFlow}>
            <Text variant="body1" className={styles.messageText}>
              Generate product recommendation report for
            </Text>

            <div style={{ flexBasis: 300 }} className={styles.input}>
              <TextAreaInput
                autoFocus
                value={formik.values.context}
                placeholder="...a target application,
                scenario or metrics (Enter some context)"
                onChange={(event) => {
                  formik.setFieldValue("context", event.currentTarget.value)
                }}
                onBlur={formik.handleBlur}
                withError={!!(formik.touched.context && formik.errors.context)}
                rows={3}
              />
            </div>
          </div>
        </Templates.RollScript>
      </form>
    )
  }
)

export default ProductRecommenderForm
