import { useFormikContext } from "formik"
import React from "react"

import VoiceInputButton from "@components/ui/Button/VoiceInputButton"
import { useStore } from "@store/index"
import { useSpeechRecognizer } from "@pages/search/components/SearchWidget/useSpeechRecognizer"
import SearchInputField from "@pages/search/components/SearchWidget/SearchInputField"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import { SearchSubmitButton } from "./SearchSubmitButton"

import styles from "./SearchInput.module.sass"

interface Props {
  disabled?: boolean
}

export const SearchInput: React.FC<Props> = ({ disabled }) => {
  const { restrictionsStore: access } = useStore()
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)
  const formik = useFormikContext<any>()

  const speechControl = useSpeechRecognizer({
    onChange: (value) => {
      formik.setFieldValue("query", value)
    },
  })

  const isListening = speechControl.listening
  const canSearch = !disabled && !isListening && formik.values.query.length > 0

  return (
    <>
      <SearchInputField
        disabled={isListening || disabled}
        placeholder=" Ask a question"
        name="query"
      />

      {access.isSearchVoiceRecognitionEnabled && (
        <VoiceInputButton
          size={isMobile ? "small" : "big"}
          listening={isListening}
          className={styles.voiceInputBtn}
          error={speechControl.error}
          onClick={() => speechControl.toggle()}
        />
      )}

      <SearchSubmitButton autoFocus={isListening} disabled={!canSearch} />
    </>
  )
}

export default SearchInput
