import React, { CSSProperties } from "react"

import styles from "./ScoreBar.module.sass"

interface ScoreBarProps {
  currentScore: number
  topScore?: number
}

export const ScoreBar: React.FC<ScoreBarProps> = ({
  currentScore,
  topScore = currentScore,
}) => {
  const progress = (currentScore / topScore) * 100

  return (
    <div
      className={styles.root}
      style={{ "--progress-value": `${progress}%` } as CSSProperties}
    >
      <span>Contributions: </span>
      {Number(currentScore).toLocaleString("en-US")}
    </div>
  )
}

export default ScoreBar
