import React from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Dropdown/Box"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"
import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store"

import styles from "./SuggestImprovements.module.sass"

const SuggestImprovementsBanner: React.FC = observer(() => {
  const {
    factFinderSolutionStore: {
      searchFlowStore: { setSuggestImprovementsBanner },
    },
    knowledgeStore: { expertQuestions },
  } = useStore()

  const closeBanner = () => {
    setSuggestImprovementsBanner(false)
  }

  const answeredQuestionCount = expertQuestions?.answered?.reduce(
    (prev, curr) => {
      if (!curr.verifiedBy) {
        return prev
      }

      return prev + curr.verifiedBy.length
    },
    0
  )

  return (
    <Box color="primary" className={styles.suggestBannerBox}>
      <Text>
        <Text className={styles.heading} variant="h3" weight="semibold">
          Verify or Improve?
        </Text>
        <Text className={styles.caption} variant="caption1" color="text40Color">
          As a designated expert, your insights and knowledge are critical to
          verify or improve this answer summary. Thank you for helping us
          improve the accuracy and usefulness of these answers.
        </Text>
      </Text>

      <Text className={styles.subCaption}>
        {!expertQuestions?.answered ? (
          <Icon
            name="loader"
            size={10}
            color="green"
            className={styles.suggestionCount}
          />
        ) : (
          <Text inline color="greenColor" className={styles.suggestionCount}>
            {answeredQuestionCount}
          </Text>
        )}
        Expert Improvements to date.
      </Text>

      <IconButton
        onClick={closeBanner}
        className={styles.suggestedBannerCloseBtn}
      >
        <Icon name="cross" />
      </IconButton>
    </Box>
  )
})

export default SuggestImprovementsBanner
