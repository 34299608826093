import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { ErrorBoundary } from "react-error-boundary"

import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import { InlineReport } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/UnifiedMatrix/Report"
import Templates from "@components/ui/Templates"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"
import Button from "@components/ui/Button/Button"
import { copyToClipboard } from "@utils/clipboard"
import mainRoutes from "@root/main.routes"

import DownloadReportButton from "./DownloadReportButton"
import { renderDate, renderETA } from "./utils"

import styles from "./ReportViewCard.module.sass"

export type ReportViewCardProps = {
  reportId: string
  onClose?: () => void
}

const ReportViewCard: React.FC<ReportViewCardProps> = observer(
  ({ reportId, onClose }) => {
    const alert = useAlert()

    const { workbookReportsStore } = useStore()

    const { unifiedMatrixController } = useController()

    React.useEffect(() => {
      unifiedMatrixController.pullReport(reportId)
    }, [reportId])

    const report = workbookReportsStore.getById(reportId)

    const handleCopyLink = () => {
      try {
        copyToClipboard(
          encodeURI(
            `${window.location.origin}${mainRoutes.workbookReport(report.id)}`
          )
        )
        alert.success("Report link copied to clipboard")
      } catch (e) {
        alert.success("Failed to generate report link")
      }
    }

    const renderBody = () => {
      if (report.status === "completed")
        return report.data?.sections.length ? (
          <InlineReport data={report.data} />
        ) : report.isLoading ? (
          <Loader size="huge" fluid />
        ) : (
          <NotFound fluid>
            {report.error || "Unable to generate report"}
          </NotFound>
        )

      if (report.status === "in-progress")
        return (
          <div className={styles.placeholder}>
            <Loader size="large" />
            <Text align="center" variant="h4" weight="bold">
              Generating your report
            </Text>
            <Text color="text50Color" align="center" variant="body2">
              We&#39;re generating your product comparison report.
              <br />
              This usually takes about {renderETA(report.eta)}.
            </Text>
          </div>
        )

      return (
        <div className={styles.placeholder}>
          <Icon name="close" color="red" />
          <Text align="center" variant="h4" weight="bold">
            Generation failed
          </Text>
          <Text color="text50Color" align="center" variant="body2">
            Something went wrong while generating comparison report
          </Text>
        </div>
      )
    }

    return (
      <div className={styles.root}>
        <div className={styles.box}>
          <header className={styles.header}>
            <div className={styles.meta}>
              <Text
                title={report.data?.title}
                variant="h4"
                weight="bold"
                className={styles.title}
              >
                {report.data?.title}
              </Text>
              <Text variant="caption2" color="text70Color">
                Last Updated Date: {renderDate(report.updatedAt)}
              </Text>
            </div>

            {onClose != null && (
              <IconButton size="medium" onClick={onClose}>
                <Icon name="cross" />
              </IconButton>
            )}
          </header>

          <ErrorBoundary
            fallback={
              <NotFound>
                Failed to display report.
                <br />
                Unexpected format
              </NotFound>
            }
            key={reportId}
          >
            <div className={styles.body}>{renderBody()}</div>

            <Templates.Header
              right={
                <>
                  <Button
                    color="secondary"
                    size="medium"
                    before={<Icon name="clipboard" />}
                    onClick={handleCopyLink}
                  >
                    Copy Link
                  </Button>

                  {report.data != null && report.status === "completed" && (
                    <DownloadReportButton report={report.data} size="medium" />
                  )}
                </>
              }
              className={styles.footer}
            />
          </ErrorBoundary>
        </div>
      </div>
    )
  }
)

export default ReportViewCard
