import { makeAutoObservable } from "mobx"
import sumBy from "lodash/sumBy"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
import every from "lodash/every"

import { convertToBytes, formatBytes } from "@utils/file"

import QueryAttachment from "./query-attachment"

export class QueryAttachmentsStore {
  attachments: QueryAttachment[] = []

  attachmentsSizeLimit = convertToBytes(100, "MB")

  isPopupActive = false

  constructor() {
    makeAutoObservable(this)
  }

  get isLoaded(): boolean {
    return (
      this.hasAttachments &&
      every(this.attachments, (attachment) => {
        return !isEmpty(attachment.sessionId)
      })
    )
  }

  get sessionId(): string {
    if (!this.isLoaded) {
      throw new Error("Could not get session - Attachments not loaded yet")
    }

    return this.attachments[0].sessionId as string
  }

  get attachmentsSizeLimitText(): string {
    return formatBytes(this.attachmentsSizeLimit)
  }

  get hasAttachments(): boolean {
    return this.attachments.length > 0
  }

  checkIfAttachmentsSizeUnderLimit(attachments: QueryAttachment[]): boolean {
    return sumBy(attachments, "file.size") <= this.attachmentsSizeLimit
  }

  addAttachment(attachment: QueryAttachment) {
    const attachments = [...this.attachments, attachment]

    if (!this.checkIfAttachmentsSizeUnderLimit(attachments)) {
      throw new Error(
        `Total size exceed the acceptable limit of - ${this.attachmentsSizeLimitText}`
      )
    }

    this.attachments = attachments
  }

  removeAttachment(attachment: QueryAttachment) {
    this.attachments = filter(this.attachments, (att) => {
      return att.id !== attachment.id
    })
  }

  showPopup(value: boolean) {
    this.isPopupActive = value
  }

  reset = () => {
    this.attachments = []
  }
}

export default QueryAttachmentsStore
