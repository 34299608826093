import React from "react"
import clsx from "clsx"
import { Slate } from "slate-react"
import { observer, useLocalObservable } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { FileRejection } from "react-dropzone"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import {
  MarkdownEditorContextProvider,
  MarkdownEditorState,
} from "@components/ui/RichEditor/EditorContext"
import { EditorVoiceToTextContextProvider } from "@components/ui/RichEditor/EditorVoiceToTextContext"
import Editor from "@components/ui/RichEditor/MarkdownEditor"
import {
  markdownToSlate,
  slateToMarkdown,
} from "@components/ui/RichEditor/markdown-parser"
import { initEditor } from "@components/ui/RichEditor/utils"
import { useStore } from "@store"
import DocumentUploadRow from "@pages/upload/components/DocumentUploadRow/DocumentUploadRow"
import { isDocumentIconName } from "@framework/types/utils"
import { getExtension } from "@utils/textUtils"
import AttachDocumentButton from "@components/ui/DocumentDropZone/AttachDocumentButton"
import { validExtensions } from "@framework/constants/upload"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./MarkdownEditor.module.sass"

const DEFAULT_INFO_MESSAGE = `Once saved, this edited summary will be finalized as Experts Verified Citations for reference.`
const extensions = validExtensions.map((it) => `.${it}`)

interface MarkdownEditorProps {
  initialValue?: string
  className?: string
  infoMessage?: string
  loading?: boolean
  onSave: (content: {
    text: string
    attachments: File[]
  }) => Promise<void> | void
  onClose: () => void
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = observer(
  ({
    onSave,
    onClose,
    loading = false,
    infoMessage = DEFAULT_INFO_MESSAGE,
    initialValue,
    className,
  }) => {
    const { restrictionsStore: access } = useStore()

    const [text, setText] = React.useState<string>(initialValue || "")
    const [error, setError] = React.useState<string>("")
    const [attachments, setAttachments] = React.useState<File[]>([])
    const [isSaving, setIsSaving] = React.useState(false)

    const handleSave = async () => {
      try {
        setIsSaving(true)
        await onSave({ text, attachments })
      } finally {
        setIsSaving(false)
      }
    }

    const editor = React.useMemo(initEditor, [])

    const initialState: any[] = React.useMemo(
      () => markdownToSlate(initialValue || ""),
      []
    )

    const editorContext = useLocalObservable(() => new MarkdownEditorState())

    const isLoading = isSaving || loading
    const canSave = !isLoading && !error && !!text

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.body}>
          <Slate
            editor={editor}
            initialValue={initialState}
            onChange={(state) => {
              const res = slateToMarkdown(state)
              if (res.status === "FAILED") {
                setError(res.message || "Unacceptable format")
                return
              }
              setError("")
              setText(res.data)
            }}
          >
            <MarkdownEditorContextProvider value={editorContext}>
              <EditorVoiceToTextContextProvider
                disable={!access.isVoiceRecognitionEnabled || isLoading}
              >
                <Editor
                  readOnly={isLoading}
                  placeholder="Enter you answer..."
                  withError={!!error}
                  attachmentsNode={
                    access.canAttachAnswerDocuments ? (
                      <Attachments
                        loading={isLoading}
                        attachments={attachments}
                        onAdd={(files: File[]) => {
                          setAttachments((prev) => [...prev, ...files])
                        }}
                        onDelete={(file: File) => {
                          setAttachments((prev) =>
                            // eslint-disable-next-line eqeqeq
                            prev.filter((it) => it != file)
                          )
                        }}
                      />
                    ) : null
                  }
                />
              </EditorVoiceToTextContextProvider>
            </MarkdownEditorContextProvider>
          </Slate>
        </div>

        <div className={styles.footer}>
          <Text className={styles.footerText}>
            <Text variant="body2">
              <Icon name="info" inline />
            </Text>

            <Text variant="caption2" color="text50Color" inline>
              {infoMessage}
            </Text>
          </Text>

          <div className={styles.buttonContainer}>
            <Button
              size="big"
              variant="outlined"
              className={styles.closeButton}
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="big"
              variant="contained"
              color="primary"
              disabled={!canSave}
              onClick={handleSave}
              after={isLoading && <Loader />}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

export default MarkdownEditor

export const Attachments: React.FC<{
  attachments: File[]
  loading?: boolean
  onAdd?: (files: File[]) => void
  onDelete?: (file: File) => void
}> = ({ attachments, loading = false, onAdd, onDelete }) => {
  const alert = useAlert()

  const handleAdd = (files: File[], rejected: FileRejection[]) => {
    onAdd?.(files)

    rejected.forEach((it) => {
      alert.error(`File ${it.file.name} can't be attached`)
    })
  }

  return (
    <div className={styles.attachments}>
      <Text variant="caption2">Your Attachments</Text>
      {attachments.length ? (
        <div className={styles.files}>
          {attachments.map((file) => {
            const extension = getExtension(file.name)
            const icon = isDocumentIconName(`img:${extension}`)
              ? `img:${extension}`
              : "img:file"
            return (
              <DocumentUploadRow
                name={file.name}
                type={icon}
                isUploaded={!loading}
                progress={loading ? 100 : undefined}
                onCancel={
                  loading || !onDelete ? undefined : () => onDelete(file)
                }
                key={file.name}
              />
            )
          })}
        </div>
      ) : null}

      {!onAdd || loading ? null : (
        <AttachDocumentButton
          size="medium"
          color="secondary"
          onAttach={handleAdd}
          extensions={extensions}
        />
      )}
    </div>
  )
}
