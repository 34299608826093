import React from "react"
import SpeechRecognition from "react-speech-recognition"

import Text from "@components/ui/Typography/Text"

const speechRecognizer = SpeechRecognition.getRecognition()

export type Options = {
  continuous?: boolean
  disabled?: boolean
  onStart?: () => void
  onChange?: (newValue: string) => void
  onEnd?: () => void
}

export type SpeechRecognizerState = {
  listening: boolean
  disabled: boolean
  transcript: string
  error: string | null
  toggle: (value?: boolean) => void
}

export const useSpeechRecognizer = ({
  continuous,
  disabled = false,
  onChange,
  onStart,
  onEnd,
}: Options = {}): SpeechRecognizerState => {
  const [inProgress, setInProgress] = React.useState<boolean>(false)
  const [currentValue, setCurrentValue] = React.useState<string>("")
  const [error, setError] = React.useState<string | null>(null)

  const startRecording = () => {
    if (speechRecognizer == null) return
    speechRecognizer.continuous = !!continuous
    speechRecognizer.lang = "en"
    speechRecognizer.start()
  }

  const stopRecording = () => {
    if (speechRecognizer == null) return
    speechRecognizer.stop()
    speechRecognizer.continuous = false
  }

  const toggle = (value: boolean = !inProgress) => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition) return
    if (speechRecognizer == null) return

    if (value === inProgress) return

    if (value) startRecording()
    else stopRecording()
  }

  React.useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) return undefined
    if (speechRecognizer == null) return undefined
    if (disabled) return undefined

    // handlers

    const handleResult = (e: SpeechRecognitionEvent) => {
      const value = [...e.results].map((it) => it[0].transcript).join()
      setCurrentValue(value)
      onChange?.(value)
    }

    const handleStart = () => {
      setInProgress(true)
      setError(null)
      setCurrentValue("")
      onStart?.()
      onChange?.("")
    }

    const handleEnd = () => {
      setInProgress(false)
      onEnd?.()
      speechRecognizer.abort()
    }

    const handleError = (e: SpeechRecognitionErrorEvent) => {
      if (e.error === "not-allowed") {
        setError("ACCESS_DENIED")
      }
    }

    // subscriptions

    speechRecognizer.addEventListener("start", handleStart)
    speechRecognizer.addEventListener("result", handleResult)
    speechRecognizer.addEventListener("end", handleEnd)
    speechRecognizer.addEventListener("error", handleError)

    return () => {
      speechRecognizer.removeEventListener("start", handleStart)
      speechRecognizer.removeEventListener("result", handleResult)
      speechRecognizer.removeEventListener("end", handleEnd)
      speechRecognizer.removeEventListener("error", handleError)

      stopRecording()
      handleEnd()
    }
  }, [disabled])

  return {
    toggle,
    listening: inProgress,
    transcript: currentValue,
    disabled,
    error,
  }
}

export const renderVoiceRecognitionError = (error: string) => {
  if (error === "ACCESS_DENIED")
    return (
      <Text color="inherit" variant="inherit">
        <Text variant="inherit" color="redColor">
          Access Denied:
        </Text>{" "}
        Please, update your browser security settings to allow using microphone
        on this page
      </Text>
    )

  return (
    <Text color="inherit" variant="inherit">
      <Text variant="inherit" color="redColor">
        Field:
      </Text>
      Unexpected error occurs while speech recognition
    </Text>
  )
}
