import React from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import { useAppConfig } from "@components/theme/AppConfigProvider"
import Text from "@components/ui/Typography/Text"

import styles from "./SignIn.module.sass"

export const AgreementStamp = observer(() => {
  const theme = useAppConfig()

  const mentionNesh = !!theme.copy.mentionNesh

  return (
    <Text variant="h6" align="center" className={styles.footer}>
      By signing in or creating an account, you agree with{" "}
      {mentionNesh ? "Nesh’s" : "our"}&nbsp;
      <Link to="/terms">Terms & Conditions</Link> and&nbsp;
      <Link to="/privacy">Privacy Statement</Link>
    </Text>
  )
})

export default AgreementStamp
