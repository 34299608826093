import clsx from "clsx"
import React from "react"

import styles from "./Table.module.sass"

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  className?: string
  children?: React.ReactNode
}

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, children, ...rest }, ref) => (
    <table ref={ref} className={clsx(styles.root, className)} {...rest}>
      {children}
    </table>
  )
)

export default Table
