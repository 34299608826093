import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import filter from "lodash/filter"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import TextInput from "@components/ui/TextInput/TextInput"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import useSearch from "@components/hooks/useSearch"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"

import { BaseEntityItem } from "../types"

import styles from "./BaseContainer.module.sass"

interface BaseContainerProps<T> {
  items: T[]
  addLength?: number
  isLoading?: boolean
  renderer: (item: T) => React.ReactNode
  onSubmit: () => void
  entityKey?: string
  filterProperty?: (keyof T)[]
  isEditing?: boolean
  onEdit: (value: boolean) => void
}

const BaseContainer = observer(
  <T extends BaseEntityItem>({
    items = [],
    entityKey = "Entity",
    isLoading,
    isEditing,
    addLength,
    filterProperty = ["name"],
    renderer,
    onSubmit,
    onEdit,
  }: BaseContainerProps<T>) => {
    const [searchProps] = useSearch()

    const query = searchProps.value

    const handleSubmit = () => onSubmit()

    const filteredItems = useMemo(
      () =>
        filter(items, (item) =>
          filterProperty
            .map((key) => item[key])
            .join(" ")
            .toLowerCase()
            .includes(query.toLowerCase())
        ),
      [items, query]
    )

    return isLoading ? (
      <Loader size="large" fluid />
    ) : (
      <List gutter="32">
        <div className={styles.header}>
          <TextInput
            placeholder="Search"
            before={<Icon name="search" />}
            autoFocus
            width={100}
            className={styles.search}
            {...searchProps}
          />
        </div>
        <Templates.RollScript
          gutter="16"
          footerSocket={
            <ModalFooterContainer>
              {isEditing ? (
                <>
                  <Button
                    size="big"
                    disabled={isLoading}
                    variant="outlined"
                    onClick={() => onEdit(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="big"
                    disabled={isLoading}
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {`Add ${addLength ? `(${addLength})` : ""}`}
                  </Button>
                </>
              ) : (
                <Button
                  size="big"
                  variant="outlined"
                  before={<Icon name="plus" />}
                  onClick={() => onEdit(true)}
                >
                  Add {entityKey}
                </Button>
              )}
            </ModalFooterContainer>
          }
        >
          {filteredItems.length ? (
            <List gutter="8">
              {filteredItems.map((item) => renderer(item))}
            </List>
          ) : (
            <NotFound>No {entityKey} found!</NotFound>
          )}
        </Templates.RollScript>
      </List>
    )
  }
)

export default BaseContainer
