import { ReactNode } from "react"

import { PaginationListMeta } from "./utils"
import { DataConnectorSourceName } from "./upload"
import { Company } from "./company"

export interface GetKibanaConnectorAccountsResponse {
  data: KibanaConnector[]
  meta: PaginationListMeta
}

export interface DocumentsListResponse {
  data: ConnectorDocument[]
  meta: PaginationListMeta
}

export interface DocumentsDetailsResponse {
  data: ConnectorDocument
}

export interface CreateContentSourceParams {
  clientId: string
  clientSecret: string
  contentType: string
  domain: string
  enumerateAllSites?: boolean
  fetchSubsites?: boolean
  name: string
  tenantId?: string
  tenantName?: string
  type: string
  useTextExtractionService?: boolean
  websiteName?: string
  companyIds: string[]
  keywords?: string
  childUrls?: string
  certificate?: {
    certificate: string
    privateKey: string
  }
  useCertificate?: boolean
}

export interface GetKibanaConnectorsParams {
  type?: string
  status?: string
}

export interface ConnectWebsiteParams {
  url: string
  websiteName: string
  companyIds?: string[]
  keywords?: string
  childUrls?: string[]
}

export interface CreateContentSourceResponse
  extends CreateContentSourceParams {}

export interface ContentSourceAccount {
  id: string
  accountName: string
  sourceId: string
}

export interface KibanaConnectorInfo {
  accountCount: number
  documentCount: number
  fileCount?: number
  type: string
  sizeInBytes: number
}

export type SimpleContentTypeData = {
  name: string
  description?: string
  iconUrl: string
  id: string
}

export interface ConnectorDocument {
  connectorName: string
  connectorId: string
  contentType: SimpleContentTypeData
  id: string
  name?: string
  sizeInBytes?: number
  connectedDate?: string
  lastModifiedDateTime?: string
  docInfo?: any
  status?: string
  source: DataConnectorSourceName
  companies?: Company[]
  connectedBy: {
    id: string
    name: string
    avatarUrl: string
  }
  uploadedBy: {
    id: string
    name: string
    avatarUrl: string
  }
  filename: string
  filePath: string
  lastModified: string
  link?: string
  contentDocumentLinks?: {
    records: ContentDocumentLink[]
    done: boolean
  }
  isTrialDoc?: boolean
}

export interface KibanaConnector {
  id: string
  name: string
  status: string
  type: string
  domain: string
  siteCollections: string
  lastSyncDate: string
  companies: Company[]
  connectedBy: {
    name: string
    id: string
    avatarUrl: string
  }
  contentCount: number
  statusObj?: any
  error?: string
  sizeInBytes?: number
  childUrls?: string[]
  isPublic?: boolean
}

export type ConnectorDocumentsFilters = {
  contentType?: string[]
  contentConnector?: string[]
  contentSource?: string[]
  connectedBy?: string[]
  connectedDate?: {
    dateRangeStart: number
    dateRangeEnd: number
  }
  include?: boolean
  manufacturer?: string[]
}

export interface GetConnectorDocumentsParams {
  pageNum: number
  pageSize: number
  query?: string
  filter?: string
}

export interface DocumentParams {
  connectorId: string
  documentId: string
}

export interface DocumentByConnectorNameParams {
  connectorName: string
  contentId: string // a.k.a documentId
  isPublic: boolean
}

export interface UpdateDocumentContentTypeParams extends DocumentParams {
  contentType: string
}

export type ConnectorFilter = {
  label: string
  value: string
  icon?: ReactNode
  options?: ConnectorFilter[]
}

export type SyncJobInfo = {
  type: string
  name: string
  domain: string
  id: string
  status: string
  indexed_document_count: string
  statusInfo?: { key: string; value: string }[]
  companies?: Company[]
  error?: string
}

export interface GetSyncJobsResponse {
  data: SyncJobInfo[]
  meta: PaginationListMeta
}

export enum MANUAL_UPLOAD_FILE_STATUS {
  UPLOADING = "uploading",
  COMPLETED = "completed",
  FAILED = "failed",
}

export type ManualUploadFileInfo = {
  fileName?: string
  status?: MANUAL_UPLOAD_FILE_STATUS
  progress?: number
  data?: any
  error?: string
}

export interface WebsiteInfo {
  id: string
  companyId: string
  uuid: string
  keywords: string
  url: string
  websiteName: string
  status: string
  errors: string | null
  createdById: string
  createdAt: string
  updatedAt: string
  pages: number
  productsFound: number
  companies: Company[]
  result: {
    success: number
    failed: number
    running: number
    total: number
    productsFound: number
  }
  connectedBy: {
    id: string
    name: string
    avatarUrl: string
  }
  childUrls?: string[]
  sizeInBytes?: number
  isPublic?: boolean
}

// content document start
interface UserAttributes {
  type: string
  url: string
}

interface User {
  Email: string
  attributes: UserAttributes
  Id: string
  Name: string
}

interface ContentVersionAttributes {
  type: string
  url: string
}

interface LatestPublishedVersion {
  VersionDataUrl: string
  CreatedDate: string
  attributes: ContentVersionAttributes
  Id: string
  VersionNumber: string
}

interface ContentDocumentAttributes {
  type: string
  url: string
}

interface ContentDocument {
  LastModifiedDate: string
  Owner: User
  linked_ids: string[]
  Description: string | null
  CreatedBy: User
  LatestPublishedVersion: LatestPublishedVersion
  FileExtension: string
  CreatedDate: string
  Title: string
  attributes: ContentDocumentAttributes
  Id: string
  ContentSize: number
}

interface ContentDocumentLinkAttributes {
  type: string
  url: string
}

interface ContentDocumentLink {
  ContentDocument: ContentDocument
  attributes: ContentDocumentLinkAttributes
}
// content document end
