import React from "react"
import { observer } from "mobx-react-lite"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Info from "@components/ui/Tooltip/Info"

import TopActiveUsers from "../components/ActiveUsers/TopActiveUsers"
import SearchingTrendChart from "../components/Chart/SearchingTrendChart"

export interface FeedbackTrendWidgetProps {}

export const FeedbackTrendWidget: React.FC<FeedbackTrendWidgetProps> = observer(
  () => {
    return (
      <section>
        <Templates.Header
          offsetBottom="middle"
          left={
            <Text variant="h4">
              Usage Trend
              <Info>
                Day wise trend of number of queries, feedback rating and
                questions posted to experts.
              </Info>
            </Text>
          }
        />

        <List direction="row" gutter="24" align="stretch" overflow="initial">
          <SearchingTrendChart />

          <TopActiveUsers />
        </List>
      </section>
    )
  }
)

export default FeedbackTrendWidget
