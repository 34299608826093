import React from "react"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import {
  attachTypeMapper,
  getDataSourceNodeContextProps,
} from "@framework/constants/upload"
import { DataSourceName } from "@framework/types/upload"

import styles from "./ConnectDataSourceButton.module.sass"

type ConnectDataSourceButtonProps = {
  sourceName: DataSourceName
} & ButtonProps<"button">

export const ConnectDataSourceButton: React.FC<
  ConnectDataSourceButtonProps
> = ({ sourceName, ...rest }) => {
  const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)
  const status = attachTypeMapper[sourceTypeDescription.attachType ?? "CONNECT"]

  const connectButtonLabel = `${status.action} data`

  return (
    <Button
      className={styles.uploadButton}
      color="primary"
      size="big"
      {...rest}
    >
      {connectButtonLabel}
    </Button>
  )
}

export default ConnectDataSourceButton
