import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import CollapsibleContextProvider from "@components/ui/Collapsable/CollapsibleContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { getReportDescriptionByType } from "@framework/constants/workbook"
import {
  ReportDescriptor,
  WorkbookReportTypeName,
} from "@framework/types/workbook-report"

import InfoCard from "./InfoCard"
import LineItem from "./LineItem"
import Question from "./Question"
import { getCompanyOptions, getProductOptions } from "./SolutionOptions"
import ReportSuggestionCard from "./ReportSuggestionCard"
import PrimaryReportSuggestionCard from "./PrimaryReportSuggestionCard"

import styles from "./SidePanel.module.sass"

const suggestedReports: WorkbookReportTypeName[] = [
  "PRODUCTCOMPARISON",
  "PRODUCTPOSITIONING",
  "PRODUCTRECOMMENDATION",
]

const reportOptions = suggestedReports.map<ReportDescriptor>(
  getReportDescriptionByType
)

export type SidePanelProps = {
  isSearching: any
}

const SidePanel: React.FC<SidePanelProps> = observer(({ isSearching }) => {
  const isSmallScreen = useMediaQuery(AppMediaQueries.maxDesktop)

  const {
    restrictionsStore: access,
    factFinderSolutionStore: { searchFlowStore, highlights, loadingHighlights },
  } = useStore()

  const { factFinderSolutionController } = useController()

  const [suggestedQuestions, setSuggestedQuestion] = React.useState<string[]>(
    []
  )

  const summaryBlock = React.useMemo(() => {
    const data = searchFlowStore.getLatestSearchBlock()

    if (data == null) return null

    const block = data.blocks.find(
      (it) => it instanceof SearchSummaryBlockStore
    ) as SearchSummaryBlockStore | undefined

    return block ?? null
  }, [searchFlowStore.flowSequence.length, isSearching])

  const fetchHighlights = async (summaryBlock: SearchSummaryBlockStore) => {
    const rawAnswer = summaryBlock.searchSummary.summary || ""
    if (rawAnswer) {
      await factFinderSolutionController.getQueryHighlights(
        rawAnswer,
        searchFlowStore.questionId
      )
    }
  }

  const updateSuggestedQuestions = (summaryBlock: SearchSummaryBlockStore) => {
    setSuggestedQuestion(summaryBlock.searchSummary.suggestedQuestion || [])
  }

  React.useEffect(() => {
    if (searchFlowStore.flowSequence?.length) return

    setSuggestedQuestion([])
  }, [searchFlowStore.flowSequence?.length])

  React.useEffect(() => {
    if (isSearching || summaryBlock == null) return

    updateSuggestedQuestions(summaryBlock)

    if (!access.showSearchCompanies && !access.showSearchProducts) return

    fetchHighlights(summaryBlock)
  }, [isSearching, summaryBlock])

  const companiesAvailable =
    !!highlights?.companies?.length && access.showSearchCompanies

  const productsAvailable =
    !!highlights?.products?.length && access.showSearchProducts

  const questionsAvailable = !!suggestedQuestions?.length

  const suggestionsAvailable = questionsAvailable

  const reportsAvailable =
    !!reportOptions.length &&
    suggestionsAvailable &&
    access.canSeeSearchWorkbookSuggestions

  return (
    <CollapsibleContextProvider singleActive>
      <div
        className={clsx(styles.root, {
          [styles.visible]:
            companiesAvailable ||
            productsAvailable ||
            questionsAvailable ||
            reportsAvailable,
          [styles.moveRight]: !isSmallScreen,
        })}
      >
        {questionsAvailable && (
          <InfoCard
            icon={<Icon name="question-mark" />}
            heading="Suggested Questions"
          >
            <div className={styles.lineItemWrapper}>
              {suggestedQuestions.map((question) => (
                <Question question={question} disabled={isSearching} />
              ))}
            </div>
          </InfoCard>
        )}

        {reportsAvailable && (
          <InfoCard icon={<Icon name="line-chart" />} heading="Sales Workflows">
            <div className={styles.lineItemWrapper}>
              {reportOptions.map((it, idx) => {
                const handleClick = () =>
                  searchFlowStore.generateReport(it.type)

                if (idx === 0) {
                  return (
                    <PrimaryReportSuggestionCard
                      data={it}
                      onClick={handleClick}
                      disabled={isSearching}
                      key={it.type}
                    />
                  )
                }

                return (
                  <ReportSuggestionCard
                    data={it}
                    onClick={handleClick}
                    disabled={isSearching}
                    key={it.type}
                  />
                )
              })}
            </div>
          </InfoCard>
        )}

        {productsAvailable && (
          <InfoCard icon={<Icon name="parcel" />} heading="Products">
            <div className={styles.lineItemWrapper}>
              {loadingHighlights && (
                <Skeleton
                  lineHeight={30}
                  minWidth={100}
                  rounded
                  className={styles.skeletonLoader}
                />
              )}
              {highlights?.products?.map((product) => (
                <LineItem
                  key={product.id}
                  groupId={product.id}
                  name={product.name}
                  product={product}
                  options={getProductOptions(product)}
                />
              ))}
            </div>
          </InfoCard>
        )}

        {companiesAvailable && (
          <InfoCard icon={<Icon name="factory" />} heading="Accounts">
            <div className={styles.lineItemWrapper}>
              {loadingHighlights && (
                <Skeleton
                  lineHeight={30}
                  minWidth={100}
                  rounded
                  className={styles.skeletonLoader}
                />
              )}
              {highlights?.companies?.map((company) => (
                <LineItem
                  key={company.id}
                  name={company.name}
                  groupId={company.id}
                  options={getCompanyOptions(company)}
                  info={company.url}
                />
              ))}
            </div>
          </InfoCard>
        )}
      </div>
    </CollapsibleContextProvider>
  )
})

export default SidePanel
