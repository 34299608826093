import React from "react"
import { nanoid } from "nanoid"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Text from "@components/ui/Typography/Text"
import { ConnectorDocument } from "@framework/types/content-manager"
import { IconName } from "@components/ui/Icon/IconName"

import styles from "./index.module.sass"

interface ContentCardProps {
  content: ConnectorDocument
  lastItemRef: React.RefObject<HTMLDivElement> | null
  handleContentClick: (content: ConnectorDocument) => void
}

const ContentCard: React.FC<ContentCardProps> = ({
  content,
  lastItemRef,
  handleContentClick,
}) => {
  const fullText =
    content.docInfo?.title ||
    content.docInfo?.Name ||
    content.docInfo?.displayName
  const uniqueId = nanoid()
  return (
    <div
      key={content.id + uniqueId}
      className={styles.contentCard}
      ref={lastItemRef}
    >
      <div className={styles.contentHeader}>
        <div>
          <Icon name={content?.contentType?.iconUrl as IconName} />
          <span title={fullText} className={styles.contentTitleText}>
            {fullText} {content.isTrialDoc ? " (Public)" : ""}
          </span>
        </div>
        {content.source.toString() !== "manual" && (
          <IconButton
            rounded
            color="primary"
            onClick={() => {
              handleContentClick(content)
            }}
            className={styles.contentButton}
          >
            <Icon name="arrow-up" rotateAngle={90} />
          </IconButton>
        )}
      </div>
      <Text variant="caption1">Content ID: {content.id}</Text>
    </div>
  )
}

export default ContentCard
