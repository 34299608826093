import * as React from "react"

import ListItem from "../ListItem/ListItem"
import Icon from "../Icon/Icon"
import { useDropdownContext } from "./DropdownContext"
import Text from "../Typography/Text"

export const DropdownItem: React.FC<{
  index: number
  children: React.ReactNode
  selected: boolean
}> = ({ index, selected, children }) => {
  const context = useDropdownContext()

  const { interactions, listRef } = context

  const handleSelect = (index: number) => {
    context.onSelectByIndex(index)

    if (context.closeOnSelect) {
      context.onOpenChange(false)
    }
  }

  const isActive = context.activeIndex === index

  return (
    <ListItem
      ref={(node) => {
        listRef.current[index] = node
      }}
      role="option"
      tabIndex={isActive ? 0 : -1}
      aria-selected={selected && isActive}
      active={selected}
      focused={isActive}
      {...interactions.getItemProps({
        onMouseDown(e) {
          handleSelect(index)
          e.preventDefault()
          e.stopPropagation()
        },
        onKeyDown(e) {
          if (e.key === "Enter") {
            handleSelect(index)
            e.preventDefault()
          }
        },
      })}
    >
      <Text variant="inherit">{children}</Text>
      {selected ? <Icon color="primary" name="done" /> : null}
    </ListItem>
  )
}

export default DropdownItem
