import { makeAutoObservable } from "mobx"
import cloneDeep from "lodash/cloneDeep"

import {
  Identifier,
  IdentifierDetails,
  IdentifierFilter,
  IdentifierPaginationMetaData,
} from "@framework/types/identifier"
import { ConnectorDocument } from "@framework/types/content-manager"
import { SortBy } from "@framework/types/common"

const defaultFilter = {
  manufacturers: [],
  includes: true,
}

const defaultOrder = { name: "name", direction: true }

const defaultSearchQuery = ""

class IdentifierStore {
  loadingCreate = false

  loadingList = false

  loadingUpdate: boolean = false

  loadingDelete: boolean = false

  loading = false

  identifiers: Identifier[] = []

  error: string | null = null

  orderBy: SortBy | null = cloneDeep(defaultOrder)

  activeFilter: IdentifierFilter = cloneDeep(defaultFilter)

  searchQuery: string = defaultSearchQuery

  reloadTrigger: number = Date.now()

  identifiersMetaData: IdentifierPaginationMetaData = {
    pageNum: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  }

  contentPaginationMeta: IdentifierPaginationMetaData | null = null

  identifierContents: ConnectorDocument[] = []

  isContentsLoading = false

  errorList: string = ""

  activePage: number = 1

  identifierDetails: IdentifierDetails | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.activeFilter = cloneDeep(defaultFilter)
    this.orderBy = cloneDeep(defaultOrder)
    this.searchQuery = defaultSearchQuery
  }
}

export default IdentifierStore
