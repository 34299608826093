import React, { useMemo } from "react"
import groupBy from "lodash/groupBy"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import { CategoryChild, ProductDetails } from "@framework/types/product"
import Chip from "@components/ui/Chip/Chip"

import styles from "./index.module.sass"

type OverviewProps = {
  productDetails: ProductDetails | null
}

type CategoryPath = { parent: string; child: string }

function formatCategories(categories: CategoryChild[]): CategoryPath[] {
  const formattedCategories: CategoryPath[] = []

  function traverse(category: CategoryChild, path: string, index: number) {
    const currentPath = path ? `${path} / ${category.name}` : category.name

    if (!category.child) {
      formattedCategories[index].child = currentPath
    }

    if (category.child) {
      traverse(category.child, currentPath, index)
    }
  }

  categories.forEach((category, index) => {
    formattedCategories.push({ parent: category.name, child: "" })
    if (category?.child) traverse(category.child, "", index)
  })
  return formattedCategories
}

const Overview: React.FC<OverviewProps> = ({ productDetails }) => {
  const formattedCategories = useMemo(() => {
    return productDetails?.categories
      ? formatCategories(productDetails.categories)
      : []
  }, [productDetails?.categories])

  const mainCategoriesWithoutChild = formattedCategories.filter((c) => !c.child)
  const mainCategoriesWithChild = groupBy(
    formattedCategories.filter((c) => !!c.child),
    "parent"
  )

  return (
    <>
      <Text variant="h4" className={styles.header}>
        Description
      </Text>
      <Text variant="body2" color="text50Color">
        {productDetails?.description || "N/A"}
      </Text>
      <Text variant="h4" className={styles.header}>
        Product Notes
      </Text>
      <Text variant="body2" color="text50Color">
        {productDetails?.notes || "N/A"}
      </Text>
      {!!formattedCategories.length && (
        <>
          <Text variant="h4" className={styles.header}>
            Identifiers
          </Text>
          <div className={clsx(styles.identifiers, styles.card)}>
            {mainCategoriesWithoutChild.map((category) => (
              <Chip
                key={category.parent}
                variant="rounded"
                color="secondary-solid"
              >
                {category.parent}
              </Chip>
            ))}

            <div className={styles.categoriesWithChild}>
              {Object.entries(mainCategoriesWithChild).map(([key, chs]) => (
                <div className={styles.childParentWrapper} key={key}>
                  <Text color="text70Color" variant="caption2">
                    {key}
                  </Text>
                  {chs.map((item) => (
                    <Chip
                      key={item.child || item.parent}
                      variant="rounded"
                      color="secondary-solid"
                    >
                      {item.child || item.parent}
                    </Chip>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Overview
