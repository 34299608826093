import React from "react"
import { observer } from "mobx-react-lite"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import clsx from "clsx"

import { SearchEntityContextProvider } from "@pages/search/SearchContext/SearchResultContext"
import AppControlContainer from "@pages/launch-solutions/SolutionTabsContainer/AppControlContainer"
import SearchAnswerSection, {
  WorkbookReportSection,
} from "@pages/launch-solutions/SolutionTabsContainer/AnswerSection"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"
import SidebarPopup from "@components/modals/components/SidebarPopup"
import useResizeObserver from "@components/hooks/useResizeObserver"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { useSolution } from "@pages/launch-solutions/ActiveSolutionContext"
import SearchEntityStore from "@store/search/search-entity.store"
import WorkbookEntityStore from "@store/search/workbook-entity.store"
import { WorkbookEntityContextProvider } from "@pages/search/SearchContext/WorkbookResultContext"

import SearchWidget from "./components/SearchWidget/SearchWidget"
import FactFinderSearchPassagesSidebar from "./components/SourcePassagesSidebar"
import SidePanel from "./components/SidePanel/SidePanel"
import RecentActivity from "./components/RecentActivity/RecentActivity"
import ReportViewCard from "../UnifiedMatrix/ReportViewCard"

import styles from "./index.module.sass"

interface Props {
  data: SolutionData
}

const FactFinderPage: React.FC<Props> = observer(({ data }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const isMobile = useMediaQuery(AppMediaQueries.maxTablet)
  const isSmallScreen = useMediaQuery(AppMediaQueries.wideDesktop)

  const { solution } = useSolution()

  const { solutionsController, analyticsController } = useController()
  const {
    factFinderSolutionStore: { searchFlowStore },
    solutionsStore: {
      appliedFilters,
      isRecentActivityShown,
      showRecentActivity,
    },
    restrictionsStore: access,
    usageAnalyticsStore: { queryHistoryStore },
  } = useStore()

  const closeModal = () => navigate(location.state?.backTo ?? mainRoutes.home())

  const isSearching = searchFlowStore.isAnyLoading()

  const hideRecentActivity = () => {
    analyticsController.abortQueryHistory()
    queryHistoryStore.resetDate()
    showRecentActivity(false)
  }

  React.useEffect(() => {
    if (solutionsController && access.isSearchFilterAvailable) {
      solutionsController.loadFilters()
    }
  }, [solutionsController])

  React.useEffect(() => {
    searchFlowStore?.reset()
  }, [appliedFilters])

  React.useEffect(() => {
    solutionsController.applyFilters(solution?.filters ?? [])
  }, [solution])

  const autofocusLatest = React.useRef(true)
  const scrollableRef = React.useRef<HTMLDivElement | null>(null)
  const contentRef = React.useRef<HTMLDivElement | null>(null)
  const contentRect = useResizeObserver(contentRef)

  React.useEffect(() => {
    if (
      !autofocusLatest.current ||
      scrollableRef.current == null ||
      contentRect == null ||
      !isSearching
    )
      return

    scrollableRef.current.scrollTo({
      top: scrollableRef.current.scrollHeight - 100,
      behavior: "smooth",
    })
  }, [contentRect?.height])

  React.useEffect(() => {
    if (!isSearching && autofocusLatest.current) {
      scrollableRef.current?.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: "smooth",
      })
    }

    autofocusLatest.current = true
  }, [isSearching])

  React.useEffect(() => {
    return hideRecentActivity
  }, [])

  const isAnySearchStarted = !!searchFlowStore.flowSequence.length
  const isLeftAligned = isAnySearchStarted && isSmallScreen

  return (
    <div
      className={clsx(styles.root, {
        [styles.leftAligned]: isLeftAligned,
      })}
    >
      <div className={styles.body}>
        <div
          className={styles.container}
          onWheel={(e) => {
            if (e.deltaY < 0) {
              autofocusLatest.current = false
              return
            }

            const node = e.currentTarget

            const actualBottom = Math.ceil(node.scrollTop + node.clientHeight)
            const bottom = node.scrollHeight

            autofocusLatest.current = Math.abs(actualBottom - bottom) < 10
          }}
          ref={scrollableRef}
        >
          <div className={styles.list} ref={contentRef}>
            {searchFlowStore.flowSequence.map((searchId) => {
              const entity = searchFlowStore.getById(searchId)

              if (entity instanceof SearchEntityStore)
                return (
                  <SearchEntityContextProvider
                    searchEntity={entity}
                    key={searchId}
                  >
                    <SearchAnswerSection />
                  </SearchEntityContextProvider>
                )

              if (entity instanceof WorkbookEntityStore)
                return (
                  <WorkbookEntityContextProvider
                    reportEntity={entity}
                    key={searchId}
                  >
                    <WorkbookReportSection />
                  </WorkbookEntityContextProvider>
                )

              return null
            })}
          </div>

          <AppControlContainer
            title={data.name}
            description={data.description}
            moveDown={isAnySearchStarted}
            disableAnimation={!isAnySearchStarted || isLeftAligned}
          >
            <SearchWidget disabled={isSearching} />
          </AppControlContainer>

          {isRecentActivityShown && (
            <SidebarPopup
              open={isRecentActivityShown}
              onClose={hideRecentActivity}
            >
              <RecentActivity onClose={hideRecentActivity} />
            </SidebarPopup>
          )}

          <Routes>
            <Route
              path=":searchId/:blockId/:answerIndex"
              element={<FactFinderSearchPassagesSidebar onBack={closeModal} />}
            />
          </Routes>
        </div>
      </div>

      {!isMobile && isAnySearchStarted && (
        <div className={clsx(styles.sidePanel)}>
          {searchFlowStore.selectedReportId != null ? (
            <ReportViewCard
              reportId={searchFlowStore.selectedReportId}
              onClose={() => searchFlowStore.showReport(null)}
            />
          ) : (
            <SidePanel isSearching={isSearching} />
          )}
        </div>
      )}
    </div>
  )
})

export default FactFinderPage
