import React from "react"
import { useAlert } from "react-alert"

import DocumentDropZone from "@components/ui/DocumentDropZone/DocumentDropZone"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { validExtensions } from "@framework/constants/upload"
import { convertBytesToUnit } from "@utils/file"

import styles from "./ContentManagerUploadFormModal.module.sass"

const MAX_FILE_SIZE_MB = 100

const acceptedExtension = validExtensions.map((x) => `.${x}`)

const isFileSizeValid = (file: File) => {
  return convertBytesToUnit(file.size, "MB") <= MAX_FILE_SIZE_MB
}

type DocumentDropZoneContainerProps = {
  onFilesDrop: (files: File[]) => void
  disabled?: boolean
}

const DocumentDropZoneContainer: React.FC<DocumentDropZoneContainerProps> = ({
  onFilesDrop,
  disabled,
}) => {
  const alert = useAlert()

  const handleFilesDrop = (files: File[]) => {
    const validFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || ""
      if (!isFileSizeValid(file)) {
        alert.error(
          `File ${file.name} exceeds the ${MAX_FILE_SIZE_MB} MB size limit.`
        )
        return false
      }
      if (!validExtensions.includes(fileExtension)) {
        alert.error(
          `File ${
            file.name
          } has an invalid extension. Allowed extensions are: ${validExtensions.join(
            ", "
          )}.`
        )
        return false
      }
      return true
    })

    onFilesDrop(validFiles)
  }

  return (
    <div className={styles.inputContainer}>
      <Text variant="body1">Upload File</Text>
      <DocumentDropZone
        multiple
        disabled={disabled}
        extensions={acceptedExtension}
        alternativeTitle={
          <div className={styles.dropZoneHeader}>
            <Icon name="image-add" rotateAngle={-35} /> Drag and Drop your files
            here
            <Icon name="file-chart" rotateAngle={35} />
          </div>
        }
        className={styles.documentDropZone}
        description={
          <Text variant="body2" align="center">
            File should be {validExtensions.join(", ")}. File size cannot exceed
            100 MB. (Max 10 files)
          </Text>
        }
        onDrop={handleFilesDrop}
      />
    </div>
  )
}

export default DocumentDropZoneContainer
