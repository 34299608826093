import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { CompanyWithMetaInfo } from "@framework/types/company"
import Button from "@components/ui/Button/Button"

import ManufacturerContextMenu from "./ManufacturerContextMenu"

import styles from "./ManufacturerTable.module.sass"

interface ManufacturerRowProps {
  item: CompanyWithMetaInfo
  canConnectPublicData: boolean
  openLinkPublicData: (item: CompanyWithMetaInfo) => void
}

export const ManufacturerRow: React.FC<ManufacturerRowProps> = observer(
  ({ item, canConnectPublicData, openLinkPublicData }) => {
    return (
      <Row className={clsx(styles.row)}>
        <Column key="name" align="center">
          <div className={styles.company}>
            <Icon name="factory" color="primary" className={styles.icon} />
            <div>
              <Text variant="body1" color="text100Color">
                {item.name}
              </Text>
              <Text variant="caption2" color="text50Color">
                {item.url}
              </Text>
            </div>
          </div>
        </Column>

        <Column key="actions" align="center">
          <Text align="center" variant="body1" color="text100Color">
            {item.productsCount}
          </Text>
        </Column>
        <Column key="actions" align="center">
          <Text align="center" variant="body1" color="text100Color">
            {item.contentCount}
          </Text>
        </Column>
        <Column key="actions" align="center">
          {item.linkedPublicCompanies?.length
            ? item.linkedPublicCompanies.map((item) => (
                <Text variant="body2">{item?.name}</Text>
              ))
            : canConnectPublicData && (
                <Button
                  size="tiny"
                  before={<Icon name="plus" />}
                  color="default"
                  variant="outlined"
                  onClick={() => openLinkPublicData(item)}
                >
                  Link to Public Data
                </Button>
              )}
        </Column>
        <Column key="actions" align="center">
          <ManufacturerContextMenu company={item} />
        </Column>
      </Row>
    )
  }
)

export default ManufacturerRow
