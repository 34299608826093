import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import { DataCategoryEntity } from "@framework/types/knowledge"

export class DataTypesStore {
  constructor() {
    makeAutoObservable(this)
  }

  data: DataCategoryEntity[] | null = null

  error: string | null = null

  isLoading: boolean = false

  reloadTrigger = nanoid()

  getById = (id: string) => {
    if (this.data == null) return null
    return this.data.find((it) => it.id === id) ?? null
  }

  storeData = (data: DataCategoryEntity[]) => {
    this.data = data
  }

  triggerReload = () => {
    this.reloadTrigger = nanoid()
  }
}

export default DataTypesStore
