import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { ReportDescriptor } from "@framework/types/workbook-report"

import styles from "./ReportSuggestionCard.module.sass"

export interface ReportSuggestionCardProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  data: ReportDescriptor
  disabled?: boolean
}

const ReportSuggestionCard: React.FC<ReportSuggestionCardProps> = observer(
  ({ data, disabled = false, className, ...rest }) => {
    return (
      <button
        type="button"
        className={clsx(styles.root, className)}
        disabled={disabled}
        {...rest}
      >
        <Icon name={data.icon} className={styles.titleIcon} />
        <Text
          color="inherit"
          variant="body3"
          align="start"
          className={styles.body}
        >
          {data.message}
        </Text>
        <Icon name="arrow-up" rotateAngle={90} className={styles.runIcon} />
      </button>
    )
  }
)

export default ReportSuggestionCard
