import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { DataSourceNodeName } from "@framework/types/upload"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { renderShortNumber } from "@utils/numberUtils"
import Avatar from "@components/ui/Avatar/Avatar"
import { KibanaConnector } from "@framework/types/content-manager"
import { DocumentIconType } from "@framework/types/utils"
import { IconName } from "@components/ui/Icon/IconName"
import { capitalizeFirstLetter } from "@utils/textUtils"
import { formatBytes } from "@utils/file"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components/ui/Tooltip/v2/Tooltip"
import { Company } from "@framework/types/company"

import ContextMenu from "./ContextMenu"

import styles from "./DataConnectorAccountItem.module.sass"

export interface DataConnectorAccountItemProps {
  data: KibanaConnector
  icon?: DocumentIconType | IconName
  isWebsite: boolean
}

const DataConnectorAccountItem: React.FC<DataConnectorAccountItemProps> =
  observer(({ data, icon, isWebsite }) => {
    const {
      type,
      name,
      domain,
      siteCollections,
      companies,
      lastSyncDate,
      status,
      statusObj,
      connectedBy,
      contentCount,
      error,
      childUrls,
      sizeInBytes,
      isPublic,
    } = data
    const connectorContext = getDataSourceNodeContextProps(
      type as DataSourceNodeName
    )
    const totalFiles = contentCount ?? 0

    const statusColor = useMemo(() => {
      const lowerStatus = status.toLowerCase()
      if (lowerStatus === "success") return "green"
      if (lowerStatus === "error" || lowerStatus === "failed") return "red"
      return "primary"
    }, [status])

    const renderStatusChip = () => (
      <Chip variant="rounded" color={statusColor}>
        {capitalizeFirstLetter(status)}
      </Chip>
    )

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <span>
            <DocumentIcon icon={icon || connectorContext.icon} size="medium" />
            <Text variant="h3">
              {name} {isPublic && "(Public)"}
            </Text>
          </span>
          <ContextMenu isWebsite={isWebsite} connector={data} />
        </div>
        {domain && (
          <div className={styles.info}>
            <span>Domain:</span>
            <Text variant="body2">{domain}</Text>
          </div>
        )}
        {!!childUrls?.length && (
          <div className={styles.info}>
            <span>Child URLs:</span>
            <Text variant="body2">{childUrls.join(", ")}</Text>
          </div>
        )}
        {siteCollections && (
          <div className={styles.info}>
            <span>Site Collections:</span>
            <Text variant="body2">{siteCollections}</Text>
          </div>
        )}
        <div className={styles.info}>
          <span>Manufacturers:</span>
          <Text variant="body2">{renderCompanies(companies)}</Text>
        </div>
        <div className={styles.info}>
          <span>Size:</span>
          <Text variant="body2">{formatBytes(sizeInBytes || 0, 2)}</Text>
        </div>
        <div className={styles.info}>
          <span>Last Sync Date:</span>
          <Text variant="body2">{moment(lastSyncDate).fromNow()}</Text>
        </div>
        <div>
          <div className={styles.info}>
            <span>Status:</span>
            {statusObj ? (
              <Tooltip color="primary" placement="right">
                <TooltipContent>
                  <div>
                    {Object.entries(statusObj).map(([key, value]) => (
                      <div key={key}>
                        <strong>{capitalizeFirstLetter(key)}:</strong> {value}
                      </div>
                    ))}
                  </div>
                </TooltipContent>
                <TooltipTrigger>{renderStatusChip()}</TooltipTrigger>
              </Tooltip>
            ) : (
              renderStatusChip()
            )}
          </div>
          {!!error && (
            <Text
              className={styles.errorMessage}
              color="goldColor"
              variant="body2"
            >
              {error}
            </Text>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.info}>
            <span>Connected By:</span>
            <div className={styles.userAvatar}>
              <Avatar height={24} width={24} name={connectedBy?.name || ""} />
              {connectedBy?.name || ""}
            </div>
          </div>
          <Chip
            title={`Content: ${totalFiles} Files`}
            color="secondary"
            variant="rounded"
          >
            {isWebsite ? "Pages" : "Content"}: {renderShortNumber(totalFiles)}
          </Chip>
        </div>
      </div>
    )
  })

export default React.memo(DataConnectorAccountItem)

const renderCompanies = (companies: Company[] = []) => {
  return (
    companies
      .reduce<string[]>((acc, it) => {
        if (it?.name) acc.push(it.name)
        return acc
      }, [])
      .join(", ") || "N/A"
  )
}
