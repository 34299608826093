import React from "react"
import uniq from "lodash/uniq"

import Markdown from "@components/ui/Typography/Markdown"

import MarkdownNavLink from "./MarkdownNavLink"

const citationRegex = /(\[\d+\])/g

const citationBracketsRegex = /[\\[\]\s]/g

interface MarkdownWithCitationsProps {
  children?: string
  hideCitations?: boolean
  citationLink?: (value: string) => string
}

const MarkdownWithCitations: React.FC<MarkdownWithCitationsProps> = ({
  children = "",
  hideCitations = false,
  citationLink,
}) => {
  const matches = citationLink
    ? uniq(children.match(citationRegex)).map((it) =>
        it.replace(citationBracketsRegex, "")
      )
    : []

  const updatedContent = matches.reduce((text, citationIndex) => {
    const citation = `[${citationIndex}]`
    const link = citationLink?.(citationIndex) ?? `/${citationIndex}`
    return text.replaceAll(
      citation,
      hideCitations ? "" : `[${citationIndex}](${link})`
    )
  }, children)

  const elementKey = `${updatedContent.slice(-30)}_${updatedContent.length}`

  return (
    <Markdown key={elementKey} customElements={customElements}>
      {updatedContent}
    </Markdown>
  )
}

export default MarkdownWithCitations

const customElements = (props: any) => {
  const { attributes, children, element }: any = props
  if (element.type === "link") {
    return (
      <MarkdownNavLink
        {...attributes}
        title={element.title}
        href={element.url}
        text={element.children?.[0]?.text}
      >
        {children}
      </MarkdownNavLink>
    )
  }

  return null
}
