import React from "react"
import { Descendant } from "slate"
import remarkParse from "remark-parse"
import { unified } from "unified"
import remarkMath from "remark-math"
import remarkGfm from "remark-gfm"
import rehypeKatex from "rehype-katex"
import rehypeReact from "rehype-react"
import remarkRehype from "remark-rehype"
import { remarkToSlate, slateToRemark } from "remark-slate-transformer"
import remarkStringify from "remark-stringify"

import { FailResponse, SuccessResponse } from "@framework/types/common"

import { HEADING_LEVEL_MAP } from "./constants"

export const markdownToSlate = (markdown: string) => {
  if (!markdown)
    return [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ]

  const processor = unified()
    .use(remarkParse)
    .use(remarkMath)
    .use(remarkGfm)
    .use(remarkToSlate, {
      overrides: {
        table: (node, next) => {
          return {
            ...node,
            children: [
              {
                type: "tableBody",
                children: node.children?.length
                  ? next(node.children)
                  : [{ text: "" }],
              },
            ],
          }
        },
        math: (node) => ({
          ...node,
          type: "text",
          math: true,
          text: `$$\n${node.value}\n$$`,
        }),
        inlineMath: (node) => ({
          ...node,
          type: "text",
          math: true,
          text: `$${node.value}$`,
        }),
        heading: (node, next) => ({
          ...node,
          type: HEADING_LEVEL_MAP[(node.depth || 0) - 1] || "paragraph",
          children: node.children?.length
            ? next(node.children)
            : [{ text: "" }],
        }),
        list: (node, next) => ({
          ...node,
          type: node.ordered ? "numbered-list" : "bulleted-list",
          children: node.children?.length
            ? next(node.children)
            : [{ text: "" }],
        }),
        html: (node) => {
          return {
            ...node,
            children: [{ type: "paragraph", text: node.value, html: true }],
          }
        },
        listItem: (node: any, next) => ({
          ...node,
          type: "list-item",
          children: node.children?.length
            ? next(node.children)
            : [{ type: "paragraph", children: [{ text: "" }] }],
        }),
        blockquote: (node, next) => {
          return {
            ...node,
            children: node.children?.length
              ? next(node.children)
              : [{ text: "" }],
          }
        },
        tableCell: (node, next) => {
          return {
            ...node,
            children: node.children?.length
              ? next(node.children)
              : [{ text: "" }],
          }
        },
      },
    })

  const res = processor.processSync(markdown)

  return res.result
}

export const renderMath = (markdown: string): React.ReactNode => {
  const processor = unified()
    .use(remarkParse)
    .use(remarkMath)
    .use(remarkRehype)
    .use(rehypeKatex, { output: "mathml" })
    .use(rehypeReact, {
      components: {
        p: "span",
      },
      createElement: React.createElement,
      Fragment: React.Fragment,
    })

  const res = processor.processSync(markdown)

  return res.result
}

export const slateToMarkdown = (
  value: Descendant[]
): FailResponse | SuccessResponse<string> => {
  try {
    const processor = unified()
      .use(slateToRemark, {
        overrides: {
          table: (node: any, next) => {
            const children = node.children.flatMap((it: any) => it.children)
            return {
              ...node,
              children: next(children),
            }
          },
          heading_one: (node: any, next) => ({
            ...node,
            type: "heading",
            depth: 1,
            children: next(node.children),
          }),
          heading_two: (node: any, next) => ({
            ...node,
            type: "heading",
            depth: 2,
            children: next(node.children),
          }),
          heading_three: (node: any, next) => ({
            ...node,
            type: "heading",
            depth: 3,
            children: next(node.children),
          }),
          "numbered-list": (node: any, next) => ({
            ...node,
            type: "list",
            ordered: true,
            spread: false,
            children: next(node.children),
          }),
          "bulleted-list": (node: any, next) => ({
            ...node,
            type: "list",
            ordered: false,
            spread: false,
            children: next(node.children),
          }),
          "list-item": (node: any, next) => ({
            ...node,
            type: "listItem",
            children: next(node.children),
          }),
        },
      })
      .use(remarkGfm)
      .use(remarkMath)
      .use(remarkStringify)

    const ast = processor.runSync({ type: "root", children: value })

    const text = processor.stringify(ast)

    return {
      data: text,
      status: "SUCCESS",
    }
  } catch (error: any) {
    return {
      message: error.message || "Parsing failed",
      status: "FAILED",
    }
  }
}
