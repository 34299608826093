import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import MenuItem from "@components/containers/Sidebar/MenuItem"

interface ContextMenuProps {
  onEdit: () => void
  onDelete: () => void
}

const ContextMenu: React.FC<ContextMenuProps> = observer(
  ({ onEdit, onDelete }) => {
    const [isOpened, setIsOpened] = React.useState(false)

    const handleToggle = () => setIsOpened((prev) => !prev)

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setIsOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            <MenuItem icon="edit" onClick={onEdit} key="edit">
              Edit
            </MenuItem>
            <MenuItem
              icon="trash-can"
              color="red"
              onClick={onDelete}
              key="delete"
            >
              Delete
            </MenuItem>
          </TooltipContainer>
        }
      >
        <IconButton size="medium" active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
