import {
  ExtendedResultType,
  SolutionTypes,
} from "@framework/constants/search-results"
import { DataSourceNodeName } from "@framework/types/upload"

export interface SimpleRouteType {
  (): string
}

type ID = string | number

export interface RouteWithIdType<T extends ID = ID> {
  (id: T): string
}

export interface ExtendedResultRoute {
  (id: string, tab: ExtendedResultType): string
}

const home: SimpleRouteType = () => `/home`

const solutionView: RouteWithIdType<string> = (solutionId) =>
  `/solutions/${solutionId}`

const solutionEdit = (solutionId: string) => `/solutions/${solutionId}/edit`

const solutionsFlowExtendedPassage = (
  solutionType: SolutionTypes,
  searchId: string,
  blockId: string,
  passageId: string,
  defaultTab?: ExtendedResultType
) =>
  `/solutions/${solutionType}/source/${searchId}/${blockId}/${passageId}/${defaultTab}`

const workbookReport: RouteWithIdType = (id) => `/workbook-report/${id}`

const profile: SimpleRouteType = () => "/profile"

const signUp: SimpleRouteType = () => "/sign-up"

const login: SimpleRouteType = () => "/login"

const loginConfirm: SimpleRouteType = () => "/confirm/login"

const signUpConfirm: SimpleRouteType = () => "/confirm/sign-up"

const changeEmail: SimpleRouteType = () => "/email-change"

const upload = (path: (DataSourceNodeName | string)[] = []): string => {
  const suffix = path.length ? `/${path.join("/")}` : ""
  return `/upload${suffix}`
}

const library: SimpleRouteType = () => "/library"

const contentManager: SimpleRouteType = () => "/content-manager"

const contentTypeManager: SimpleRouteType = () => "/content-type"

const manufacturer: SimpleRouteType = () => "/manufacturer"

const uploadDataSource: RouteWithIdType<string> = (path) =>
  `/upload/${path}/add`

const updates: SimpleRouteType = () => "/updates"

const questions: SimpleRouteType = () => "/questions"

const question = (questionId?: string) =>
  `/questions${questionId ? `/${questionId}` : ""}`

const analytics: SimpleRouteType = () => `/analytics`

const usageAnalytics: SimpleRouteType = () => `/analytics/usage`

const apiUsageAnalytics: SimpleRouteType = () => `/analytics/api-usage`

const experts: SimpleRouteType = () => "/expert"

const expertsMenu: SimpleRouteType = () => "/expert-menu"

const expertInsights: SimpleRouteType = () => "/expert-insights"

const adminPanel: SimpleRouteType = () => `/admin`

const adminPanelTab: RouteWithIdType = (tab) => `/admin/${tab}`

const products: SimpleRouteType = () => `/products/list`

const productView: RouteWithIdType<string> = (productId: string) =>
  `/products/view/${productId}`

const productEdit: RouteWithIdType<string> = (productId: string) =>
  `/products/edit/${productId}`

const productsIdentifiers: SimpleRouteType = () => `/product-identifiers/list`

const productsIdentifierView: RouteWithIdType<string> = (identifierId) =>
  `/product-identifiers/view/${identifierId}`

const productsIdentifierEdit: RouteWithIdType<string> = (identifierId) =>
  `/product-identifiers/edit/${identifierId}`

export default {
  home,
  solutionView,
  solutionEdit,
  workbookReport,
  profile,
  signUp,
  login,
  loginConfirm,
  signUpConfirm,
  changeEmail,
  upload,
  uploadDataSource,
  updates,
  question,
  questions,
  analytics,
  usageAnalytics,
  apiUsageAnalytics,
  experts,
  expertsMenu,
  expertInsights,
  adminPanel,
  adminPanelTab,
  solutionsFlowExtendedPassage,
  contentManager,
  contentTypeManager,
  manufacturer,
  library,
  products,
  productView,
  productEdit,
  productsIdentifiers,
  productsIdentifierView,
  productsIdentifierEdit,
}
