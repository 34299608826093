import * as React from "react"

import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  offset,
  flip,
  size,
  autoUpdate,
  useFocus,
} from "@floating-ui/react"

import { DropdownContextState, DropdownContext } from "./DropdownContext"

interface DropdownRootProps {
  closeOnSelect: boolean
  loading?: boolean
  disabled?: boolean
  clearable?: boolean
  creatable?: boolean
  searchable?: boolean
  isMulti?: boolean
  children: React.ReactNode
  searchQuery?: string
  exactMatch?: number
  onSelectByIndex: (value: number) => void
  onAddOption?: (value: string) => Promise<void> | void
  onSearch?: (value: string) => void
}

export const DropdownRoot = ({
  closeOnSelect = true,
  loading = false,
  clearable = true,
  creatable = false,
  searchable = true,
  searchQuery = "",
  isMulti = false,
  disabled = false,
  children,
  exactMatch,
  onAddOption,
  onSelectByIndex,
  onSearch,
}: DropdownRootProps) => {
  const [open, setOpen] = React.useState(false)

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)

  const listRef = React.useRef<Array<HTMLElement | null>>([])

  const isDisabled = disabled

  const floating = useFloating({
    placement: "bottom-start",
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(6),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, 300)}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })

  const click = useClick(floating.context, {
    event: "mousedown",
    keyboardHandlers: false,
    toggle: !searchable,
    enabled: !isDisabled,
  })

  const focus = useFocus(floating.context, { enabled: !isDisabled })

  const dismiss = useDismiss(floating.context)

  const role = useRole(floating.context, { role: "listbox" })

  const listNav = useListNavigation(floating.context, {
    listRef,
    activeIndex,
    selectedIndex: exactMatch,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  })

  const interactions = useInteractions([dismiss, role, listNav, click, focus])

  const context: DropdownContextState = {
    open,
    onOpenChange: setOpen,
    activeIndex,
    onActiveIndexChange: setActiveIndex,
    searchQuery,
    setSearchQuery: onSearch,
    interactions,
    floating,
    listRef,
    closeOnSelect,
    loading,
    clearable,
    creatable,
    searchable,
    isMulti,
    disabled: isDisabled,
    onSelectByIndex,
    onAddOption,
  }

  return (
    <DropdownContext.Provider
      value={React.useMemo(() => context, Object.values(context))}
    >
      {children}
    </DropdownContext.Provider>
  )
}

export default DropdownRoot
