import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { useFormik } from "formik"
import * as yup from "yup"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store"
import Label from "@components/ui/Label/Label"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./ReportContentModal.module.sass"

export const validationSchema = yup.object({
  contentId: yup.string().required().label("Content ID").default("").trim(),
  reason: yup.string().required().label("Note").default("").trim(),
})

export type FormData = yup.InferType<typeof validationSchema>

export interface ReportContentModalProps {
  contentId: string
}

export const ReportContentModal: React.FC<ReportContentModalProps> = observer(
  ({ contentId }) => {
    const alert = useAlert()

    const modal = useModal(ModalsTypes.REPORT_CONTENT_MODAL)

    const { contentManagerStore } = useStore()
    const { contentManagerController } = useController()

    const handleSubmit = async (data: FormData) => {
      const res = await contentManagerController.reportContent(data)

      if (res.status === "FAILED") {
        alert.error("Failed to send report to an admin")
        return
      }

      alert.success("Content was successfully reported")
      modal.hideModal()
    }

    const formik = useFormik({
      initialValues: validationSchema.cast({ contentId }),
      validationSchema,
      validateOnBlur: false,
      onSubmit: handleSubmit,
    })

    return (
      <BaseModal
        title={<ModalTitle titleText="Report to Admin" />}
        onClose={modal.hideModal}
        className={styles.root}
        containerClassName={styles.container}
      >
        <form className={styles.body} onSubmit={formik.handleSubmit}>
          <Label id="reason" label="Reason for Reporting *">
            <TextAreaInput
              name="reason"
              placeholder="Write your notes..."
              onChange={formik.handleChange("reason")}
              onBlur={formik.handleBlur("reason")}
              withError={!!(formik.touched && formik.errors.reason)}
              rows={7}
            />
          </Label>

          <ModalFooterContainer>
            <Button
              size="big"
              variant="outlined"
              onClick={modal.hideModal}
              disabled={contentManagerStore.isContentReporting}
            >
              Cancel
            </Button>
            <Button
              size="big"
              color="primary"
              type="submit"
              after={contentManagerStore.isContentReporting && <Loader />}
              disabled={contentManagerStore.isContentReporting}
            >
              Send
            </Button>
          </ModalFooterContainer>
        </form>
      </BaseModal>
    )
  }
)

export default ReportContentModal
