import React from "react"
import { observer } from "mobx-react-lite"
import throttle from "lodash/throttle"

import { useController, useStore } from "@store/index"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import Templates from "@components/ui/Templates"
import BackButton from "@components/prototypes/BackButton"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import {
  getLastNDaysRange,
  renderDatePeriodTupleToRange,
  sortDatePeriodTuple,
} from "@utils/date"
import { defaultDateRangePresets } from "@framework/constants/common"
import { DAY_14_NUMBER, DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { DateRange } from "@framework/types/common"

import FeedbackTrendWidget from "./FeedbackTrendWidget"
import OverallReport from "./OverallReport"
import QueryHistoryWidget from "./QueryHistoryWidget"
import {
  DateRangeFilterContextProvider,
  useDateRangeFilterContext,
} from "../components/DateRangeFilterContext"
import ExportReportButton from "../components/ExportButtons/ExportReportButton"
import UserStickinessWidget from "./UserStickinessWidget"
import UserTrendWidget from "./UserTrendWidget"

import styles from "./index.module.sass"

const getDefaultDateRange = () => getLastNDaysRange(DAY_14_NUMBER)

export interface UsageAnalyticsPageProps {}

export const UsageAnalyticsPage: React.FC<UsageAnalyticsPageProps> = observer(
  () => {
    const [dateRange, setDateRange] =
      React.useState<[Date, Date]>(getDefaultDateRange)
    const isFirstRender = React.useRef(true)

    const { analyticsController } = useController()

    const {
      usageAnalyticsStore: { queryHistoryStore },
    } = useStore()

    const loadData = React.useCallback(
      throttle((startDate: string, endDate: string) => {
        analyticsController.loadUsageSummary(startDate, endDate)
        analyticsController.loadSearchingTrendData(startDate, endDate)
        analyticsController.loadUserTrendData(startDate, endDate)
      }, 1000),
      []
    )

    const period = React.useMemo(
      () => renderDatePeriodTupleToRange(dateRange, DD_MM_YYYY_FORMAT),
      [dateRange]
    )

    React.useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false
      } else {
        analyticsController.cancelPreviousRequest()
      }
      loadData(period.start, period.end)
    }, [loadData, period.start, period.end])

    React.useEffect(() => {
      return () => {
        queryHistoryStore.resetDate()
      }
    }, [])

    return (
      <MainLayout>
        <DateRangeFilterContextProvider
          value={dateRange}
          onChange={setDateRange}
          getDefaultDateRange={getDefaultDateRange}
        >
          <div className={styles.root}>
            <Templates.Header
              className={styles.header}
              left={<BackButton>Usage Analytics</BackButton>}
              right={<HeaderControl period={period} />}
            />

            <div className={styles.body}>
              <OverallReport />

              <div className={styles.row}>
                <UserTrendWidget />

                <UserStickinessWidget />
              </div>

              <FeedbackTrendWidget />

              <QueryHistoryWidget />
            </div>
          </div>
        </DateRangeFilterContextProvider>
      </MainLayout>
    )
  }
)

export default UsageAnalyticsPage

const HeaderControl: React.FC<{ period: DateRange }> = observer(
  ({ period }) => {
    const { initialDateRange, setDateRange } = useDateRangeFilterContext()

    const handleChangePeriod = (value?: Date | Date[]) => {
      if (!value || !Array.isArray(value) || value.length !== 2) return

      const normalized = sortDatePeriodTuple(value)

      setDateRange(normalized as [Date, Date])
    }

    return (
      <>
        <DatePicker
          defValue={initialDateRange}
          onChange={handleChangePeriod}
          presets={defaultDateRangePresets}
          maxDate={new Date()}
        />

        <ExportReportButton period={period} />
      </>
    )
  }
)
