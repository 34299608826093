import React from "react"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import Templates from "@components/ui/Templates"
import ProductsField from "@components/prototypes/ProductPicker/MultipleProductsField"

import { ReportRequest } from "./WorkbookReportTemplate"
import Footer from "./ReportTemplateFooter"

import styles from "./WorkbookReportTemplate.module.sass"

export interface ProductComparisonFormProps {
  onSubmit?: () => Promise<void | boolean> | void | boolean
  onClose?: () => void
}

const minProductsNum = 2

const validationSchema = yup.object({
  products: yup
    .array()
    .label("Products")
    .of(yup.string().required().default(""))
    .min(
      minProductsNum,
      `at least ${minProductsNum} products needed for comparison`
    )
    .required()
    .default([]),
})

type FormData = yup.InferType<typeof validationSchema>

export const renderQuery = (products: string[]) => {
  const productsText = products.length
    ? `${products.map((it) => `[${it}]`).join(" vs ")}`
    : "[Not provided]"

  return `Generate a comparison report for products ${productsText}`
}

const ProductComparisonForm: React.FC<ProductComparisonFormProps> = observer(
  ({ onSubmit, onClose }) => {
    const { productComparisonStore } = useStore()

    const { unifiedMatrixController, factFinderSolutionController } =
      useController()

    const [isLoading, setLoading] = React.useState(false)

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: async (data) => {
        try {
          setLoading(true)

          const products = data.products.map((id) => {
            const item = productComparisonStore.productStore.getProductById(id)
            if (item == null) throw new Error("Unable to load product")
            return item.name
          })

          const query = renderQuery(products)

          const store = factFinderSolutionController.pushReport({
            type: "PRODUCTCOMPARISON",
            query,
            data,
          })

          if (store == null) return

          const shouldClose = (await onSubmit?.()) ?? true

          const res =
            await unifiedMatrixController.generateProductComparisonReport(
              products
            )

          if (res.status === "SUCCESS") {
            store.setData(res.data)

            if (shouldClose) onClose?.()
            return
          }

          const error =
            "Unexpected error while report generation. Please try again later"

          store.setError(error)
        } finally {
          setLoading(false)
        }
      },
    })

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={clsx(styles.form)}>
          <Templates.RollScript
            gutter="24"
            footerSocket={<Footer isLoading={isLoading} onClose={onClose} />}
          >
            <div className={styles.inlineFlow}>
              <Text variant="body1" className={styles.messageText}>
                Generate a comparison report for products
              </Text>

              <div className={styles.input}>
                <ProductsField
                  name="products"
                  placeholder={`Select ${minProductsNum} and more products...`}
                  clearable={false}
                  disabled={isLoading}
                />
              </div>
            </div>
          </Templates.RollScript>
        </form>
      </FormikProvider>
    )
  }
)

export default ProductComparisonForm
