import { makeAutoObservable } from "mobx"

import EntitiesApi, { GetEntitiesRequest } from "@services/entities.service"
import RootStore from "@store/RootStore"

import EntitiesStore from "./entities.store"

export default class EntitiesController {
  entitiesStore: EntitiesStore

  constructor(injections: RootStore) {
    this.entitiesStore = injections.entitiesStore

    makeAutoObservable(this)
  }

  loadEntities = async (params: GetEntitiesRequest, reset: boolean = false) => {
    const store = this.entitiesStore

    if (store.isLoading) return

    if (reset) store.entities = []

    store.isLoading = true
    store.error = null

    try {
      const response = await EntitiesApi.getUserAndGroups(params)

      store.meta = response.data.pagination

      const uniqueUsersAndGroups = response.data.data.filter(
        (newUser) =>
          !store.entities.some((existingUser) => existingUser.id === newUser.id)
      )

      store.entities = [...store.entities, ...uniqueUsersAndGroups]
    } catch (error: any) {
      store.error = error.message
      store.entities = []
    } finally {
      store.isLoading = false
    }
  }
}
