import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import mainRoutes from "@root/main.routes"
import { AppMediaQueries } from "@framework/constants/app"
import useMediaQuery from "@components/hooks/useMediaQuery"
import Chip from "@components/ui/Chip/Chip"
import BackButton from "@components/prototypes/BackButton"

import UnifiedMatrix from "./SolutionTabsContainer/components/Tabs/UnifiedMatrix"
import { useSolution } from "./ActiveSolutionContext"

import styles from "./LaunchSolutions.module.sass"

const WorkbookSolution: React.FC = observer(() => {
  const { solution } = useSolution()

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    })
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!solution) return null
  return (
    <div className={clsx(styles.root)}>
      <Header />

      <div className={styles.body}>
        <UnifiedMatrix solution={solution} />
      </div>
      <Outlet />
    </div>
  )
})

const Header: React.FC = observer(() => {
  const navigate = useNavigate()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const { solution } = useSolution()

  const { restrictionsStore: access } = useStore()

  const handleEditWorkbookPreset = (solutionId: string) => {
    navigate(`${mainRoutes.solutionEdit(solutionId)}?session=reload`)
  }

  const buttonSize = isMobile ? "tiny" : isTablet ? "small" : "medium"

  return (
    <Templates.Header
      className={styles.header}
      left={
        <div className={styles.backButtonWrapper}>
          <BackButton to={mainRoutes.home()} size={buttonSize}>
            {solution?.name}
          </BackButton>
          {solution?.isBeta && (
            <Chip variant="rounded" color="green">
              Beta
            </Chip>
          )}
        </div>
      }
      right={
        <>
          {!!access.canUpdateSolution && (
            <Button
              size={buttonSize}
              color={isMobile ? "default" : "secondary"}
              variant={isMobile ? "text" : "contained"}
              onClick={() => handleEditWorkbookPreset(solution.id)}
              before={<Icon name="standards" />}
            >
              Edit Preset
            </Button>
          )}
        </>
      }
    />
  )
})

export default WorkbookSolution
