import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import Loader from "@components/ui/Loader/BarLoader"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import { ConnectorDocument } from "@framework/types/content-manager"
import { useController, useStore } from "@store"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { capitalizeEachFirstLetter, removeHyphens } from "@utils/textUtils"
import IconButton from "@components/ui/IconButton/IconButton"
import { DocumentTypeId } from "@framework/types/product"

import styles from "./LinkedContentTable.module.sass"

export type LinkedContentTableProps = {
  contentIds: {
    added: DocumentTypeId[]
    deleted: DocumentTypeId[]
  }
  setContentIds: Dispatch<{
    added: DocumentTypeId[]
    deleted: DocumentTypeId[]
  }>
}

const LinkedContentTable: React.FC<LinkedContentTableProps> = observer(
  ({ contentIds, setContentIds }) => {
    const [allContents, setAllContents] = useState<ConnectorDocument[]>([])

    const { id } = useParams()

    const {
      productComparisonStore: { productStore },
    } = useStore()
    const { contentManagerController, productController } = useController()
    const linkContentModal = useModal(ModalsTypes.LINK_CONTENT_MODAL)

    const { isContentsLoading, contentPaginationMeta, productContents } =
      productStore

    const hasMoreContent =
      (contentPaginationMeta?.page || 0) <
      (contentPaginationMeta?.totalPages || 0)

    const loadMoreContent = useCallback(() => {
      if (hasMoreContent && id) {
        productController.loadMoreProductContentIds(
          (contentPaginationMeta?.page || 1) + 1,
          100,
          id || ""
        )
      }
    }, [contentPaginationMeta, productController])

    const lastItemRef = useInfiniteScroll({
      callback: loadMoreContent,
      isLoading: isContentsLoading,
      hasMore: hasMoreContent,
    })

    useEffect(() => {
      contentManagerController.getCompanies()
    }, [])

    const handleLinkContentClick = () => {
      linkContentModal.showModal({
        selectedContentIds: allContents.map((content) => content.id),
        onSave: (docs: ConnectorDocument[]) => {
          const newIds: DocumentTypeId[] = docs.map((item) => ({
            id: item.id,
            isTrialDoc: item.isTrialDoc,
          }))
          const uniqueNewIds: DocumentTypeId[] = Array.from(
            new Map(
              [...contentIds.added, ...newIds].map((item) => [item.id, item])
            ).values()
          )
          setContentIds({
            added: uniqueNewIds,
            deleted: contentIds.deleted.filter(
              (id) => !uniqueNewIds.includes(id)
            ),
          })
          const newDocs = [
            ...docs,
            ...allContents.filter(
              (doc) => !docs.some((content) => content.id === doc.id)
            ),
          ]
          setAllContents(newDocs)
        },
      })
    }

    useEffect(() => {
      if (id) {
        productController.loadMoreProductContentIds(1, 1000, id || "")
      }
    }, [id])

    useEffect(() => {
      if (productContents.length) {
        setAllContents([
          ...allContents,
          ...productContents.filter(
            (doc) => !allContents.some((content) => content.id === doc.id)
          ),
        ])
      }
    }, [productContents])

    const getContentSource = (item: ConnectorDocument) => {
      const { source, isTrialDoc } = item
      const docIcon = getDataSourceNodeContextProps(source)
      return (
        <div className={styles.source}>
          {docIcon && <DocumentIcon icon={docIcon.icon} />}
          {capitalizeEachFirstLetter(removeHyphens(source, true))}
          {isTrialDoc && " (Public)"}
        </div>
      )
    }

    const handleRemoveContent = ({ id, isTrialDoc }: ConnectorDocument) => {
      setAllContents(allContents.filter((content) => content.id !== id))
      const deletedIds: DocumentTypeId[] = contentIds.deleted
      const index = contentIds.deleted.findIndex((item) => item.id === id)
      if (index === -1) {
        deletedIds.push({ id, isTrialDoc })
      }

      setContentIds({
        added: contentIds.added.filter(
          (item) => !deletedIds.some((deleted) => deleted.id === item.id)
        ),
        deleted: deletedIds,
      })
    }

    return (
      <div className={styles.linkedContents}>
        <Text variant="h2" weight="bold">
          Linked Content
        </Text>
        <div className={styles.scrollableTable}>
          <Table
            header={
              <Row>
                <TextColumn>Content ID</TextColumn>
                <TextColumn>Content Type</TextColumn>
                <TextColumn>Content Source</TextColumn>
                <TextColumn>Content Connector</TextColumn>
                <Column width={38}> </Column>
              </Row>
            }
          >
            {allContents.map((item, index) => (
              <>
                <Row key={item.id}>
                  <Column> {item.id}</Column>
                  <Column>
                    <div className={styles.contentType}>
                      {item.contentType?.iconUrl ? (
                        <Icon
                          color="primary"
                          name={item.contentType.iconUrl as IconName}
                        />
                      ) : null}
                      {item.contentType?.name || "N/A"}
                    </div>
                  </Column>
                  <Column>{getContentSource(item)}</Column>
                  <Column>
                    {" "}
                    <div className={styles.connectorName}>
                      <Icon name="storage" />
                      {item.connectorName}
                    </div>{" "}
                  </Column>
                  <Column className={styles.actionColumn}>
                    <IconButton onClick={() => handleRemoveContent(item)}>
                      <Icon color="red" name="trash-can" />
                    </IconButton>
                  </Column>
                </Row>
                {index === allContents.length - 1 && (
                  <div className={styles.lastElem} ref={lastItemRef} />
                )}
              </>
            ))}
          </Table>
          {isContentsLoading && (
            <div className={styles.loader}>
              <Loader size="small" />
            </div>
          )}
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            size="tiny"
            variant="text"
            before={<Icon name="link" />}
            onClick={handleLinkContentClick}
          >
            Link Content
          </Button>
        </div>
      </div>
    )
  }
)

export default LinkedContentTable
