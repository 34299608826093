import React from "react"
import { observer } from "mobx-react-lite"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Info from "@components/ui/Tooltip/Info"

import UserTrendChart from "../components/Chart/UserTrendChart"

export const UserTrendWidget: React.FC = observer(() => {
  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Text variant="h4">
            User Trend
            <Info>Trend of DAU, MAU and New Users over chosen time period</Info>
          </Text>
        }
      />

      <List direction="row" gutter="24" overflow="initial">
        <UserTrendChart />
      </List>
    </section>
  )
})

export default UserTrendWidget
