import { AxiosResponse } from "axios"

import {
  AddIdentifierRequest,
  Identifier,
  IdentifierDetails,
  IdentifierListQueryRequest,
  IdentifierPaginationMetaData,
  UpdateIdentifierRequest,
  UpdateIdentifierResponse,
} from "@framework/types/identifier"
import { ConnectorDocument } from "@framework/types/content-manager"

import HttpService from "./http.service"
import { SortByParams } from "./common/types"

export type CreateIdentifierResponse = {
  data: IdentifierDetails
}

export type GetIdentifiersListResponse = {
  data: Identifier[]
}

export interface GetContentResponse {
  data: ConnectorDocument[]
  meta: IdentifierPaginationMetaData
}

class IdentifierAPI extends HttpService {
  createIdentifier = (
    body: AddIdentifierRequest
  ): Promise<AxiosResponse<CreateIdentifierResponse>> =>
    this.post("ts/pims/categories", body, true)

  getIdentifierById = (
    identifierId: string
  ): Promise<AxiosResponse<{ data: IdentifierDetails }>> =>
    this.get(`ts/pims/categories/${identifierId}`, true)

  deleteIdentifier = (
    identifierId: string
  ): Promise<AxiosResponse<IdentifierDetails>> =>
    this.delete(`ts/pims/categories/${identifierId}`, true)

  getIdentifierList = (
    body: IdentifierListQueryRequest
  ): Promise<
    AxiosResponse<{
      data: Identifier[]
      meta: IdentifierPaginationMetaData
    }>
  > => this.post("ts/pims/categories/search", body, true)

  getIdentifierContents = (
    pageNum: number,
    pageSize: number,
    identifierId: string
  ): Promise<AxiosResponse<GetContentResponse>> =>
    this.get(`ts/pims/categories/${identifierId}/contents`, true, {
      pageSize,
      pageNum,
    })

  updateIdentifier = (
    id: string,
    body: UpdateIdentifierRequest
  ): Promise<AxiosResponse<{ data: UpdateIdentifierResponse }>> =>
    this.patch(`ts/pims/categories/${id}`, body, true)
}

export default new IdentifierAPI()
