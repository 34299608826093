import React from "react"
import { Field, FormikContextType } from "formik"
import { observer } from "mobx-react-lite"
import pickBy from "lodash/pickBy"
import moment from "moment"

import FilterSidebar from "@components/prototypes/FilterSidebar"
import FilterButton from "@components/ui/Button/FilterButton"
import { useStore } from "@store"
import FormSwitch from "@components/prototypes/form-elements/FormSwitch"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import DateFormField from "@components/prototypes/form-elements/DateFormField"
import { RecentActivityFilters } from "@framework/types/question"
import { DateRange } from "@framework/types/common"
import { countActiveFilters } from "@utils/filters"
import { SearchContext } from "@components/prototypes/SearchContext"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"

import RecentActivityStatusField from "./RecentActivityFilterStatusField"

interface RecentActivityFilterProps {}

export type FilterForm = {
  status?: string[]
  connectedDate?: DateRange
}

const RecentActivityFilterForm: React.FC = observer(() => {
  const {
    recentActivityStore: { filterSearchQuery },
  } = useStore()
  return (
    <>
      <Field
        name="status"
        label="Status"
        valueKey="id"
        component={RecentActivityStatusField}
        searchQuery={filterSearchQuery}
      />

      <Field
        name="date"
        label="Date"
        format={DD_MM_YYYY_FORMAT}
        component={DateFormField}
      />
    </>
  )
})

const RecentActivityFilter: React.FC<RecentActivityFilterProps> = observer(
  () => {
    const {
      recentActivityStore: { updateActiveFilter, activeFilter },
    } = useStore()
    const [searchQuery, setSearchQuery] = React.useState("")

    const appliedFiltersLength = React.useMemo(() => {
      return countActiveFilters({ ...activeFilter })
    }, [activeFilter])

    const searchContext = React.useMemo(
      () => ({ query: searchQuery, setQuery: setSearchQuery }),
      [searchQuery]
    )
    const [formInstance, setFormInstance] =
      React.useState<FormikContextType<FilterForm>>()

    React.useMemo(() => {
      if (formInstance)
        formInstance.resetForm({ values: transformFilterForUI(activeFilter) })
    }, [activeFilter, formInstance])

    return (
      <SearchContext.Provider value={searchContext}>
        <FilterSidebar<FilterForm>
          initialValue={transformFilterForUI(activeFilter)}
          getFormInstance={setFormInstance}
          defaultValue={{}}
          onChange={(newValue) => {
            updateActiveFilter(transformFilterForAPI(newValue))
          }}
          targetSlot={({ onToggle, onReset }) => (
            <FilterButton
              size="big"
              variant="contained"
              color="secondary"
              counter={appliedFiltersLength}
              onOpen={onToggle}
              onClean={onReset}
            >
              Filter
            </FilterButton>
          )}
          headerSlot={<SearchContextInput />}
        >
          <RecentActivityFilterForm />
        </FilterSidebar>
      </SearchContext.Provider>
    )
  }
)

export default RecentActivityFilter

const transformFilterForUI = (filters: RecentActivityFilters): FilterForm => {
  const { connectedDate, ...rest } = filters

  return {
    ...pickBy(
      {
        connectedDate:
          connectedDate != null
            ? {
                start: moment(connectedDate.dateRangeStart).format(
                  DD_MM_YYYY_FORMAT
                ),
                end: moment(connectedDate.dateRangeEnd).format(
                  DD_MM_YYYY_FORMAT
                ),
              }
            : undefined,
        ...rest,
      },
      (it) => it != null
    ),
  }
}

const transformFilterForAPI = (filters: FilterForm): RecentActivityFilters => {
  const { connectedDate, ...rest } = filters

  return pickBy(
    {
      connectedDate:
        connectedDate != null
          ? {
              dateRangeEnd: moment(connectedDate.end, DD_MM_YYYY_FORMAT)
                .endOf("day")
                .unix(),
              dateRangeStart: moment(connectedDate.start, DD_MM_YYYY_FORMAT)
                .startOf("day")
                .unix(),
            }
          : undefined,
      ...rest,
    },
    (it) => it != null
  )
}
