import React from "react"
import clsx from "clsx"

import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement as PlacementType,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react"
import { ColorType } from "@framework/types/utils"

import styles from "./Tooltip.module.sass"

export type Placement = PlacementType

interface TooltipOptions {
  disabled?: boolean
  initialOpen?: boolean
  placement?: Placement
  color?: ColorType
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export const useTooltip = ({
  initialOpen = false,
  placement = "top",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  color = "default",
  disabled = false,
}: TooltipOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)

  const id = useId()

  const open = (controlledOpen ?? uncontrolledOpen) && !disabled
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(12),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({
        element: document.getElementById(`arrow-${id}`),
      }),
    ],
  })

  const { context } = data

  const hover = useHover(context, {
    enabled: controlledOpen == null && !disabled,
    move: false,
    delay: 100,
  })
  const focus = useFocus(context, {
    enabled: controlledOpen == null && !disabled,
  })

  const dismiss = useDismiss(context)

  const role = useRole(context, { role: "tooltip" })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      id,
      open,
      setOpen,
      color,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip>

const TooltipContext = React.createContext<ContextType | null>(null)

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />")
  }

  return context
}

// ROOT

export interface TooltipProps extends TooltipOptions {
  children: React.ReactNode
}

export const Tooltip = ({ children, ...options }: TooltipProps) => {
  const tooltip = useTooltip(options)
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}

// TARGET

export interface TooltipTargetProps extends React.HTMLProps<HTMLElement> {
  children?: React.ReactNode
  asChild?: boolean
}

export const TooltipTrigger = React.forwardRef<HTMLElement, TooltipTargetProps>(
  function TooltipTrigger(
    { children, asChild = false, className, ...props },
    propRef
  ) {
    const context = useTooltipContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": context.open ? "open" : "closed",
        })
      )
    }

    return (
      <span
        ref={ref}
        className={clsx(styles.target, className)}
        data-state={context.open ? "open" : "closed"}
        {...context.getReferenceProps(props)}
      >
        {children}
      </span>
    )
  }
)

// BUBBLE

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  {
    maxWidth?: React.CSSProperties["maxWidth"]
  } & React.HTMLProps<HTMLDivElement>
>(({ style, children, className, maxWidth = 250, ...props }, propRef) => {
  const context = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!context.open) return null

  const { middlewareData } = context.context

  const arrowStyle = {
    left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : "",
    top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : "",
  }

  const placement = middlewareData.offset?.placement || "top"
  const position = placement.split("-")[0]

  return (
    <FloatingPortal>
      <div
        className={clsx(
          styles.body,
          styles[`color-${context.color}`],
          styles[`position-${position}`],
          className
        )}
        ref={ref}
        style={{
          ...context.floatingStyles,
          maxWidth,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      >
        {children}
        <span
          id={`arrow-${context.id}`}
          className={styles.tip}
          style={arrowStyle}
        />
      </div>
    </FloatingPortal>
  )
})
