import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store"
import Label from "@components/ui/Label/Label"

import styles from "./SubscriptionUpgradeModal.module.sass"

export interface SubscriptionUpgradeModalProps {}

export const SubscriptionUpgradeModal: React.FC<SubscriptionUpgradeModalProps> =
  observer(() => {
    const alert = useAlert()

    const modal = useModal(ModalsTypes.SUBSCRIPTION_UPGRADE_MODAL)

    const [description, setDescription] = React.useState("")

    const { subscriptionStore } = useStore()
    const { subscriptionController } = useController()

    const handleSubmit = async () => {
      const res = await subscriptionController.upgradeSubscription(description)
      if (res?.data?.message) {
        alert.success(res.data.message)
      } else {
        alert.error("Failed top send upgrade request")
      }
      modal.hideModal()
    }

    return (
      <BaseModal
        title={<ModalTitle titleText="Upgrade Request Form" />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <div className={styles.body}>
          <Label id="description" label="Notes to Nesh">
            <TextAreaInput
              name="description"
              placeholder="Write a short note to Nesh"
              onChange={(e) =>
                setDescription((e.target as HTMLTextAreaElement).value)
              }
              onBlur={(e) =>
                setDescription((e.target as HTMLTextAreaElement).value)
              }
              rows={5}
            />
          </Label>
        </div>
        <ModalFooterContainer>
          <Button
            size="big"
            variant="outlined"
            onClick={modal.hideModal}
            disabled={subscriptionStore.isLoading}
          >
            Cancel
          </Button>
          <Button
            size="big"
            color="primary"
            onClick={handleSubmit}
            disabled={!description || subscriptionStore.isLoading}
          >
            Submit
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  })

export default SubscriptionUpgradeModal
