import React from "react"
import clsx from "clsx"

import Markdown from "@components/ui/Typography/Markdown"
import Text from "@components/ui/Typography/Text"
import HTMLText from "@components/ui/Typography/HTMLText"
import TruncatedText from "@components/ui/Typography/TruncatedText"

import styles from "./InsightCard.module.sass"

export type InsightCardType = {
  answer: string
  headerSocket?: React.ReactNode
  isInThread?: boolean
  htmlContent?: boolean
  lineClamp?: number
} & React.HTMLProps<HTMLDivElement>

const InsightCard: React.FC<InsightCardType> = ({
  answer,
  headerSocket,
  isInThread = false,
  lineClamp = 3,
  htmlContent,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleReadMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      className={clsx(styles.root, { [styles.thread]: isInThread })}
      {...rest}
    >
      {headerSocket}

      <Text variant="body2" className={styles.infoContent}>
        <TruncatedText
          maxLines={3}
          truncated={!isExpanded}
          onReadMoreClick={toggleReadMore}
        >
          {htmlContent ? (
            <HTMLText>{answer}</HTMLText>
          ) : (
            <Markdown>{answer}</Markdown>
          )}
        </TruncatedText>
      </Text>
    </div>
  )
}

export default InsightCard
