import clsx from "clsx"
import React from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"

import styles from "./Chip.module.sass"
import colorStyles from "./Chip-colors.module.sass"

export type ColorType =
  | "primary"
  | "secondary"
  | "default"
  | "green"
  | "gold"
  | "red"
  | "blue"
  | "yellow"
  | "transparent"

export type FilledColorType = `${ColorType}-solid`

export type ChipColorType = ColorType | FilledColorType

export interface ChipProps extends React.HTMLProps<HTMLDivElement> {
  variant?: "rounded" | "default"
  color?: ChipColorType
  before?: React.ReactNode
  subTitle?: React.ReactNode
  uppercase?: boolean
  contentClassName?: string
  onRemove?: React.MouseEventHandler
}

export const Chip = ({
  children,
  variant = "default",
  color = "default-solid",
  before,
  subTitle,
  uppercase,
  className,
  contentClassName,
  role,
  tabIndex,
  onClick,
  onRemove,
  onKeyPress,
  ...rest
}: ChipProps) => {
  const removeNode =
    onRemove != null ? (
      <Button
        tabIndex={-1}
        onMouseDown={(e) => {
          onRemove(e)
          e.stopPropagation()
        }}
        className={styles.closeButton}
        color="secondary"
      >
        <Icon name="cross" />
      </Button>
    ) : null

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      {...rest}
      className={clsx(
        styles.root,
        styles[`variant-${variant}`],
        colorStyles[`color-${color}`],
        {
          [styles.clickable]: !!onClick,
          [styles.uppercase]: uppercase,
        },
        className
      )}
      onClick={onClick}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
      role={role}
    >
      {before}
      <span className={clsx(styles.title, contentClassName)}>{children}</span>
      {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
      {removeNode}
    </div>
  )
}

export default Chip
