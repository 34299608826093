import React from "react"
import clsx from "clsx"

import styles from "./index.module.sass"

export interface ColumnProps
  extends React.HTMLAttributes<HTMLTableCellElement> {
  width?: React.CSSProperties["width"]
  height?: React.CSSProperties["height"]
  align?: "center" | "start" | "end"
  justify?: "start" | "center" | "end"
  as?: "th" | "td"
  colspan?: number
}

export const Column: React.FC<ColumnProps> = React.memo(
  ({
    as: Component = "td",
    width,
    height,
    className,
    align = "start",
    justify = "start",
    style,
    children,
    ...rest
  }) => {
    return (
      <Component
        className={clsx(
          styles.column,
          styles[`align-${align}`],
          styles[`justify-${justify}`],
          className
        )}
        style={{ ...style, ...{ width, height } }}
        {...rest}
      >
        {children}
      </Component>
    )
  }
)

export default Column
