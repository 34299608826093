import { makeAutoObservable } from "mobx"

import WorkbookReportStore from "./report.store"

export default class WorkbookReportsStore {
  reports: Map<string, WorkbookReportStore>

  constructor() {
    this.reports = new Map()

    makeAutoObservable(this)
  }

  get getById() {
    return (id: string) => {
      const report = this.reports.get(id)
      if (report == null) this.reports.set(id, new WorkbookReportStore(id))
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.reports.get(id)!
    }
  }
}
