import { AxiosProgressEvent, AxiosResponse } from "axios"
import { Observable } from "rxjs"

import {
  ConnectorDocument,
  ConnectWebsiteParams,
  CreateContentSourceParams,
  CreateContentSourceResponse,
  DocumentByConnectorNameParams,
  DocumentParams,
  DocumentsListResponse,
  GetConnectorDocumentsParams,
  GetKibanaConnectorAccountsResponse,
  GetKibanaConnectorsParams,
  GetSyncJobsResponse,
  KibanaConnectorInfo,
  UpdateDocumentContentTypeParams,
  WebsiteInfo,
} from "@framework/types/content-manager"
import { Company } from "@framework/types/company"
import { ProductCategory } from "@store/product/product.store"
import { PaginationParams } from "@framework/types/utils"

import HttpService from "./http.service"

class ContentManagerAPI extends HttpService {
  getAvailableContentSources = (
    params: GetKibanaConnectorsParams & Partial<PaginationParams>
  ): Promise<AxiosResponse<GetKibanaConnectorAccountsResponse>> =>
    this.get("ts/kibana-connectors", true, {
      pageSize: 1000,
      pageNum: 1,
      ...params,
    })

  getContentSources$ = (
    params: GetKibanaConnectorsParams & Partial<PaginationParams>
  ): Observable<AxiosResponse<GetKibanaConnectorAccountsResponse>> =>
    this.getStream$("ts/kibana-connectors", {
      params: {
        pageSize: 1000,
        pageNum: 1,
        ...params,
      },
    })

  getKibanaConnectorsCount = (): Promise<
    AxiosResponse<KibanaConnectorInfo[]>
  > => this.get(`ts/kibana-connectors/counts`, true)

  createContentSource = (
    data: CreateContentSourceParams
  ): Promise<AxiosResponse<CreateContentSourceResponse>> =>
    this.post("ts/kibana-connectors", data, true)

  deleteKibanaConnectorAccount = (id: string): Promise<AxiosResponse<any>> =>
    this.delete(`ts/kibana-connectors/${id}`, true)

  connectWebsite = (
    data: ConnectWebsiteParams
  ): Promise<AxiosResponse<CreateContentSourceResponse>> =>
    this.post("ts/kibana-connectors/website/crawls", data, true)

  getWebsites = (isRunning?: boolean): Promise<AxiosResponse<WebsiteInfo[]>> =>
    this.get(
      "ts/kibana-connectors/website/crawls",
      true,
      isRunning ? { isRunning: true } : {}
    )

  getDocumentsList = (
    params: GetConnectorDocumentsParams,
    signal?: AbortSignal
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.get("ts/kibana-connectors/documents", true, params, null, signal)

  getSyncJobs = (
    status: string = "in_progress"
  ): Promise<AxiosResponse<GetSyncJobsResponse>> =>
    this.get("ts/kibana-connectors/sync-jobs", true, {
      pageSize: 10,
      pageNum: 1,
      status,
    })

  uploadDocument = (
    file: File,
    contentType?: string,
    companyIds?: string[],
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData()
    formData.append("file", file)
    if (contentType) formData.append("contentType", contentType)
    if (companyIds)
      companyIds?.forEach((it) => formData.append(`companyIds`, it))

    return this.post("ts/kibana-connectors/documents", formData, true, {
      headers: {
        "Content-Type": "application/form-data",
      },
      onUploadProgress,
    })
  }

  updateContentTypeForDocument = (
    params: UpdateDocumentContentTypeParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.patch(
      `ts/kibana-connectors/${params.connectorId}/documents/${params.documentId}`,
      {
        contentType: params.contentType,
      },
      true
    )

  getCompanies = (
    filterByHasContent?: boolean
  ): Promise<AxiosResponse<Company[]>> =>
    this.get(`ts/v2/companies`, true, { filterByHasContent })

  getCompanies$ = (
    filterByHasContent?: boolean
  ): Observable<AxiosResponse<Company[]>> =>
    this.getStream$(`ts/v2/companies`, { params: { filterByHasContent } })

  getIdentifiers = (
    companyId?: string
  ): Promise<AxiosResponse<{ data: ProductCategory[] }>> =>
    this.get("ts/pims/categories", true, companyId ? { companyId } : {})

  deleteDocument = (
    params: DocumentParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.delete(
      `ts/kibana-connectors/${params.connectorId}/documents/${params.documentId}`,
      true
    )

  getDocumentDetails = (
    params: DocumentByConnectorNameParams
  ): Promise<AxiosResponse<ConnectorDocument>> =>
    this.get(
      `ts/kibana-connectors/connectors/${
        params.connectorName
      }/documents/${encodeURIComponent(params.contentId)}`,
      true,
      {
        isPublic: params.isPublic,
      }
    )

  reportDocument = (params: any): Promise<AxiosResponse<unknown>> =>
    this.post(`ts/kibana-connectors/content/report`, params)
}

export {}

export default new ContentManagerAPI()
