import React from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { ErrorBoundary } from "react-error-boundary"

import Logo from "@components/prototypes/Logo/Logo"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import Report from "./Report"
import { DownloadReportButton } from "./DownloadReportButton"

import styles from "./WorkbookReport.module.sass"

const WorkbookReportPage: React.FC = observer(() => {
  const { reportId } = useParams()

  if (!reportId)
    throw new Error("WorkbookReportPage requires reportId path parameter")

  const { workbookReportsStore: workbookReportStore } = useStore()

  const { unifiedMatrixController } = useController()

  React.useEffect(() => {
    unifiedMatrixController.pullReport(reportId)
  }, [reportId])

  const report = workbookReportStore.getById(reportId)

  return (
    <div className={styles.root}>
      <ErrorBoundary
        fallback={
          <NotFound>
            Failed to display report.
            <br />
            Unexpected format
          </NotFound>
        }
        key={reportId}
      >
        <div className={styles.box}>
          <Templates.Header
            left={<Logo height={40} withText />}
            right={
              report.status === "completed" &&
              report.data != null && (
                <DownloadReportButton report={report.data} />
              )
            }
            className={styles.header}
          />

          <div className={styles.body}>
            {report.data != null ? (
              <Report data={report.data} />
            ) : report.isLoading ? (
              <Loader size="large" fluid />
            ) : (
              <NotFound fluid />
            )}
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
})

export default WorkbookReportPage
