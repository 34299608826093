import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"

import { renderETA } from "./utils"

import styles from "./ReportChip.module.sass"

export type ReportChipProps = {
  reportId: string
  active?: boolean
  onClick?: React.MouseEventHandler
}

const ReportChip: React.FC<ReportChipProps> = observer(
  ({ reportId, active = false, onClick }) => {
    const { workbookReportsStore } = useStore()

    const { unifiedMatrixController } = useController()

    React.useEffect(() => {
      unifiedMatrixController.pullReport(reportId)
    }, [reportId])

    const report = workbookReportsStore.getById(reportId)

    const renderBadge = () => {
      if (report.status === "completed")
        return <Icon name="checkbox-circle-line" color="green" />

      if (report.status === "failed") return <Icon name="close" color="red" />

      return <Loader />
    }

    const renderInfo = () => {
      if (report.status === "completed") {
        return (
          <Text
            variant="caption2"
            color="text50Color"
            className={styles.oneliner}
          >
            Click to view the report
          </Text>
        )
      }

      if (report.status === "failed") {
        const errorMessage = report.error || `Generation Failed`
        return (
          <Text variant="caption2" color="redColor" className={styles.oneliner}>
            {errorMessage}
          </Text>
        )
      }

      const progressText = `Expected to take ${renderETA(report.eta)}`

      return (
        <Text
          variant="caption2"
          color="text50Color"
          className={styles.oneliner}
        >
          {progressText}
        </Text>
      )
    }

    return (
      <button
        type="button"
        className={clsx(styles.root, {
          [styles.clickable]: onClick,
          [styles.active]: active,
        })}
        onClick={onClick}
      >
        <div className={styles.status}>{renderBadge()}</div>

        <div className={styles.body}>
          <Text
            title={report.data?.title}
            variant="h4"
            className={styles.oneliner}
          >
            {report.data?.title ?? "Workflow report"}
          </Text>
          {renderInfo()}
        </div>
      </button>
    )
  }
)

export default ReportChip
