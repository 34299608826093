import React from "react"

import { QueryHistoryData } from "@framework/types/analytics"
import Table from "@components/ui/BaseTable/Table"

import QueryHistoryTableHeader from "./QueryHistoryTableHeader"
import QueryHistoryTableRow from "./QueryHistoryTableRow"

import styles from "./QueryHistoryTable.module.sass"

interface QueryHistoryProps {
  rows: QueryHistoryData[]
  onAssignQuestionClick: (question: QueryHistoryData) => void
}

const QueryHistoryTable: React.FC<QueryHistoryProps> = React.memo(
  ({ rows, onAssignQuestionClick }) => {
    return (
      <Table header={<QueryHistoryTableHeader />} className={styles.root}>
        {rows.map((query) => {
          return (
            <QueryHistoryTableRow
              key={query.id}
              queryInfo={query}
              onAssignQuestionClick={() => onAssignQuestionClick(query)}
            />
          )
        })}
      </Table>
    )
  }
)

export default QueryHistoryTable
