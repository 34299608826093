import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store/index"
import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { SearchCitationData } from "@store/search/search-citations.store"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { pluralize } from "@utils/textUtils"

import styles from "./Title.module.sass"

const Title: React.FC<{ answer: SearchCitationData }> = observer(
  ({ answer }) => {
    const isTablet = useMediaQuery(AppMediaQueries.minTablet)

    const reportModal = useModal(ModalsTypes.REPORT_CONTENT_MODAL)

    const { restrictionsStore: access } = useStore()
    const { dataConnectorController } = useController()

    const isExpertKnowledge = answer.isExpertAnswer

    const handleSourceClick = (sourceURL: string) => async () => {
      const res = await dataConnectorController.getSignedDataURL(sourceURL)
      if (res.status === "SUCCESS") window.open(res.data, "_blank")
    }

    const renderStatus = () => {
      if (answer.isDocumentOld && access.showSearchContentWarming) {
        const message = `Content source is older then ${
          answer.documentAgeCutoff
            ? pluralize(
                `${answer.documentAgeCutoff} year`,
                answer.documentAgeCutoff !== 1
              )
            : "a few years"
        }`
        return (
          <Chip color="gold-solid" variant="rounded" title={message}>
            Outdated
          </Chip>
        )
      }

      if (!isExpertKnowledge)
        return (
          <Chip color="green-solid" variant="rounded">
            New
          </Chip>
        )

      return null
    }

    return (
      <div className={styles.titleContainer}>
        {renderStatus()}
        <div className={styles.title}>
          {isExpertKnowledge ? (
            <>
              <Icon color="green" name="user-insight" inline />

              <Text variant="inherit" className={styles.titleText}>
                Expert Insights
              </Text>
            </>
          ) : answer.contentId ? (
            <>
              <Text variant="inherit" weight="bold" className={styles.prefix}>
                Content ID:{" "}
              </Text>
              <Text
                variant="inherit"
                className={styles.titleText}
                title={answer.contentId}
              >
                {answer.contentId}
              </Text>
            </>
          ) : (
            <>
              <Icon color="gold" name="global" inline />

              <Text
                variant="inherit"
                className={styles.titleText}
                title={answer.sourceName || "N/A"}
              >
                {answer.sourceName || "N/A"}
              </Text>
            </>
          )}

          {isExpertKnowledge ? (
            <div className={styles.expertBadge}>
              <Icon name="shield-check-fill" color="green" inline />
              <Text variant="h5">Expert Verified</Text>
            </div>
          ) : (
            <>
              {!!answer.sourceURL && (
                <Button
                  onClick={handleSourceClick(answer.sourceURL)}
                  className={styles.source}
                  color="secondary"
                  size={isTablet ? "tiny" : "small"}
                  before={<Icon name="external-link" />}
                >
                  Open file
                </Button>
              )}

              {answer.contentId != null && (
                <Button
                  className={styles.source}
                  color="secondary"
                  size={isTablet ? "tiny" : "small"}
                  before={<Icon name="alert" />}
                  onClick={() => {
                    if (answer.contentId == null) return
                    reportModal.showModal({ contentId: answer.contentId })
                  }}
                >
                  Report
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
)

export default Title
