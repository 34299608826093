import moment from "moment"

import { MMM_DD_YYYY } from "@framework/constants/global"
import { ReportETA } from "@framework/types/workbook-report"
import { renderDuration } from "@utils/date"

export const renderDate = (dateStr?: string | null) => {
  try {
    if (dateStr == null) return "-"
    return moment(dateStr).format(MMM_DD_YYYY)
  } catch (error) {
    return "-"
  }
}

export const renderETA = (data: ReportETA) => {
  try {
    const minTime = renderDuration(data.minTimeInSec * 1000, "s")
    const maxTime = renderDuration(data.maxTimeInSec * 1000, "s")

    return `from ${minTime} to ${maxTime}`
  } catch (error) {
    return "from 10 to 20 secs"
  }
}
