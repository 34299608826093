import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useAppConfig } from "@components/theme/AppConfigProvider"
import mainRoutes from "@root/main.routes"
import useQueryParams from "@components/hooks/useQueryParams"

import SignUpPage from "./SignUp"
import SignUpUserRequestPage from "./SignUpUserRequest"

export enum SignUpMode {
  confirmation = "confirmation",
  userRequest = "userRequest",
}

export interface SignUpPageSwitcherProps {}

const SignUpPageSwitcher: React.FC<SignUpPageSwitcherProps> = observer(() => {
  const navigate = useNavigate()
  const theme = useAppConfig()
  const params = useQueryParams()

  const invitationCode = !!params.x && typeof params.x === "string"

  const mode = invitationCode ? SignUpMode.confirmation : SignUpMode.userRequest

  const canRequestAccount = !theme.authorization.signUp.classic.disabled

  React.useEffect(() => {
    if (mode === SignUpMode.userRequest && !canRequestAccount)
      navigate(mainRoutes.login())
  }, [canRequestAccount, mode])

  return (
    <>
      {mode === SignUpMode.confirmation && <SignUpPage />}
      {mode === SignUpMode.userRequest && canRequestAccount && (
        <SignUpUserRequestPage />
      )}
    </>
  )
})

export default SignUpPageSwitcher
