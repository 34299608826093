import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store/index"
import useToggle from "@components/hooks/useToggle"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { renderDatePeriodTupleToRange } from "@utils/date"
import Info from "@components/ui/Tooltip/Info"

import TopActiveUsersList from "./TopActiveUsersList"
import AllActiveUsersSidebar from "./AllActiveUsersSidebar"
import { useDateRangeFilterContext } from "../DateRangeFilterContext"

import styles from "./TopActiveUsers.module.sass"

const TopActiveUsers: React.FC = observer(() => {
  const {
    usageAnalyticsStore: { activeUsersStore },
  } = useStore()

  const sidebarToggle = useToggle()

  const list = activeUsersStore.state.top

  const { dateRange } = useDateRangeFilterContext()

  const period = React.useMemo(
    () => renderDatePeriodTupleToRange(dateRange, DD_MM_YYYY_FORMAT),
    [dateRange]
  )

  React.useEffect(() => {
    activeUsersStore.resetList()
  }, [])

  React.useEffect(() => {
    activeUsersStore.updateFilter({ period })
  }, [period])

  const isAnyLoaded = activeUsersStore.state.total > 0 && list.length > 0

  return (
    <div className={styles.root}>
      <Text variant="h4" weight="bold" inline>
        Top Active Users
        <Info>
          Leaderboard of the most active users over chosen time period
        </Info>
      </Text>

      <div className={styles.body}>
        <TopActiveUsersList />
      </div>

      <Button
        onClick={() => sidebarToggle.setOpened(true)}
        size="small"
        variant="outlined"
        color="default"
        className={styles.viewAll}
        disabled={!isAnyLoaded}
      >
        View All
      </Button>

      <AllActiveUsersSidebar
        open={sidebarToggle.isOpened}
        onClose={() => sidebarToggle.setOpened(false)}
      />
    </div>
  )
})

export default TopActiveUsers
