import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { ConnectorDocument } from "@framework/types/content-manager"
import { useController, useStore } from "@store"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import { IconName } from "@components/ui/Icon/IconName"
import { formatStringToJSON } from "@utils/textUtils"

import ContentCard from "./ContentCard"

import styles from "./index.module.sass"

const LinkContent: React.FC<{ id?: string }> = observer(({ id }) => {
  const [allContents, setAllContents] = useState<ConnectorDocument[]>([])
  const {
    productComparisonStore: { productStore },
  } = useStore()
  const { productController } = useController()
  const { isContentsLoading, contentPaginationMeta, productContents } =
    productStore
  const hasMoreContent =
    (contentPaginationMeta?.page || 0) <
    (contentPaginationMeta?.totalPages || 0)

  const loadMoreContent = useCallback(() => {
    if (hasMoreContent && id) {
      productController.loadMoreProductContentIds(
        (contentPaginationMeta?.page || 1) + 1,
        100,
        id || ""
      )
    }
  }, [contentPaginationMeta, productController])

  const lastItemRef = useInfiniteScroll({
    callback: loadMoreContent,
    isLoading: isContentsLoading,
    hasMore: hasMoreContent,
  })

  useEffect(() => {
    if (productContents.length) {
      setAllContents([
        ...allContents,
        ...productContents.filter(
          (doc) => !allContents.some((content) => content.id === doc.id)
        ),
      ])
    }
  }, [productContents])

  const handleContentClick = (content: ConnectorDocument) => {
    const source = content.source.toString()
    if (source === "website") {
      const metadata = formatStringToJSON(content.docInfo.metadata)
      window.open(metadata?.webpageUrl, "_blank")
    } else if (source === "sharepoint_online") {
      window.open(content.docInfo?.webUrl, "_blank")
    } else if (source === "salesforce") {
      window.open(content.docInfo?.url, "_blank")
    }
  }

  useEffect(() => {
    setAllContents([])
  }, [])

  return (
    <div className={styles.linkedContent}>
      <Text weight="bold" variant="h3">
        Linked Content
      </Text>
      <div className={styles.linkedContentList}>
        {allContents.map((content, index) => (
          <ContentCard
            key={content.id + content.connectedDate}
            content={content}
            lastItemRef={index === allContents.length - 1 ? lastItemRef : null}
            handleContentClick={handleContentClick}
          />
        ))}
        {!allContents.length && !isContentsLoading && <NotFound />}
        {isContentsLoading && (
          <div className={styles.loader}>
            <Loader size="small" />
          </div>
        )}
      </div>
    </div>
  )
})

export default LinkContent
