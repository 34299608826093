import React from "react"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import { useController, useStore } from "@store/index"
import SearchInput from "@pages/launch-solutions/SolutionTabsContainer/SearchInput"
import { useSolution } from "@pages/launch-solutions/ActiveSolutionContext"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { WorkbookReportTypeName } from "@framework/types/workbook-report"
import ShiftContainer from "@components/ui/ShiftContainer/ShiftContainer"

import WorkbookReportTemplate, { ReportRequest } from "./WorkbookReportTemplate"

import styles from "./SearchWidget.module.sass"

const validationSchema = yup.object({
  query: yup.string().trim().min(1).default(""),
})

export interface Props {
  disabled?: boolean
}

export const SearchWidget: React.FC<Props> = observer(({ disabled }) => {
  const {
    productComparisonStore: { productStore },
    factFinderSolutionStore: { searchFlowStore },
  } = useStore()

  const { factFinderSolutionController } = useController()

  const isSearching = searchFlowStore.isAnyLoading()

  React.useEffect(() => {
    if (!isSearching)
      factFinderSolutionController.searchFlow.generateReport(null)
  }, [isSearching])

  React.useEffect(() => {
    productStore.loadProducts({
      filters: { search: "" },
      pagination: { ...productStore.productsMetaData, pageSize: 500 },
    })
  }, [])

  return (
    <div className={styles.root} aria-disabled={disabled}>
      <ShiftContainer>
        {searchFlowStore.reportCandidateType ? (
          <WorkbookReportTemplate
            reportType={searchFlowStore.reportCandidateType}
            onSubmit={() => {
              searchFlowStore.generateReport(null)
            }}
            onCancel={() => {
              searchFlowStore.generateReport(null)
            }}
            key={searchFlowStore.reportCandidateType}
          />
        ) : (
          <SearchForm disabled={isSearching} key="search" />
        )}
      </ShiftContainer>
    </div>
  )
})

export const SearchForm: React.FC<Props> = observer(({ disabled }) => {
  const { solution } = useSolution()

  const {
    solutionsStore: { appliedFilters },
    contentManagerStore: { companies },
  } = useStore()

  const { avatar } = useActiveAvatar()

  const { factFinderSolutionController: controller } = useController()

  const handleSubmit = (data: any) => {
    if (solution?.id)
      controller.search(
        data.query,
        avatar,
        solution.id,
        appliedFilters,
        companies
      )
    formik.setFieldValue("query", "")
  }

  const formik = useFormik({
    initialValues: validationSchema.getDefault(),
    validationSchema,
    validateOnChange: true,
    onSubmit: handleSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <section className={styles.searchContainer}>
          <SearchInput disabled={disabled} />
        </section>
      </form>
    </FormikProvider>
  )
})

export default SearchWidget
