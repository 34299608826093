import * as React from "react"
import keyBy from "lodash/keyBy"

import { Option } from "@framework/types/utils"
import useEvent from "@components/hooks/useEvent"
import { searchBy } from "@utils/optionsUtils"

import Chip from "../Chip/Chip"
import Text from "../Typography/Text"
import DropdownRoot from "./DropdownRoot"
import DropdownItem from "./DropdownItem"
import AddButton from "./AddButton"
import DropdownList from "./DropdownList"
import DropdownContainer from "./DropdownContainer"
import DropdownTarget from "./DropdownTarget"
import Skeleton from "../Skeleton/Skeleton"

import styles from "./Select.module.sass"

export type SingleValue<T> = T | null
export type MultiValue<T> = readonly T[]

type Value<T> = MultiValue<T> | SingleValue<T>

type OnChangeValue<T, M extends boolean> = M extends true
  ? MultiValue<T>
  : SingleValue<T>

interface SelectProps<T extends string, IsMulti extends boolean> {
  isMulti?: IsMulti
  disabled?: boolean
  value: OnChangeValue<T, IsMulti>
  options: Option<T>[]
  loading?: boolean
  clearable?: boolean
  creatable?: boolean
  searchable?: boolean
  placeholder?: string
  withError?: boolean
  onChange?: (value: OnChangeValue<T, IsMulti>) => void
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
  onAddOption?: (
    value: string
  ) => Promise<string | void | null> | string | void | null
}

const getValue = (value: Option): string => {
  return value.value
}

const getId = <T extends string>(value: Option<T>): T => {
  return value.name
}

const isValueMultiple = <T, IsMulti extends boolean>(
  value: Value<T>,
  isMulti: IsMulti
): value is MultiValue<T> => {
  return isMulti
}

export const Select = <T extends string, IsMulti extends boolean = false>({
  value,
  options,
  loading = false,
  clearable = false,
  creatable = false,
  searchable = true,
  disabled = false,
  withError = false,
  isMulti,
  placeholder = "Select...",
  onChange,
  onAddOption,
  onFocus,
  onBlur,
}: SelectProps<T, IsMulti>) => {
  const multi: IsMulti = isMulti ?? (false as IsMulti)

  const isAnySelected = multi && Array.isArray(value) ? !!value.length : !!value

  const isSelected = React.useCallback(
    (option: string) => {
      if (Array.isArray(value)) return value.includes(option)
      return value === option
    },
    [multi, value]
  )

  const getOptionByName = React.useMemo(() => {
    const mapper = keyBy(options, "name")
    return (name?: string | null): Option | undefined =>
      name ? mapper[name] : undefined
  }, [options])

  const handleSelect = useEvent((newValue: string) => {
    if (!onChange) return

    if (isValueMultiple(value, multi)) {
      if (isSelected(newValue)) {
        const newList: Value<string> = value.filter((it) => it !== newValue)
        onChange?.(newList as OnChangeValue<T, IsMulti>)
      } else {
        const newList: Value<string> = [...value, newValue]
        onChange?.(newList as OnChangeValue<T, IsMulti>)
      }
      return
    }

    if (isSelected(newValue)) {
      onChange?.(null as OnChangeValue<T, IsMulti>)
    } else {
      onChange?.(newValue as OnChangeValue<T, IsMulti>)
    }
  })

  const resetValue = useEvent(() => {
    if (!onChange) return
    onChange((multi ? [] : null) as OnChangeValue<T, IsMulti>)
  })

  const handleAddOption = useEvent(async (value: string) => {
    if (!onAddOption) return

    const option = await onAddOption(value)

    if (option != null) handleSelect(option)
  })

  const getValuePlaceholder = () => {
    if (!isAnySelected) return null
    if (isValueMultiple(value, multi)) return null

    const option = getOptionByName(value)
    return (option != null && getValue(option)) || "Unknown"
  }

  const valuePlaceholder = getValuePlaceholder()
  const renderValue = () => {
    if (!isAnySelected) return null
    if (isValueMultiple(value, multi))
      return (
        <>
          {value.map((it) => {
            const option = getOptionByName(it)
            if (option == null && loading)
              return (
                <div style={{ width: "min(120px, 100%)" }}>
                  <Skeleton rounded count={1} minWidth={100} lineHeight={25} />
                </div>
              )
            const content = option?.value || "Unknown"
            return (
              <Chip
                title={content}
                variant="rounded"
                color="secondary-solid"
                onRemove={(e) => {
                  handleSelect(it)
                  e.preventDefault()
                }}
              >
                {content}
              </Chip>
            )
          })}
        </>
      )

    return (
      <Text
        variant="inherit"
        className={styles.valueText}
        title={valuePlaceholder ?? ""}
      >
        {valuePlaceholder}
      </Text>
    )
  }

  const [searchQuery, setSearchQuery] = React.useState("")

  const searchedOptions = React.useMemo(() => {
    if (!searchable) return options
    return searchBy(options, searchQuery.trim(), (it) => it.value)
  }, [searchQuery, searchable, options])

  const exactMatch = React.useMemo(() => {
    const query = searchQuery.trim().toLowerCase()
    return searchedOptions.findIndex((it) => it.value.toLowerCase() === query)
  }, [searchQuery, creatable, searchedOptions])

  const handleSelectByIndex = useEvent((index: number) => {
    if (!onChange) return
    const candidate = searchedOptions[index]
    if (candidate == null) return
    handleSelect(getId(candidate))
  })

  React.useEffect(() => {
    setSearchQuery("")
  }, [value])

  return (
    <DropdownRoot
      loading={loading}
      clearable={clearable}
      creatable={creatable}
      searchable={searchable}
      closeOnSelect={!multi}
      isMulti={multi}
      onSelectByIndex={handleSelectByIndex}
      onAddOption={handleAddOption}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      exactMatch={exactMatch}
      disabled={disabled}
    >
      <DropdownTarget
        selected={isAnySelected}
        onReset={resetValue}
        placeholder={valuePlaceholder || placeholder}
        withError={withError}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {renderValue()}
      </DropdownTarget>

      <DropdownContainer>
        <DropdownList>
          {searchedOptions.map((value, i) => {
            return (
              <DropdownItem
                index={i}
                selected={isSelected(getId(value))}
                key={getId(value)}
              >
                {getValue(value)}
              </DropdownItem>
            )
          })}
        </DropdownList>
        {Boolean(creatable && exactMatch === -1) && <AddButton />}
      </DropdownContainer>
    </DropdownRoot>
  )
}

export default Select
