import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import Icon from "@components/ui/Icon/Icon"

import styles from "./WorkbookReportTemplate.module.sass"

export const Footer: React.FC<{
  isLoading?: boolean
  onClose?: React.MouseEventHandler
}> = observer(({ isLoading, onClose }) => {
  return (
    <div className={styles.footer}>
      <Button
        size="big"
        variant="outlined"
        disabled={isLoading}
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        size="big"
        color="primary"
        disabled={isLoading}
        after={
          isLoading ? (
            <Loader size="small" />
          ) : (
            <Icon name="arrow-up" rotateAngle={90} />
          )
        }
      >
        Generate Report
      </Button>
    </div>
  )
})

export default Footer
