import React from "react"

import AnswerFeedbackModal, {
  AnswerFeedbackModalProps,
} from "./AnswerFeedbackModal/AnswerFeedbackModal"
import DeleteFlagModal, {
  DeleteFlagModalProps,
} from "./AppConfig/DeleteFlagModal"
import EditFeatureFlagModal, {
  EditFeatureFlagModalProps,
} from "./AppConfig/EditFeatureFlagModal"
import AssignExpertToQuestionModal, {
  AssignExpertToQuestionModalProps,
} from "./AssignExpertToQuestionModal/AssignExpertToQuestionModal"
import AssignUserRolesModal, {
  AssignUserRolesModalProps,
} from "./AssignUserRolesModal/AssignUserRolesModal"
import ConfirmUserDeleteModal, {
  ConfirmUserDeleteModalProps,
} from "./ConfirmUserDeleteModal/ConfirmUserDeleteModal"
import EditAvatarModal, {
  EditAvatarModalProps,
} from "./CreateAvatarModal/EditAvatarModal"
import DeleteAvatarModal, {
  DeleteAvatarModalProps,
} from "./DeleteAvatarModal/DeleteAvatarModal"
import FeedbackModal, {
  FeedbackModalProps,
} from "./FeedbackModal/FeedbackModal"
import DeleteAcronymModal, {
  DeleteAcronymModalProps,
} from "./GlossaryModal/DeleteAcronymModal"
import DeleteSynonymModal, {
  DeleteSynonymModalProps,
} from "./GlossaryModal/DeleteSynonymModal"
import InviteUserModal, {
  InviteUserModalProps,
} from "./InviteUserModal/InviteUserModal"
import { LogoutModal, LogoutModalProps } from "./LogoutModal/LogoutModal"
import {
  SuccessMessageModal,
  SuccessMessageModalProps,
} from "./SuccessMessageModal/SuccessMessageModal"
import UploadFilesModal, {
  UploadFilesModalProps,
} from "./UploadFilesModal/UploadFilesModal"
import ResetUserPasswordModal, {
  ResetUserPasswordModalProps,
} from "./UserModal/ResetUserPasswordModal"
import DeleteUserActionModal, {
  DeleteUserActionModalProps,
} from "./UserRoleModal/DeleteUserActionModal"
import DeleteUserRoleModal, {
  DeleteUserRoleModalProps,
} from "./UserRoleModal/DeleteUserRoleModal"
import EditUserActionModal, {
  EditUserActionModalProps,
} from "./UserRoleModal/EditUserActionModal"
import EditUserRoleModal, {
  EditUserRoleModalProps,
} from "./UserRoleModal/EditUserRoleModal"
import { ModalsTypes } from "./constants"
import CreateInstanceConfigModal, {
  CreateInstanceConfigModalProps,
} from "./InstanceConfigModal/InstanceConfigModal"
import DeleteConfigModal, {
  DeleteConfigModalProps,
} from "./InstanceConfigModal/DeleteConfigModal"
import ReassignFilesToDataTypeModal, {
  ReassignFilesToDataTypeModalProps,
} from "./AddSourceToDataTypeModal/ReassignFileToDataTypeModal"
import EditTopicModal, {
  EditTopicModalProps,
} from "./ExpertTopicModal/EditTopicModal"
import EditSubtopicModal, {
  EditSubtopicModalProps,
} from "./ExpertTopicModal/EditSubtopicModal"
import ConfirmDeleteModal, {
  ConfirmDeleteModalProps,
} from "./Common/ConfirmDeleteModal"
import EditArticleModal, {
  EditArticleModalProps,
} from "./ExpertTopicModal/EditArticleModal"
import EditAvatarMembershipModal, {
  EditAvatarMembershipModalProps,
} from "./AvatarMembership/EditModal"
import DeleteOptionModal, {
  DeleteOptionModalProps,
} from "./AvatarMembership/DeleteOptionModal"
import PreselectDataTypeModal, {
  PreselectDataTypeModalProps,
} from "./PreselectDataTypeModal"
import SelectDataConnectorAccountModal, {
  SelectDataConnectorAccountModalProps,
} from "./SelectDataConnectorAccountModal"
import DatatypeAssignAvatarModal, {
  DatatypeAssignAvatarModalProps,
} from "./Datatypes/AssignAvatar"
import DatatypeCreateModal, {
  DatatypeCreateModalProps,
} from "./Datatypes/Create"
import DatatypeDeleteModal, {
  DatatypeDeleteModalProps,
} from "./Datatypes/Delete"
import CreateApiKeyModal, {
  CreateApiKeyModalProps,
} from "./ApiKeysModal/CreateApiKeyModal"
import DeleteApiKeyModal, {
  DeleteApiKeyModalProps,
} from "./ApiKeysModal/DeleteApiKeyModal"
import ShowApiKeyModal, {
  ShowApiKeyModalProps,
} from "./ApiKeysModal/ShowApiKeyModal"
import ConfirmModal, { ConfirmModalProps } from "./ConfirmModal/ConfirmModal"
import SubscriptionUpgradeModal, {
  SubscriptionUpgradeModalProps,
} from "./SubscriptionUpgradeModal/SubscriptionUpgradeModal"
import ProductThemesModal, {
  ProductThemesModalProps,
} from "./ProductThemesModal/ProductThemesModal"
import LongTextModal, {
  LongTextModalProps,
} from "./LongTextModal/LongTextModal"
import EditUserGroupModal, {
  EditUserGroupModalProps,
} from "./UserRoleModal/EditUserGroupModal"
import SearchFilterModal, {
  SearchFilterModalProps,
} from "./SearchFilterModal/SearchFilterModal"
import EditUserGroupMembershipModal, {
  EditUserGroupMembershipModalProps,
} from "./EditModal"
import DataConnectorAccountsModal, {
  DataConnectorAccountModalProps,
} from "./DataConnectorAccounts"
import ChooseContentSourceModal, {
  ChooseContentSourceModalProps,
} from "./ChooseContentSourceModal/ChooseContentSourceModal"
import ContentManagerConfigFormModal, {
  ContentManagerConfigFormModalProps,
} from "./ContentManagerConfigFormModal/ContentManagerConfigFormModal"
import EditSolutionModal, {
  EditSolutionModalProps,
} from "./EditSolutionModal/EditSolutionModal"
import SelectSolutionTypeModal, {
  SelectSolutionTypeModalProps,
} from "./EditSolutionModal/SelectSolutionTypeModal"
import ContentManagerUploadFormModal, {
  ContentManagerUploadFormModalProps,
} from "./ContentManagerManualFormModal/ContentManagerUploadFormModal"
import AddCommentModal, {
  AddCommentModalProps,
} from "./AddCommentModal/AddCommentModal"
import EditSearchSolutionPresetModal, {
  EditSearchSolutionPresetModalProps,
} from "./SearchFilterModal/EditSearchSolutionPresetModal"
import SelectContentTypeModal, {
  SelectContentTypeModalProps,
} from "./SelectContentTypeModal/SelectContentTypeModal"
import AddManufacturerModal, {
  AddManufacturerModalProps,
} from "./AddManufacturerModal/AddManufacturerModal"
import LinkPublicDataModal, {
  LinkPublicDataModalProps,
} from "./LinkPublicDataModal/LinkPublicDataModal"
import LinkContentModal, {
  LinkContentModalProps,
} from "./LinkContentModal/LinkContentModal"
import AddProductsModal, {
  AddProductsModalProps,
} from "./AddProductsModal/AddProductsModal"
import AddProductIdentifierModal, {
  AddProductIdentifierModalProps,
} from "./AddProductIdentifierModal/AddProductIdentifierModal"
import QueryAnswerModal, {
  QueryAnswerModalProps,
} from "./QueryAnswerModal/QueryAnswerModal"
import SolutionAccessPermissionModal, {
  SolutionAccessPermissionModalProps,
} from "./AccessPermissionModal/SolutionAccessPermissionModal"
import LinkProductsModal, {
  LinkProductsModalProps,
} from "./LinkProductsModal/LinkProductsModal"
import LinkProductRelationshipModal, {
  LinkProductRelationshipModalProps,
} from "./LinkProductRelationshipModal/LinkProductRelationshipModal"
import AddAttributeModal, {
  AddAttributeModalProps,
} from "./AddAttributeModal/AddAttributeModal"
import ManufacturerAccessPermissionModal, {
  ManufacturerAccessPermissionModalProps,
} from "./AccessPermissionModal/ManufacturerAccessPermissionModal"
import ReportContentModal, {
  ReportContentModalProps,
} from "./ReportContentModal/ReportContentModal"
import GenerateWorkbookReportModal, {
  GenerateWorkbookReportModalProps,
} from "./GenerateWorkbookReportModal/GenerateWorkbookReportModal"

export type ModalsFactoryProps = {
  [ModalsTypes.SUCCESS_MESSAGE_MODAL]: SuccessMessageModalProps
  [ModalsTypes.LOGOUT_MODAL]: LogoutModalProps
  [ModalsTypes.UPLOAD_FILES_MODAL]: UploadFilesModalProps
  [ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL]: AssignExpertToQuestionModalProps
  [ModalsTypes.CREATE_AVATAR_MODAL]: EditAvatarModalProps
  [ModalsTypes.EDIT_AVATAR_MEMBERSHIP_MODAL]: EditAvatarMembershipModalProps
  [ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL]: DeleteOptionModalProps
  [ModalsTypes.APP_FEEDBACK_MODAL]: FeedbackModalProps
  [ModalsTypes.ANSWER_FEEDBACK_MODAL]: AnswerFeedbackModalProps
  [ModalsTypes.REASSIGN_FILE_TO_DATA_TYPE_MODAL]: ReassignFilesToDataTypeModalProps
  [ModalsTypes.PRESELECT_DATA_TYPE_MODAL]: PreselectDataTypeModalProps
  [ModalsTypes.SELECT_DATA_CONNECTOR_ACCOUNT_MODAL]: SelectDataConnectorAccountModalProps
  [ModalsTypes.DATA_CONNECTOR_ACCOUNT_MODAL]: DataConnectorAccountModalProps
  [ModalsTypes.PRESELECT_DATA_TYPE_MODAL]: PreselectDataTypeModalProps
  [ModalsTypes.CHANGE_USER_PASSWORD_MODAL]: ResetUserPasswordModalProps
  [ModalsTypes.INVITE_USER_MODAL]: InviteUserModalProps
  [ModalsTypes.CONFIRM_USER_DELETE_MODAL]: ConfirmUserDeleteModalProps
  [ModalsTypes.EDIT_USER_ROLE_MODAL]: EditUserRoleModalProps
  [ModalsTypes.EDIT_USER_ACTION_MODAL]: EditUserActionModalProps
  [ModalsTypes.EDIT_USER_GROUP_MODAL]: EditUserGroupModalProps
  [ModalsTypes.EDIT_USER_GROUP_MEMBERSHIP_MODAL]: EditUserGroupMembershipModalProps
  [ModalsTypes.DELETE_USER_ROLE_MODAL]: DeleteUserRoleModalProps
  [ModalsTypes.DELETE_USER_ACTION_MODAL]: DeleteUserActionModalProps
  [ModalsTypes.ASSIGN_USER_ROLES_MODAL]: AssignUserRolesModalProps
  [ModalsTypes.EDIT_FEATURE_FLAG_MODAL]: EditFeatureFlagModalProps
  [ModalsTypes.DELETE_FEATURE_FLAG_MODAL]: DeleteFlagModalProps
  [ModalsTypes.DELETE_AVATAR_MODAL]: DeleteAvatarModalProps
  [ModalsTypes.DELETE_SYNONYM_MODAL]: DeleteSynonymModalProps
  [ModalsTypes.DELETE_ACRONYM_MODAL]: DeleteAcronymModalProps
  [ModalsTypes.CREATE_INSTANCE_CONFIG_MODAL]: CreateInstanceConfigModalProps
  [ModalsTypes.DELETE_INSTANCE_CONFIG_MODAL]: DeleteConfigModalProps
  [ModalsTypes.EDIT_EXPERT_TOPIC_MODAL]: EditTopicModalProps
  [ModalsTypes.EDIT_EXPERT_SUBTOPIC_MODAL]: EditSubtopicModalProps
  [ModalsTypes.EDIT_EXPERT_ARTICLE_MODAL]: EditArticleModalProps
  [ModalsTypes.CONFIRM_DELETE_MODAL]: ConfirmDeleteModalProps
  [ModalsTypes.DATATYPE_CREATE_MODAL]: DatatypeCreateModalProps
  [ModalsTypes.DATATYPE_DELETE_MODAL]: DatatypeDeleteModalProps
  [ModalsTypes.DATATYPE_ASSIGN_AVATAR_MODAL]: DatatypeAssignAvatarModalProps
  [ModalsTypes.CREATE_API_KEY_MODAL]: CreateApiKeyModalProps
  [ModalsTypes.DELETE_API_KEY_MODAL]: DeleteApiKeyModalProps
  [ModalsTypes.SHOW_API_KEY_MODAL]: ShowApiKeyModalProps
  [ModalsTypes.CONFIRM_MODAL]: ConfirmModalProps
  [ModalsTypes.SUBSCRIPTION_UPGRADE_MODAL]: SubscriptionUpgradeModalProps
  [ModalsTypes.PRODUCT_THEMES_MODAL]: ProductThemesModalProps
  [ModalsTypes.LONG_TEXT_MODAL]: LongTextModalProps
  [ModalsTypes.SEARCH_FILTERS_MODAL]: SearchFilterModalProps
  [ModalsTypes.CHOOSE_CONTENT_SOURCE_MODAL]: ChooseContentSourceModalProps
  [ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL]: ContentManagerConfigFormModalProps
  [ModalsTypes.SELECT_SOLUTION_TYPE_MODAL]: SelectSolutionTypeModalProps
  [ModalsTypes.EDIT_SOLUTION_MODAL]: EditSolutionModalProps
  [ModalsTypes.CONTENT_MANAGER_UPLOAD_FORM_MODAL]: ContentManagerUploadFormModalProps
  [ModalsTypes.EDIT_SEARCH_SOLUTION_PRESET_MODAL]: EditSearchSolutionPresetModalProps
  [ModalsTypes.ADD_COMMENT_MODAL]: AddCommentModalProps
  [ModalsTypes.SELECT_CONTENT_TYPE_MODAL]: SelectContentTypeModalProps
  [ModalsTypes.ADD_MANUFACTURER_MODAL]: AddManufacturerModalProps
  [ModalsTypes.LINK_PUBLIC_DATA_MODAL]: LinkPublicDataModalProps
  [ModalsTypes.LINK_CONTENT_MODAL]: LinkContentModalProps
  [ModalsTypes.ADD_PRODUCTS_MODAL]: AddProductsModalProps
  [ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL]: AddProductIdentifierModalProps
  [ModalsTypes.QUERY_ANSWER_MODAL]: QueryAnswerModalProps
  [ModalsTypes.SOLUTION_ACCESS_PERMISSIONS_MODAL]: SolutionAccessPermissionModalProps
  [ModalsTypes.MANUFACTURER_ACCESS_PERMISSIONS_MODAL]: ManufacturerAccessPermissionModalProps
  [ModalsTypes.LINK_PRODUCTS_MODAL]: LinkProductsModalProps
  [ModalsTypes.LINK_PRODUCT_RELATIONSHIP_MODAL]: LinkProductRelationshipModalProps
  [ModalsTypes.ADD_UPDATE_ATTRIBUTE_MODAL]: AddAttributeModalProps
  [ModalsTypes.REPORT_CONTENT_MODAL]: ReportContentModalProps
  [ModalsTypes.CREATE_WORKBOOK_REPORT_MODAL]: GenerateWorkbookReportModalProps
}

type ModalsFactoryType = {
  [key in ModalsTypes]: React.FC<ModalsFactoryProps[key]>
}

const ModalsFactory: ModalsFactoryType = {
  [ModalsTypes.SUCCESS_MESSAGE_MODAL]: (props) => (
    <SuccessMessageModal {...props} />
  ),
  [ModalsTypes.LOGOUT_MODAL]: (props) => <LogoutModal {...props} />,
  [ModalsTypes.UPLOAD_FILES_MODAL]: (props) => <UploadFilesModal {...props} />,
  [ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL]: (props) => (
    <AssignExpertToQuestionModal {...props} />
  ),
  [ModalsTypes.CREATE_AVATAR_MODAL]: (props) => <EditAvatarModal {...props} />,
  [ModalsTypes.EDIT_AVATAR_MEMBERSHIP_MODAL]: (props) => (
    <EditAvatarMembershipModal {...props} />
  ),
  [ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL]: (props) => (
    <DeleteOptionModal {...props} />
  ),
  [ModalsTypes.APP_FEEDBACK_MODAL]: (props) => <FeedbackModal {...props} />,
  [ModalsTypes.ANSWER_FEEDBACK_MODAL]: (props) => (
    <AnswerFeedbackModal {...props} />
  ),
  [ModalsTypes.REASSIGN_FILE_TO_DATA_TYPE_MODAL]: (props) => (
    <ReassignFilesToDataTypeModal {...props} />
  ),
  [ModalsTypes.PRESELECT_DATA_TYPE_MODAL]: (props) => (
    <PreselectDataTypeModal {...props} />
  ),
  [ModalsTypes.SELECT_DATA_CONNECTOR_ACCOUNT_MODAL]: (props) => (
    <SelectDataConnectorAccountModal {...props} />
  ),
  [ModalsTypes.DATA_CONNECTOR_ACCOUNT_MODAL]: (props) => (
    <DataConnectorAccountsModal {...props} />
  ),
  [ModalsTypes.CHANGE_USER_PASSWORD_MODAL]: (props) => (
    <ResetUserPasswordModal {...props} />
  ),
  [ModalsTypes.INVITE_USER_MODAL]: (props) => <InviteUserModal {...props} />,
  [ModalsTypes.CONFIRM_USER_DELETE_MODAL]: (props) => (
    <ConfirmUserDeleteModal {...props} />
  ),
  [ModalsTypes.EDIT_USER_ROLE_MODAL]: (props) => (
    <EditUserRoleModal {...props} />
  ),
  [ModalsTypes.EDIT_USER_ACTION_MODAL]: (props) => (
    <EditUserActionModal {...props} />
  ),
  [ModalsTypes.EDIT_USER_GROUP_MODAL]: (props) => (
    <EditUserGroupModal {...props} />
  ),
  [ModalsTypes.EDIT_USER_GROUP_MEMBERSHIP_MODAL]: (props) => (
    <EditUserGroupMembershipModal {...props} />
  ),
  [ModalsTypes.DELETE_USER_ROLE_MODAL]: (props) => (
    <DeleteUserRoleModal {...props} />
  ),
  [ModalsTypes.DELETE_USER_ACTION_MODAL]: (props) => (
    <DeleteUserActionModal {...props} />
  ),
  [ModalsTypes.ASSIGN_USER_ROLES_MODAL]: (props) => (
    <AssignUserRolesModal {...props} />
  ),
  [ModalsTypes.EDIT_FEATURE_FLAG_MODAL]: (props) => (
    <EditFeatureFlagModal {...props} />
  ),
  [ModalsTypes.DELETE_FEATURE_FLAG_MODAL]: (props) => (
    <DeleteFlagModal {...props} />
  ),
  [ModalsTypes.DELETE_AVATAR_MODAL]: (props) => (
    <DeleteAvatarModal {...props} />
  ),
  [ModalsTypes.DELETE_SYNONYM_MODAL]: (props) => (
    <DeleteSynonymModal {...props} />
  ),
  [ModalsTypes.DELETE_ACRONYM_MODAL]: (props) => (
    <DeleteAcronymModal {...props} />
  ),
  [ModalsTypes.CREATE_INSTANCE_CONFIG_MODAL]: (props) => (
    <CreateInstanceConfigModal {...props} />
  ),
  [ModalsTypes.DELETE_INSTANCE_CONFIG_MODAL]: (props) => (
    <DeleteConfigModal {...props} />
  ),
  [ModalsTypes.EDIT_EXPERT_TOPIC_MODAL]: (props) => (
    <EditTopicModal {...props} />
  ),
  [ModalsTypes.EDIT_EXPERT_SUBTOPIC_MODAL]: (props) => (
    <EditSubtopicModal {...props} />
  ),
  [ModalsTypes.EDIT_EXPERT_ARTICLE_MODAL]: (props) => (
    <EditArticleModal {...props} />
  ),
  [ModalsTypes.CONFIRM_DELETE_MODAL]: (props) => (
    <ConfirmDeleteModal {...props} />
  ),
  [ModalsTypes.DATATYPE_CREATE_MODAL]: (props) => (
    <DatatypeCreateModal {...props} />
  ),
  [ModalsTypes.DATATYPE_DELETE_MODAL]: (props) => (
    <DatatypeDeleteModal {...props} />
  ),
  [ModalsTypes.DATATYPE_ASSIGN_AVATAR_MODAL]: (props) => (
    <DatatypeAssignAvatarModal {...props} />
  ),
  [ModalsTypes.CREATE_API_KEY_MODAL]: (props) => (
    <CreateApiKeyModal {...props} />
  ),
  [ModalsTypes.DELETE_API_KEY_MODAL]: (props) => (
    <DeleteApiKeyModal {...props} />
  ),
  [ModalsTypes.SHOW_API_KEY_MODAL]: (props) => <ShowApiKeyModal {...props} />,
  [ModalsTypes.CONFIRM_MODAL]: (props) => <ConfirmModal {...props} />,

  [ModalsTypes.SUBSCRIPTION_UPGRADE_MODAL]: (props) => (
    <SubscriptionUpgradeModal {...props} />
  ),
  [ModalsTypes.PRODUCT_THEMES_MODAL]: (props) => (
    <ProductThemesModal {...props} />
  ),
  [ModalsTypes.LONG_TEXT_MODAL]: (props) => <LongTextModal {...props} />,
  [ModalsTypes.SEARCH_FILTERS_MODAL]: (props) => (
    <SearchFilterModal {...props} />
  ),
  [ModalsTypes.CHOOSE_CONTENT_SOURCE_MODAL]: (props) => (
    <ChooseContentSourceModal {...props} />
  ),
  [ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL]: (props) => (
    <ContentManagerConfigFormModal {...props} />
  ),
  [ModalsTypes.SELECT_SOLUTION_TYPE_MODAL]: (props) => (
    <SelectSolutionTypeModal {...props} />
  ),
  [ModalsTypes.EDIT_SOLUTION_MODAL]: (props) => (
    <EditSolutionModal {...props} />
  ),
  [ModalsTypes.EDIT_SEARCH_SOLUTION_PRESET_MODAL]: (props) => (
    <EditSearchSolutionPresetModal {...props} />
  ),
  [ModalsTypes.ADD_COMMENT_MODAL]: (props) => <AddCommentModal {...props} />,
  [ModalsTypes.CONTENT_MANAGER_UPLOAD_FORM_MODAL]: (props) => (
    <ContentManagerUploadFormModal {...props} />
  ),
  [ModalsTypes.SELECT_CONTENT_TYPE_MODAL]: (props) => (
    <SelectContentTypeModal {...props} />
  ),
  [ModalsTypes.ADD_MANUFACTURER_MODAL]: (props) => (
    <AddManufacturerModal {...props} />
  ),
  [ModalsTypes.LINK_PUBLIC_DATA_MODAL]: (props) => (
    <LinkPublicDataModal {...props} />
  ),
  [ModalsTypes.LINK_CONTENT_MODAL]: (props) => <LinkContentModal {...props} />,
  [ModalsTypes.ADD_PRODUCTS_MODAL]: (props) => <AddProductsModal {...props} />,
  [ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL]: (props) => (
    <AddProductIdentifierModal {...props} />
  ),
  [ModalsTypes.QUERY_ANSWER_MODAL]: (props) => <QueryAnswerModal {...props} />,
  [ModalsTypes.SOLUTION_ACCESS_PERMISSIONS_MODAL]: (props) => (
    <SolutionAccessPermissionModal {...props} />
  ),
  [ModalsTypes.MANUFACTURER_ACCESS_PERMISSIONS_MODAL]: (props) => (
    <ManufacturerAccessPermissionModal {...props} />
  ),
  [ModalsTypes.LINK_PRODUCTS_MODAL]: (props) => (
    <LinkProductsModal {...props} />
  ),
  [ModalsTypes.LINK_PRODUCT_RELATIONSHIP_MODAL]: (props) => (
    <LinkProductRelationshipModal {...props} />
  ),
  [ModalsTypes.ADD_UPDATE_ATTRIBUTE_MODAL]: (props) => (
    <AddAttributeModal {...props} />
  ),
  [ModalsTypes.REPORT_CONTENT_MODAL]: (props) => (
    <ReportContentModal {...props} />
  ),
  [ModalsTypes.CREATE_WORKBOOK_REPORT_MODAL]: (props) => (
    <GenerateWorkbookReportModal {...props} />
  ),
}

export default ModalsFactory
