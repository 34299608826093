import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { useNavigate } from "react-router-dom"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton, {
  IconButtonSize,
} from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"
import QuestionPreview from "@components/modals/AssignExpertToQuestionModal/QuestionPreview"
import { ExpertQuestion } from "@framework/types/question"

interface ContextMenuProps {
  question: ExpertQuestion
  buttonSize?: IconButtonSize
}

const ExpertQuestionContextMenu: React.FC<ContextMenuProps> = observer(
  ({ question, buttonSize = "medium" }) => {
    const alert = useAlert()
    const navigate = useNavigate()

    const { restrictionsStore: access, knowledgeStore } = useStore()

    const { isOpened, handleToggle, setOpened } = useToggle()

    const deleteModal = useModal(ModalsTypes.CONFIRM_DELETE_MODAL)

    const handleDelete = () =>
      deleteModal.showModal({
        message: "Are you sure you want to remove this question?",
        subMessage: <QuestionPreview questions={[question]} />,
        onSubmit: async () => {
          const error = await knowledgeStore.deleteExpertQuestion(question.id)

          if (error) {
            alert.error(error)
            return false
          }

          knowledgeStore.reloadExpertQuestions()
          navigate(mainRoutes.question(), { replace: true })

          alert.success("Question was removed successfully")
          return true
        },
      })

    const options = []

    if (access.canDeleteExpertQuestion)
      options.push(
        <MenuItem
          color="red"
          icon="trash-can"
          onClick={handleDelete}
          key="remove"
        >
          Remove
        </MenuItem>
      )

    if (!options.length) return null
    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            {options}
          </TooltipContainer>
        }
      >
        <IconButton size={buttonSize} active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ExpertQuestionContextMenu
