import React from "react"
import { observer } from "mobx-react-lite"

import { UserAndGroup } from "@framework/types/user"
import { useController, useStore } from "@store"
import useDebounce from "@components/hooks/useDebounce"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { SearchContext } from "@components/prototypes/SearchContext"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Skeleton from "@components/ui/Skeleton/Skeleton"

import EntityCard from "./EntityCard"

import styles from "./ParticipantList.module.sass"

export interface ManufacturerParticipantListProps {
  companyId: string
  loading?: boolean
  editable?: boolean
  isSelected: (value: UserAndGroup) => boolean
  onSelect: (value: UserAndGroup) => void
}

const ManufacturerParticipantList: React.FC<ManufacturerParticipantListProps> =
  observer(
    ({ companyId, loading = false, editable = true, onSelect, isSelected }) => {
      const {
        companyStore: { permissions },
      } = useStore()

      const { companyController } = useController()

      const searchContext = React.useContext(SearchContext)
      const query = searchContext?.query ?? ""

      const { debouncedValue: searchQuery, setDebounce } = useDebounce({
        value: query,
      })

      const loadMoreContent = () => {
        companyController.loadPermissions(companyId, {
          pageNum: permissions.meta.pageNum + 1,
          pageSize: 10,
          query: searchQuery,
        })
      }

      const hasMoreContent =
        (permissions.meta?.pageNum || 1) < (permissions.meta?.totalPages || 0)

      const lastItemRef = useInfiniteScroll({
        callback: loadMoreContent,
        isLoading: permissions.isLading,
        hasMore: hasMoreContent,
      })

      React.useEffect(() => {
        setDebounce(query)
      }, [query])

      React.useEffect(() => {
        companyController.loadPermissions(
          companyId,
          {
            pageNum: 1,
            pageSize: 10,
            query: searchQuery,
          },
          true
        )
      }, [searchQuery, permissions.reloadTrigger])

      const { entities } = permissions

      const isLoading = permissions.isLading || loading

      return (
        <div className={styles.root}>
          {!entities.length ? (
            isLoading ? (
              <Loader fluid size="large" />
            ) : searchQuery ? (
              <NotFound fluid>No user or group found by query</NotFound>
            ) : (
              <NotFound fluid>Nobody has access yet</NotFound>
            )
          ) : (
            <div className={styles.list}>
              {entities.map((entity) => {
                return (
                  <EntityCard
                    key={entity.id}
                    user={entity}
                    toRemove
                    {...(editable
                      ? {
                          isSelected: isSelected(entity),
                          onSelect,
                        }
                      : null)}
                  />
                )
              })}
              {hasMoreContent && (
                <div ref={lastItemRef}>
                  <Skeleton
                    count={permissions.meta.pageSize || 3}
                    lineHeight={56}
                    minWidth={100}
                    rounded
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )
    }
  )

export default ManufacturerParticipantList
