import React from "react"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import HeaderCell from "@components/ui/BaseTable/HeaderCell"

export const ManufacturerTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <HeaderCell as="th" name="name" key="name" width="auto">
        Manufacturer
      </HeaderCell>

      <HeaderCell
        as="th"
        name="productsCount"
        key="actions"
        width="auto"
        justify="center"
      >
        Products
      </HeaderCell>

      <HeaderCell
        as="th"
        name="contentCount"
        key="roles"
        width="auto"
        justify="center"
      >
        Content
      </HeaderCell>

      <HeaderCell as="th" key="groups" width="auto">
        Link to Public Data
      </HeaderCell>

      <HeaderCell as="th" key="created_date" width="auto" />
    </Row>
  )
})

export default ManufacturerTableHeader
