import { observer } from "mobx-react-lite"
import React from "react"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { ContextOption } from "@framework/types/common"
import { useController, useStore } from "@store"
import { DataCategoryEntity } from "@framework/types/knowledge"

interface ContextMenuProps {
  datatype: DataCategoryEntity
}

const enum DatatypeAction {
  EDIT = "edit",
  DELETE = "delete",
  MANAGE_ACCESS = "access",
}

const ContextMenu: React.FC<ContextMenuProps> = observer(({ datatype }) => {
  const { isOpened, setOpened, handleToggle } = useToggle()

  const deleteModal = useModal(ModalsTypes.DATATYPE_DELETE_MODAL)
  const editModal = useModal(ModalsTypes.DATATYPE_CREATE_MODAL)
  const assignModal = useModal(ModalsTypes.DATATYPE_ASSIGN_AVATAR_MODAL)

  const { restrictionsStore: access } = useStore()
  const { datatypesController } = useController()

  const handleContextAction = (type: DatatypeAction) => {
    switch (type) {
      case DatatypeAction.EDIT:
        editModal.showModal({
          datatypeId: datatype.id,
          onSubmit: datatypesController.requestDataTypesReload,
        })
        break
      case DatatypeAction.MANAGE_ACCESS:
        assignModal.showModal({
          datatypeId: datatype.id,
          onSubmit: datatypesController.requestDataTypesReload,
        })
        break
      case DatatypeAction.DELETE:
        deleteModal.showModal({
          datatypeId: datatype.id,
          onSubmit: datatypesController.requestDataTypesReload,
        })
        break
      default:
        break
    }
  }

  const contextOptions = React.useMemo(() => {
    const options: ContextOption<DatatypeAction>[] = []

    if (access.canEditContentType)
      options.push({ label: "Edit", type: DatatypeAction.EDIT, icon: "edit" })

    if (access.canEditContentType)
      options.push({
        label: "Manage Assigned Avatars",
        type: DatatypeAction.MANAGE_ACCESS,
        icon: "user",
      })

    if (access.canEditContentType)
      options.push({
        label: "Delete Content Type",
        type: DatatypeAction.DELETE,
        icon: "trash-can",
        color: "red",
      })

    return options
  }, [access.canEditContentType])

  if (!contextOptions.length) return null
  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer color="primary" placement="bottom">
          {contextOptions.map(({ label, type, ...rest }) => (
            <MenuItem
              key={type}
              onClick={() => handleContextAction(type)}
              {...rest}
            >
              {label}
            </MenuItem>
          ))}
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
