import React from "react"
import { observer } from "mobx-react-lite"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"

import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"

import FormalKnowledgeSidebar from "./FormalKnowledgeDetails"
import ExpertKnowledgeSidebar from "./ExpertKnowledgeDetails"
import ChatsSidebar from "./InformalKnowledgeDetails/ChatsSidebar"
import EmailsSidebar from "./InformalKnowledgeDetails/EmailsSidebar"
import MeetingsSidebar from "./InformalKnowledgeDetails/MeetingsSidebar"
import AvatarScopeContextProvider from "./AvatarScopeContext"
import ExpertInsightsSidebar from "./ExpertInsightsDetails"
import Home from "./HomeBeta"

const SearchRoot: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()

  const closeModal = () => navigate(location.state?.backTo ?? mainRoutes.home())

  const { userSetupStore, restrictionsStore: access } = useStore()

  return (
    <AvatarScopeContextProvider
      avatarId={userSetupStore.avatarId}
      onChange={userSetupStore.setAvatarId}
    >
      <Routes>
        <Route path="*" element={<Home />}>
          <Route
            path="data-source/:avatarId/:categoryId/*"
            element={<FormalKnowledgeSidebar open onClose={closeModal} />}
          />

          {access.isInformalKnowledgeSectionAvailable && (
            <Route
              path="emails"
              element={<EmailsSidebar open onClose={closeModal} />}
            />
          )}
          {access.isInformalKnowledgeSectionAvailable && (
            <Route
              path="chats"
              element={<ChatsSidebar open onClose={closeModal} />}
            />
          )}
          {access.isInformalKnowledgeSectionAvailable && (
            <Route
              path="meetings"
              element={<MeetingsSidebar open onClose={closeModal} />}
            />
          )}

          {access.isExpertKnowledgeSectionAvailable && (
            <Route
              path="expert-source/:avatarId/*"
              element={<ExpertKnowledgeSidebar open onClose={closeModal} />}
            />
          )}

          <Route
            path="expert-insights/:avatarId/*"
            element={<ExpertInsightsSidebar open onClose={closeModal} />}
          />

          <Route path="*/*" element={<Navigate to="/home" replace />} />
        </Route>

        <Route path="*/*" element={<Navigate to="/home" replace />} />
      </Routes>
    </AvatarScopeContextProvider>
  )
})

export default SearchRoot
