import React from "react"

const useEvent = <T extends (...args: any[]) => any>(handler: T) => {
  const handlerRef = React.useRef(handler)

  React.useLayoutEffect(() => {
    handlerRef.current = handler
  }, [handler])

  return React.useCallback(
    (...args: Parameters<T>) => {
      return handlerRef.current.apply(null, args)
    },
    [handlerRef]
  ) as T
}

export default useEvent
