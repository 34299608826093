import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"
import {
  AutoSizer,
  InfiniteLoader,
  List as VirtualList,
  ListRowProps,
} from "react-virtualized"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import {
  DataConnectorContentGroupName,
  DataConnectorSourceName,
} from "@framework/types/upload"
import { useStore } from "@store/index"
import BackButton from "@components/prototypes/BackButton"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import List from "@components/ui/List/List"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import mainRoutes from "@root/main.routes"
import Button from "@components/ui/Button/Button"

import { SelectDataTypeContextProvider } from "../PreselectDataTypeContext"
import CloudFoldersTableHeader from "./CloudFoldersTableHeader"
import DataConnectorPickerButton from "./DataConnectorPickerButton"
import ConnectDCExplorerEntitiesButton from "../DataSourceConnections/ConnectDCExplorerEntitiesButton"
import CloudFolderRow from "./CloudFolderEntityRow"
import CloudEntitiesControlBar from "./CloudEntitiesControlBar"
import CloudEntitiesExplorerControlHeader from "./CloudEntitiesExplorerControlHeader"
import CloudFileRow from "./CloudFileEntityRow"

import styles from "./DataConnectorExplorerPage.module.sass"

const BATCH_SIZE = 24

export interface DataConnectorExplorerPageProps {
  sourceName: DataConnectorContentGroupName
  connectorSourceName: DataConnectorSourceName
  backPath: string
}

const DataConnectorExplorerPage: React.FC<DataConnectorExplorerPageProps> =
  observer(({ sourceName, backPath, connectorSourceName }) => {
    const navigate = useNavigate()

    const { dataConnectorId } = useParams()

    if (dataConnectorId == null) throw new Error("dataConnectorId is required")

    const { restrictionsStore: access, uploadStore } = useStore()

    const sourceTypeDescription =
      getDataSourceNodeContextProps(connectorSourceName)

    const contentTypeDescription = getDataSourceNodeContextProps(sourceName)

    const store = uploadStore.getCloudEntitiesStore(
      connectorSourceName,
      dataConnectorId
    )

    const renderRow = ({ index, key, style }: ListRowProps) => {
      return (
        <div style={{ ...style, padding: 2 }} key={key}>
          <CheckboxCardItem
            index={index}
            dataConnectorId={dataConnectorId}
            connectorSourceName={connectorSourceName}
            sourceName={sourceName}
          />
        </div>
      )
    }

    React.useEffect(() => {
      store.resetPath()
    }, [dataConnectorId])

    const isReadonly = !access.canEditDataSource
    const collection = store.state

    return (
      <MainLayout>
        <div className={styles.root}>
          <section className={styles.header}>
            <EntityPageHeaderLayout
              left={
                <BackButton onClick={() => navigate(backPath)}>
                  {sourceTypeDescription.label}
                </BackButton>
              }
              right={
                <>
                  <Button
                    color="secondary"
                    size="big"
                    onClick={() => {
                      navigate(mainRoutes.contentTypeManager())
                    }}
                  >
                    Manage Content Types
                  </Button>
                  {isReadonly ? null : (
                    <SelectDataTypeContextProvider>
                      <ConnectDCExplorerEntitiesButton
                        dataConnectorId={dataConnectorId}
                        sourceName={sourceName}
                        connectorSourceName={connectorSourceName}
                      />
                    </SelectDataTypeContextProvider>
                  )}
                </>
              }
            >
              <DataConnectorPickerButton
                connectorName={connectorSourceName}
                dataConnectorId={dataConnectorId}
              />
            </EntityPageHeaderLayout>
          </section>

          <section className={styles.control}>
            <List gutter="32" overflow="initial">
              <CloudEntitiesExplorerControlHeader
                connectorSourceName={connectorSourceName}
                dataConnectorId={dataConnectorId}
              />

              <CloudEntitiesControlBar
                connectorSourceName={connectorSourceName}
                dataConnectorId={dataConnectorId}
              />
            </List>
          </section>

          <section className={styles.body}>
            <CloudFoldersTableHeader />

            <div className={styles.tableBody}>
              {collection.total ? (
                <InfiniteLoader
                  isRowLoaded={(it) => store.getByIndex(it.index) != null}
                  loadMoreRows={store.load}
                  rowCount={collection.total}
                  minimumBatchSize={BATCH_SIZE}
                  threshold={BATCH_SIZE}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <VirtualList
                          rowCount={collection.total}
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          rowHeight={60}
                          rowRenderer={renderRow}
                          height={height}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              ) : collection.isLoading ? (
                <Loader size="huge" fluid />
              ) : (
                <NotFound>
                  {store.state.errorMessage ||
                    `No ${contentTypeDescription.label}s found`}
                </NotFound>
              )}
            </div>
          </section>
        </div>
      </MainLayout>
    )
  })

export default DataConnectorExplorerPage

const CheckboxCardItem: React.FC<{
  index: number
  dataConnectorId: string
  connectorSourceName: DataConnectorSourceName
  sourceName: DataConnectorContentGroupName
}> = observer(({ index, dataConnectorId, connectorSourceName, sourceName }) => {
  const { uploadStore } = useStore()

  const store = uploadStore.getCloudEntitiesStore(
    connectorSourceName,
    dataConnectorId
  )

  const item = store.getByIndex(index)

  if (item?.type === "dir")
    return (
      <CloudFolderRow
        data={item}
        dataConnectorId={dataConnectorId}
        connectorSourceName={connectorSourceName}
        sourceName={sourceName}
      />
    )

  if (item?.type === "file")
    return (
      <CloudFileRow
        data={item}
        dataConnectorId={dataConnectorId}
        connectorSourceName={connectorSourceName}
        sourceName={sourceName}
      />
    )

  return <Skeleton lineHeight={56} minWidth={100} rounded />
})
