import React from "react"
import { Field, useFormikContext } from "formik"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import FilterButton from "@components/ui/Button/FilterButton"
import { useStore } from "@store"
import FormSwitch from "@components/prototypes/form-elements/FormSwitch"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"
import {
  SearchContext,
  useSearchContext,
} from "@components/prototypes/SearchContext"

import ProductFilterManufacturerField from "./ProductFilterManufacturerField"
import ProductFilterIdentifierField from "./ProductFilterIdentifierField"
import ProductFilterHeader from "../ProductList/ProductFilterHeader"

import styles from "./ProductFilterModal.module.sass"

interface ProductsFilterProps {}

interface ProductFilterButtonProps {
  onToggle: () => void
  onReset: () => void
}

const ProductFilterButton: React.FC<ProductFilterButtonProps> = observer(
  ({ onToggle, onReset }) => {
    const {
      productComparisonStore: {
        productStore: { activeFilter },
      },
    } = useStore()

    const filterCount =
      activeFilter.identifiers.length + activeFilter.manufacturers.length

    return (
      <FilterButton
        size="big"
        color="secondary"
        counter={filterCount}
        onOpen={onToggle}
        onClean={onReset}
      >
        Filter
      </FilterButton>
    )
  }
)

const options = [
  { name: "true", value: "Include" },
  { name: "false", value: "Exclude" },
]

const ProductFilterForm: React.FC<{ visible?: boolean }> = observer(
  ({ visible = false }) => {
    const form = useFormikContext()

    const searchContext = useSearchContext()

    const {
      productComparisonStore: {
        productStore: { activeFilter },
      },
    } = useStore()

    React.useEffect(() => {
      if (!visible) searchContext.setQuery("")
    }, [visible])

    React.useEffect(() => {
      form.setFieldValue("manufacturers", activeFilter.manufacturers)
      form.setFieldValue("identifiers", activeFilter.identifiers)
    }, [activeFilter])

    return (
      <>
        <FormSwitch items={options} name="includes" />

        <Field
          name="manufacturers"
          label="Manufacturer"
          valueKey="id"
          component={ProductFilterManufacturerField}
          searchQuery={searchContext.query}
        />

        <Field
          name="identifiers"
          label="Identifier"
          valueKey="id"
          component={ProductFilterIdentifierField}
          searchQuery={searchContext.query}
        />
      </>
    )
  }
)

const ProductsFilter: React.FC<ProductsFilterProps> = observer(() => {
  const {
    productComparisonStore: {
      productStore: { updateActiveFilter, activeFilter },
    },
  } = useStore()

  const [query, setQuery] = React.useState("")

  const searchContext = React.useMemo(() => ({ query, setQuery }), [query])

  return (
    <SearchContext.Provider value={searchContext}>
      <Templates.Header
        className={styles.filterHeader}
        left={
          activeFilter.identifiers.length +
          activeFilter.manufacturers.length ? (
            <ProductFilterHeader />
          ) : null
        }
        right={
          <FilterSidebar
            initialValue={{
              identifiers: [],
              manufacturers: [],
              searchQuery: "",
              includes: "true",
            }}
            defaultValue={{
              identifiers: [],
              manufacturers: [],
              searchQuery: "",
              includes: "true",
            }}
            onChange={(newValue) => {
              updateActiveFilter({
                manufacturers: toJS(newValue.manufacturers) || [],
                identifiers: toJS(newValue.identifiers) || [],
                includes: toJS(newValue.includes) === "true",
              })
            }}
            targetSlot={({ onToggle, onReset }) => (
              <ProductFilterButton onReset={onReset} onToggle={onToggle} />
            )}
            headerSlot={<SearchContextInput />}
          >
            {({ isOpen }) => <ProductFilterForm visible={isOpen} />}
          </FilterSidebar>
        }
      />
    </SearchContext.Provider>
  )
})

export default ProductsFilter
