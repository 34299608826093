import React from "react"

export const MaintenanceImage: React.FC = () => (
  <svg
    width="480"
    height="280"
    viewBox="0 0 480 280"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M407.791 277.805C407.791 277.805 479.858 245.819 478.133 181.184C476.408 116.549 408.189 86.5539 361.869 126.37C315.549 166.319 285.554 132.874 261.532 99.1624C237.509 65.4512 184.023 22.8476 113.68 42.6231C40.0199 63.1949 17.4572 137.784 24.7569 187.422C29.933 223.124 49.1776 253.385 75.191 276.744C75.9873 277.54 76.9164 278.203 77.8454 279L407.791 277.805Z"
      fill="var(--primary-105-color, #956DEF)"
      fillOpacity="0.2"
    />
    <path
      d="M272.283 256.437H212.027V271.169H272.283V256.437Z"
      fill="var(--primary-50-color, #502ba0)"
    />
    <path
      d="M283.563 278.866H200.613C198.489 278.866 196.764 277.14 196.764 275.017C196.764 272.893 198.489 271.168 200.613 271.168H283.563C285.687 271.168 287.412 272.893 287.412 275.017C287.412 277.14 285.687 278.866 283.563 278.866Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M377.531 86.9531C377.531 82.1751 373.682 78.3262 368.904 78.3262H115.406C110.628 78.3262 106.779 82.1751 106.779 86.9531V233.743H377.663V86.9531H377.531Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M365.984 90.6699H118.326V222.329H365.984V90.6699Z"
      fill="#EAEDFB"
    />
    <path
      d="M115.273 258.03H368.771C373.549 258.03 377.398 254.181 377.398 249.403V233.742H106.646V249.403C106.646 254.049 110.495 258.03 115.273 258.03Z"
      fill="white"
    />
    <path
      d="M247.198 245.822C247.198 248.609 244.942 250.998 242.022 250.998C239.235 250.998 236.846 248.741 236.846 245.822C236.846 243.034 239.102 240.646 242.022 240.646C244.942 240.646 247.198 243.034 247.198 245.822Z"
      fill="var(--primary-50-color, #502ba0)"
    />
    <g opacity="0.57">
      <path
        opacity="0.57"
        d="M187.076 90.6699L130.537 222.462H150.445L207.648 90.6699H187.076Z"
        fill="white"
      />
      <path
        opacity="0.57"
        d="M331.211 90.6709L274.672 222.463H294.713L351.916 90.6709H331.211Z"
        fill="white"
      />
      <path
        opacity="0.57"
        d="M233.131 90.6709L176.592 222.463H235.786L292.988 90.6709H233.131Z"
        fill="white"
      />
    </g>
    <path d="M240.961 118.274H135.846V192.731H240.961V118.274Z" fill="white" />
    <path
      d="M240.961 118.274H135.846V192.731H240.961V118.274Z"
      stroke="#A2C9F8"
      strokeWidth="3.0138"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M253.039 123.848H349.129"
      stroke="white"
      strokeWidth="2.8055"
      strokeMiterlimit="10"
    />
    <path
      d="M253.039 146.278H312.1"
      stroke="white"
      strokeWidth="2.8055"
      strokeMiterlimit="10"
    />
    <path
      d="M253.039 134.997H270.027"
      stroke="white"
      strokeWidth="2.8055"
      strokeMiterlimit="10"
    />
    <path
      d="M253.039 160.877H349.129"
      stroke="white"
      strokeWidth="2.8055"
      strokeMiterlimit="10"
    />
    <path
      d="M253.039 183.307H349.129"
      stroke="white"
      strokeWidth="2.8055"
      strokeMiterlimit="10"
    />
    <path
      d="M253.039 172.025H309.711"
      stroke="white"
      strokeWidth="2.8055"
      strokeMiterlimit="10"
    />
    <path
      d="M209.136 159.11C211.413 146.561 203.085 134.542 190.536 132.266C177.988 129.989 165.969 138.316 163.693 150.865C161.416 163.414 169.743 175.432 182.292 177.709C194.841 179.986 206.859 171.658 209.136 159.11Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M174.6 155.037H197.295"
      stroke="white"
      strokeWidth="4.2082"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M393.721 199.366L398.631 272.761H406.595L408.187 199.366H393.721Z"
      fill="#1B1754"
    />
    <path
      d="M392.129 278.735H406.463V272.099H398.765C395.181 272.099 392.129 275.019 392.129 278.735Z"
      fill="#243051"
    />
    <path
      d="M409.117 278.735H424.115L423.451 272.099H415.753C412.17 272.099 409.117 275.019 409.117 278.735Z"
      fill="#243051"
    />
    <path
      d="M418.54 199.366L423.583 272.098L415.753 272.363L402.879 199.366H418.54Z"
      fill="#2A2B7B"
    />
    <path
      d="M405.004 161.539L425.443 179.855C427.168 181.314 429.69 181.447 431.415 179.987C433.406 178.395 433.672 175.342 431.946 173.351C431.946 173.351 422.125 160.079 416.551 155.699L405.004 161.539Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M436.591 206.002C436.591 208.657 434.335 210.913 431.68 210.913C429.026 210.913 426.77 208.657 426.77 206.002C426.77 203.348 429.026 201.092 431.68 201.092C434.467 201.092 436.591 203.348 436.591 206.002Z"
      fill="#F89A6E"
    />
    <path
      d="M425.443 177.865L428.098 202.285H435.132L433.539 177.068C433.407 174.679 431.15 172.954 428.894 173.352C426.638 173.618 425.178 175.608 425.443 177.865Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M403.012 152.38C403.012 152.38 392.925 152.646 392.394 162.732C391.864 172.819 393.589 199.496 393.589 199.496H418.54L419.204 166.847C420.797 158.087 416.284 152.646 407.392 152.247C405.932 152.115 404.472 152.247 403.012 152.38Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M418.939 174.014L414.691 170.165L418.806 181.977L418.939 174.014Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M369.036 143.622C369.036 146.276 366.78 148.532 364.125 148.532C361.471 148.532 359.215 146.276 359.215 143.622C359.215 140.967 361.471 138.711 364.125 138.711C366.78 138.711 369.036 140.967 369.036 143.622Z"
      fill="#F89A6E"
    />
    <path
      d="M379.521 170.432L368.904 146.409L362.135 148.533L371.691 172.821C372.354 175.077 374.876 176.404 377 175.475C379.256 174.679 380.318 172.423 379.521 170.432Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M399.031 153.177C385.759 158.618 373.947 167.112 373.947 167.112C371.956 168.174 371.027 170.563 371.823 172.687C372.753 175.076 375.407 176.403 377.796 175.474L397.97 165.653L399.031 153.177Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M392.263 168.438L392.395 162.731L387.883 170.562L392.263 168.438Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M400.094 146.677L400.359 153.445C400.492 154.905 401.288 156.232 402.748 156.763C405.403 157.825 408.057 155.702 407.792 152.914L407.393 146.278L400.094 146.677Z"
      fill="#F89A6E"
    />
    <path
      d="M400.226 151.453C400.226 151.453 406.464 152.25 407.393 146.144C408.322 140.039 401.421 142.561 401.421 142.561L400.094 147.604L400.226 151.453Z"
      fill="#E88057"
    />
    <path
      d="M395.714 130.616C391.6 135.128 391.865 142.03 396.245 146.144C400.757 150.259 407.659 149.993 411.773 145.613C415.888 141.101 415.622 134.199 411.242 130.085C406.73 125.971 399.828 126.236 395.714 130.616Z"
      fill="#F89A6E"
    />
    <path
      d="M406.996 141.5C406.996 141.5 402.616 149.33 408.722 151.454C414.827 153.577 416.552 136.589 416.552 136.589L409.916 123.582L393.857 122.521L392.928 128.095C392.928 128.095 394.52 133.536 407.925 138.58L406.996 141.5Z"
      fill="#243051"
    />
    <path
      d="M403.678 135.262L405.536 141.632L410.446 138.845L403.678 135.262Z"
      fill="#243051"
    />
    <path
      d="M405.933 139.243C404.871 140.438 404.871 142.296 406.066 143.358C407.26 144.419 409.118 144.419 410.18 143.225C411.242 142.03 411.242 140.172 410.047 139.111C408.853 138.049 406.995 138.049 405.933 139.243Z"
      fill="#F89A6E"
    />
    <path
      d="M392.528 134.598C392.528 134.598 391.997 126.767 398.633 122.653C404.074 119.335 411.507 121.06 414.427 126.767C415.887 129.421 416.683 132.076 415.754 137.119L392.528 134.598Z"
      fill="#FDD63B"
    />
    <path
      d="M415.356 138.315C415.356 138.315 415.223 138.315 415.356 138.315L388.812 135.528C388.148 135.395 387.75 134.864 387.75 134.2C387.883 133.537 388.414 133.139 389.077 133.139L415.489 135.926C416.152 136.059 416.55 136.589 416.55 137.253C416.418 137.917 415.887 138.315 415.356 138.315Z"
      fill="#F2A62F"
    />
    <path
      d="M408.34 41.5799C410.942 30.5206 404.086 19.4462 393.027 16.8444C381.967 14.2426 370.893 21.0987 368.291 32.158C365.689 43.2173 372.545 54.2918 383.605 56.8936C394.664 59.4953 405.738 52.6392 408.34 41.5799Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M391.334 45.6746C391.334 49.6563 385.361 49.6563 385.361 45.6746C385.361 41.693 391.334 41.693 391.334 45.6746ZM390.936 25.501L390.803 40.3658H385.76C385.76 34.7915 385.627 31.208 385.627 25.501H390.936Z"
      fill="white"
    />
    <path
      d="M257.152 0.152344L258.877 4.53215C259.939 7.18658 262.063 9.31011 264.717 10.3719L269.097 12.0973L264.717 13.8226C262.063 14.8844 259.939 17.0079 258.877 19.6624L257.152 24.0422L255.427 19.6624C254.365 17.0079 252.241 14.8844 249.587 13.8226L245.207 12.0973L249.587 10.3719C252.241 9.31011 254.365 7.18658 255.427 4.53215L257.152 0.152344Z"
      fill="#EAEDFB"
    />
    <path
      d="M304.268 37.9775L305.462 40.8974C306.126 42.7555 307.586 44.0827 309.444 44.879L312.364 46.0735L309.444 47.268C307.586 47.9316 306.259 49.3916 305.462 51.2497L304.268 54.1695L303.073 51.2497C302.41 49.3916 300.95 48.0644 299.092 47.268L296.172 46.0735L299.092 44.879C300.95 44.2154 302.277 42.7555 303.073 40.8974L304.268 37.9775Z"
      fill="#EAEDFB"
    />
    <path
      d="M18.6518 63.1953L19.5808 65.5843C20.1117 67.0442 21.3062 68.2387 22.7661 68.7696L25.1552 69.6987L22.7661 70.6277C21.3062 71.1586 20.1117 72.3531 19.5808 73.813L18.6518 76.202L17.7227 73.813C17.1919 72.3531 15.9974 71.1586 14.5374 70.6277L12.1484 69.6987L14.5374 68.7696C15.9974 68.2387 17.1919 67.0442 17.7227 65.5843L18.6518 63.1953Z"
      fill="#EAEDFB"
    />
    <path
      d="M454.376 75.2725L455.305 77.6614C455.836 79.1214 457.031 80.3159 458.491 80.8468L460.88 81.7758L458.491 82.7048C457.031 83.2357 455.836 84.4302 455.305 85.8902L454.376 88.2792L453.447 85.8902C452.916 84.4302 451.722 83.2357 450.262 82.7048L447.873 81.7758L450.262 80.8468C451.722 80.3159 452.916 79.1214 453.447 77.6614L454.376 75.2725Z"
      fill="#EAEDFB"
    />
    <path
      d="M122.439 26.4309L127.217 31.2089C128.279 32.2707 129.739 32.6688 131.199 32.1379C133.853 31.2089 134.517 27.7581 132.394 25.9L127.616 21.1221L122.439 26.4309Z"
      fill="#F89A6E"
    />
    <path
      d="M125.758 29.8821C125.758 29.8821 128.678 23.7769 127.484 21.1225C124.962 15.4155 120.715 22.4497 120.715 22.4497L123.104 27.0949L125.758 29.8821Z"
      fill="#E88057"
    />
    <path
      d="M108.505 17.5402C108.372 23.6454 113.283 28.5561 119.255 28.6888C125.36 28.8215 130.271 23.9109 130.404 17.9384C130.536 11.8332 125.626 6.92253 119.653 6.78981C113.681 6.65709 108.637 11.4351 108.505 17.5402Z"
      fill="#F89A6E"
    />
    <path
      d="M124.166 18.0706C124.166 18.0706 126.157 26.8302 132.13 24.3085C138.102 21.7868 128.148 7.98376 128.148 7.98376L114.61 2.80762L101.869 12.7617L103.329 21.3886C103.329 21.3886 107.842 18.2033 121.379 12.8944L124.166 18.0706Z"
      fill="#243051"
    />
    <path
      d="M117.531 15.6833L123.238 19.134L124.964 13.8252L117.531 15.6833Z"
      fill="#243051"
    />
    <path
      d="M124.698 20.0598C124.698 20.0598 134.121 45.9404 145.933 34.1282C156.418 23.6432 140.624 17.2726 136.244 16.2108C132.926 15.4145 131.732 14.22 127.617 10.3711L119.389 11.5656L124.698 20.0598Z"
      fill="#243051"
    />
    <path
      d="M121.91 17.1406C121.91 18.7332 123.237 20.0604 124.83 20.1932C126.423 20.1932 127.75 18.866 127.883 17.2733C127.883 15.6806 126.555 14.3534 124.963 14.2207C123.237 14.2207 121.91 15.5479 121.91 17.1406Z"
      fill="#F89A6E"
    />
    <path
      d="M107.442 19.2644C107.442 19.2644 103.062 12.6283 106.911 5.85954C109.964 0.28524 117.263 -1.70558 122.705 1.61246C125.227 3.20511 127.218 5.0632 128.943 10.1066L107.442 19.2644Z"
      fill="#FDD63B"
    />
    <path
      d="M129.209 11.1686C129.076 11.3013 129.076 11.3013 129.209 11.1686L104.788 21.6536C104.257 21.919 103.594 21.6536 103.328 20.99C103.063 20.4591 103.328 19.7955 103.992 19.5301L128.413 9.04508C128.943 8.77964 129.607 9.04508 129.873 9.70868C129.873 10.3723 129.74 10.9032 129.209 11.1686Z"
      fill="#F2A62F"
    />
    <path
      d="M106.913 80.5808L82.6246 105.532L76.3867 102.745L97.3567 72.883C99.4803 69.4322 104.258 69.2995 106.647 72.4848C108.24 74.4756 108.24 78.7227 106.913 80.5808Z"
      fill="#1B1754"
    />
    <path
      d="M67.0957 102.613L79.3061 111.24L82.6241 105.4L76.2535 101.02C73.4664 98.8965 69.2192 99.6929 67.0957 102.613Z"
      fill="#243051"
    />
    <path
      d="M123.766 65.8496L99.2129 70.2294L104.256 79.7853H137.171C137.171 79.7853 147.125 79.5199 142.48 68.6368L123.766 65.8496Z"
      fill="#2A2B7B"
    />
    <path
      d="M108.239 73.2832L119.918 112.038L113.282 115.754L94.8338 78.3266C92.9757 74.8759 95.232 70.4961 99.2136 70.0979C101.603 69.9652 107.177 71.1597 108.239 73.2832Z"
      fill="#2A2B7B"
    />
    <path
      d="M110.363 124.647L122.573 118.143L119.919 112.038L113.15 115.622C109.832 117.347 108.637 121.329 110.363 124.647Z"
      fill="#243051"
    />
    <path
      d="M122.308 36.6514C122.308 36.6514 123.237 32.2715 124.564 30.9443C125.493 30.0153 128.678 26.9627 130.404 26.2991C130.935 26.1664 131.466 26.1664 131.996 26.4318C134.12 27.759 139.96 32.0061 142.747 40.5003C145.534 48.729 146.065 72.3534 146.065 72.3534C138.234 77.3968 123.635 65.7173 123.635 65.7173C124.166 51.118 120.45 50.4544 122.308 36.6514Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M120.715 66.3806L132.262 66.9115C136.243 67.1769 137.969 64.2571 138.101 61.7353L138.632 41.8271L120.715 66.3806Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M110.363 63.4596L103.594 61.0706C103.063 60.9379 102.4 60.9379 102.001 61.336L98.9488 63.8577C98.0198 64.5213 98.2853 65.9813 99.347 66.3794C99.8779 66.6449 100.541 66.5121 100.94 66.114L102.4 64.7868C102.4 64.7868 107.443 67.7066 109.699 66.9103L110.363 63.4596Z"
      fill="#F97D7D"
    />
    <path
      d="M127.748 33.2002L126.554 55.4974L108.371 60.2753L109.167 67.0441L131.332 64.7879C132.925 64.6551 134.384 63.9915 135.446 62.797C135.977 62.1334 136.375 61.3371 136.375 60.4081L139.162 37.1818L127.748 33.2002Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M97.2242 72.3521L122.972 67.8396C123.768 67.7069 124.299 66.9106 124.167 66.1142C124.034 65.3179 123.238 64.787 122.441 64.9197L96.6933 69.4323C95.897 69.565 95.3661 70.3613 95.4988 71.1576C95.6316 72.0867 96.4279 72.4849 97.2242 72.3521Z"
      fill="#243051"
    />
    <path
      d="M97.3562 71.0254C97.2235 71.0254 97.0907 71.0253 96.958 70.8926L81.4296 53.3734C81.2969 53.108 81.2969 52.8425 81.4296 52.5771C81.6951 52.3116 81.9605 52.4444 82.226 52.5771L97.7543 70.0963C97.887 70.3617 97.887 70.6272 97.7543 70.8926C97.6216 71.0253 97.4889 71.0254 97.3562 71.0254Z"
      fill="#243051"
    />
    <path
      d="M127.617 26.1645C127.617 26.1645 134.917 43.8164 145.004 35.0568C155.091 26.2972 133.988 21.1211 133.988 21.1211L127.617 26.1645Z"
      fill="#243051"
    />
    <path
      d="M327.891 275.813L341.03 241.969C341.694 240.376 343.95 240.376 344.614 241.969L357.753 275.813H327.891Z"
      fill="#F2A62F"
    />
    <path d="M354.17 249.269H330.943V255.772H354.17V249.269Z" fill="#FAFAFA" />
    <path
      d="M355.894 261.479H329.615V267.982H355.894V261.479Z"
      fill="#FAFAFA"
    />
    <path
      d="M359.612 278.733H325.901C324.573 278.733 323.512 277.671 323.512 276.344C323.512 275.017 324.573 273.955 325.901 273.955H359.612C360.939 273.955 362.001 275.017 362.001 276.344C362.001 277.671 360.939 278.733 359.612 278.733Z"
      fill="#EB9924"
    />
    <path
      d="M80.1004 236.793H69.8809V265.726H80.1004V236.793Z"
      fill="#8BBDF8"
    />
    <path
      d="M134.118 236.793H123.898V265.726H134.118V236.793Z"
      fill="#8BBDF8"
    />
    <path
      d="M139.825 278.733H63.6434C61.1217 278.733 59.1309 276.742 59.1309 274.221V268.513C59.1309 265.992 61.1217 264.001 63.6434 264.001H139.825C142.347 264.001 144.338 265.992 144.338 268.513V274.221C144.338 276.742 142.347 278.733 139.825 278.733Z"
      fill="var(--primary-100-color, #8854F7)"
    />
    <path
      d="M138.498 239.049H66.0325C62.3163 239.049 59.2637 235.996 59.2637 232.28V219.804C59.2637 216.088 62.3163 213.035 66.0325 213.035H138.498C142.215 213.035 145.267 216.088 145.267 219.804V232.28C145.267 235.996 142.215 239.049 138.498 239.049Z"
      fill="#F2A62F"
    />
    <path
      d="M58.0694 248.34H42.541L78.774 208.125H94.3023L58.0694 248.34Z"
      fill="white"
    />
    <path
      d="M121.112 248.341H105.584L141.817 208.126H157.345L121.112 248.341Z"
      fill="white"
    />
    <path
      d="M88.9932 248.341H73.4648L109.565 208.126H125.093L88.9932 248.341Z"
      fill="white"
    />
    <path
      d="M1 278.999H479.062"
      stroke="#102236"
      strokeWidth="0.6539"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default React.memo(MaintenanceImage)
