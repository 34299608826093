import React, { HTMLAttributes } from "react"
import clsx from "clsx"

import colorStyles from "./TextColor.module.sass"
import styles from "./Text.module.scss"

export const textColors = [
  "text100Color",
  "text70Color",
  "text50Color",
  "text40Color",
  "primaryColor",
  "greenColor",
  "redColor",
  "goldColor",
  "inherit",
] as const

export const textVariants = [
  "title",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "body1",
  "body2",
  "body3",
  "caption1",
  "caption2",
  "caption3",
  "inherit",
] as const

export const elementNames = [
  "div",
  "span",
  "label",
  "p",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
] as const

export const textWeights = ["normal", "semibold", "bold"] as const

export const textAlign = ["start", "end", "center"] as const

type TextColors = (typeof textColors)[number]

type TextVariant = (typeof textVariants)[number]

type ElementName = (typeof elementNames)[number]

type TextWeight = (typeof textWeights)[number]

type TextAlign = (typeof textAlign)[number]

export interface TextProps extends HTMLAttributes<HTMLElement> {
  inline?: boolean
  weight?: TextWeight
  color?: TextColors
  upperCase?: boolean
  variant?: TextVariant
  align?: TextAlign
  as?: ElementName
}

export const Text: React.FC<TextProps> = ({
  color = "text100Color",
  variant = "body1",
  as: Component = getDefaultComponent(variant),
  weight = "normal",
  inline = false,
  upperCase = false,
  align = "start",
  className,
  children,
  ...rest
}: TextProps) => (
  <Component
    className={clsx(
      styles.root,
      colorStyles[color],
      styles[weight],
      styles[variant],
      styles[align],
      {
        [styles.upperCase]: upperCase,
        [styles.inline]: inline,
      },
      className
    )}
    {...rest}
  >
    {children}
  </Component>
)

export default Text

const getDefaultComponent = (variant: TextVariant): ElementName => {
  if (variant.charCodeAt(0) === 104) return variant as ElementName
  if (variant.charCodeAt(0) === 98) return "p"
  return "div"
}
