import React from "react"
import get from "lodash/get"
import { observer } from "mobx-react-lite"

import { APIUsageSummaryData } from "@framework/types/analytics"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { renderDuration } from "@utils/date"
import TrendCard from "@components/ui/TrendCard/TrendCard"
import { useStore } from "@store/index"
import Info from "@components/ui/Tooltip/Info"

import styles from "./OverallReport.module.sass"

const TITLE = "Overview"
const DESCRIPTION =
  "Overview of all time total API calls, total current month API calls, error rate and average response time."

const trendWidgetOptions: {
  title: string
  name: string
  description: string
  valueFieldName: keyof APIUsageSummaryData
}[] = [
  {
    name: "totalAPICalls",
    title: "Total API Calls",
    valueFieldName: "totalAPICount",
    description: "All time total API calls",
  },
  {
    name: "currentAPICalls",
    title: "Current Month API Calls",
    valueFieldName: "currentMonthAPICount",
    description: "Total API calls in current month",
  },
  {
    name: "errorRate",
    title: "Error Rate",
    valueFieldName: "errorRate",
    description: "Percentage of error rate",
  },
  {
    name: "responseTime",
    title: "Average Response Time",
    valueFieldName: "averageResponseTimeInMs",
    description: "Average response time of APIs",
  },
]

export const OverallReport: React.FC = observer(() => {
  const { apiUsageAnalyticsStore } = useStore()

  const { data, isLoading } = apiUsageAnalyticsStore.summaryStore.state

  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Text>
            {TITLE} <Info>{DESCRIPTION}</Info>
          </Text>
        }
      />
      <div className={styles.content}>
        {trendWidgetOptions.map((it) => (
          <TrendCard
            title={it.title}
            description={it.description}
            totalAmount={get(data, it.valueFieldName)}
            loading={isLoading}
            renderAmountValue={(value) => {
              if (it.name === "errorRate") return `${Number(value).toFixed(2)}%`
              if (it.name === "responseTime")
                return renderDuration(value, "ms") || "0"
              return value
            }}
            key={it.title}
          />
        ))}
      </div>
    </section>
  )
})

export default OverallReport
