import React from "react"
import { Editable, RenderElementProps } from "slate-react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { renderElement, renderLeaf } from "./utils"

import styles from "./MarkdownEditor.module.sass"

export interface ViewerProps {
  className?: string
  customElement?: (props: RenderElementProps) => JSX.Element | null
}

export const Viewer: React.FC<ViewerProps> = observer(
  ({ className, customElement }) => {
    const handleRenderElement = React.useCallback(
      (props: RenderElementProps) => {
        return customElement?.(props) ?? renderElement(props, true)
      },
      [customElement]
    )

    const handleRenderLeaf = React.useCallback(
      (props) => renderLeaf(props, true),
      []
    )

    return (
      <div className={clsx(styles.root, styles.readonly, className)}>
        <div className={clsx(styles.container, styles.noneSelectable)}>
          <Editable
            className={styles.editor}
            renderElement={handleRenderElement}
            renderLeaf={handleRenderLeaf}
            readOnly
          />
        </div>
      </div>
    )
  }
)

export default Viewer
