import React from "react"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Table from "@components/ui/BaseTable/Table"
import Row from "@components/ui/BaseTable/Row"
import Column from "@components/ui/BaseTable/Column"
import {
  ListReport,
  SectionDetails,
  ReportData,
  TableDetails,
  SubSection,
} from "@framework/types/workbook-report"
import Markdown from "@components/ui/Typography/Markdown"

import styles from "./Report.module.sass"

export type ReportProps = {
  data: ReportData
}

export const Report: React.FC<ReportProps> = ({ data }) => (
  <div className={styles.root}>
    <div className={styles.headerContainer}>
      <ReportHeader data={data} />
    </div>

    <div className={styles.body}>
      <div className={styles.menuContainer}>
        <ReportMenu data={data} />
      </div>

      <div className={styles.contentContainer}>
        <ReportContent data={data} />
      </div>
    </div>
  </div>
)

export const InlineReport: React.FC<ReportProps> = ({ data }) => (
  <div className={styles.root}>
    <ReportMenu data={data} size="slim" />

    <div className={styles.box}>
      <ReportContent data={data} />
    </div>
  </div>
)

const ReportContent: React.FC<ReportProps> = ({ data }) => {
  return (
    <div className={styles.content}>
      {data.sections.map((section, sectionIndex) => {
        return (
          <section
            id={section.name}
            className={styles.section}
            key={section.name}
          >
            <Text variant="h1" weight="bold">
              {1 + sectionIndex}. {section.name}
            </Text>

            {section.type === "list" && <ListBock content={section} />}

            {section.type === "table" &&
              section.details?.map((it) => {
                return <TableBock content={it} />
              })}

            {section.type === "section" && (
              <SectionBock content={section.details} />
            )}
          </section>
        )
      })}
    </div>
  )
}

type MenuProps = ReportProps & {
  size?: "default" | "slim"
}

const ReportMenu: React.FC<MenuProps> = ({ data, size = "default" }) => {
  return (
    <div className={clsx(styles.menu, styles[size])}>
      <Text
        className={styles.title}
        variant={size === "slim" ? "h3" : "h2"}
        weight="bold"
      >
        Sections
      </Text>
      <ol>
        {data.sections.map((section) => {
          return (
            <li className={styles.menuItem} key={section.name}>
              <Text variant="h5">
                <a href={`#${section.name}`}>{section.name}</a>
              </Text>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

const ReportHeader: React.FC<ReportProps> = ({ data }) => {
  return (
    <div className={styles.header}>
      <Text variant="title" weight="bold" className={styles.title}>
        {data.title}
      </Text>

      <Text variant="h4">{data.subtitle}</Text>

      <Text variant="body1" color="inherit">
        Last Updated Date:{" "}
        <Text variant="inherit" inline>
          {data.last_updated}
        </Text>
      </Text>
    </div>
  )
}

const ListBock: React.FC<{ content: ListReport }> = ({ content }) => {
  const isSimple = content.details[0].subsections == null
  return (
    <div className={styles.block}>
      <Text variant="body2" color="inherit">
        {isSimple ? (
          <div className={styles.subsection}>
            {content.name && (
              <Text variant="h3" weight="bold">
                {content.name}
              </Text>
            )}
            <ul className={styles.textBlock}>
              {content.details?.map((it) => {
                return <li key={it.label}>{it.label}</li>
              })}
            </ul>
          </div>
        ) : (
          <div>
            {content.details.map((it) => (
              <div key={it.label} className={styles.subsection}>
                <Text variant="h2" weight="bold">
                  {it.label}
                </Text>

                <div className={styles.list}>
                  {it.subsections?.map((it) => {
                    return <SubSectionBock content={it} />
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
      </Text>
    </div>
  )
}

const SectionBock: React.FC<{ content: SectionDetails[] | string }> = ({
  content,
}) => {
  if (typeof content === "string") return <Text variant="body2">{content}</Text>
  return (
    <>
      {content.map((it) => {
        if ("type" in it) {
          if (it.type === "list") {
            return <ListBock content={it} />
          }
        } else {
          return (
            <div className={styles.block}>
              <Text variant="body2" color="text100Color" weight="bold">
                {it.label}:
              </Text>
              <Text
                variant="body2"
                color="inherit"
                className={styles.textBlock}
              >
                <Markdown>{it.value.toString()}</Markdown>
              </Text>
              <AnalysisBock content={it.analysis} />
            </div>
          )
        }

        return null
      })}
    </>
  )
}

const SubSectionBock: React.FC<{ content: SubSection }> = ({ content }) => {
  return (
    <div className={styles.block}>
      <Text variant="body2" color="text100Color" weight="bold">
        {content.name}:
      </Text>{" "}
      <Text variant="body2" color="inherit" className={styles.textBlock}>
        {content.value.toString()}
      </Text>
    </div>
  )
}

const TableBock: React.FC<{ content: TableDetails }> = ({ content }) => {
  return (
    <div className={styles.block}>
      <Table
        className={styles.table}
        header={
          <Row>
            {content.headers?.map((col) => (
              <Column as="th" key={col}>
                {col}
              </Column>
            ))}
          </Row>
        }
      >
        {content.rows?.map((row) => (
          <Row>
            {row.map((col) => (
              <Column as="td" key={col}>
                {col}
              </Column>
            ))}
          </Row>
        ))}
      </Table>

      <AnalysisBock content={content.analysis} />
    </div>
  )
}

const AnalysisBock: React.FC<{ content: string | undefined | null }> = ({
  content,
}) => {
  if (!content) return null
  return (
    <Text variant="inherit" color="inherit">
      <Text variant="h5" weight="semibold">
        Analysis:
      </Text>{" "}
      <Text variant="body2" color="inherit">
        {content}
      </Text>
    </Text>
  )
}

export default Report
