import { makeAutoObservable } from "mobx"

import { Entity, ErrorMessage } from "@framework/types/common"
import { WorkbookReportTypeName } from "@framework/types/workbook-report"

export type WorkbookReportRequest = {
  type: WorkbookReportTypeName
  query: string
  data: any
}

export class WorkbookEntityStore {
  // injections

  // constructor

  constructor(config: {
    id: string
    request: WorkbookReportRequest
    retryAttempt?: number
  }) {
    this.id = config.id
    this.timestamp = Date.now()

    this.retryAttempt = config.retryAttempt ?? 0

    this.request = { ...config.request }

    makeAutoObservable(this)
  }

  // state

  readonly id: string

  readonly retryAttempt: number

  request: WorkbookReportRequest

  data: Entity | null = null

  timestamp: number

  isLoading: boolean = false

  error: ErrorMessage | null = null

  // actions

  setData = (data: Entity) => {
    this.isLoading = false
    this.data = data
  }

  setError = (message: string | null, status: string = "UNEXPECTED_ERROR") => {
    this.isLoading = false
    this.error = message
      ? {
          message,
          status,
        }
      : null
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading
  }
}

export default WorkbookEntityStore
