import React from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import RadioItem from "@components/prototypes/RadioItem/RadioItem"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import {
  CompanyWithMetaInfo,
  CreateCompanyRequest,
} from "@framework/types/company"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"

import { ModalsTypes } from "../constants"

import styles from "./AddManufacturerModal.module.sass"

const validationSchema = Yup.object({
  name: Yup.string().required("Manufacturer name is required"),
  url: Yup.string().url("Invalid URL format").required("Website is required"),
})

export type AddManufacturerModalProps = {
  selectedCompany?: CompanyWithMetaInfo
}
const AddManufacturerModal: React.FC<AddManufacturerModalProps> = observer(
  ({ selectedCompany }) => {
    const modal = useModal(ModalsTypes.ADD_MANUFACTURER_MODAL)
    const { companyStore } = useStore()
    const { companyController } = useController()
    const { loading } = companyStore
    const alert = useAlert()

    const formik = useFormik({
      initialValues: {
        name: selectedCompany?.name || "",
        url: selectedCompany?.url || "",
        isCompetitor: selectedCompany?.isCompetitor || false,
      },
      validationSchema,
      onSubmit: async (values) => {
        // extract only updated fields
        const updatedFields = Object.fromEntries(
          Object.entries(values).filter(
            ([key, value]) =>
              value !== formik.initialValues[key as keyof typeof values]
          )
        ) as Partial<CreateCompanyRequest>

        const isSuccess = await companyController.createOrUpdateCompany(
          updatedFields as CreateCompanyRequest,
          selectedCompany?.id
        )
        if (isSuccess) {
          alert.success(
            `Manufacturer ${
              selectedCompany?.id ? "updated" : "added"
            } successfully`
          )
          companyController.requestCompaniesReload()
          modal.hideModal()
        } else {
          alert.error(
            `Failed to ${selectedCompany?.id ? "update" : "add"} Manufacturer`
          )
        }
      },
    })

    return (
      <BaseModal
        className={styles.root}
        title={
          <Text variant="h2" align="center" className={styles.header}>
            Add Manufacturer
          </Text>
        }
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <div className={styles.inputContainer}>
              <Text variant="body2">Manufacturer Name</Text>
              <FormTextInput
                name="name"
                placeholder="Enter Manufacturer Name"
              />
            </div>
            <div className={styles.inputContainer}>
              <Text variant="body2">Website URL</Text>
              <FormTextInput name="url" placeholder="https://" />
            </div>
            <div className={styles.radioGroup}>
              <Text variant="body2">Is this a competitor?</Text>
              <div>
                <RadioItem
                  label="Yes"
                  checked={formik.values.isCompetitor}
                  onClick={() => formik.setFieldValue("isCompetitor", true)}
                />
                <RadioItem
                  label="No"
                  checked={!formik.values.isCompetitor}
                  onClick={() => formik.setFieldValue("isCompetitor", false)}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                size="medium"
                variant="outlined"
                onClick={modal.hideModal}
              >
                Cancel
              </Button>
              <Button
                disabled={loading}
                after={loading && <Loader size="small" />}
                type="submit"
                size="medium"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </form>
        </FormikProvider>
      </BaseModal>
    )
  }
)

export default AddManufacturerModal
