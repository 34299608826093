import { useFormik } from "formik"
import isEqual from "lodash/isEqual"
import sortBy from "lodash/sortBy"
import without from "lodash/without"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import * as yup from "yup"

import { useController, useStore } from "@store"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import Button from "@components/ui/Button/Button"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import TextInput from "@components/ui/TextInput/TextInput"
import Text from "@components/ui/Typography/Text"
import { ApiKey } from "@framework/types/api-keys"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import styles from "./CreateApiKeyModal.module.sass"

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Field is required")
    .min(3, "Must be at least 3 chars length")
    .default("")
    .trim(),
  selectedSolutions: yup
    .array()
    .of(yup.string())
    .min(1, "At least one must be selected")
    .default([]),
})

export type CreateApiKeyFormData = yup.InferType<typeof validationSchema>

export interface CreateApiKeyModalProps {
  apiKey?: ApiKey
  onSubmit?: (form: CreateApiKeyFormData) => Promise<void>
  onHide?: () => void
}

const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = observer(
  ({ apiKey, onSubmit, onHide }) => {
    const { hideModal } = useModal(ModalsTypes.CREATE_API_KEY_MODAL)

    const {
      solutionsStore: {
        allSolutions,
        isAllSolutionsLoading: allSolutionsLoading,
      },
    } = useStore()

    const { solutionsController } = useController()

    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (form: CreateApiKeyFormData) => {
      setLoading(true)
      await onSubmit?.(validationSchema.cast(form))
      setLoading(false)
    }

    const handleClose = () => {
      hideModal()
      onHide?.()
    }

    const formik = useFormik<CreateApiKeyFormData>({
      initialValues: validationSchema.cast(
        {
          name: apiKey?.name,
          selectedSolutions: apiKey?.permissions,
        },
        {
          stripUnknown: true,
        }
      ),
      validateOnMount: true,
      validationSchema,
      onSubmit: handleSubmit,
    })

    const disabled =
      isLoading ||
      (formik.values.name === (apiKey?.name ?? "") &&
        isEqual(
          sortBy(formik.values.selectedSolutions),
          sortBy(apiKey?.permissions) ?? []
        ))

    const { selectedSolutions } = formik.values

    const handleSolutionsSelect = (solution: string) => {
      const newValue = selectedSolutions.includes(solution)
        ? without(selectedSolutions, solution)
        : [...selectedSolutions, solution]

      formik.setFieldValue("selectedSolutions", newValue)
    }

    useEffect(() => {
      if (!allSolutions) solutionsController.loadAllSolutions()
    }, [])

    return (
      <BaseModal
        title={<ModalTitle titleText="New API Key" />}
        onClose={handleClose}
        className={styles.root}
      >
        <form onSubmit={formik.handleSubmit}>
          <Templates.RollScript
            footerSocket={
              <ModalFooterContainer>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={disabled}
                  variant="contained"
                  color="primary"
                  type="submit"
                  after={isLoading && <Loader />}
                >
                  {!apiKey ? "Create" : "Update"}
                </Button>
              </ModalFooterContainer>
            }
            bodyClassName={styles.container}
          >
            <div className={styles.input}>
              <Text variant="caption2">NAME OF API KEY</Text>
              <TextInput
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter name of API key"
                withError={!!(formik.touched.name && formik.errors.name)}
                after={
                  formik.touched.name &&
                  formik.errors.name && (
                    <div className={styles.after}>
                      <ErrorChip
                        message={formik.errors.name}
                        messagePlacement="left"
                      />
                    </div>
                  )
                }
              />
            </div>
            <div className={styles.input}>
              <Text variant="caption2">CHOOSE SCOPES</Text>
              <hr className={styles.divider} />
              {allSolutionsLoading ? (
                <div className={styles.loader}>
                  <Loader size="medium" />
                </div>
              ) : (
                <div className={styles.solutionsContainer}>
                  <Text variant="h5" weight="bold">
                    Solution APIs
                  </Text>
                  <div className={styles.solutionList}>
                    {allSolutions?.map((solution) => (
                      <CheckboxWithLabel
                        key={solution.id}
                        label={solution.name}
                        checked={formik.values.selectedSolutions.includes(
                          solution.name
                        )}
                        onCheck={() => handleSolutionsSelect(solution.name)}
                        size="tiny"
                        className={styles.checkbox}
                      />
                    ))}
                    {formik.touched.selectedSolutions &&
                      formik.errors.selectedSolutions && (
                        <div className={styles.error}>
                          {formik.errors.selectedSolutions}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </Templates.RollScript>
        </form>
      </BaseModal>
    )
  }
)
export default CreateApiKeyModal
