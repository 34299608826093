import React, { useEffect } from "react"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import * as Yup from "yup"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import useQueryParams from "@components/hooks/useQueryParams"
import Select from "@components/ui/MultiSelect/Select"
import { Option } from "@framework/types/utils"

import styles from "./AddProductsModal.module.sass"

export interface AddProductsModalProps {}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required").default(""),
  manufacturer: Yup.string().required("Manufacturer is required").default(""),
  identifier: Yup.array(Yup.string().required().default(""))
    .required()
    .default([]),
  description: Yup.string().optional().default(""),
})

type FormData = Yup.InferType<typeof validationSchema>

const AddProductsModal: React.FC<AddProductsModalProps> = observer(() => {
  const addProductsModal = useModal(ModalsTypes.ADD_PRODUCTS_MODAL)

  const {
    contentManagerStore: {
      companies,
      loadingCompanies,
      loadingCategories,
      categories,
    },
    productComparisonStore: {
      productStore: {
        addingProduct,
        addProduct,
        updateActivePage,
        activePage,
        searchQuery,
        loadProducts,
        activeFilter,
      },
    },
  } = useStore()

  const { contentManagerController } = useController()

  const searchParams = useQueryParams()

  const manufacturerId = searchParams?.manufacturer || ""

  const closeModal = () => {
    addProductsModal.hideModal()
  }

  const alert = useAlert()

  const getProducts = () => {
    const companyIds = activeFilter.manufacturers.map((company) => company.id)

    loadProducts({
      pagination: {
        page: activePage,
      },
      filters: {
        search: searchQuery,
        companyIds,
        categoryIds: activeFilter.identifiers.map((category) => category.id),
      },
    })
  }

  const formik = useFormik<FormData>({
    initialValues: validationSchema.getDefault(),
    validationSchema,
    onSubmit: async (values) => {
      const data = await addProduct([
        {
          name: values.name,
          categoryIds: values.identifier,
          description: values.description,
          companyId: values.manufacturer,
        },
      ])

      if (data?.errors) {
        data.errors.forEach((error) => {
          alert.error(error.message)
        })
      }

      data?.success.forEach((success) => {
        alert.success(`Product ${success.name} created successfully`)
      })

      if (data.errors.length === 0) {
        if (activePage === 1) {
          getProducts()
        } else {
          updateActivePage(1)
        }

        formik.resetForm()
        addProductsModal.hideModal()
      }
    },
  })

  useEffect(() => {
    contentManagerController.getCompanies()

    return formik.resetForm()
  }, [])

  const companyOptions: Option[] = React.useMemo(() => {
    return (companies ?? []).map((category) => {
      return {
        name: category.id,
        value: category.name,
      }
    })
  }, [companies])

  const identifierOptions: Option[] = React.useMemo(() => {
    if (formik.values.manufacturer)
      return (categories ?? []).map((category) => {
        return {
          name: category.id,
          value: category.name,
        }
      })
    return []
  }, [formik.values.manufacturer, categories])

  return (
    <BaseModal
      title={<ModalTitle titleText="Add Product" />}
      onClose={closeModal}
      className={styles.root}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className={styles.formInputPaddingRight}>
            <Text variant="body2" className={styles.formInputHeading}>
              Product Name
            </Text>
            <FormTextInput
              value={formik.values.name}
              name="name"
              placeholder="Product Name"
              onChange={(name) => {
                formik.setFieldValue("name", name)
              }}
            />
          </div>
          <div className={styles.formInputPaddingLeft}>
            <Text variant="body2" className={styles.formInputHeading}>
              Manufacturer
            </Text>
            <Select
              placeholder="Select Manufacturer..."
              options={companyOptions}
              value={formik.values.manufacturer || null}
              onChange={(selectedCompanyId) => {
                formik.setFieldValue("identifier", [], false)
                formik.setFieldValue("manufacturer", selectedCompanyId)
                contentManagerController.getCategories(
                  selectedCompanyId || undefined
                )
              }}
              loading={loadingCompanies}
              withError={Boolean(
                formik.touched.manufacturer && formik.errors.manufacturer
              )}
            />
          </div>
          <div className={styles.fullWidthInput}>
            <Text variant="body2" className={styles.formInputHeading}>
              Identifier Name
            </Text>
            <Select
              isMulti
              clearable
              loading={loadingCategories}
              placeholder="Select Identifiers..."
              value={formik.values.identifier}
              onChange={(selectedItems) => {
                formik.setFieldValue("identifier", selectedItems)
              }}
              options={identifierOptions}
              withError={Boolean(
                formik.touched.identifier && formik.errors.identifier
              )}
              disabled={!formik.values.manufacturer}
            />
          </div>
          <div className={styles.fullWidthInput}>
            <Text variant="body2" className={styles.formInputHeading}>
              Product Description (optional)
            </Text>
            <TextAreaInput
              value={formik.values.description}
              placeholder="Add description here"
              onChange={(event) => {
                formik.setFieldValue(
                  "description",
                  (event.target as HTMLTextAreaElement).value
                )
              }}
            />
          </div>
          <div className={styles.footer}>
            <Button size="medium" variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              disabled={addingProduct}
              after={addingProduct && <Loader size="small" />}
              type="submit"
              size="medium"
              color="primary"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormikProvider>
    </BaseModal>
  )
})

export default AddProductsModal
