import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import TextResult from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/TextResult/TextResult"
import Text from "@components/ui/Typography/Text"
import { useController } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import {
  ContentCitationData,
  SearchCitationData,
} from "@store/search/search-citations.store"
import Markdown from "@components/ui/Typography/Markdown"

import ExpertAnswer from "./ExpertAnswer"

import styles from "./AnswerViewer.module.sass"

const DEFAULT_SCALE = 90

const isViewablePDF = (citation: ContentCitationData) => {
  return (
    citation?.sourceType != null &&
    ["public", "manual"].includes(citation.sourceType.toLowerCase()) &&
    citation.sourceURL?.endsWith(".pdf")
  )
}

const AnswerViewer: React.FC<{
  citation: SearchCitationData
  fullscreen: boolean
  onFullScreenToggle: (show: boolean) => void
}> = observer(({ citation, fullscreen, onFullScreenToggle }) => {
  const [scale, setScale] = React.useState<number>(DEFAULT_SCALE)
  const [pdfState, setPdfState] = React.useState<{
    isPdf: boolean
    pdfUrl: string
    loadingPdf: boolean
  }>({
    isPdf: false,
    pdfUrl: "",
    loadingPdf: false,
  })
  const { dataConnectorController } = useController()

  const handleScaleUp = React.useCallback(() => {
    setScale((currScale) => (currScale < 170 ? currScale + 10 : currScale))
  }, [])

  const handleScaleDown = React.useCallback(() => {
    setScale((currScale) => (currScale > 20 ? currScale - 10 : currScale))
  }, [])

  const getPdfUrl = React.useCallback(async () => {
    setPdfState((prevState) => ({ ...prevState, loadingPdf: true }))
    const res = await dataConnectorController.getSignedDataURL(
      citation?.answerSource?.source || ""
    )
    const { page } = citation?.answerSource || {}
    setPdfState({
      isPdf: true,
      pdfUrl: `${(res as any)?.data}${page ? `#page=${page}` : ""}`,
      loadingPdf: false,
    })
  }, [
    dataConnectorController,
    citation?.answerSource?.source,
    citation?.answerSource?.page,
  ])

  React.useEffect(() => {
    setScale(DEFAULT_SCALE)
    if (!citation?.isExpertAnswer && isViewablePDF(citation)) {
      setPdfState((prevState) => ({ ...prevState, isPdf: true, pdfUrl: "" }))
      getPdfUrl()
    } else {
      setPdfState({ isPdf: false, pdfUrl: "", loadingPdf: false })
    }
  }, [citation, getPdfUrl])

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {pdfState.isPdf ? (
          <div className={styles.pdfContainer}>
            {pdfState.loadingPdf ? (
              <Loader size="medium" />
            ) : (
              <iframe
                title={citation?.answerSource?.source}
                src={pdfState.pdfUrl}
              />
            )}
          </div>
        ) : citation?.url ? (
          <div className={styles.imageContainer}>
            <img
              style={{
                width: fullscreen ? `${scale}%` : "100%",
              }}
              src={citation?.url}
              alt={citation?.url}
            />
            <div className={styles.control}>
              <Icon
                onClick={() => onFullScreenToggle(!fullscreen)}
                className={styles.controlButton}
                name={fullscreen ? "minimize" : "maximize"}
              />
              {fullscreen && (
                <>
                  <Icon
                    onClick={handleScaleUp}
                    className={styles.controlButton}
                    name="zoom-in"
                  />
                  <Icon
                    onClick={handleScaleDown}
                    className={styles.controlButton}
                    name="zoom-out"
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {citation?.isExpertAnswer ? (
              <ExpertAnswer details={citation} />
            ) : (
              <Text className={styles.extractedTextHeading} variant="body1">
                Extracted Text
              </Text>
            )}

            {citation?.isExpertAnswer ? (
              <Markdown className={styles.textContent}>
                {citation?.description ?? ""}
              </Markdown>
            ) : (
              <TextResult
                className={styles.textContent}
                content={citation?.description}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
})

export default AnswerViewer
